<template>
  <b-container class="mt-5">
    <PageHeading class="mb-5">{{ $t('auth.login.headings.pageHeading') }}</PageHeading>

    <!-- Form -->
    <b-form class="mb-5" id="login-form" @submit="onSubmit">

      <!-- Email -->
      <FormInput
          :label="$t('auth.login.email.label')"
          :editable="true"
          :description="$t('auth.login.email.description')"
          :placeholder="$t('auth.login.email.placeholder')"

          class="my-3"
          v-model="form.email"
      />

      <!-- Password -->
      <FormInput
          v-model="form.password"
          :editable="true"
          :label="$t('auth.login.password.label')"
          :placeholder="$t('auth.login.password.placeholder')"

          class="my-3"
          type="password"
          field="password"
      />

      <!-- Submit -->
      <Button :loading="loading" type="submit" variant="primary">{{ $t('auth.login.submit.label') }}</Button>

    </b-form>

    <!-- Go to register -->
    <div class="div">
      <p>
        <router-link to="request-password">{{ $t('auth.login.forgotPassword') }}</router-link>
      </p>
      <p>{{ $t('auth.login.noAccountYet') }}</p>
    </div>
  </b-container>
</template>

<script>
import JWT from '../../helper/jwt'
import NetworkAuth from '../../helper/network/NetworkAuth'
import FormInput from '../../components/base/input/FormInput'
import Button from '../../components/base/button/Button'
import user from '../../helper/user'
import { toaster } from '@/helper/mixins/toaster'
import PageHeading from '../../components/base/typography/PageHeading'

export default {
  components: { PageHeading, Button, FormInput },
  mixins: [toaster],
  data () {
    return {
      form: {
        email: '',
        password: '',
        is_admin: true, // tells the api whether it's the emplify or the customer frontend
      },
      loading: false,
    }
  },
  created () {
    // This removes the user from the local storage
    // and the user's email address is no longer displayed in the top right corner
    // when the user is logged out.
    user.deleteStoredUser()
  },
  methods: {
    async onSubmit (evt) {
      evt.preventDefault()
      try {
        this.loading = true
        const { data } = await NetworkAuth.login(this.form)
        JWT.setToken(data)
        await this.$router.push('/')
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
