<template>
  <div>
    <!-- Circle image -->
    <div v-if="filePath" class="photo-wrapper circle">
      <div class="inner">
        <b-img class="photo" :src="filePath"/>
      </div>
      <DeleteButtonCircle @click.native.stop="$emit('remove')" class="photo-cancel-button" icon="times"/>
    </div>
    <vue-dropzone
        id="customdropzone"
        :class="{'hide-dropzone': filePath !== null}"
        ref="vueDropzone"
        :duplicateCheck="true"
        @vdropzone-files-added="addFile"
        @vdropzone-removed-file="removeFile"
        :destroyDropzone="false"
        :useCustomSlot=true
        :options="dropOptions">
      <div class="dropzone-custom-content">
        <p class="dropzone-custom-title">{{ title }}</p>
        <font-awesome-icon size="3x" icon="file-upload"/>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DeleteButtonCircle from "./base/button/DeleteButtonCircle";

export default {
  components: {
    DeleteButtonCircle,
    vueDropzone: vue2Dropzone
  },
  props: {
    // if it is true, remove all files
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
      type: String
    },
    showThumbnails: {
      required: false,
      type: Boolean,
      default: false
    },
    existingFile: {
      required: false,
      default: null,
    },
    /**
     * Dropzone
     */
    preventRemoveEvent: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      files: [],
      filePath: null,
      // need two flags to prevent remove event
      preventRemoveEvent2: false,
      dropOptions: {
        // important : set autoProcessQueue on false
        autoProcessQueue: false,
        url: 'http://localhost',
        maxFilesize: 2, //MB
        maxFiles: 1,
        addRemoveLinks: true,
        thumbnailMethod: 'crop',
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        createImageThumbnails: this.showThumbnails,
        acceptedFiles: 'image/jpeg, image/png',
        dictRemoveFile: 'Datei entfernen',
        init: function () {
          this.on('resetFiles', function () {
            this.removeAllFiles();
          });
        }
      }
    }
  },
  created() {
    this.filePath = this.existingFile
  },
  watch: {
    /**
     * v-model is used to remove all files
     */
    value(newValue) {
      if (newValue) {
        this.preventRemoveEvent2 = true
        this.$refs.vueDropzone.removeAllFiles()
        this.$emit('input', false)
      }
    },
    /**
     * reset filePath and files
     * @param newValue
     */
    existingFile(newValue) {
      // If it is a blob, the image was just uploaded through the dropzone
      if (newValue && newValue.startsWith("blob")) {
        this.filePath = null
      } else {
        this.preventRemoveEvent2 = true
        this.$refs.vueDropzone.removeAllFiles()
        this.filePath = newValue
      }
      this.files = []
    }
  },
  methods: {
    getType() {
      if (this.existingFile.includes(".jpg") || this.existingFile.includes(".jpeg")) {
        return "image/jpeg"
      }
      if (this.existingFile.includes(".png")) {
        return "image/png"
      }
      return ""
    },
    addFile(files) {
      let filesLength = this.files.length;
      if (this.existingFile) {
        filesLength++;
      }
      if (filesLength >= this.dropOptions.maxFiles) {
        return
      }
      for (let file of files) {
        this.files.push(file)
      }
      this.sendFiles()
    },
    removeFile(file) {
      // if change user in UserDetail.vue or if change existing-file don't fire remove event
      if (this.preventRemoveEvent || this.preventRemoveEvent2) {
        this.preventRemoveEvent2 = false
        return
      }
      let index = this.files.indexOf(file)
      if (index >= 0) {
        this.files.splice(index, 1)
      }
      this.$emit("remove", this.files.length > 0 ? this.files[0] : null)
    },
    sendFiles() {
      let size = 0;
      for (let file of this.files) {
        size += file.size
      }
      let fileToBig = (size > (1000 * this.dropOptions.maxFilesize) * 1024)
      this.$emit("file", this.files.length > 0 ? this.files[0] : null, fileToBig)
    },
  }
}
</script>

<style scoped>

.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  text-transform: none;
}

#customdropzone >>> .dz-progress {
  /* progress bar covers file name */
  display: none !important;
}

#customdropzone >>> .dz-error-message {
  /* progress bar covers file name */
  display: none !important;
}

#customdropzone >>> .dz-filename {
  /* file name covers remove Button */
  display: none !important;
}

.hide-dropzone {
  display: none;
}

/* General */

.photo-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: .2s;
}

.photo-wrapper .photo-cancel-button {
  position: absolute;
  transform: scale(0);
  transition: .2s;
}

/* Regular */

.photo-wrapper.regular {
}

.photo-wrapper.regular .photo {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.photo-wrapper.regular .photo-cancel-button {
  top: -12px;
  right: -12px;
}

.photo-wrapper.regular:hover .photo-cancel-button {
  transform: scale(1);
}

/* Circle */

.photo-wrapper.circle {
  position: relative;
}

.photo-wrapper.circle .inner {
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.photo-wrapper.circle .inner .photo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100%;
}

.photo-wrapper.circle:after { /* Overlay */
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  transition: .2s;
}

.photo-wrapper.circle:hover:after {
  background-color: rgba(255, 255, 255, .3);
}

.photo-wrapper.circle .photo-cancel-button {
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
}

.photo-wrapper.circle:hover .photo-cancel-button {
  transform: scale(1);
}

</style>