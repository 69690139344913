import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkCategory extends NetworkHelper {
  static getCategories(config) {
    return axios.get('/api/category', config);
  }

  static getCategory(category, config) {
    return axios.get(`/api/category/${category.id}`, config);
  }

  static updateCategory(category, config) {
    return axios.put(`/api/category/${category.id}`, category, config);
  }

  static createCategory(category, config) {
    return axios.post('/api/category', category, config);
  }

  static deleteCategory(category, config) {
    return axios.delete(`/api/category/${category.id}`, config);
  }

  static deleteMultipleCategories(categories, config) {
    return axios.delete(`/api/category`, {data: {categories}, ...config});
  }
}
