<template>
  <div>
    <Collapsible id="toggle-job-posting-campaign" :visible="true">
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingCampaign.heading') }}</template>
      <template slot="content">
        <b-table :items="jobPostingSel.campaigns" :fields="fields" bordered hover>
          <!-- cell for the campaign orders -->
          <template #cell(orders)="data">
            <ul>
              <li v-for="(order, i) of data.item.orders" :key="i">{{ order.product.name }}</li>
            </ul>
          </template>
        </b-table>
      </template>
    </Collapsible>

  </div>
</template>

<script>

import Collapsible from "../../../components/base/Collapsible";

export default {
  name: "JobPostingCampaign",
  components: {Collapsible},
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'name',
          label: this.$t('jobPosting.detail.jobPostingCampaign.campaign'),
        },
        {
          key: 'orders',
          label: this.$t('jobPosting.detail.jobPostingCampaign.orders'),
        },
      ],
    }
  }, computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
  }
}
</script>

<style scoped>

</style>