import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkLog extends NetworkHelper {

  static getLogs(config) {
    return axios.get('/api/changes', config);
  }

}
