<template>
    <div class="company-user">
        <Collapsible id="toggle-company-detail-user">
            <template slot="heading">{{$t('company.users.heading')}}</template>
            <template slot="content">
                <!-- Users belonging to company -->
                <b-table
                        :items="usersComputed"
                        :fields="fields"
                        :bordered="true"
                        :responsive="true"
                >
                    <!-- Default -->
                    <template #cell()="data">
                        <div class="text-nowrap">{{data.value}}</div>
                    </template>
                    <template #cell(administrator)="data">
                        <FormInput v-model="data.value" checkable disabled/>
                    </template>
                </b-table>
            </template>
        </Collapsible>
    </div>
</template>

<script>
  import FormInput from '../../components/base/input/FormInput';
  import Collapsible from '../../components/base/Collapsible';

  export default {
    components: {Collapsible, FormInput},
    data() {
      return {
        fields: [
          {
            key: 'email',
            label: this.$t('user.fields.email'),
          },
          {
            key: 'salutation',
            label: this.$t('user.fields.salutation'),
          },
          {
            key: 'firstname',
            label: this.$t('user.fields.firstname'),
          },
          {
            key: 'lastname',
            label: this.$t('user.fields.lastname'),
          },
          {
            key: 'administrator',
            label: this.$t('user.fields.administrator'),
          },
        ],
      };
    },
    computed: {
      company() {
        return this.$store.state.company.selectedItem
      },
      companyComputed() {
        return Object.assign({}, this.company);
      },
      usersComputed() {
        return this.companyComputed.users;
      },
    },
  };
</script>

<style scoped>
</style>
