<template>
  <b-modal
      v-model="modelHelper"
      id="company-test-rule-extension-modal"
      centered
      hide-footer
      :title="$t('company_rule_extensions.affected_jobs')"
      size="xl"
      no-close-on-backdrop
  >
    <!-- Pagination -->
    <div v-if="query">
      <b-col class="flex-grow-0">
        <b-pagination v-model="query.current_page" :total-rows="query.total" :per-page="query.per_page"
                      @change="onPaginateChange"/>
        <span>{{ this.$t('general.pagination.jobPosting') + ': ' }}</span>
        <span id="count-job-posting">{{ query.total }}</span>
      </b-col>
    </div>
    <b-table
        id="affected-rules-table"
        class="position-relative"
        :items="jobPostings"
        :fields="jobPostingsFields"
        :responsive="true"
        :bordered="true"
        ref="dataTable"
    >

    </b-table>
  </b-modal>
</template>
<script>


import {toaster} from "@/helper/mixins/toaster";
import NetworkCompany from "@/helper/network/NetworkCompany";

export default {
  components: {},
  props: {
    company: {
      type: Object,
      required: true
    },
    ruleId: {
      type: Number,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  mixins: [toaster],
  data() {
    return {
      loading: false,
      jobPostingsFields: [
        {
          key: 'company',
          label: this.$t('jobPosting.fields.company.label'),
          sortable: true,
        },
        {
          key: 'title',
          label: this.$t('jobPosting.fields.title.label'),
        },
        {
          key: 'referencenumber',
          label: this.$t('jobPosting.fields.refnum.label'),
          sortable: true,
        },
        {
          key: 'city',
          label: this.$t('jobPosting.fields.city.label'),
        },
        {
          key: 'category',
          label: this.$t('jobPosting.fields.category.label'),
        },
      ],
    };
  },
  computed: {

    jobPostings() {
      return this.$store.state.company.affectedJobpostings;
    },

    query() {
      return this.$store.state.company.queryTestRules;
    },
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    }
  },
  methods: {
    async onPaginateChange(pageNum) {
      const affectedJobpostings = await NetworkCompany.testRules(this.company.id, this.ruleId, {params: {page: pageNum}})
      this.$store.commit('company/setAffectedJobpostings', affectedJobpostings.data);
    }
  },
}
</script>

<style scoped>


</style>
