<template>
  <b-navbar toggleable="lg" id="top-navbar" type="dark">
    <b-navbar-brand to="/">
      <img src="@/assets/logos/emplify_Logo_invertiert125_40.png"
           height="40"
           class="d-inline-block align-top" alt="emplify GmbH Logo"/>
    </b-navbar-brand>
    <!-- Toggle button -->
    <b-navbar-nav v-if="hasSidebar" class="ml-5">
      <Button
          @click="onSidebarToggle"
          :class="{'active': value}"
          class="text-white"
          variant="outline-secondary"
          icon="bars"
          icon-only
      />
    </b-navbar-nav>
    <!-- Logout button -->
    <b-navbar-nav class="ml-auto">
      <b-nav-item @click="logout" v-if="showLogout">
        <Button icon="sign-out-alt" variant="outline_secondary" class="logout-button text-white-50">
          {{ $t('general.button.logout') }}
        </Button>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import NetworkAuth from '../../helper/network/NetworkAuth'
import User from '../../helper/user'
import Button from '@/components/base/button/Button'

export default {
  components: { Button },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data () {
    return {
      user: null,
    }
  },
  computed: {
    hasSidebar () {
      return this.$route.meta.hasSidebar
    },
    showLogout() {
      // Cheap solution for detecting if a user is logged in.
      // Using the User helper would not work since something needs to trigger the
      // update of the computed property.
      return this.$route.path !== "/login" && this.$route.path !== "/request-password";
    }
  },
  methods: {
    logout () {
      NetworkAuth.logout().then(() => {
        this.user = null
        User.deleteStoredUser()
        this.$router.push('/login')
      })
    },
    onSidebarToggle () {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

#top-navbar {
  position: relative;
  background-color: $dark;
  z-index: 1040; /* Magic number that lays above the sidebar but leaves the toasts still visible. */

  .logout-button {
    border: none;

    &:hover {
      color: white !important;
    }
  }
}
</style>
