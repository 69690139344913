<template>
  <router-link class="sidebar-link" :to="to" @click.native="onClick">
    <!-- Icon -->
    <font-awesome-icon v-if="icon" :icon="icon" fixed-width class="mr-3"/>
    <!-- Link text -->
    <slot/>
  </router-link>
</template>

<script>
export default {
  props: {
    /**
     * The router object or the route name to navigate to.
     */
    to: {
      required: false,
      type: [Object, String],
      default: '',
    },
    /**
     * If an icon is set it is displayed next to the text.
     */
    icon: {
      required: false,
      type: String,
    },
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

.sidebar-link {
  position: relative;
  padding: 1rem 1.5rem;
  display: block;
  color: rgba(255, 255, 255, .6);
  text-decoration: none !important;
  font-size: 16px;
  @include transition;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: .5rem;
    @include transition;
  }

  &:hover,
  &:hover:before,
  &.router-link-active,
  &.router-link-active:before {
    width: 100%;
  }

  &:hover,
  &.router-link-active {
    color: white;
    width: 100%;
  }
}

</style>
