import NetworkHelper from './NetworkHelper';
import axios from 'axios';

export default class NetworkXmlFeed extends NetworkHelper {

    static getXmlFeeds(config) {
        return axios.get('/api/xmlfeed', config);
    }

    static activateOrInactivateXmlFeed(xmlFeedId, active, config) {
        return axios.put(`/api/xmlfeed/${xmlFeedId}/active`, {active: active}, config);
    }

    static updateXmlFeedTMParameters(xmlFeedId, utmParameters, config) {
        return axios.post(`/api/utmparameter`,
            {xmlFeed_id: xmlFeedId, utm_parameters: utmParameters}, config);
    }
}
