<template>
  <b-modal :id="id" :title="$t('filemanager')" size="xl" centered hide-footer>
    <!-- Add new files -->
    <b-form-file
        v-model="filesToUpload"
        :placeholder="$t('general.upload.placeholder')"
        :drop-placeholder="$t('general.upload.dropPlaceholder')"
        :browse-text="$t('general.upload.browseText')"
        multiple
    />

    <!-- Current Files -->
    <div class="mt-3">
      <div v-if="jobPostingFiles.length > 0">
        <b-list-group class="mb-2">
          <b-list-group-item v-for="file in jobPostingFiles" @click="handleClickOnFile(file)" :key="file.filename"
                             :class="{'active': file.isActive}" class="file-record p-2">
            <b-row class="align-items-center">
              <b-col class="flex-grow-1">
                <div class="mr-3">
                  <font-awesome-icon :icon="getIconForFile(file)" fixed-width class="mr-2"/>
                  <span>{{ file.filename }}</span><br>
                  <small>{{ file.updated_at }}</small>
                </div>
              </b-col>
              <b-col>
                <span>{{ $t('general.uploadedBy') }}</span><br>
                <span>{{ file.user_uploaded !== null ? file.user_uploaded : "-" }}</span>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <DeleteButtonCircle @click.native="onDeleteFile($event, file)"/>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <!-- Controls -->
        <div class="d-flex">
          <Button @click.native="downloadFilesZipped" icon="download" variant="primary" class="mr-2">
            {{ $t('general.button.downloadZip') }}
          </Button>
          <b-button :href="jobPostingSel.preview" variant="primary" target="_blank">
            <font-awesome-icon class="mr-2" icon="external-link-alt"/>
            {{ $t('general.button.viewJob') }}
          </b-button>
        </div>
      </div>
      <div v-else class="d-flex align-items-center">
        <font-awesome-icon icon="exclamation-triangle" fixed-width class="mr-2"/>
        <span>{{ $t('jobPosting.detail.jobPostingFiles.preview.noFilesFound') }}</span>
      </div>
    </div>

    <div v-if="loading" class="mt-3">
      <b-spinner/>
    </div>
    <div v-else class="mt-3">
      <!-- Code editor / Preview -->
      <div v-if="showPreview">
        <p v-if="selectedFile">{{ selectedFile.filename }}</p>
        <!-- If file is of type html display code editor -->
        <!--                <prism-editor style="font-size: 14px !important;" v-if="html" v-model="html"-->
        <!--                              language="html"></prism-editor>-->
        <editor v-if="html" v-model="html" @init="editorInit" lang="html" theme="chrome" width="100%"
                height="60vh"></editor>
        <!-- Else if file is of type image display the image (base64 encoded) -->
        <b-img v-else-if="imgSrc" :src="imgSrc" fluid/>
        <!-- Show default text -->
        <span v-else>{{ $t('jobPosting.detail.jobPostingFiles.preview.noFileSelected') }}</span>
      </div>
      <!-- Controls (only visible when html file is edited) -->
      <div v-if="html" class="d-flex justify-content-between align-items-center mt-3">
        <!-- Save button -->
        <Button
            @click.native="saveHtml"
            :loading="loadingSaveHtmlFile"
            icon="save"
            variant="success"
            class="mt-2"
        >
          {{ $t('general.button.save') }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DeleteButtonCircle from '../../../components/base/button/DeleteButtonCircle';
import NetworkJobPosting from '../../../helper/network/NetworkJobPosting';
import Button from '../../../components/base/button/Button';
import {toaster} from '@/helper/mixins/toaster';
import Editor from 'vue2-ace-editor';
import {convertToFormData} from '@/helper/utility'
import NetworkAuth from "../../../helper/network/NetworkAuth";

export default {
  components: {Button, DeleteButtonCircle, Editor},
  mixins: [toaster],
  props: {
    id: {
      required: false,
      type: String,
      default: 'file-manager'
    },
  },
  created() {
    this.getJobPostingFiles();
  },
  data() {
    return {
      loading: false,
      loadingSaveHtmlFile: false,
      showPreview: false,
      jobPostingFiles: [], // files that come with job posting when fetching
      filesToUpload: [],
      selectedFile: null, // the currently selected file in the file manager
      selectedFileContents: null, // contents of the selected file (necessary for html)
      html: null, // html to display in the editor
      imgSrc: null, // image src converted to base64
    };
  },
  watch: {

    /**
     * As filesToUpload changes, check if the user added or removed a file.
     * If added, upload the file(s).
     * Else do nothing.
     */
    filesToUpload(files) {
      if (files.length > 0) this.submit();
    },
    /**
     * When the user selects a file in the file manager this watcher is triggered.
     * If the file is of type image fetch the image and convert it to base64
     * to display it in the preview section.
     */
    selectedFile(file) {
      // Reset data
      this.resetImgSrc();
      this.resetHTML();

      if (!file) {
        // file was deselected (e.g. preview closed)
        return;
      } else {
        this.showPreview = true;
      }

      if (this.isImageFile(file.filename)) {
        // Is image file
        NetworkJobPosting.getJobPostingFile(this.jobPostingSel, file, {responseType: 'arraybuffer'}).then(res => this.imgSrc = 'data:' + res.headers['content-type'] + ';base64,' +
            Buffer.from(res.data).toString('base64')).catch(err => this.showErrorMessageFromErr(err));
      } else if (this.isHtmlFile(file.filename) >= 0) {
        // Is html file
        NetworkJobPosting.getJobPostingFile(this.jobPostingSel, file).then(res => this.html = res.data).catch(err => console.log(err));
      } else {
        // Is other file type
      }
    },
    jobPostingSel() {
      this.getJobPostingFiles();
    },
  },
  computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
  },
  methods: {
    convertToFormData,
    /**
     * Upload the files added by the user.
     */
    async submit() {
      this.loading = true;
      // Create promises (one for each file to upload)
      try {
        // Get user
        const {data} = await NetworkAuth.getUserdata()
        for (const file of this.filesToUpload) {
          const formData = this.convertToFormData({file: file});
          await NetworkJobPosting.uploadFile(this.jobPostingSel, formData, data.data.email);
        }
        // Display success message or error message to the user
        this.resetJobPostingFiles();
        this.getJobPostingFiles();
        this.showMessage({
          title: this.$t('general.upload.success.title'),
          message: this.$t('general.upload.success.message'),
          variant: 'success',
        });
        await this.$store.dispatch('jobPosting/fetchJobPosting', this.jobPostingSel)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handle delete process of a file.
     */
    async onDeleteFile(e, file) {
      e.stopPropagation();
      try {
        const res = await NetworkJobPosting.deleteFile(this.jobPostingSel, file)
        this.showSuccessMessageFromRes(res);
        this.getJobPostingFiles();
        await this.$store.dispatch('jobPosting/fetchJobPosting', this.jobPostingSel)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      }
    },
    /**
     * Save the edited html.
     */
    async saveHtml() {
      const {data} = await NetworkAuth.getUserdata()
      let userEmail = data.data.email
      this.loadingSaveHtmlFile = true;
      NetworkJobPosting.updateHTMLFile(this.jobPostingSel, this.selectedFile, this.html, userEmail).then(res => {
        console.error(res)
        this.showSuccessMessageFromRes(res);
        this.html = res.data.data;
        this.updateJobPostingFile(this.selectedFile, userEmail);
      }).catch(err => this.showErrorMessageFromErr(err)).then(() => this.loadingSaveHtmlFile = false);
    },

    /**
     * update "hochgeladen von" email
     */
    updateJobPostingFile(file, userEmail) {
      for (const jobPostingFile of this.jobPostingFiles) {
        if (jobPostingFile.id === file.id) {
          jobPostingFile.user_uploaded = userEmail;
          break;
        }
      }
    },
    /**
     * Handle the click on a file in the list group items of the file manager.
     * @param file
     */
    handleClickOnFile(file) {
      // Store the active state of the file when clicked
      const fileIsActive = file.isActive;
      // Deselect all files
      this.jobPostingFiles.map(f => f.isActive = false);
      if (!fileIsActive) {
        // File was selected
        file.isActive = true;
        // Fetch the file contents for the UI
        this.getJobPostingFile(file);
      } else {
        this.selectedFile = null;
      }
    },
    downloadFilesZipped() {
      NetworkJobPosting.downloadFilesZipped(this.jobPostingSel);
    },
    /**
     * Get the type of a given file.
     */
    getType(filename) {
      const matches = filename.match(/\.[0-9a-z]+$/);
      if (matches && matches.length > 0) {
        return matches[0].substring(1);
      } else {
        return null;
      }
    },
    /**
     * Check if a file is of one of the given types.
     * @param filename The name of the file.
     * @returns {boolean} True if is of one of the given types. False otherwise.
     */
    isImageFile(filename) {
      const fileType = this.getType(filename);
      return ['jpg', 'jpeg', 'png'].includes(fileType);
    },
    /**
     * Check if a file is an html file.
     * @param filename The name of the file.
     * @returns {boolean} True if is html. False otherwise.
     */
    isHtmlFile(filename) {
      const fileType = this.getType(filename);
      return ['html'].includes(fileType);
    },
    /**
     * Get the name of the icon for a specific file.
     * This is used to display the correct icon in the file list.
     */
    getIconForFile(file) {
      return this.isImageFile(file.filename) ? 'image' : 'file';
    },
    /**
     * Set job posting files to empty array.
     */
    resetJobPostingFiles() {
      this.jobPostingFiles = [];
    },
    /**
     * Get job posting files.
     */
    getJobPostingFiles() {
      NetworkJobPosting.getJobPostingFiles(this.jobPostingSel).then(res => this.jobPostingFiles = res.data.data).catch(err => this.showErrorMessageFromErr(err)).then(() => this.resetSelectedFile());
    },
    /**
     * Get contents for specific job posting file.
     * @param file
     */
    getJobPostingFile(file) {
      this.loading = true;
      this.selectedFile = file;
      NetworkJobPosting.getJobPostingFile(this.jobPostingSel, file).then(res => this.selectedFileContents = res.data).catch(err => console.error(err)).then(() => this.loading = false);
    },
    /**
     * Reset the images src data.
     */
    resetImgSrc() {
      this.imgSrc = null;
    },
    /**
     * Reset the html data.
     */
    resetHTML() {
      this.html = null;
    },
    /**
     * Reset the selected file data.
     */
    resetSelectedFile() {
      this.selectedFile = null;
    },
    editorInit: function (editor) {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/html');
      require('brace/mode/javascript');    //language
      require('brace/mode/less');
      require('brace/theme/chrome');
      require('brace/snippets/javascript'); //snippet
      editor.session.setOption("wrap", true); // soft line wraps
    },
  },
}
;
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 95% !important;
}

.file-record {
  cursor: pointer;

  &:hover {
    background-color: var(--emp-light-bg);
  }

  &.active {
    color: white;
    background-color: var(--emp-secondary);
  }
}
</style>
