<template>
    <Collapsible id="company-crawler">
        <template slot="heading">{{$t('company.crawlers.heading')}}</template>
        <template slot="content">
            <b-table
                    :items="company.crawlers"
                    :fields="fields"
                    :responsive="true"
                    :bordered="true"
                    :show-empty="true"
            >
                <template #cell(url)="data">
                    <a :href="data.item.url">{{data.item.url}}</a>
                </template>
            </b-table>
        </template>
    </Collapsible>
</template>

<script>
  import Collapsible from '../../components/base/Collapsible';
  export default {
    components: {Collapsible},
    computed: {
      company() {
          return this.$store.state.company.selectedItem
        }
    },
    data() {
      return {
        fields: [
          {
            key: 'name',
            label: this.$t('company.crawlers.fields.name.label'),
          },
          {
            key: 'url',
            label: this.$t('company.crawlers.fields.url.label'),
          },
          {
            key: 'publisher',
            label: this.$t('company.crawlers.fields.publisher.label'),
          },
          {
            key: 'last_crawling',
            label: this.$t('company.crawlers.fields.last_crawling.label'),
          },
        ]
      }
    },
  };
</script>

<style scoped>

</style>
