export const toaster = {
  methods: {
    /**
     * Display a toast with a success message in case the network request was successful.
     * @param res
     */
    showSuccessMessageFromRes(res) {
      const config = {
        title: this.$t('general.toast.title.success'),
        message: res.data.message,
        variant: 'success',
        autoHideDelay: 5000, // this is the default value for auto hide
      };
      this.showMessage(config);
    },
    showErrorMessageFromErr(err) {
      const config = {
        title: this.$t('general.toast.title.error'),
        message: this.errorMessage(err),
        variant: 'danger',
        autoHideDelay: 1000 * 60 * 60 * 24, // auto hide after one day ;)
      };
      this.showMessage(config);
    },
    showMessage(config) {
      this.$bvToast.toast(config.message, {
        title: config.title,
        variant: config.variant,
        autoHideDelay: config.autoHideDelay,
      });
    },
    errorMessage(err) {
      const h = this.$createElement
      let msg = [h('b', err.response.data.message)]
      if (err.response.data.errors && typeof err.response.data.errors !== 'string') {
        let list = [];
        for (let error in err.response.data.errors) {
          list.push(h('li', err.response.data.errors[error]));
        }
        msg.push(h('ul', [list]));
      }
      return msg
    }
  },
};
