import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkAuth {

  static getUserdata() {
    return axios({
      method: 'post',
      url: '/api/auth/me',
      headers: NetworkHelper.buildHeaders(),
    });
  }

  static login(params, config) {
    return axios.post('/api/auth/login', params, config);
  }

  static logout(params, config) {
    return axios.post('/api/auth/logout', params, config);
  }

  static refreshToken() {
    return axios({
      method: 'post',
      url: '/api/auth/refresh',
      headers: NetworkHelper.buildHeaders(),
    });
  }

  static requestPassword(params, config) {
    return axios.post('/api/password/request', params, config);
  }

  static resetPassword(params, config) {
    return axios.post('/api/password/reset', params, config);
  }

  // TODO: Refresh token

}
