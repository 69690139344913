import NetworkHelper from './NetworkHelper';
import axios from 'axios';

export default class NetworkDashboard extends NetworkHelper {

  static getDashboardTiles(config) {
    return axios.get('/api/dashboardtile', config)
  }

}
