<template>
  <ViewContainer>
    <ViewToggle :show-detailed-view="selectedItem">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('order.pageHeading') }}</PageHeading>

          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton v-model="filter"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy="isBusy"
              :filter="filter"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :no-local-sorting="true"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('order.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('order.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.company"
                      :placeholder="$t('general.filter.company')"
                      type="search"

                      debounce="700"
                  />
                </b-th>
                <!-- Loop through filters defined in data -->
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.user_name"
                      :placeholder="$t('general.filter.user')"
                      type="search"

                      debounce="700"
                  />
                </b-th>
              </b-tr>
            </template>
            <!-- Head 'Selected' -->
            <template #head(selected)>
              <b-form-checkbox class="mx-0 d-block" @change="onToggleAll"/>
            </template>
            <!-- Cell 'Selected' -->
            <template #cell(selected)="data">
              <b-form-checkbox v-model="data.item[data.field.key]" class="mx-0 d-block"/>
            </template>
            <!-- Cell default -->
            <template #cell()="data">
              <div class="text-nowrap">{{data.value}}</div>
            </template>
          </b-table>
        </ViewElement>
      </template>

      <!-- Detailed View -->
      <template slot="detailed-view">
        <!-- Detail -->
        <ViewElement class="mb-3">
          <OrderDetail :order="selectedItem"/>
        </ViewElement>
        <!-- Job posting -->
        <ViewElement class="mb-3">
          <OrderJobPosting :order="selectedItem"/>
        </ViewElement>
        <!-- Product -->
        <ViewElement>
          <OrderProduct :order="selectedItem"/>
        </ViewElement>
      </template>
    </ViewToggle>
  </ViewContainer>
</template>

<script>
import {toaster} from '@/helper/mixins/toaster';
import ViewContainer from '../../../components/base/ViewContainer';
import ViewElement from '../../../components/base/ViewElement';
import PageHeading from '../../../components/base/typography/PageHeading';
import NetworkOrder from '@/helper/network/NetworkOrder';
import {paginate} from '@/helper/mixins/paginate';
import ViewToggle from '@/components/base/ViewToggle';
import OrderJobPosting from '@/views/product/order/OrderJobPosting';
import OrderProduct from '@/views/product/order/OrderProduct';
import OrderDetail from '@/views/product/order/OrderDetail';
import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
import PerPagePicker from '@/components/PerPagePicker'

export default {
  components: {
    PerPagePicker,
    RemoveFilterButton,
    OrderDetail,
    OrderProduct,
    OrderJobPosting,
    ViewToggle,
    PageHeading,
    ViewElement,
    ViewContainer,
  },
  mixins: [toaster, paginate],
  data() {
    return {
      // Loading state
      isBusy: false,
      // Item handling
      items: [],
      selectedItem: null,
      // Fields
      fields: [
        {
          key: "company",
          label: this.$t('order.fields.company.label'),
        },
        {
          key: "user_name",
          label: this.$t('order.fields.user_name.label'),
        }
      ]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      this.items = await NetworkOrder.getOrderOverview({params: this.getQuery()}).
          then(res => {
            this.totalItems = res.data.meta.total;
            this.isBusy = false;
            this.updateRoute();
            return res.data.data;
          });
    },
    onRowClicked(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        this.selectedItem = null;
      } else {
        this.selectedItem = Object.assign({}, item);
      }
    },
    onItemClosed() {
      this.selectedItem = null;
    },
  },
};
</script>
