<template>
  <!-- Modal place order -->
  <b-modal :id="id" :title="$t('general.component.orderApi.title')" size="lg" centered hide-footer>
    <!-- Jass Ids -->
    <b-form-group :label="$t('jass_id')" class="mb-4">
      <b-form-select v-model="jassIdSel" :options="jassIdsOptions"/>
    </b-form-group>
    <!-- Comment -->
    <b-form-group :label="$t('comment')" class="mb-4">
      <b-form-textarea v-model="jassComment"/>
    </b-form-group>
    <!-- Orders -->
    <p class="mb-2">{{ $t('orders_selected') }}</p>
    <b-list-group class="mb-4 max-height-list-group">
      <b-list-group-item v-for="order in ordersSel" :key="order.id">
        <div class="d-flex align-items-center justify-content-between">
          <div class="order-detail">
            <strong>{{ order.product.jobportal }}</strong><br>
            <span>{{ order.product.name }}</span>
          </div>
          <div>
            <b-badge v-if="order.budget" class="budget-list-item" variant="success">{{ order.budget + '€' }}</b-badge>
            <span v-else class="budget-list-item">-</span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <!-- Place order button -->
    <div class="d-flex justify-content-end">
      <Button :disabled="ordersSel.length === 0 || jassIdSel === null" :loading="loading" @click="onPlaceApiOrder" icon="shipping-fast" variant="success">
        {{ $t('general.button.order.api.place') }}
      </Button>
    </div>
  </b-modal>
</template>

<script>
import Button from '@/components/base/button/Button';
import NetworkBooking from '@/helper/network/NetworkBooking';
import {getSignificantValue} from '@/helper/utility';
import {toaster} from '@/helper/mixins/toaster';

export default {
  components: {Button},
  mixins: [toaster],
  props: {
    /**
     * Id used to toggle modal.
     */
    id: {
      required: false,
      type: String,
      default: 'order-api',
    },
    /**
     * The job postings
     */
    jobPosting: {
      required: true,
      type: Object,
    },

    /**
     * The orders selected.
     */
    ordersSel: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      jassIdSel: null,
      jassComment: '',
    };
  },
  computed: {
    jassIdsOptions () {
      const options = this.jobPosting.jass_ids.map(jass => ({ value: jass.jass_customer_id, text: jass.name }))
      options.unshift({ value: null, text: this.$t('please_select_an_option') })
      return options
    },
  },
  methods: {
    async onPlaceApiOrder() {
      try {
        // Set loading state
        this.loading = true;
        // Construct order object
        const orderIds = this.ordersSel.map(order => order.id);
        const res = await NetworkBooking.placeApiOrderForJobPosting(orderIds, this.jassIdSel, this.jassComment)
        // Reset jassIdSel
        this.jassIdSel = null
        this.jassComment = ''
        this.showSuccessMessageFromRes(res)
        this.$emit('order:placed');
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    },
    getSignificantValue,
  },
};
</script>

<style scoped>
.budget-list-item {
  font-size: 1em;
  font-weight: 900;
}
</style>
