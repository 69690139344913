<template>
    <div>
        <!-- Filter -->
        <FormInput v-model="filter" :placeholder="$t('general.filter.default')" class="mb-1" editable/>
        <!-- Table -->
        <b-table
                :items="itemsWithRowVariants"
                :fields="fields"
                :busy="loading"
                :responsive="true"
                :bordered="true"
                :hover="true"
                :filter="filter"
                :filter-function="productsFilter"
                @row-clicked="onRowClicked"
                class="pointer"
        />
    </div>
</template>

<script>
  import NetworkProduct from '../../helper/network/NetworkProduct';
  import FormInput from '../base/input/FormInput';

  export default {
    components: {FormInput},
    props: {
      /**
       * This prop represents the products, which are already part of the catalogue.
       * We need this prop to determine which rows of the products table should be marked green (this means they're
       * already part of the catalogue).
       */
      addedProducts: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        items: [],
        fields: [
          {
            label: this.$t('catalogue.fields.jobportal.name.label'),
            key: 'jobportal.name',
          },
          {
            label: this.$t('catalogue.fields.name.label'),
            key: 'name',
          },
        ],
        filter: null,
      };
    },
    created() {
      this.loading = true;
      NetworkProduct.getProducts({params: {perPage: 999}}).
          then(res => this.items = res.data.data).
          catch(err => console.error(err)).
          finally(() => this.loading = false);
    },
    computed: {
      /**
       * The rows which contain products that are part of the catalogue already should be marked.
       * Therefore this function uses the already filtered items.
       * @return {*}
       */
      itemsWithRowVariants() {
        return this.items.map(item => {
          if (this.addedProducts.length === 0) {
            delete item._rowVariant;
            return item;
          } else {
            for (const p of this.addedProducts) {
              if (p.id === item.id) {
                item._rowVariant = 'success';
                break;
              } else {
                delete item._rowVariant;
              }
            }
            return item;
          }
        });
      },
    },
    methods: {
      onRowClicked(item) {
        this.$emit('item:clicked', item);
      },
      prepare(string) {
        // Replace whitespaces and transform to lowercase.
        return string.replace(/\s/g, '').toLowerCase();
      },
      /**
       * Filter the procuts based on every word in the input string.
       * If one word of the input string doesn't match the filter function returns false.
       * @param item The original item.
       * @param value The value of the filter.
       */
      productsFilter(item, value) {
        const product = item.name.toLowerCase().replace(/\s/g, '');
        const jobPortal = item.jobportal.name.toLowerCase().replace(/\s/g, '');
        const combined = jobPortal + product;

        // Loop through every word in the filter
        const words = value.toLowerCase().split(' ');
        for (const word of words) {
          // If one word does not match, return false
          if (combined.indexOf(word) === -1)
            return false
        }

        return true;
      },
    },
  };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
