<template>
  <ViewContainer id="xml-feed">
    <ViewToggle :show-detailed-view="selectedItem">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('crawler.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton v-model="filter"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy.sync="isBusy"
              :filter.sync="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :no-local-sorting="true"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('crawler.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('crawler.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary"/>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.name"
                      :placeholder="$t('general.filter.name')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.url"
                      :placeholder="$t('general.filter.url')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.publisher"
                      :placeholder="$t('general.filter.publisher')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.command"
                      :placeholder="$t('general.filter.command')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.last_crawling"
                      :placeholder="$t('general.filter.last_crawling')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary"/>
              </b-tr>
            </template>
            <template #cell(toggle)="data">
              <b-form-checkbox v-model="data.item.active" switch :value="1" :unchecked-value="0"
                               class="align-self-center"
                               @click.native.prevent="confirmationDialog(data.item)">
                <b-spinner v-if="data.item.id === loadingCrawlerId" variant="primary" class="align-top"/>
                <span v-else>{{ data.item.active === 1 ? $t('general.active') : $t('general.inactive') }}</span>
              </b-form-checkbox>
            </template>
            <!-- Filters -->
            <template #cell(url)="data">
              <a :href="data.value" target="_blank" class="text-break">
                {{ getTrimmedText(data.value, 150) }}
              </a>
            </template>
          </b-table>
        </ViewElement>
      </template>
      <template slot="detailed-view">
        <ViewElement class="mb-3">
          <CrawlerDetail
              :crawler="selectedItem"
              @item:closed="onItemClosed"
              @crawler:run="onCrawlerRun"
          />
        </ViewElement>
        <ViewElement>
          <CrawlerReport
              :crawler="selectedItem"
          />
        </ViewElement>
      </template>
    </ViewToggle>
    <ConfirmationDialogWithUserInput v-if="selectedCrawler" v-model="openConfirmationDialog"
                                     @ok="activateOrInactivateCrawler"
                                     :text="$t('crawler.deleteConfirmation', { company: selectedCrawler.name, active: getActivateText(false)})"
                                     :title="selectedCrawler.name + ' ' + getActivateText(false) + '?'"
                                     :ok-titel="getActivateText(true)"/>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../../components/base/ViewContainer';
import ViewToggle from '../../../components/base/ViewToggle';
import ViewElement from '../../../components/base/ViewElement';
import PageHeading from '../../../components/base/typography/PageHeading';
import NetworkCrawler from '../../../helper/network/NetworkCrawler';
import {paginate} from '../../../helper/mixins/paginate';
import CrawlerReport from './CrawlerReport';
import CrawlerDetail from './CrawlerDetail';
import {toaster} from '../../../helper/mixins/toaster';
import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
import PerPagePicker from '@/components/PerPagePicker'
import ConfirmationDialogWithUserInput from "../../../components/dialog/ConfirmationDialogWithUserInput";

export default {
  components: {
    ConfirmationDialogWithUserInput,
    PerPagePicker,
    RemoveFilterButton, CrawlerDetail, CrawlerReport, PageHeading, ViewElement, ViewToggle, ViewContainer
  },
  mixins: [paginate, toaster],
  data() {
    return {
      fields: [
        {
          key: 'toggle',
          sortable: false,
          label: "",
        },
        {
          key: 'name',
          label: this.$t('crawler.fields.name.label'),
          sortable: true,
        },
        {
          key: 'url',
          label: this.$t('crawler.fields.url.label'),
          sortable: true,
        },
        {
          key: 'publisher',
          label: this.$t('crawler.fields.publisher.label'),
          sortable: true,
        },
        {
          key: 'command',
          label: this.$t('crawler.fields.command.label'),
          sortable: true,
        },
        {
          key: 'last_crawling',
          label: this.$t('crawler.fields.last_crawling.label'),
          sortable: true,
        },
        {
          key: 'amount_failed',
          label: this.$t('crawler.fields.failed'),
          sortable: true
        }
      ],
      // Item handling
      items: [],
      selectedItem: null,
      loadingCrawlerId: null,
      openConfirmationDialog: false,
      // Busy state
      isBusy: false,
    };
  },
  computed: {
    selectedCrawler: {
      set(value) {
        this.$store.commit("crawler/setSelectedCrawler", value);
      },
      get() {
        return this.$store.state.crawler.selectedCrawler;
      }
    }
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      try {
        const res = await NetworkCrawler.getCrawlers({params: this.getQuery()});
        this.totalItems = res.data.meta.total;
        this.updateRoute();
        this.items = res.data.data
        // if crawler failed x times, print row in specific color
        for (let item of this.items) {
          if (item.amount_failed > 5) {
            item._rowVariant = 'danger'
          } else if (item.amount_failed > 0) {
            item._rowVariant = 'warning'
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isBusy = false
      }
    },
    confirmationDialog(item) {
      this.selectedCrawler = item;
      this.openConfirmationDialog = true;
    },
    // text for confirmation dialog
    getActivateText(firstLetterUp) {
      if (firstLetterUp) {
        return this.selectedCrawler.active === 1 ? this.$t('general.Deactivate') : this.$t('general.Activate')
      }
      return this.selectedCrawler.active === 1 ? this.$t('general.deactivate') : this.$t('general.activate')
    },
    /**
     * activate or inactivate crawler
     * @param item
     * @returns {Promise<void>}
     */
    async activateOrInactivateCrawler() {
      if (this.selectedCrawler === null) {
        return;
      }
      this.loadingCrawlerId = this.selectedCrawler.id
      try {
        const res = await NetworkCrawler.activateOrInactivateCrawler(this.selectedCrawler.id, !this.selectedCrawler.active)
        this.showSuccessMessageFromRes(res);
      } catch (e) {
        console.error(e)
        this.showErrorMessageFromErr(e)
      } finally {
        this.loadingCrawlerId = null;
        this.selectedCrawler = null;
        await this.fetchData()
      }
    },
    /**
     * Fetch detailed crawler and replace the non-detailed crawler in the items array with the detailed one.
     * This is necessary to click between different crawlers and keep track of their loading state.
     * @param item
     */
    onRowClicked(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        this.selectedItem = null;
      } else {
        this.isBusy = true;
        NetworkCrawler.getCrawler(item).then(res => {
          const crawler = res.data.data;
          crawler.loading = false;
          this.selectedItem = crawler;
        }).catch(err => this.showErrorMessageFromErr(err)).then(() => this.isBusy = false);
      }
    },
    onCrawlerRun(crawler) {
      // TODO: Set crawler loading and trigger update in CrawlerDetail.vue
      // TODO: When run crawler button was pressed and then another crawlers is selected the button is not updated.
      this.selectedItem.loading = true;
      NetworkCrawler.runCrawler(crawler).then(res => {
        setTimeout(() => crawler.loading = false, 10000);
        // Show message
        this.showSuccessMessageFromRes(res);
      }).catch(err => this.showErrorMessageFromErr(err)).then(() => this.selectedItem.loading = false);
    },
    onItemClosed() {
      this.selectedItem = null;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (this.selectedItem && item.id === this.selectedItem.id) return 'selected';
    },
    getTrimmedText(text, limit) {
      if (text.length <= limit)
        return text;
      else
        return text.substring(0, limit) + '[...]';
    },
  },
};
</script>

<style scoped>
</style>
