<template>
    <Collapsible id="user-password-set">
        <template slot="heading">{{$t('user.passwordSet.heading')}}</template>
        <template slot="content">
            <FormGroup :label="$t('user.fields.password.label')" class="mb-3">
                <FormInput
                        v-model="password"
                        :errors="errors"
                        error-field-name="password_new"
                        type="password"
                        editable
                />
            </FormGroup>
            <FormGroup :label="$t('user.fields.password_confirmation.label')">
                <FormInput
                        v-model="passwordConfirm"
                        :errors="errors"
                        error-field-name="password_new_confirmed"
                        type="password"
                        editable
                />
            </FormGroup>
            <Separator/>
            <!-- Controls -->
            <div class="d-flex align-items-center justify-content-between">
                <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
                <Button icon="save" variant="success" :loading="loading"
                        @click.native="onSave(true)">
                    {{$t('general.button.save')}}
                </Button>
            </div>
        </template>
    </Collapsible>
</template>

<script>
  import Collapsible from '../../components/base/Collapsible';
  import FormGroup from '../../components/base/input/FormGroup';
  import FormInput from '../../components/base/input/FormInput';
  import Separator from '../../components/base/Separator';
  import Button from '../../components/base/button/Button';
  import NetworkUser from '../../helper/network/NetworkUser';
  import {toaster} from '../../helper/mixins/toaster';
  import {errorHelper} from '../../helper/mixins/error-helper';

  export default {
    components: {Button, Separator, FormInput, FormGroup, Collapsible},
    mixins: [toaster, errorHelper],
    props: {
      user: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        password: null,
        passwordConfirm: null,
        loading: false,
      };
    },
    watch: {
      user() {
        this.resetData();
        this.resetErrors();
      },
    },
    methods: {
      onSave() {
        this.loading = true;
        NetworkUser.setPassword(this.user, this.password, this.passwordConfirm).
            then(res => {
              this.resetErrors();
              this.resetData();
              this.showSuccessMessageFromRes(res);
            }).
            catch(err => {
              this.setErrors(err);
              this.showErrorMessageFromErr(err);
            }).
            then(() => this.loading = false);
        this.$emit('item:saved');
      },
      onClose() {
        this.$emit('item:closed');
      },
      resetData() {
        this.password = null;
        this.passwordConfirm = null;
      },
    },
  };
</script>

<style scoped>

</style>
