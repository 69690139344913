export default {
    namespaced: true,
    state: {
        selectedXmlFeed: null
    },
    mutations: {
        setSelectedXmlFeed: (state, xmlFeed) => state.selectedXmlFeed = xmlFeed,
    },
    actions: {},
    getters: {},
}
