<template>

  <div>
    <Collapsible id="xml-feed-detail-reports">
      <template slot="heading">{{ $t('crawler.detail.reports.heading') }}</template>
      <template slot="content">
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalItems"

        />
        <b-table
            @row-clicked="onRowClicked"
            :items="items"
            :fields="fields"
            :responsive="true"
            :bordered="true"
            :hover="true"
            :busy="isBusy"
        />
      </template>
    </Collapsible>

    <!-- Modal showing report detailed -->
    <b-modal v-model="showModal" v-if="report" scrollable hide-header hide-footer size="xl">
      <b-container class="py-2">
        <Heading>Crawling Report</Heading>
        <b-table
            :items="[report]"
            :fields="reportDetailedFields"
            stacked
        />
        <pre class="p-2 rounded">{{ report.content.trim() }}</pre>
      </b-container>
    </b-modal>
  </div>

</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import NetworkCrawler from '../../../helper/network/NetworkCrawler'
import Heading from '../../../components/base/typography/Heading'

export default {
  components: { Heading, Collapsible },
  props: {
    crawler: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      showModal: false,
      report: null,
      fields: [
        {
          key: 'content',
          label: this.$t('crawler.detail.reports.fields.content.label'),
        },
        {
          key: 'crawling_started_at',
          label: this.$t('crawler.detail.reports.fields.crawling_started_at.label'),
        },
      ],
      reportDetailedFields: [
        {
          key: 'id',
          label: this.$t('crawler.detail.reports.fields.id.label'),
        },
        {
          key: 'has_failed',
          label: this.$t('crawler.detail.reports.fields.has_failed.label'),
        },
        {
          key: 'crawling_started_at',
          label: this.$t('crawler.detail.reports.fields.crawling_started_at.label'),
        },
        {
          key: 'crawling_finished_at',
          label: this.$t('crawler.detail.reports.fields.crawling_finished_at.label'),
        },
        {
          key: 'created_at',
          label: this.$t('crawler.detail.reports.fields.created_at.label'),
        },
        {
          key: 'updated_at',
          label: this.$t('crawler.detail.reports.fields.updated_at.label'),
        },
      ],
      isBusy: false,
      items: [],
      // Pagination data
      currentPage: 1,
      perPage: 10,
      totalItems: 999999,
    }
  },
  computed: {
    paginationParams () {
      return { page: this.currentPage, perPage: this.perPage }
    },
  },
  watch: {
    crawler () {
      // Reset pagination data
      this.currentPage = 1
      this.perPage = 10
      this.totalItems = 999
      this.fetchData()
    },
    currentPage () {
      this.fetchData()
    },
  },
  methods: {
    async fetchData () {
      this.isBusy = true
      try {
        const { data } = await NetworkCrawler.getCrawlerReports(this.crawler, { params: this.paginationParams })
        this.isBusy = false
        this.totalItems = data.meta.total
        this.items = this.getReports(data)
      } catch (err) {
        console.error(err)
        this.isBusy = false
      }
    },
    getReports (data) {
      const reports = data.data
      // Mark rows green and red in table
      return reports.map(report => {
        if (report.has_failed === 1)
          report._rowVariant = 'danger'
        else
          report._rowVariant = 'success'

        return report
      })
    },
    onRowClicked (item) {
      NetworkCrawler.getCrawlerReport(this.crawler, item).
          then(res => {
            this.report = res.data.data
            this.showModal = true
          }).
          catch(err => console.error(err))
    },
  },
  created () {
    this.fetchData()
  },
}
</script>

<style>
pre {
  background-color: var(--emp-light-bg);
}
</style>
