<template>
  <div v-if="jobPostingsSel.length > 0" ref="top">

    <!--    Show selected Job postings-->
    <Collapsible id="toggle-sel-jobpostings" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('jobPosting.selected_job_postings') }}</template>
      <template slot="content">
        <div class="scroll">
          <b-table-simple borderless>
            <b-tr>
              <b-th>{{ $t('jobPosting.fields.title.label') }}</b-th>
              <b-th>{{ $t('jobPosting.fields.location.label') }}</b-th>
            </b-tr>
            <b-tr v-for="jobposting of jobPostingsSel" :key="jobposting.id">
              <b-td>{{ jobposting.title }}</b-td>
              <b-td>{{ jobposting.city }}</b-td>
            </b-tr>
          </b-table-simple>
        </div>
      </template>
    </Collapsible>
    <div v-if="!isCategory">

      <!-- Batch processing with all data fields-->
      <Collapsible id="toggle-batch" :visible="true" class="mb-4">
        <template slot="heading">{{ $t('jobPosting.button.batch_processing') }}</template>
        <template slot="content">
          <div class="px-2 pb-4">{{ $t('batch_processing.info') }}</div>
          <JobPostingXMLFields :is-batch-processing-view="true" v-model="jobPostingDataFields"/>
        </template>
      </Collapsible>
      <Separator/>

      <!-- save button-->
      <div class="d-flex justify-content-between align-items-center">
        <Button @click="onClose" icon="times">{{ $t('general.button.cancel') }}</Button>
        <Button @click="saveDialog = true" :loading="saving" :disable="considerFields.length <= 0" icon="save"
                variant="success">
          {{ $t('overwrite') }}
        </Button>
      </div>

      <b-modal v-model="saveDialog" :title="$t('batch_processing.jobpostings_override')" hide-footer centered>
        <p>{{ $t('batch_processing.jobpostings_override_message') }}</p>
        <ul>
          <li v-for="field of considerFields" :key="field['field']">{{ field["field"] }}</li>
        </ul>
        <p>{{ $t('batch_processing.jobpostings_override_confirm') }}</p>
        <b-row>
          <b-col>
            <Button @click="saveDialog = false" :disable="saving" icon="times" variant="secondary">
              {{ $t('cancel') }}
            </Button>
          </b-col>
          <b-col cols="auto">
            <Button @click="onSave" :loading="saving" icon="save" variant="success">
              {{ $t('overwrite') }}
            </Button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div v-else>

      <!-- Add Categories-->
      <Collapsible id="toggle-batch-categories-delete" :visible="false" class="mb-4">
        <template slot="heading">{{ $t('batch_processing.categories_add') }}</template>
        <template slot="content">
          <div class="px-2 pb-4">{{ $t('batch_processing.categories_add_info') }}</div>
          <p class="px-2">{{ $t('batch_processing.available_categories') }}</p>

          <b-list-group class="scroll-categories">
            <b-list-group-item v-for="category of getCategories" :key="category.name"
                               class="d-flex justify-content-between align-items-center">
              {{ category.name }}
              <b-badge variant="primary" pill>{{ category.amount }}</b-badge>
            </b-list-group-item>
          </b-list-group>

          <div class="mt-4">

            <div id="add_categories_info">
              <label class="px-2"><strong>{{ $t('batch_processing.which_categories_to_add') }}</strong></label>
              <font-awesome-icon icon="info-circle" size="lg" class="text-primary"/>
            </div>
            <b-tooltip target="add_categories_info" triggers="hover">
              <p v-html="$t('batch_processing.categories_add_info_tooltip')"/>
            </b-tooltip>

            <b-form-tags
                input_id="add-categories"
                v-model="categoriesToAdd"
                :placeholder="$t('batch_processing.category_entry')"
                tag-variant="primary"
                tag-pills
                :addButtonText="$t('general.button.add')"
                separator=","/>
          </div>
          <Separator/>

          <!-- save button-->
          <div class="d-flex justify-content-between align-items-center">
            <Button @click="onClose" :disable="savingAdd" icon="times">{{ $t('general.button.cancel') }}</Button>
            <Button @click="addCategories" :loading="savingAdd" :disable="categoriesToAdd.length <= 0" icon="save"
                    variant="success">
              {{ $t('general.button.add') }}
            </Button>
          </div>

        </template>
      </Collapsible>

      <!-- Delete Categories-->
      <Collapsible id="toggle-batch-categories-add" :visible="false" class="mb-4">
        <template slot="heading">{{ $t('batch_processing.categories_delete') }}</template>
        <template slot="content">
          <div class="px-2 pb-4">{{ $t('batch_processing.categories_delete_info') }}</div>
          <p class="px-2">{{ $t('batch_processing.available_categories_custom') }}</p>

          <b-list-group class="scroll-categories">
            <b-list-group-item v-for="category of getCustomCategories" :key="category.name"
                               class="d-flex justify-content-between align-items-center">
              {{ category.name }}
              <b-badge variant="primary" pill>{{ category.amount }}</b-badge>
            </b-list-group-item>
          </b-list-group>

          <div class="mt-4">

            <div id="delete_categories_info">
              <label class="px-2"><strong>{{ $t('batch_processing.which_categories_to_delete') }}</strong></label>
              <font-awesome-icon icon="info-circle" size="lg" class="text-primary"/>
            </div>
            <b-tooltip target="delete_categories_info" triggers="hover">
              <p v-html="$t('batch_processing.categories_delete_info_tooltip')"/>
            </b-tooltip>

            <b-form-tags id="categories-with-dropdown" v-model="categoriesToDelete" size="lg" no-outer-focus
                         class="mb-2">
              <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="danger"
                    >{{ tag }}
                    </b-form-tag>
                  </li>
                </ul>

                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                  <template #button-content>
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                    {{ $t('batch_processing.choose_categories_to_delete') }}
                  </template>
                  <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group
                        :label="$t('batch_processing.search_category')"
                        label-for="tag-search-input"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                        :disabled="disabled"
                    >
                      <b-form-input
                          v-model="searchCategory"
                          id="tag-search-input"
                          type="search"
                          size="sm"
                          autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                      v-for="option in availableOptions"
                      :key="option.name"
                      @click="onOptionClick({ option, addTag })"
                  >
                    {{ option.name }}
                  </b-dropdown-item-button>
                  <b-dropdown-text v-if="availableOptions.length === 0">
                    {{ $t('batch_processing.no_available_categories') }}
                  </b-dropdown-text>
                </b-dropdown>
              </template>
            </b-form-tags>
          </div>
          <Separator/>

          <!-- save button-->
          <div class="d-flex justify-content-between align-items-center">
            <Button @click="onClose" :disable="savingDelete" icon="times">{{ $t('general.button.cancel') }}</Button>
            <Button @click="deleteCategories" :loading="savingDelete" :disable="categoriesToDelete.length <= 0"
                    icon="save"
                    variant="success">
              {{ $t('general.button.delete') }}
            </Button>
          </div>

        </template>
      </Collapsible>
    </div>
  </div>
  <div v-else>
    {{ $t('batch_processing.no_jobpostings') }}
  </div>
</template>

<script>
import Collapsible from "../../../components/base/Collapsible";
import JobPostingXMLFields from "./components/JobPostingXMLFields";
import Button from "@/components/base/button/Button";
import Separator from "@/components/base/Separator";
import NetworkJobPosting from "@/helper/network/NetworkJobPosting";
import {toaster} from "@/helper/mixins/toaster";

export default {
  components: {Separator, Button, JobPostingXMLFields, Collapsible},
  mixins: [toaster],
  props: {
    isCategory: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      jobPostingDataFields:
          [
            {"field": "city", "value": null, "xml_override": 0, "consider": 0},
            {"field": "title", "value": null, "xml_override": 0, "consider": 0},
            {"field": "date", "value": null, "xml_override": 0, "consider": 0},
            {"field": "referencenumber", "value": null, "xml_override": 0, "consider": 0},
            {"field": "url", "value": null, "xml_override": 0, "consider": 0},
            {"field": "company", "value": null, "xml_override": 0, "consider": 0},
            {"field": "country", "value": null, "xml_override": 0, "consider": 0},
            {"field": "description", "value": null, "xml_override": 0, "consider": 0},
            {"field": "applyurl", "value": null, "xml_override": 0, "consider": 0},
            {"field": "subcompany", "value": null, "xml_override": 0, "consider": 0},
            {"field": "state", "value": null, "xml_override": 0, "consider": 0},
            {"field": "postalcode", "value": null, "xml_override": 0, "consider": 0},
            {"field": "streetaddress", "value": null, "xml_override": 0, "consider": 0},
            {"field": "email", "value": null, "xml_override": 0, "consider": 0},
            {"field": "salary", "value": null, "xml_override": 0, "consider": 0},
            {"field": "education", "value": null, "xml_override": 0, "consider": 0},
            {"field": "jobtype", "value": null, "xml_override": 0, "consider": 0},
            {"field": "category", "value": null, "xml_override": 0, "consider": 0},
            {"field": "experience", "value": null, "xml_override": 0, "consider": 0},
            {"field": "tracking_url", "value": null, "xml_override": 0, "consider": 0},
            {"field": "indeed-apply-data", "value": null, "xml_override": 0, "consider": 0},
            {"field": "metadata", "value": null, "xml_override": 0, "consider": 0}
          ],
      saving: false,
      savingAdd: false,
      savingDelete: false,
      saveDialog: false,
      categoriesToAdd: [],
      categoriesToDelete: [],
      searchCategory: ""
    }
  },
  created() {
    this.checkIdenticalJobPostingValues();
  },
  mounted() {
    // scroll first time into view
    const el = this.$refs.top;
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({behavior: "smooth"});
      }, 50)
    }
  },
  computed: {
    jobPostingsSel() {
      return this.$store.state.jobPosting.jobPostingsSel
    },
    considerFields() {
      return this.jobPostingDataFields.filter(field => field["consider"] === 1)
    },
    /**
     * Get all categories with amount from selected jobpostings
     */
    getCategories() {
      const categories = this.jobPostingsSel.reduce((acc, jobposting) => {
        if (jobposting.category == null || jobposting.category.length <= 0) {
          return acc;
        }
        const categories = jobposting.category.split(",");
        for (let category of categories) {
          // check if there are duplicates
          const index = acc.findIndex(x => x.name === category.trim());
          if (index >= 0) {
            acc.splice(index, 1, {name: category.trim(), amount: acc[index].amount + 1})
          } else {
            acc.push({name: category.trim(), amount: 1});
          }
        }
        return acc
      }, []);
      return categories.sort((a, b) => b.amount - a.amount)
    },
    /**
     * Get all categories (value database field) with amount from selected jobpostings
     */
    getCustomCategories() {
      const categories = this.jobPostingsSel.reduce((acc, jobposting) => {
        if (jobposting.category_custom == null || jobposting.category_custom.length <= 0) {
          return acc;
        }
        const categories = jobposting.category_custom.split(",");
        for (let category of categories) {
          // check if there are duplicates
          const index = acc.findIndex(x => x.name === category.trim());
          if (index >= 0) {
            acc.splice(index, 1, {name: category.trim(), amount: acc[index].amount + 1})
          } else {
            acc.push({name: category.trim(), amount: 1});
          }
        }
        return acc
      }, []);
      return categories.sort((a, b) => b.amount - a.amount)
    },
    criteria() {
      // Compute the search criteria
      return this.searchCategory.trim().toLowerCase()
    },
    availableOptions() {
      const criteria = this.criteria
      // Filter out already selected options
      const options = this.getCustomCategories.filter(opt => this.categoriesToDelete.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
      }
      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return this.$t('batch_processing.category_not_found')
      }
      return ''
    }
  },
  watch: {
    jobPostingsSel() {
      this.checkIdenticalJobPostingValues();
    }
  },
  methods: {
    /**
     * Check if the selected jobposting have identical jobposting data values
     */
    checkIdenticalJobPostingValues() {
      for (let jobPostingDataField of this.jobPostingDataFields) {
        // Do not override values, if user selected the specific checkbox
        if (jobPostingDataField['xml_override'] === 1) {
          continue;
        }
        const field = jobPostingDataField["field"];
        for (const jobPosting of this.jobPostingsSel) {
          if (jobPosting[field] === undefined) {
            continue
          }
          const equal = this.jobPostingsSel.every(job => {
            return job[field] === jobPosting[field]
          })
          if (equal) {
            jobPostingDataField['value'] = jobPosting[field]
          } else {
            jobPostingDataField['value'] = null
          }
        }
      }
    },
    onOptionClick({option, addTag}) {
      addTag(option.name)
      this.searchCategory = ''
    },
    async onSave() {
      this.saving = true;
      const jobpostingIds = this.jobPostingsSel.map(jobposting => jobposting.id);
      if (jobpostingIds.length <= 0) {
        return;
      }
      try {
        const res = await NetworkJobPosting.updateJobPostings(jobpostingIds, this.jobPostingDataFields)
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('jobPosting/fetchSelJobPostings', {vm: this})
        this.jobPostingDataFields.forEach(jobpostingField => {
          jobpostingField['xml_override'] = 0
          jobpostingField['consider'] = 0
        })
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.saving = false
        this.saveDialog = false
      }
    },
    async addCategories() {
      this.savingAdd = true;
      const jobpostingIds = this.jobPostingsSel.map(jobposting => jobposting.id);
      if (jobpostingIds.length <= 0) {
        return;
      }
      try {
        const res = await NetworkJobPosting.addCategories(jobpostingIds, this.categoriesToAdd)
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('jobPosting/fetchSelJobPostings', {vm: this})
        this.categoriesToAdd = []
      } catch (err) {
        console.log(err)
        this.showErrorMessageFromErr(err)
      } finally {
        this.savingAdd = false;
      }
    },
    async deleteCategories() {
      this.savingDelete = true;
      const jobpostingIds = this.jobPostingsSel.map(jobposting => jobposting.id);
      if (jobpostingIds.length <= 0) {
        return;
      }
      try {
        const res = await NetworkJobPosting.deleteCategories(jobpostingIds, this.categoriesToDelete)
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('jobPosting/fetchSelJobPostings', {vm: this})
        this.categoriesToDelete = []
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.savingDelete = false;
      }
    },
    onClose() {
      this.$emit("onClose")
    },
  }
}
</script>

<style scoped>

>>> .scroll {
  max-height: 60vh;
  overflow-x: auto;
}

>>> .scroll-categories {
  max-height: 30vh;
  overflow-x: auto;
}

</style>