function getVariantForStatus(status) {
  // Switch status for job postings
  switch (status) {
    case 'created':
      return '';
    case 'active':
      return 'success';
    case 'inactive':
      return 'secondary';
    case 'reactivated':
      return 'warning';
    case 'waiting for approval':
      return 'info';
    case 'approved':
      return 'primary';
    case 'rejected':
      return 'danger';
    default:
      return '';
  }
}

function getFormattedDate(val) {
  return (new Date(val)).toLocaleString();
}

/**
 * Set the color for a given row depending on the status.
 * @param item
 */
function rowClassByStatus(item) {
  let cssClass;

  // Switch status for job postings
  switch (item?.status?.name) {
    case 'created':
      cssClass = '';
      break;
    case 'active':
      cssClass = 'table-success';
      break;
    case 'inactive':
      cssClass = 'table-secondary';
      break;
    case 'reactivated':
      cssClass = 'table-warning';
      break;
    case 'waiting for approval':
      cssClass = 'table-info';
      break;
    case 'approved':
      cssClass = 'table-primary';
      break;
    case 'rejected':
      cssClass = 'table-danger';
      break;
    default:
      cssClass = '';
  }

  return cssClass;
}

/**
 * Get the significant value of a job posting.
 * Job postings can come in different variations. When used on job postings from detailed view, a jobposting_data field is available.
 * If they for example come along with a campaign, then there is no jobposting_data field. Instead the keys are set directly.
 *
 * If no value is found, return null.
 */
function getSignificantValue(jobPosting, key) {
  if (jobPosting.jobposting_data) {
    // Job posting is detailed
    const objectAtKey = jobPosting.jobposting_data.find(data => data.field === key);
    if (!jobPosting.is_from_crawler) // when the job posting was created manually
      return objectAtKey.value;
    else
      return objectAtKey.xml_override === 1 ? objectAtKey.value : objectAtKey.value_xml;
  } else if (jobPosting.hasOwnProperty(key)) {
    // Job posting is from some other source (e.g. from a campaign).
    return jobPosting[key];
  } else {
    return null;
  }
}

function formatDateYYYMMDD(date) {
  let day = date.getDate(), month = date.getMonth() + 1, year = date.getFullYear();
  if (month < 10)
    month = '0' + month;
  if (day < 10)
    day = '0' + day;

  return [year, month, day].join('-');
}

/**
 * Convert a javascript object into form data.
 * @param obj
 * @return {null|FormData}
 */
function convertToFormData (obj) {
  const formData = new FormData()
  if (obj instanceof Object) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key))
        formData.append(key, obj[key])
    }

    return formData
  } else {
    console.error(
      'getFormData: Argument must be of type object, found ' +
      obj.constructor.name + 'instead.')

    return null
  }
}

function clone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export {
  getVariantForStatus,
  getFormattedDate,
  getSignificantValue,
  rowClassByStatus,
  formatDateYYYMMDD,
  convertToFormData,
  clone
};
