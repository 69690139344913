import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkCatalogue extends NetworkHelper {

  static getCatalogues(config) {
    return axios.get('/api/catalogue', config);
  }

  static getCataloguesSimplified(config) {
    return axios.get('/api/catalogue/simplified', config);
  }

  static getCatalogue(catalogue, config) {
    return axios.get(`/api/catalogue/${catalogue.id}`, config);
  }

  static updateCatalogue(catalogue, config) {
    return axios.put(`/api/catalogue/${catalogue.id}`, catalogue, config);
  }

  static createCatalogue(catalogue, config) {
    return axios.post('/api/catalogue', catalogue, config);
  }

  static deleteMultipleCatalogues(catalogues, config) {
    return axios.delete(`/api/catalogue`, {data: {catalogues}, ...config});
  }

}
