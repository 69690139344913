<template>
  <div class="company-contact-person">
    <Collapsible id="toggle-company-contact-person" :visible="true">
      <template slot="heading">{{ $t('company.contactPerson.heading') }}</template>
      <template slot="content">
        <!-- View -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span v-if="!company.contact_person">
              {{ $t('company.contactPerson.fields.contact.none') }}
            </span>
            <span v-else>
              <a :href="'mailto:' + company.contact_person.email">{{ company.contact_person.email }}</a>
            </span>
          </div>
          <!-- Display when no person is set yet -->
          <Button
              v-if="!company.contact_person"
              @click.native="selectingContactPerson = true"
              :disabled="users.length === 0"
              variant="success"
              icon="plus"
          >
            {{ $t('general.button.add') }}
          </Button>
          <!-- Display when a contact person is already set for this company -->
          <Button
              v-else
              @click.native="selectingContactPerson = true"
              :disabled="users.length === 0"
              variant="success"
              icon="exchange-alt"
          >
            {{ $t('general.button.change') }}
          </Button>
        </div>

        <!-- Modal -->
        <b-modal
            v-model="selectingContactPerson"
            :title="$t('company.contactPerson.fields.contact.add')"
            hide-footer
            centered
        >
          <div class="mb-3">
            <FormInput
                v-model="selectedContactPersonId"
                :options="usersComputed"
                :description="$t('company.contactPerson.fields.contact.description')"
                selectable
            />
          </div>
          <div class="d-flex justify-content-end">
            <Button
                icon="save"
                variant="success"
                @click.native="onSave"
                :disabled="!selectedContactPersonId"
                :loading="loading"
            >
              {{ $t('general.button.save') }}
            </Button>
          </div>
        </b-modal>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import FormInput from '@/components/base/input/FormInput';
import Button from '@/components/base/button/Button';
import {toaster} from '@/helper/mixins/toaster';
import NetworkCompany from "../../helper/network/NetworkCompany";

export default {
  components: {Button, FormInput, Collapsible},
  mixins: [toaster],
  data() {
    return {
      selectedContactPersonId: null,
      selectingContactPerson: false,
      loading: false,
    }
  },
  computed: {
    company() {
      return this.$store.state.company.selectedItem
    },
    users: {
      get() {
        return this.$store.state.company.users
      },
      set(users) {
        this.$store.commit('company/setUsers', users)
      }
    },
    usersComputed() {
      return this.users.map(user => {
        return {
          value: user.id,
          text: user.email,
        };
      }).sort((a, b) => a.text.localeCompare(b.text));
    },
    selectedContactPerson() {
      if (this.selectedContactPersonId)
        return this.users.find(user => user.id === this.selectedContactPersonId);
      else
        return null;
    },
  },
  async created() {
    this.loading = true
    try {
      const res = await NetworkCompany.getUsersForCompanyEmplify()
      this.users = res.data.data
    } catch (err) {
      console.error(err)
      return err
    } finally {
      this.loading = false
    }

  },
  methods: {
    async onSave() {
      this.loading = true
      try {
        const res = await NetworkCompany.assignContactPerson(this.company, this.selectedContactPersonId)
        this.showSuccessMessageFromRes(res)
        this.$store.commit('company/setCompanyContactPerson', this.selectedContactPerson)
        this.selectingContactPerson = false;
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }

    },
  },
};
</script>

<style scoped>

</style>
