<template>
  <ViewContainer id="job-posting">
    <ViewToggle :show-detailed-view="jobPostingSel" :show-batch-processing-view="showBatchProcessingView">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('jobPosting.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination v-model="query.page" :total-rows="totalItems" :per-page="query.perPage"/>
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker id="per-page" v-model="query.perPage"/>
            </b-col>
            <!-- Is from feed -->
            <b-col class="flex-grow-0 mb-2">
              <IsFromCrawlerPicker id="is-from-crawler" v-model="query.is_from_crawler"/>
            </b-col>
            <!-- Flag for 'is_deleted' -->
            <b-col class="mb-2 mt-1" cols="auto">
              <b-form-checkbox id="show-deleted"
                               v-model="query.with_deleted" :value="1" :unchecked-value="null">
                <span>{{ $t('general.filter.with_deleted') }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-wrap align-items-center" style="column-gap: 1rem">
              <!-- Jobposting count-->
              <div class="mb-2 mr-2">
                <span>{{ this.$t('general.pagination.jobPosting') + ': ' }}</span>
                <span id="count-job-posting" class="mb-2">{{ totalItems }}</span>
              </div>
              <!-- Selected jobpostings count-->
              <span class="mb-2">{{ this.$t('general.pagination.selectedJobsPostings') + ': ' + jobPostingsSel.length }}</span>
              <div>
                <!-- Deselect job postings -->
                <b-button
                    variant="link"
                    class="mb-2"
                    @click="onResetSelectedJobPostings"
                    :disabled="jobPostingsSel.length === 0"
                >
                  {{ this.$t('jobPosting.button.reset_job_postings_sel') }}
                </b-button>
                <!-- Remove filter button -->
                <RemoveFilterButton
                id="remove-filter"
                class="mb-2"
                :disabled="Object.keys(this.query).length <= 5"
                @click="$store.dispatch('jobPosting/resetQuery')"
                />
              </div>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              class="position-relative"
              :items="jobPostings"
              :fields="filteredJobPostingFields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy="isBusy"
              :no-local-sorting="true"
              :sort-by="query.sortBy"
              :sort-desc="query.sortDesc"
              @sort-changed="sortingChanged"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('jobPosting.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('jobPosting.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th v-if="system === 'automate'" colspan="3" variant="secondary"/>
                <b-th v-else colspan="2" variant="secondary"/>
                <!-- Filters -->
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.company"
                      :placeholder="$t('general.filter.company')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.subcompany"
                      :placeholder="$t('general.filter.subcompany')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.title"
                      :placeholder="$t('general.filter.title')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.refnum"
                      :placeholder="$t('general.filter.refnum')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.customer_refnum"
                      :placeholder="$t('general.filter.customer_refnum')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.status"
                      :placeholder="$t('general.filter.status')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.city"
                      :placeholder="$t('general.filter.city')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.category"
                      :placeholder="$t('general.filter.category')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary"/>
              </b-tr>
            </template>
            <!-- Head 'Selected' -->
            <template #head(selected)>
              <b-form-checkbox v-model="checkAll" @change="onToggleAll" class="mx-0 d-block"/>
            </template>
            <!-- Cell 'Selected' -->
            <template #cell(selected)="data">
              <b-form-checkbox :checked="jobPostingIsSel(data.item)" @change="toggleSingleJobPosting($event, data.item)"
                               class="mx-0 d-block"/>
            </template>
            <!-- Strikethrough for job postings with status 'deleted' -->
            <template #cell()="data">
              {{ data.value }}
            </template>
            <!-- Cell 'status' -->
            <template #cell(status)="data">
              {{ data.item.status.name }}
            </template>
            <!-- Cell 'is_from_crawler' -->
            <template #cell(is_from_crawler)="data">
              <font-awesome-icon v-if="data.item.is_from_crawler" icon="robot" fixed-width/>
              <font-awesome-icon v-else icon="keyboard" fixed-width/>
            </template>
            <!-- Cell 'visible_for_customer' -->
            <template #cell(visible_for_customer)="data">
              <font-awesome-icon v-if="data.item.visible_for_customer" icon="eye" fixed-width/>
              <font-awesome-icon v-else icon="eye-slash" fixed-width/>
            </template>
            <!-- Cell 'created_at' -->
            <template #cell(created_at)="data">
              {{ getFormattedDate(data.value) }}
            </template>
          </b-table>
          <!-- Controls -->
          <div class="d-flex flex-wrap">
            <!-- Copy -->
            <div class="mr-2 mb-2">
              <b-button v-if="system === 'reseller'" @click="onCopy(0)" :disabled="jobPostingsSel.length !== 1"
                        variant="primary">
                <template>
                  <font-awesome-icon icon="copy" class="mr-2"/>
                  {{ $t('general.button.copy.default') }}
                </template>
              </b-button>
              <b-button-group v-if="system === 'automate'">
                <b-dropdown id="copy-btn" :disabled="jobPostingsSel.length !== 1" variant="primary">
                  <template #button-content>
                    <font-awesome-icon icon="copy" class="mr-2"/>
                    {{ $t('general.button.copy.default') }}
                    <b-spinner v-if="loadingCopy" class="mx-2 align-middle" small/>
                  </template>
                  <!-- Copy with products -->
                  <b-dropdown-item id="copy-with-btn" @click="onCopy(1)">
                    <span>{{ $t('general.button.copy.withProducts') }}</span>
                  </b-dropdown-item>
                  <!-- Copy without products -->
                  <b-dropdown-item id="copy-without-btn" @click="onCopy(0)">
                    <span>{{ $t('general.button.copy.withoutProducts') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
            <!-- Manage Campaign -->
            <div class="mr-2 mb-2" v-if="system === 'automate'">
              <b-button-group>
                <b-dropdown id="manage-campaign-btn" :disabled="jobPostingsSel.length === 0" variant="primary">
                  <template #button-content>
                    <font-awesome-icon icon="chart-line" class="mr-2"/>
                    {{ $t('campaign.button.default') }}
                  </template>
                  <!-- Create new campaign -->
                  <b-dropdown-item id="new-campaign-btn" v-b-modal.campaign-create>
                    <span>{{ $t('campaign.button.create') }}</span>
                  </b-dropdown-item>
                  <!-- Add to existing campaign -->
                  <b-dropdown-item id="add-campaign-btn" v-b-modal.campaign-add>
                    <span>{{ $t('campaign.button.add') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
            <div class="mr-2 mb-2">
              <b-button-group>
                <b-dropdown id="change-status-btn" :disabled="jobPostingsSel.length === 0 || loadingStatuses"
                            variant="primary">
                  <template #button-content>
                    <font-awesome-icon icon="toggle-on" class="mr-2"/>
                    {{ $t('jobPosting.button.change_status.default') }}
                    <b-spinner v-if="loadingChangeStatuses" class="ml-2" small/>
                  </template>
                  <b-dropdown-item
                      v-for="status in jobPostingStatuses"
                      :key="status"
                      :id="status.replaceAll(' ', '-') + '-status-btn'"
                      @click.native="setStatusForJobPostingsSel(status)"
                  >
                    <span>{{ $t('jobPosting.button.change_status.' + status.replaceAll(' ', '-')) }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
            <div class="mr-2 mb-2" v-if="system === 'automate'">
              <b-button-group>
                <b-dropdown id="customer-visibility-btn" :disabled="jobPostingsSel.length === 0" variant="primary">
                  <template #button-content>
                    <font-awesome-icon icon="toggle-on" class="mr-2"/>
                    {{ $t('jobPosting.button.change_customer_visibility.default') }}
                    <b-spinner v-if="loadingChangesCustomerVisibility" class="ml-2" small/>
                  </template>
                  <!-- Set status active -->
                  <b-dropdown-item id="customer-visible-btn" @click.native="setSelectedCustomerVisibility(true)">
                    <span>{{ $t('jobPosting.button.change_customer_visibility.visible') }}</span>
                  </b-dropdown-item>
                  <!-- Set status inactive -->
                  <b-dropdown-item id="customer-invisible-btn" @click.native="setSelectedCustomerVisibility(false)">
                    <span>{{ $t('jobPosting.button.change_customer_visibility.invisible') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
            <div class="mr-2 mb-2">
              <b-button-group>
                <b-dropdown id="batch-processing-dropdown" :disabled="jobPostingsSel.length === 0" variant="primary">
                  <template #button-content>
                    <font-awesome-icon icon="layer-group" class="mr-2"/>
                    {{ $t('jobPosting.button.batch_processing') }}
                  </template>
                  <!-- Set status active -->
                  <b-dropdown-item id="batch-processing-btn" @click.native="showBatchProcessing(false)">
                    <span>{{ $t('batch_processing.data_fields') }}</span>
                  </b-dropdown-item>
                  <!-- Set status inactive -->
                  <b-dropdown-item id="batch-processing-categories-btn" @click.native="showBatchProcessing(true)">
                    <span>{{ $t('batch_processing.categories') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>
          </div>
        </ViewElement>
        <!-- Create job posting -->
        <ViewElement class="mb-3">
          <JobPostingCreate/>
        </ViewElement>
      </template>
      <template slot="detailed-view">
        <ViewElement class="mb-3">
          <JobPostingDetail/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingStatus/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingUtm/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingCompany/>
        </ViewElement>
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <JobPostingOrder/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingCampaign/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingFile/>
        </ViewElement>
        <ViewElement class="mb-3">
          <JobPostingMail/>
        </ViewElement>
      </template>
      <template slot="batch-processing-view">
        <ViewElement class="mb-3">
          <JobPostingBatchProcessing v-if="showBatchProcessingView" @onClose="showBatchProcessingView = false"
                                     :is-category="isBatchProcessingCategories"/>
        </ViewElement>
      </template>
    </ViewToggle>

    <!-- Modal for campaign create -->
    <JobPostingCampaignCreate/>
    <!-- Modal for campaign add -->
    <JobPostingCampaignAdd/>

  </ViewContainer>
</template>

<script>
import ViewContainer from '../../../components/base/ViewContainer'
import ViewToggle from '../../../components/base/ViewToggle'
import ViewElement from '../../../components/base/ViewElement'
import PageHeading from '../../../components/base/typography/PageHeading'
import { toaster } from '@/helper/mixins/toaster'
import JobPostingCreate from './JobPostingCreate'
import { errorHelper } from '@/helper/mixins/error-helper'
import { getFormattedDate, rowClassByStatus } from '@/helper/utility'
import RemoveFilterButton from '../../../components/base/button/RemoveFilterButton'
import PerPagePicker from '@/components/PerPagePicker'
import IsFromCrawlerPicker from '@/components/IsFromCrawlerPicker'
import JobPostingDetail from '@/views/jobposting/jobposting/JobPostingDetail'
import JobPostingStatus from './JobPostingStatus'
import JobPostingUtm from './JobPostingUtm'
import JobPostingCompany from './JobPostingCompany'
import JobPostingOrder from './JobPostingOrder'
import JobPostingFile from './JobPostingFile'
import NetworkJobPosting from '../../../helper/network/NetworkJobPosting'
import JobPostingCampaignCreate from './JobPostingCampaignCreate'
import JobPostingCampaignAdd from './JobPostingCampaignAdd'
import JobPostingMail from './JobPostingMail'
import JobPostingCampaign from './JobPostingCampaign'
import JobPostingBatchProcessing from '@/views/jobposting/jobposting/JobPostingBatchProcessing'

export default {
  components: {
    JobPostingBatchProcessing,
    JobPostingCampaignAdd,
    JobPostingCampaignCreate,
    JobPostingFile,
    JobPostingOrder,
    JobPostingCompany,
    JobPostingUtm,
    JobPostingMail,
    JobPostingStatus,
    JobPostingDetail,
    IsFromCrawlerPicker,
    PerPagePicker,
    RemoveFilterButton,
    JobPostingCreate,
    PageHeading,
    ViewElement,
    ViewToggle,
    ViewContainer,
    JobPostingCampaign
  },
  mixins: [toaster, errorHelper],
  data () {
    return {
      jobPostingsFields: [
        {
          key: 'selected',
          class: 'checkbox-col',
        },
        {
          key: 'is_from_crawler',
          label: '',
        },
        {
          key: 'visible_for_customer',
          label: '',
        },
        {
          key: 'company',
          label: this.$t('jobPosting.fields.company.label'),
          sortable: true,
        },
        {
          key: 'subcompany',
          label: this.$t('jobPosting.fields.subcompany.label'),
          sortable: true,
        },
        {
          key: 'title',
          label: this.$t('jobPosting.fields.title.label'),
        },
        {
          key: 'refnum',
          label: this.$t('jobPosting.fields.refnum.label'),
          sortable: true,
        },
        {
          key: 'customer_refnum',
          label: this.$t('jobPosting.fields.customer_refnum.label'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('jobPosting.fields.status.label'),
          sortable: true,
        },
        {
          key: 'city',
          label: this.$t('jobPosting.fields.city.label'),
        },
        {
          key: 'category',
          label: this.$t('jobPosting.fields.category.label'),
        },
        {
          key: 'created_at',
          label: this.$t('jobPosting.fields.created_at.label'),
          sortable: true,
        },
      ],
      // Adding to existing campaign
      addingToCampaign: false,
      campaigns: [],
      selectedCampaign: null,
      // Loading
      loadingCopy: false,
      loadingDelete: false,
      loadingChangeStatuses: false,
      loadingChangesCustomerVisibility: false,
      checkAll: false,
      system: process.env.VUE_APP_SYSTEM,
      loadingStatuses: true,
      jobPostingStatuses: [],
      showBatchProcessingView: false,
      isBatchProcessingCategories: false,
    }
  },
  computed: {
    filteredJobPostingFields () {
      return this.jobPostingsFields.filter(field => this.system !== 'reseller' || field.key !== 'visible_for_customer')
    },
    isBusy () {
      return this.$store.state.jobPosting.isBusy
    },
    jobPostings () {
      return this.$store.state.jobPosting.jobPostings
    },
    jobPostingSel: {
      get () {
        return this.$store.state.jobPosting.jobPostingSel
      },
      set (jobPosting) {
        this.$store.commit('jobPosting/setJobPostingSel', jobPosting)
      },
    },
    jobPostingsSel () {
      return this.$store.state.jobPosting.jobPostingsSel
    },
    query: {
      get () {
        return this.$store.state.jobPosting.query
      },
      set (query) {
        this.$store.commit('jobPosting/setQuery', query)
      },
    },
    totalItems () {
      return this.$store.state.jobPosting.meta?.total ?? 999999
    },
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler () {
        this.$store.dispatch('jobPosting/fetchJobPostings')
      },
    },
    /**
     * Watch for changes on jobPostingSel and update in store.
     * This watcher works for all child components which update any property of jobPostingSel.
     */
    jobPostingSel: {
      deep: true,
      handler (jobPosting) {
        this.$store.commit('jobPosting/setJobPostingSel', jobPosting)
      },
    },
    jobPostings (jobpostings) {
      // Check whether all currently visible selected jobs are in the store.
      this.checkAll = jobpostings.every(jp => this.jobPostingsSel.findIndex(jps => jps.id === jp.id) >= 0)
    }
  },
  methods: {
    sortingChanged (ctx) {
      // :sort-by.sync and :sort-desc.sync doesnt work, because it fires the watcher twice
      this.query.sortBy = ctx.sortBy
      this.query.sortDir = ctx.sortDesc ? 'desc' : 'asc'
    },
    async onRowClicked (jobPosting) {
      this.showBatchProcessingView = false
      // If job posting already selected, deselect and return
      if (jobPosting.id === this.jobPostingSel?.id) {
        this.$store.commit('jobPosting/setJobPostingSel', null)
        return
      }
      // Job posting not selected yet, fetch
      await this.$store.dispatch('jobPosting/fetchJobPosting', jobPosting)
      await this.$store.dispatch('mail/fetchMails', jobPosting)
    },
    onToggleAll (selectAll) {
      if (selectAll) {
        this.$store.commit('jobPosting/addJobPostingsSel', this.jobPostings)
      } else {
        this.$store.commit('jobPosting/removeJobPostingsSel', this.jobPostings)
      }
    },
    onResetSelectedJobPostings () {
      this.$store.commit('jobPosting/setJobPostingsSel', [])
      this.checkAll = false
    },
    showBatchProcessing (isBatchProcessingCategories) {
      this.isBatchProcessingCategories = isBatchProcessingCategories
      this.jobPostingSel = null
      this.showBatchProcessingView = true
    },
    async setSelectedCustomerVisibility (setVisibility) {
      this.loadingChangesCustomerVisibility = true
      const jobPostingIds = this.jobPostingsSel.map(jp => jp.id)
      const visibility = setVisibility ? 1 : 0
      try {
        const res = await NetworkJobPosting.updateCustomerVisibility(jobPostingIds, visibility)
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('jobPosting/fetchJobPostings')
        this.$store.commit('jobPosting/removeJobPostingsSel', this.jobPostingsSel)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingChangesCustomerVisibility = false
      }
    },
    jobPostingIsSel (jobPosting) {
      return this.jobPostingsSel.some(jp => jp.id === jobPosting.id)
    },
    toggleSingleJobPosting (isSelected, jobPosting) {
      if (isSelected) {
        this.$store.commit('jobPosting/addJobPostingsSel', [jobPosting])
      } else {
        this.$store.commit('jobPosting/removeJobPostingsSel', [jobPosting])
      }
    },
    async onCopy (withProducts) {
      this.loadingCopy = true
      try {
        // Copy job posting
        const res = await NetworkJobPosting.copyJobPosting(this.jobPostingsSel[0], withProducts)
        // Set the new copied job posting selected
        this.$store.commit('jobPosting/setJobPostingSel', res.data.data)
        // Fetch the job postings anew so the copied job posting appears in the table
        await this.$store.dispatch('jobPosting/fetchJobPostings')
        // Deselect (uncheck) the selected job postings. In the case of 'copy' only one job posting can be selected.
        this.$store.commit('jobPosting/setJobPostingsSel', [])
        // Show the success message
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingCopy = false
      }
    },
    async setStatusForJobPostingsSel (status) {
      try {
        this.loadingChangeStatuses = true
        const res = await NetworkJobPosting.updateJobPostingsStatuses(this.jobPostingsSel, status)
        await this.$store.dispatch('jobPosting/fetchJobPostings')
        this.$store.commit('jobPosting/setJobPostingsSel', [])
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingChangeStatuses = false
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      let cssClass = rowClassByStatus(item, type)
      if (item.id === this.jobPostingSel?.id) cssClass += ' selected'
      return cssClass.trim()
    },
    getFormattedDate,
    rowClassByStatus,
  },
  async created () {
    try {
      const res = await NetworkJobPosting.getJobPostingStatuses()
      this.jobPostingStatuses = res.data.data.map(status => {
        return status.name
      }).filter(status => {
        return !['rejected', 'approved', 'waiting for approval'].includes(status)
      })
    } catch (err) {
      this.showErrorMessageFromErr(err)
    } finally {
      this.loadingStatuses = false
    }
  }
}
</script>

<style scoped>
.min-width-radio-group {
  min-width: 235px;
}
</style>
