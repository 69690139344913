<template>
  <Collapsible id="jobposting-file" :visible="true">
    <template slot="heading">{{ $t('jobPosting.detail.jobPostingFiles.heading') }}</template>
    <template slot="content">
      <Button v-b-modal.jobposting-file-manager variant="primary">Dateimanager</Button>
      <JobPostingFileManager id="jobposting-file-manager"/>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible';
import JobPostingFileManager from './JobPostingFileManager';
import Button from '../../../components/base/button/Button';

export default {
  components: {Button, JobPostingFileManager, Collapsible},
};
</script>

<style scoped>

</style>
