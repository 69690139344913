import { Timeframe } from "@/types/indeed-budget";
import { addDays, endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfWeek, startOfYear, subDays, subMonths, subQuarters, subWeeks, subYears } from "date-fns";

export function getTimeframe(timeframe: string): Timeframe {
  switch (timeframe) {
    case "today":
      return timeFrameToday();
    case "yesterday":
      return timeFrameYesterday();
    case "week.this":
      return timeFrameThisWeek();
    case "week.last":
      return timeFrameLastWeek();
    case "month.this":
      return timeFrameThisMonth();
    case "month.last":
      return timeFrameLastMonth();
    case "quarter.this":
      return timeFrameThisQuarter();
    case "quarter.last":
      return timeFrameLastQuarter();
    case "year.this":
      return timeFrameThisYear();
    case "year.last":
      return timeFrameLastYear();
    case "all":
      return timeFrameAll();
    default:
      return timeFrameThisMonth()
  }
}

const timeFrameToday = (): Timeframe => ({
  start: new Date(),
  end: new Date()
});

const timeFrameYesterday = (): Timeframe => ({
  start: subDays(new Date(), 1),
  end: subDays(new Date(), 1)
});

const timeFrameThisWeek = (): Timeframe => ({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: new Date()
});

const timeFrameLastWeek = (): Timeframe => ({
  start: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
  end: subWeeks(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 4), 1)
});

const timeFrameThisMonth = (): Timeframe => ({
  start: startOfMonth(new Date()),
  end: new Date()
});

const timeFrameLastMonth = (): Timeframe => ({
  start: subMonths(startOfMonth(new Date()), 1),
  end: subMonths(endOfMonth(new Date()), 1)
});

const timeFrameThisQuarter = (): Timeframe => ({
  start: startOfQuarter(new Date()),
  end: new Date()
});

const timeFrameLastQuarter = (): Timeframe => ({
  start: subQuarters(startOfQuarter(new Date()), 1),
  end: subQuarters(endOfQuarter(new Date()), 1)
})

const timeFrameThisYear = (): Timeframe => ({
  start: startOfYear(new Date()),
  end: new Date()
})

const timeFrameLastYear = (): Timeframe => ({
  start: subYears(startOfYear(new Date()), 1),
  end: subYears(endOfYear(new Date()), 1)
});

const timeFrameAll = (): Timeframe => ({
  start: new Date("2022-01-01"),
  end: new Date()
});
