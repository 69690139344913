import JWT from "../jwt";

export default class NetworkHelper {
    static buildHeaders(headers = {}) {
        return {
            ...JWT.authHeaders(),
            'X-localization': process.env.VUE_APP_I18N_LOCALE,
            ...headers
        }
    }
}
