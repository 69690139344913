export default class User {

    /**
     * Stores given userData in the local storage
     * @param userData
     */
    static storeUser(userData) {
        localStorage.setItem('emp_user', JSON.stringify(userData.data));
    }

    /**
     * returns the stored user as JSON Object
     * @returns {any}
     */
    static getStoredUser() {
        return JSON.parse(localStorage.getItem('emp_user'));
    }

    static deleteStoredUser() {
        localStorage.removeItem('emp_user');
    }

    /**
     * Checks if the logged in user is administrator.
     * Returns false, if the user is not administrator or if no user is logged in.
     * @returns {boolean}
     */
    static isAdministrator() {
        const user = this.getStoredUser();
        return !!(user && user.hasOwnProperty('administrator') && user.administrator === 1);
    }

}












