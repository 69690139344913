// @ts-ignore
import user from '../../helper/user';
// @ts-ignore
import NetworkAuth from '../../helper/network/NetworkAuth';
import { Route } from 'vue-router';

export function storeUserOrBack(to: Route, from: Route, next: Function) {
  // get user data, redirect to /login if 401
  NetworkAuth.getUserdata()
    .then((response: { data: any }) => {
      user.storeUser(response.data);
      next();
    }).
    catch(() => {
      next('/login');
    });
}
