<template>
  <div>
    <!-- Job posting utm parameters -->
    <Collapsible id="toggle-job-posting-utm" :visible="true">
      <template slot="heading">{{ $t('utmParameter.heading') }}</template>
      <template slot="content">
        <UtmParameterPicker v-model="jobPostingSel.utm_parameters"/>
      </template>
    </Collapsible>
    <Separator/>
    <!-- Controls -->
    <div class="d-flex justify-content-between align-items-center">
      <Button @click="close" icon="times">{{ $t('general.button.cancel') }}</Button>
      <Button id="save-utm-parameter-btn" @click="save" :loading="savingUtmParameters" variant="success" icon="save">{{ $t('general.button.save') }}</Button>
    </div>
  </div>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import UtmParameterPicker from '../../../components/UtmParameterPicker'
import Separator from '../../../components/base/Separator'
import Button from '../../../components/base/button/Button'
import { toaster } from '../../../helper/mixins/toaster'
export default {
  components: { Button, Separator, UtmParameterPicker, Collapsible },
  mixins: [toaster],
  data () {
    return {
      savingUtmParameters: false
    }
  },
  computed: {
    jobPostingSel () {
      return this.$store.state.jobPosting.jobPostingSel
    },
  },
  methods: {
    close () {
      this.$store.commit('jobPosting/setJobPostingSel', null)
    },
    async save () {
      this.savingUtmParameters = true
      await this.$store.dispatch('jobPosting/updateJobPostingUtmParameters', { vm: this })
      this.savingUtmParameters = false
    },
  }
}
</script>

<style scoped>

</style>
