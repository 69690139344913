import NetworkHelper from './NetworkHelper';
import axios from 'axios';

export default class NetworkCampaign extends NetworkHelper {

  static getCampaigns(config) {
    return axios.get('/api/campaign', config);
  }

  static getCampaignsSimplified(config) {
    return axios.get('/api/campaign/simplified', config);
  }

  static getCampaign(campaign, config) {
    return axios.get(`/api/campaign/${campaign.id}`, config);
  }

  static getCampaignStatuses(config) {
    return axios.get('/api/campaignstatus', config);
  }

  static createCampaignEmpty(name, companyId) {
    return axios.post(`/api/company/${companyId}/campaignEmpty`,
        { name });
  }

  static createCampaign(name, companyId, jobPostingIds, order, config) {
    return axios.post(`/api/company/${companyId}/campaign`,
        { name, jobpostings: jobPostingIds, budget: order["budget"], products: order["products"] }
        , config);
  }

  static updateCampaign(campaign, config) {
    return axios.put(`/api/campaign/${campaign.id}`, campaign, config);
  }

  static updateCampaignStatus(campaign, config) {
    return axios.put(`/api/campaign/${campaign.id}/campaignstatus`, {'campaignstatus': campaign.campaign_status.name}, config);
  }

  static updateCampaignUTMParameters(campaignId, utmParameters, config) {
    return axios.post(`/api/utmparameter`,
        {campaign_id: campaignId, utm_parameters: utmParameters}, config);
  }

  static addToCampaign(campaignId, jobPostingIds, config) {
    return axios.put(
        `/api/campaign/${campaignId}/jobposting`,
        {jobpostings: jobPostingIds},
        config,
    );
  }

  static createOrderForCampaign(campaignId, order, config) {
    return axios.post(`/api/campaign/${campaignId}/order`, order, config);
  }

  static removeJobPostingsFromCampaign(campaignId, jobpostings, config) {
    return axios.delete(
        `/api/campaign/${campaignId}/jobposting`,
        {data: {jobpostings}, ...config},
    );
  }

}
