import NetworkJobPosting from "../../helper/network/NetworkJobPosting";

export default {
    namespaced: true,
    state: {
        mails: [],
    },
    mutations: {
        setMails: (state, mails) => state.mails = mails,
    },
    actions: {
        fetchMails: async ({ commit }, jobPosting) => {
            try {
                const { data } = await NetworkJobPosting.getMails(jobPosting)
                commit('setMails', data.data)
            } catch (e) {
                console.error(e)
            }
        },
    },
    getters: {},
}
