import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkCrawler extends NetworkHelper {

  static getCrawlers(config) {
    return axios.get('/api/crawler', config);
  }

  static getCrawler(crawler, config) {
    return axios.get(`/api/crawler/${crawler.id}`, config);
  }

  static getCrawlerReports(crawler, config) {
    return axios.get(`/api/crawler/${crawler.id}/report`, config);
  }

  static getCrawlerReport(crawler, report, config) {
    return axios.get(`/api/crawler/${crawler.id}/report/${report.id}`, config);
  }

  static runCrawler(crawler, config) {
    return axios.post(`/api/crawler/${crawler.id}/run`, config);
  }

  static activateOrInactivateCrawler(crawlerId, active, config) {
    return axios.put(`/api/crawler/${crawlerId}/active`, {active: active}, config);
  }

}
