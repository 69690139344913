import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkProduct extends NetworkHelper {

  static getProducts(config) {
    return axios.get('/api/product', config);
  }

  static updateProduct(product, config) {
    return axios.put(`/api/product/${product.id}`, product, config);
  }

  static createProduct(jobPortal, product, config) {
    return axios.post(`/api/jobportal/${jobPortal.id}/product`, product, config);
  }

  static deleteProduct(product, config) {
    return axios.delete(`/api/product/${product.id}`, config);
  }
}
