<template>
    <div class="xml-feed-detail">
        <Collapsible id="toggle-xml-feed-detail" :visible="true">
            <template slot="heading">{{$t('crawler.detail.heading')}}</template>
            <template slot="content">
                <FormGroup :label="$t('crawler.fields.id.label')">
                    <FormInput v-model="crawler.id"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.name.label')">
                    <FormInput v-model="crawler.name"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.url.label')">
                    <FormInputDiv borderless>
                        <a class="smaller" :href="crawler.url" target="_blank">
                            <div class="d-flex align-items-center">
                                <font-awesome-icon icon="external-link-alt" fixed-width/>
                                {{crawler.url}}
                            </div>
                        </a>
                    </FormInputDiv>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.publisher.label')">
                    <FormInput v-model="crawler.publisher"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.publisher_url.label')">
                    <FormInput v-model="crawler.publisher_url"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.contact.label')">
                    <FormInput v-model="crawler.contact"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.last_crawling.label')">
                    <FormInput v-model="crawler.last_crawling"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.created_at.label')">
                    <FormInput v-model="crawler.created_at"/>
                </FormGroup>
                <FormGroup :label="$t('crawler.fields.updated_at.label')">
                    <FormInput v-model="crawler.updated_at"/>
                </FormGroup>
            </template>
        </Collapsible>
        <Separator/>
        <!-- Footer -->
        <div class="d-flex align-items-center justify-content-between">
            <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
            <Button
                    @click.native="onCrawlerRun"
                    :loading="crawler.loading"
                    :disabled="crawler.loading"
                    icon="play-circle"
                    variant="success">
                {{$t('general.button.runCrawler')}}
            </Button>
        </div>
    </div>
</template>

<script>
  import Separator from '../../../components/base/Separator';
  import FormGroup from '../../../components/base/input/FormGroup';
  import FormInput from '../../../components/base/input/FormInput';
  import Button from '../../../components/base/button/Button';
  import FormInputDiv from '../../../components/base/input/FormInputDiv';
  import Collapsible from '../../../components/base/Collapsible';

  export default {
    components: {Collapsible, FormInputDiv, Button, FormInput, FormGroup, Separator},
    props: {
      crawler: {
        required: true,
        type: Object
      }
    },
    methods: {
      onClose() {
        this.$emit('item:closed'); // emit to main view
      },
      onCrawlerRun() {
        this.$emit('crawler:run', this.crawler);
      },
    },
  };
</script>

<style scoped>

</style>
