<!--
This component is usually used when the value to be displayed
should look like a disabled input field but needs to be formatted.
 -->
<template>
  <div class="input-style smaller py-1 px-2" :class="{'disabled': disabled, 'borderless': borderless}">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    borderless: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.input-style {
  /* Default bootstrap styles for disabled input fields */
  border: 1px solid $color-disabled;
  border-radius: 0.2rem;
}

.disabled {
  background-color: $bg-disabled;
}

.borderless {
  border: none;
}
</style>
