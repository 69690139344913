<template>
  <Collapsible id="job-portal-create">
    <template #heading>{{ $t('jobPortal.create') }}</template>
    <template slot="content">
      <b-card>
        <b-form @submit.prevent="onSubmit">
          <div class="row mb-3">
            <!-- Name -->
            <div class="col-md-6 col-lg-4 my-2">
              <FormInput
                  v-model="form.name"
                  :editable="true"
                  :label="$t('jobPortal.fields.name.label')"
                  :errors="errors"
                  error-field-name="name"
              />
            </div>
          </div>
          <!-- Buttons -->
          <Button icon="plus" type="submit" variant="success">
            {{ $t('general.button.create') }}
          </Button>
        </b-form>
      </b-card>
    </template>
  </Collapsible>
</template>

<script>
import FormInput from '../../../components/base/input/FormInput'
import Button from '../../../components/base/button/Button'
import NetworkJobPortal from '../../../helper/network/NetworkJobPortal'
import Collapsible from '../../../components/base/Collapsible'
import { toaster } from '@/helper/mixins/toaster'
import { errorHelper } from '@/helper/mixins/error-helper'

export default {
  mixins: [toaster, errorHelper],
  components: { Collapsible, Button, FormInput },
  data () {
    return {
      form: {
        name: null,
      },
    }
  },
  methods: {
    async onSubmit () {
      try {
        const res = await NetworkJobPortal.createJobPortal(this.form)
        this.showSuccessMessageFromRes(res)
        this.resetForm()
        this.resetErrors()
        const createdJobPortal = res.data.data
        // Fetch newly created job portal in order to have categories populated
        const createdJobPortalRes = await NetworkJobPortal.getJobPortal(createdJobPortal)
        const createdJobPortalFetched = createdJobPortalRes.data.data
        this.$emit('item:created', createdJobPortalFetched)
      } catch (err) {
        this.setErrors(err)
      }
    },
    resetForm () {
      this.form.name = null
    },
  },
}
</script>

<style scoped>

</style>
