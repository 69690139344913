<template>
  <div class="company-detail">
    <Collapsible id="toggle-company-detail" :visible="true">
      <template slot="heading">{{ $t('company.detail.heading') }}</template>
      <template slot="content">
        <div>
          <h6>{{ $t('company.detail.information') }}</h6>
          <FormGroupWide :label="$t('company.fields.logo.label')">
            <Photo
                @file:changed="onFileChanged"
                @photo:removed="onLogoRemoved"
                :src="company.logo_path"
                :errors="company.errors"
                error-field-name="logo"
            />
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.id.label')">
            <FormInput v-model="company.id" :errors="errors"
                       error-field-name="id"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.name.label')">
            <FormInput v-model="company.name" editable :errors="errors"
                       error-field-name="name"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.street.label')">
            <FormInput v-model="company.street" editable :errors="errors"
                       error-field-name="street"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.postcode.label')">
            <FormInput v-model="company.postcode" editable
                       :errors="errors"
                       error-field-name="postcode"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.city.label')">
            <FormInput v-model="company.city" editable :errors="errors"
                       error-field-name="city"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.country.label')">
            <FormInput v-model="company.country" :options="countries" selectable
                       :errors="errors"
                       error-field-name="country"/>
          </FormGroupWide>

          <div v-if="system === 'automate'">
            <hr>
            <h6>{{ $t('company.detail.marge') }}</h6>
            <FormGroupWide :label="$t('company.fields.marge.label')">
              <FormInput class="w-25"
                v-model="company.marge"
                :errors="errors"
                error-field-name="marge"
                editable
              />
            </FormGroupWide>
          </div>

          <div v-if="system === 'automate'">
            <hr>
            <h6>{{ $t('company.detail.indeed') }}</h6>
            <FormGroupWide :label="$t('company.fields.indeed_advertiser_id.label')">
              <FormInput v-model="company.indeed_advertiser_id"
                :errors="errors"
                error-field-name="indeed_advertiser_id"
                editable
              />
            </FormGroupWide>
            <FormGroupWide :label="$t('company.fields.indeed_employer_id.label')">
              <FormInput v-model="company.indeed_employer_id"
                         :errors="errors"
                         error-field-name="indeed_employer_id"
                         editable
              />
            </FormGroupWide>
          </div>

          <hr>
          <h6>{{ $t('company.detail.ats') }}</h6>

          <FormGroupWide :label="$t('company.fields.ats_client_id.label')">
            <FormInput v-model="company.ats_client_id"
                       :errors="errors"
                       error-field-name="ats_client_id"
                       editable
            />
          </FormGroupWide>

          <FormGroupWide :label="$t('company.fields.ats_client_secret.label')">
            <FormInput v-model="company.ats_client_secret"
                       :errors="errors"
                       error-field-name="ats_client_secret"
                       editable
            />
          </FormGroupWide>

          <FormGroupWide :label="$t('company.fields.ats_metadata.label')">
            <FormInput v-model="company.ats_metadata"
                       :errors="errors"
                       error-field-name="ats_metadata"
                       editable
            />
          </FormGroupWide>

          <div v-if="system === 'automate'">
            <hr>
            <h6>{{ $t('company.detail.likemondays') }}</h6>
            <FormGroupWide :label="$t('company.fields.likemondays.label')">
              <FormInput v-model="company.likemondays" :options="limoOptions" selectable/>
            </FormGroupWide>
          </div>

          <div v-if="system === 'automate'">
            <div v-if="jass.length <= 0">
              <hr>
              <h6>{{ $t('company.detail.jobadpartner') }}</h6>
              <FormGroupWide :label="$t('company.fields.jass_create.label')">
                <div class="d-flex align-items-center">
                  <Button
                    :disabled="jassBtnLoading || !jassIsFetched"
                    variant="success"
                    @click="onAdd"
                    :loading="jassBtnLoading || !jassIsFetched"
                  >
                    {{ $t('company.fields.jass_create.button') }}
                  </Button>
                  <div id="add_jass_id_info">
                    <font-awesome-icon icon="info-circle" size="lg" class="mx-2 text-secondary"/>
                  </div>
                  <b-tooltip target="add_jass_id_info" triggers="hover">
                    <p v-html="$t('company.fields.jass_create.tooltip')"/>
                  </b-tooltip>
                </div>
              </FormGroupWide>
            </div>
          </div>

          <hr>
          <FormGroupWide :label="$t('company.fields.created_at.label')">
            <FormInput v-model="company.created_at"/>
          </FormGroupWide>
          <FormGroupWide :label="$t('company.fields.updated_at.label')">
            <FormInput v-model="company.updated_at"/>
          </FormGroupWide>


          <!--
          <FormGroup v-if="false" :label="$t('company.fields.jass_customer_id.label')">
            <b-input-group>
              <b-form-input v-model="company.jass_customer_id"
                            :errors="errors"
                            error-field-name="jass_customer_id"
                            editable/>
              <b-input-group-append v-if="showAddButton">
                <b-overlay :show="addingJass" rounded="lg" opacity="0.6">
                  <template #overlay>
                    <div class="d-flex align-items-center">
                      <b-spinner small type="border" rounded="circle" variant="dark"></b-spinner>
                    </div>
                  </template>
                  <b-button
                      :disabled="addingJass"
                      variant="outline-secondary"
                      @click="onAdd()"
                      v-b-tooltip.hover.left="$t('company.fields.jass_customer_id.tooltip')"
                  >
                    {{ $t('company.fields.jass_customer_id.button') }}
                  </b-button>
                </b-overlay>
              </b-input-group-append>
            </b-input-group>
          </FormGroup>
          -->


        </div>
      </template>
    </Collapsible>
    <Separator/>
    <!-- Footer -->
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <Button icon="times" @click.native="company = null">{{ $t('general.button.cancel') }}</Button>
        <Button
            icon="save"
            variant="success"
            :loading="loading"
            @click.native="onSave"
        >
          {{ $t('general.button.save') }}
        </Button>
      </div>

      <b-progress
          v-if="progress !== null"
          :value="progress"
          :max="100"
          variant="success"
          class="my-3"
          show-progress animated
      />
    </div>
  </div>

</template>

<script>
import FormInput from '../../components/base/input/FormInput';
import FormGroupWide from '../../components/base/input/FormGroupWide';
import Button from '../../components/base/button/Button';
import Photo from '../../components/base/Photo';
import Separator from '../../components/base/Separator';
import Collapsible from '../../components/base/Collapsible';
import {toaster} from '@/helper/mixins/toaster';
import {errorHelper} from '@/helper/mixins/error-helper';
import {convertToFormData} from '@/helper/utility'
import NetworkCompany from "../../helper/network/NetworkCompany";
import NetworkCountry from "../../helper/network/NetworkCountry";

export default {
  components: {Collapsible, Separator, Photo, FormGroupWide, Button, FormInput},
  mixins: [toaster, errorHelper],
  data() {
    return {
      countries: [],
      progress: null,
      addingJass: false,
      jassBtnLoading: false,
      jassIsFetched: false,
      loading: false,
      logo: null,
      logoShouldBeDeleted: false,
      limoOptions: [
        {text: this.$t('company.fields.likemondays.publish'), value: 1},
        {text: this.$t('company.fields.likemondays.hide'), value: 0}
      ],
      system: process.env.VUE_APP_SYSTEM
    }
  },
  computed: {
    company: {
      get() {
        return this.$store.state.company.selectedItem
      },
      set(val) {
        this.$store.commit('company/setSelectedItem', val)
      }
    },
    jass() {
      return this.$store.state.company.jass
    },
    showAddButton() {
      return this.company.jass_customer_id === null || this.company.jass_customer_id === '';
    },
  },
  watch: {
    company() {
      this.resetErrors();
    },
    jass() {
      // wait for first change
      this.jassIsFetched = true
    }
  },
  async created() {
    if (this.countries.length <= 0) {
      try {
        this.loading = true
        const res = await NetworkCountry.getCountries(null, null)
        if (res.data.data) {
          this.countries = res.data.data.map(country => country.name)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    }
  },
  methods: {
    convertToFormData,
    async onSave() {
      this.loading = true
      const config = {onUploadProgress: this.updateProgress};
      try {
        let res = await NetworkCompany.updateCompany(this.company, config)
        if (this.logo) {
          const logoFormData = convertToFormData({logo: this.logo})
          res = await NetworkCompany.updateLogo(this.company, logoFormData)
        }
        if (this.logoShouldBeDeleted) {
          res = await NetworkCompany.deleteLogo(this.company)
        }
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('company/fetchCompanies')
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
        this.resetProgress()
      }
    },
    async onAdd() {
      try {
        this.jassBtnLoading = true
        const res = await NetworkCompany.getJassId(this.company);
        this.$store.commit('company/addJass', res.data.data);
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        console.error(err);
        this.showErrorMessageFromErr(err)
      } finally {
        this.jassBtnLoading = false
      }
    },
    onFileChanged(file) {
      this.logo = file;
      this.logoShouldBeDeleted = false;
      this.company.logo_path = URL.createObjectURL(file);
    },
    onLogoRemoved() {
      this.logo = null;
      this.logoShouldBeDeleted = true;
      this.company.logo_path = null;
    },
    resetProgress() {
      this.progress = null;
    },
    updateProgress(progressEvent) {
      this.progress = (progressEvent.loaded / progressEvent.total) * 100;
    },
  },
};
</script>

<style scoped>
</style>
