<template>
    <Collapsible id="user-detail-admin">
        <template slot="heading">{{$t('user.detail.admin.heading')}}</template>
        <template slot="content">
            <FormGroup :label="$t('user.fields.administrator.label')">
                <FormInput v-model="userComputed.administrator" checkable disabled/>
            </FormGroup>

            <Separator/>

            <!-- Controls -->
            <div class="d-flex align-items-center justify-content-between">
                <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
                <b-btn-group>
                    <Button icon="square" variant="danger" :loading="loadingRevoke"
                            @click.native="onSave(false)">
                        {{$t('general.button.admin.revoke')}}
                    </Button>
                    <Button icon="check-square" variant="success" :loading="loadingGrant"
                            @click.native="onSave(true)">
                        {{$t('general.button.admin.grant')}}
                    </Button>
                </b-btn-group>
            </div>
        </template>
    </Collapsible>
</template>

<script>
  import Collapsible from '../../components/base/Collapsible';
  import NetworkUser from '../../helper/network/NetworkUser';
  import FormGroup from '../../components/base/input/FormGroup';
  import FormInput from '../../components/base/input/FormInput';
  import Button from '../../components/base/button/Button';
  import Separator from '../../components/base/Separator';
  import {toaster} from '../../helper/mixins/toaster';

  export default {
    components: {Separator, Button, FormInput, FormGroup, Collapsible},
    mixins: [toaster],
    props: {
      user: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        loadingRevoke: false,
        loadingGrant: false,
      };
    },
    computed: {
      userComputed() {
        const clone = Object.assign({}, this.user);
        delete clone.comment;
        delete clone.company;
        delete clone.email;
        delete clone.firstname;
        delete clone.lastname;
        delete clone.mobile;
        delete clone.phone;
        delete clone.position;
        delete clone.salutation;
        return clone;
      },
    },
    methods: {
      onSave(grant) {
        let promise = null;
        if (grant) {
          this.loadingGrant = true;
          promise = NetworkUser.grantAdminRights(this.user);
        } else {
          this.loadingRevoke = true;
          promise = NetworkUser.revokeAdminRights(this.user);
        }

        promise.
            then(res => {
              this.showSuccessMessageFromRes(res);
              this.$emit('item:saved', this.user, grant);
              return res;
            }).
            catch(err => err).
            then(() => {
              this.loadingGrant = false;
              this.loadingRevoke = false;
            });
      },
      onClose() {
        this.$emit('item:closed'); // emit to main view
      },
    },
  };
</script>

<style scoped>

</style>
