import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import axios from 'axios';
// @ts-ignore
import JWT from './helper/jwt';

import './assets/scss/main.scss'

Vue.config.productionTip = false;

// ------------------------------------------------------------------------------------------------------------------
// Change axios settings
// @author Julian Huber
// ------------------------------------------------------------------------------------------------------------------

axios.defaults.baseURL = process.env.VUE_APP_RECRUITER_API;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Add the auth headers before each request
axios.interceptors.request.use(config => {
  config.headers = {
    ...JWT.authHeaders(),
    'X-localization': process.env.VUE_APP_I18N_LOCALE,
    ...config.headers,
  };
  return config;
});

// ------------------------------------------------------------------------------------------------------------------
// Include fontawesome icons
// ------------------------------------------------------------------------------------------------------------------

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {FontAwesomeLayers} from '@fortawesome/vue-fontawesome';

library.add(fas);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

// ------------------------------------------------------------------------------------------------------------------
// Include apex charts
// ------------------------------------------------------------------------------------------------------------------

import VueApexCharts from 'vue-apexcharts';

import store from './store'

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// ------------------------------------------------------------------------------------------------------------------
// Create Vue App
// ------------------------------------------------------------------------------------------------------------------

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
