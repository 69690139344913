<template>
  <b-modal v-model="modelHelper" id="copy-text-for-editor" :title="jobPosting.title" centered hide-footer size="sm">

    <!-- Choose Service Level -->
    <b-form-group label="Service Level" class="mb-3">
      <b-form-radio value="A" v-model="selectedServiceLevel">Service Level A</b-form-radio>
      <b-form-radio value="B" v-model="selectedServiceLevel">Service Level B</b-form-radio>
      <b-form-radio value="C" v-model="selectedServiceLevel">Service Level C</b-form-radio>
    </b-form-group>

    <!-- Choose Deadline -->
    <b-form-group label="Deadline" class="mb-3">
      <b-form-radio value="Heute" v-model="selectedDeadline">Heute</b-form-radio>
      <b-form-radio value="Morgen" v-model="selectedDeadline">Morgen</b-form-radio>
      <b-form-radio value="customDate" v-model="selectedDeadline">Datumsauswahl</b-form-radio>
      <b-form-datepicker :disabled="selectedDeadline !== 'customDate'" value-as-date short locale="de"
                         v-model="deadlineDate"></b-form-datepicker>
    </b-form-group>

    <!-- Choose Time for Deadline -->
    <b-form-group label="Deadline Uhrzeit (optional)" class="mb-3">
      <b-form-input v-model="time" type="time"></b-form-input>
    </b-form-group>

    <!-- Write some extra Text -->
    <b-form-group label="Notiz (optional)" class="mb-3">
      <b-form-textarea v-model="text" rows="2"></b-form-textarea>
    </b-form-group>

    <!-- Copy to Clipboard -->
    <b-button @click="copy" variant="primary" block>Text kopieren</b-button>

  </b-modal>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
  },
  data() {
    return {
      modalShow: false,
      selectedServiceLevel: 'B',
      text: '',
      time: null,
      selectedDeadline: 'Morgen',
      deadlineDate: null
    }
  },
  computed: {
    textToEditor() {
      return `Kunde: ${this.jobPosting.client.name}\nP-Nr.: ${this.refnum}\nService Level: ${this.selectedServiceLevel}\nVorschau: ${this.jobPosting.preview}\nOriginal: ${this.url}\nDeadline: ${this.selectedDeadline === 'customDate' ? this.formattedDate : this.selectedDeadline} ${this.time ? this.time + ' Uhr' : ''}\n${this.text ? 'Notiz: ' + this.text : ''}`
    },
    jobPosting() {
      return this.$store.state.jobPosting.jobPostingSel
    },
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    formattedDate() {
      let date
      if (this.deadlineDate) {
        date = new Date(
            this.deadlineDate.getFullYear(),
            this.deadlineDate.getMonth(),
            this.deadlineDate.getDate()
        ).toLocaleDateString("de-DE");
      }
      return date;
    },
    url() {
      let url = ''
      const data = this.jobPosting.jobposting_data
      if (this.jobPosting) {
        for (const set in data) {
          if (data[set].field === 'url') {
            data[set].xml_override === 1 ? url = data[set].value : url = data[set].value_xml
          }
        }
      }
      return url
    },
    refnum() {
      let refnum = this.jobPosting.refnum
      const data = this.jobPosting.jobposting_data
      if (!refnum) {
        for (const set in data) {
          if (data[set].field === 'referencenumber') {
            data[set].xml_override === 1 ? refnum = data[set].value : refnum = 'Fehlt noch!'
          }
        }
      }
      return refnum
    }
  },
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.textToEditor);
      } catch (err) {
        console.log(err)
      } finally {
        this.resetData()
        this.$bvModal.hide("copy-text-for-editor")
      }
    },
    resetData() {
      this.selectedServiceLevel = 'B'
      this.text = ''
      this.time = null
      this.selectedDeadline = 'Morgen'
      this.deadlineDate = null
    }
  }
}
</script>