import NetworkHelper from './NetworkHelper';
import axios from 'axios';

export default class NetworkUser extends NetworkHelper {

  static getUsers(config) {
    return axios.get('/api/user', config);
  }

  static updateUser(user, config) {
    return axios.put(`/api/user/${user.id}`, user, config);
  }

  static grantAdminRights(user, config) {
    return axios.post(`/api/user/${user.id}/administrator`, null, config);
  }

  static revokeAdminRights(user, config) {
    return axios.delete(`/api/user/${user.id}/administrator`, config);
  }

  static setPassword(user, password, passwordConfirm, config) {
    return axios.put(`/api/user/${user.id}/changePassword`,
        {
          password_new: password,
          password_new_confirmation: passwordConfirm,
        },
        config);
  }

  static updatePhoto(user, formData, config) {
    // makes the request method to be 'PUT'
    formData.append('_method', 'PUT');
    return axios.post(`/api/user/${user.id}/photo`, formData, config);
  }

  static deletePhoto(user, config) {
    return axios.delete(`/api/user/${user.id}/photo`, config);
  }

}
