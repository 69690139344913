<template>
  <div class="d-flex align-items-center">
    <div class="text-nowrap mr-2">{{ $t('general.pagination.perPage') }}:</div>
    <b-form-select
        v-model="modelHelper"
        :options="[10, 25, 50, 100]"
        class="per-page-select-width"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Number
    }
  },
  computed: {
    modelHelper: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    }
  }
}
</script>

<style scoped>
.per-page-select-width {
  width: 75px;
}
</style>
