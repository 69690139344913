<template>
    <Collapsible id="catalogue-create">
        <template slot="heading">{{$t('catalogue.create')}}</template>
        <template slot="content">
            <b-card>
                <b-form @submit.prevent="onSubmit">
                    <div class="row mb-3">
                        <!-- Name -->
                        <div class="col-md-6 col-lg-4 my-2">
                            <FormInput
                                    error-field-name="name"
                                    :editable="true"
                                    :label="$t(`catalogue.fields.name.label`)"
                                    :errors="errors"
                                    v-model="form.name"/>
                        </div>
                    </div>
                    <!-- Create Button -->
                    <Button :loading="loading" icon="plus" type="submit" variant="success">
                        {{$t('general.button.create')}}
                    </Button>
                </b-form>
            </b-card>
        </template>
    </Collapsible>
</template>

<script>
  import Button from '../../../components/base/button/Button';
  import FormInput from '../../../components/base/input/FormInput';
  import NetworkCatalogue from '../../../helper/network/NetworkCatalogue';
  import Collapsible from '../../../components/base/Collapsible';
  import {toaster} from '../../../helper/mixins/toaster';
  import {errorHelper} from '../../../helper/mixins/error-helper';

  export default {
    components: {Collapsible, FormInput, Button},
    mixins: [toaster, errorHelper],
    data() {
      return {
        form: {
          name: null,
        },
        loading: false,
      };
    },
    methods: {
      onSubmit: function() {
        this.loading = true;
        NetworkCatalogue.createCatalogue(this.form).
            then(res => {
              this.showSuccessMessageFromRes(res);
              this.resetForm();
              this.resetErrors();
              this.$emit('item:created', res.data.data);
            }).
            catch(err => this.setErrors(err)).
            then(() => this.loading = false);
      },
      close() {
        this.$emit('closed');
      },
      resetForm() {
        this.form.name = null;
      },
    },
  };
</script>

<style scoped>

</style>
