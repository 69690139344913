<template>

  <!-- Modal when adding job postings to existing campaign campaign -->
  <b-modal id="campaign-add" :title="$t('jobPosting.campaign.add')" hide-footer centered>
    <FormInput v-model="selectedCampaignId" :options="possibleCampaigns" :description="$t('campaign.add.fields.campaigns.description')" selectable/>
    <!-- List -->
    <b-list-group class="mt-3 max-height-list-group">
      <b-list-group-item v-for="jp in jobPostingsSel" :key="jp.id">
        <div class="d-flex justify-content-between flex-grow-1">
          <div>
            <strong>{{ jp.title }}</strong><br>
            <span class="small">{{ $t('jobPosting.fields.customer_refnum.label') }}: {{ jp.customer_refnum }}</span>
            <span v-if="jp.refnum" class="small">| {{ $t('jobPosting.fields.refnum.label') }}: {{ jp.refnum }}</span>
          </div>
          <div class="d-flex align-items-center">
            <DeleteButtonCircle @click="onJobPostingRemove(jp)"/>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <!-- Save button -->
    <div class="d-flex justify-content-end mt-3">
      <Button
          :loading="loadingAddToCampaign"
          :disabled="jobPostingsSel.length === 0 || !commonCompanyId"
          @click.native="onAddToCampaign"
          icon="save"
          variant="success"
      >
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </b-modal>

</template>

<script>
import DeleteButtonCircle from '@/components/base/button/DeleteButtonCircle'
import Button from '@/components/base/button/Button'
import NetworkCampaign from '@/helper/network/NetworkCampaign'
import FormInput from '@/components/base/input/FormInput'
import { toaster } from '@/helper/mixins/toaster'

export default {
  components: { FormInput, Button, DeleteButtonCircle },
  mixins: [toaster],
  data () {
    return {
      loadingAddToCampaign: false,
      selectedCampaignId: null,
      campaigns: [],
    }
  },
  computed: {
    jobPostingsSel () {
      return this.$store.state.jobPosting.jobPostingsSel
    },
    /**
     * When job postings are added to existing campaign, only show the campaigns that match the
     * company of the selected job postings.
     */
    possibleCampaigns () {
      return this.campaigns.
          filter(campaign => campaign.company_id === this.commonCompanyId).
          map(campaign => ({ value: campaign.id, text: campaign.name }))
    },
    /**
     * Get the common company id of all job postings.
     * @returns {null|any}
     */
    commonCompanyId () {
      if (this.jobPostingsSel.length === 0)
        return null

      // Check if all company ids are the same by putting all company ids in an array,
      // removing the duplicates and checking if there is only one element left in the array.
      const allCompanyIds = this.jobPostingsSel.map(jp => jp.company_id)
      const uniqueCompanyIds = [...new Set(allCompanyIds)]
      if (uniqueCompanyIds.length === 1)
        return uniqueCompanyIds[0]
      else
        return null
    },
  },
  created () {
    NetworkCampaign.getCampaignsSimplified().
        then(res => this.campaigns = res.data.data).
        catch(err => this.showErrorMessageFromErr(err))
  },
  methods: {
    async onAddToCampaign () {
      try {
        this.loadingAddToCampaign = true
        const res = await NetworkCampaign.addToCampaign(this.selectedCampaignId, this.jobPostingsSel.map(item => item.id))
        this.selectedCampaignId = null
        this.showSuccessMessageFromRes(res)
        this.$bvModal.hide('campaign-add')
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingAddToCampaign = false
      }
    },
    onJobPostingRemove (jobPosting) {
      this.$store.commit('jobPosting/removeJobPostingsSel', [jobPosting])
    },
  },
}
</script>

<style scoped>

</style>
