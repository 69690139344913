<template>
  <div class="photo">
    <input
        @change="onFileChanged"
        ref="inputImage"
        class="d-none"
        type="file"
        accept="image/jpeg, image/png"
        multiple
    >
    <div v-if="!src">
      <Button @click.native.stop="$refs.inputImage.click()"
              variant="outline-secondary" icon="upload">
        {{ $t('general.button.upload') }}
      </Button>
    </div>
    <div v-else>
      <!-- Circle image -->
      <div v-if="isCircle" class="photo-wrapper circle">
        <div class="inner">
          <b-img class="photo" :src="src"/>
        </div>
        <DeleteButtonCircle @click.native.stop="onPhotoRemoved" class="photo-cancel-button" icon="times"/>
      </div>
      <!-- Regular image -->
      <div v-else class="photo-wrapper regular">
        <b-img class="photo" :src="src"/>
        <DeleteButtonCircle @click.native.stop="onPhotoRemoved" class="photo-cancel-button" icon="times"/>
      </div>
    </div>
    <Error
        :errors="errors"
        :error-field-name="errorFieldName"
    />
  </div>
</template>

<script>
import Button from '../../components/base/button/Button'
import Error from './error/Error'
import DeleteButtonCircle from './button/DeleteButtonCircle'

export default {
  props: {
    src: {
      required: true,
    },
    isCircle: {
      required: false,
      type: Boolean,
      default: false,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    errors: {
      required: true,
    },
    errorFieldName: {
      required: true,
      type: String,
    },
  },
  components: { DeleteButtonCircle, Error, Button },
  methods: {
    onFileChanged (event) {
      this.$emit('file:changed', event.target.files[0])
    },
    onPhotoRemoved () {
      this.$refs.inputImage.value = '' // removes stored file in input field
      this.$emit('photo:removed')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

/* General */

.photo-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  @include transition;

  &.photo-cancel-button {
    position: absolute;
    transform: scale(0);
    @include transition;
  }

  /* Regular */

  &.regular {
    .photo {
      width: 100%;
      max-width: 150px;
      height: auto;
    }

    .photo-cancel-button {
      top: -12px;
      right: -12px;
    }

    &:hover .photo-cancel-button {
      transform: scale(1);
    }
  }

  /* Circle */

  &.circle {
    position: relative;

    &:after { /* Overlay */
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0);
      transition: .2s;
    }

    &:hover {
      &:after {
        background-color: rgba(255, 255, 255, .3);
      }

      .photo-cancel-button {
        transform: scale(1);
      }
    }

    .inner {
      width: 150px;
      height: 150px;
      border: 2px solid #ccc;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      .photo {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
      }
    }

    .photo-cancel-button {
      position: absolute;
      z-index: 999;
      top: 10px;
      right: 10px;
    }
  }
}

</style>
