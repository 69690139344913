<template>
  <div>
    <Collapsible id="toggle-job-posting-company" :visible="true">
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingCompany.heading') }}</template>
      <template slot="content">
        <b-table-simple borderless class="mb-0">
          <b-tr>
            <b-td class="align-middle">{{ $t('jobPosting.fields.company.label') }}</b-td>
            <b-td class="align-middle">
              <b-form-select v-model="jobPostingSel.client.id" :options="companiesComputed"/>
            </b-td>
          </b-tr>
        </b-table-simple>
      </template>
    </Collapsible>
    <Separator/>
    <div class="d-flex justify-content-between align-items-center">
      <Button @click="close" icon="times">{{ $t('general.button.cancel') }}</Button>
      <Button @click="save" :loading="loading" icon="save" variant="success">
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import NetworkCompany from '../../../helper/network/NetworkCompany'
import Button from '../../../components/base/button/Button'
import Separator from '../../../components/base/Separator'

export default {
  components: { Separator, Button, Collapsible },
  data () {
    return {
      companies: [],
      loading: false,
    }
  },
  async created () {
    try {
      const { data } = await NetworkCompany.getCompaniesSimplified()
      this.companies = data.data
    } catch (err) {
      this.showErrorMessageFromErr(err)
    }
  },
  computed: {
    jobPostingSel () {
      return this.$store.state.jobPosting.jobPostingSel
    },
    selectedCompany () {
      const index = this.companies.findIndex(company => company.id === this.jobPostingSel.client.id)
      return this.companies[index]
    },
    companiesComputed () {
      const companies = this.companies.map(company => {
        return {
          value: company.id,
          text: company.name,
        }
      })

      companies.sort((a, b) => {
        const textA = a.text.toLowerCase()
        const textB = b.text.toLowerCase()
        return textA < textB ? -1 : 1
      })

      return companies
    },
  },
  methods: {
    close () {
      this.$store.commit('jobPosting/setJobPostingSel', null)
    },
    async save () {
      this.loading = true
      await this.$store.dispatch('jobPosting/updateJobPostingCompany', { vm: this, company: this.selectedCompany })
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
