<template>
  <b-button
      variant="link"
      class="mb-2"
      @click="removeAllFilters">
    {{ $t('general.filter.removeAll') }}
  </b-button>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: Object
    }
  },
  methods: {
    removeAllFilters(e) {
      this.$emit('click', e)
      this.$emit('input', {})
    }
  }
}
</script>

<style scoped>

</style>
