<template>
  <!-- 'event' removes the default event to listen to so a click event can be prevented (e.g. for dropdown) -->
  <router-link class="sidebar-link-toggle" to="" event @click.native.prevent="onClick">
    <div class="toggle-link" :class="{ 'active': value }">
      <div>
        <!-- Icon -->
        <font-awesome-icon v-if="icon" :icon="icon" fixed-width class="mr-3"/>
        <!-- Link text -->
        <slot/>
      </div>
      <font-awesome-icon class="chevron" icon="chevron-down" fixed-width :class="{ 'fa-rotate-180': value }"/>
    </div>
    <b-collapse v-model="modelHelper">
      <div class="pl-4">
        <slot name="dropdown"/>
      </div>
    </b-collapse>
  </router-link>
</template>

<script>
export default {
  props: {
    /**
     * If an icon is set it is displayed next to the text.
     */
    icon: {
      required: false,
      type: String,
    },
    /**
     * Determine whether the dropdown is open or closed.
     */
    value: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    modelHelper: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    onClick (e) {
      this.modelHelper = !this.modelHelper
      this.$emit('click', e)
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebar-link-toggle {
  display: block;
  text-decoration: none !important;

  .toggle-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, .6);
    text-decoration: none;
    padding: 1rem 1.5rem;
    font-size: 16px;
  }

  .toggle-link,
  .toggle-link .chevron {
    transition: var(--emp-transition-duration);
  }

  .toggle-link:hover,
  .toggle-link.active {
    color: white;
  }
}
</style>
