import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkOrder extends NetworkHelper {

  static getOrderOverview(config) {
    return axios.get('/api/orderoverview', config);
  }

  static updateOrder(order, config) {
    return axios.put(`/api/order/${order.id}`, order, config);
  }

  static updateOrder(order, config) {
    return axios.put(`/api/campaign/campaignOrder/${order.id}`, order, config);
  }

  /**
   * Soft Delete of orders with respective Id
   */
  static deleteOrder(orderIds, config) {
    return axios.delete('/api/order',
        {data: {orders: orderIds}, ...config});
  }

  /**
   * Soft deletes orders with the respective ids
   */
  static deleteCampaignOrder(orderIds, config) {
    return axios.delete('/api/campaign/campaignOrder',
        {data: {orders: orderIds}, ...config});
  }

}
