<template>

    <b-container id="request-password" class="mt-5">
        <PageHeading>{{$t('auth.requestPassword.pageHeading')}}</PageHeading>
        <div class="mt-3">
            <b-form @submit="onSubmit">
                <FormInput
                        v-model="form.email"
                        :editable="true" type="email"
                        :label="$t('auth.requestPassword.fields.email.label')"
                        :placeholder="$t('auth.requestPassword.fields.email.placeholder')"
                        required
                />
                <Button
                        :loading="loading"
                        class="mt-3"
                        type="submit"
                        variant="primary"
                >
                    {{$t('auth.requestPassword.fields.submit.label')}}
                </Button>
            </b-form>
            <div class="mt-3">
                <b-alert v-if="message" :show="true" variant="success">
                    <span class="smaller">{{message}}</span>
                </b-alert>
                <b-alert v-if="error" :show="true" variant="danger">
                    <span class="smaller">{{error}}</span>
                </b-alert>
            </div>
        </div>
    </b-container>

</template>

<script>
  import FormInput from '../../components/base/input/FormInput';
  import PageHeading from '../../components/base/typography/PageHeading';
  import Button from '../../components/base/button/Button';
  import NetworkAuth from '../../helper/network/NetworkAuth';

  export default {
    components: {Button, PageHeading, FormInput},
    data() {
      return {
        loading: false,
        form: {
          email: null,
        },
        message: null,
        error: null,
      };
    },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        this.loading = true;
        NetworkAuth.requestPassword(this.form).
            then(res => this.message = res.data.message).
            catch(err => this.error = err.response.data.message).
            then(() => this.loading = false);
      },
    },
  };
</script>

<style scoped>
</style>
