<template>
  <ViewContainer id="user">
    <ViewToggle :show-detailed-view="selUser">
      <!-- Main View -->
      <template slot="main-view">
        <!-- Table -->
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('user.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton v-model="filter"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy.sync="isBusy"
              :filter.sync="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :no-local-sorting="true"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('user.no_data') }}</span>
            </template>
            <!-- Filters -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('user.loading') }}</strong>
              </div>
            </template>
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.email"
                      :placeholder="$t('general.filter.email')"
                      type="search"

                      debounce="500"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.salutation"
                      :placeholder="$t('general.filter.salutation')"
                      type="search"

                      debounce="500"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.firstname"
                      :placeholder="$t('general.filter.firstname')"
                      type="search"

                      debounce="500"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.lastname"
                      :placeholder="$t('general.filter.lastname')"
                      type="search"

                      debounce="500"
                  />
                </b-th>
              </b-tr>
            </template>
          </b-table>
        </ViewElement>
      </template>
      <!-- Detailed View -->
      <template slot="detailed-view">
        <ViewElement class="mb-3">
          <UserDetail
              @item:saved="onItemSaved"
          />
        </ViewElement>
        <ViewElement class="mb-3">
          <UserAdmin
              :user="selUser"
              @item:closed="onItemClosed"
              @item:saved="onItemSavedAdmin"
          />
        </ViewElement>
        <ViewElement>
          <UserPasswordSet
              :user="selUser"
              @item:closed="onItemClosed"
          />
        </ViewElement>
      </template>
    </ViewToggle>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../components/base/ViewContainer';
import ViewToggle from '../../components/base/ViewToggle';
import ViewElement from '../../components/base/ViewElement';
import NetworkUser from '../../helper/network/NetworkUser';
import UserDetail from '../user/UserDetail';
import UserAdmin from './UserAdmin';
import PageHeading from '../../components/base/typography/PageHeading';
import {paginate} from '../../helper/mixins/paginate';
import UserPasswordSet from './UserPasswordSet';
import RemoveFilterButton from "../../components/base/button/RemoveFilterButton";
import PerPagePicker from '@/components/PerPagePicker'

export default {
  components: {
    PerPagePicker,
    RemoveFilterButton,
    UserPasswordSet, PageHeading, UserAdmin, UserDetail, ViewElement, ViewToggle, ViewContainer
  },
  mixins: [paginate],
  data() {
    return {
      // Table display options
      fields: [
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'salutation',
          sortable: true,
        },
        {
          key: 'firstname',
          sortable: true,
        },
        {
          key: 'lastname',
          sortable: true,
        },
      ],
      // Item handling
      items: [],
      // Busy state
      isBusy: false,
    };
  },
  computed: {
    selUser() {
      return this.$store.state.user.selUser
    }
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      this.items = await NetworkUser.getUsers({params: this.getQuery()}).then(res => {
        this.totalItems = res.data.meta.total;
        this.isBusy = false;
        this.updateRoute();
        return res.data.data;
      });
    },
    onRowClicked(item) {
      if (this.selUser && this.selUser.id === item.id) {
        this.$store.commit("user/setSelUser", null);
      } else {
        this.$store.commit("user/setSelUser", JSON.parse(JSON.stringify(item)));
      }
    },
    onItemClosed() {
      this.$store.commit("user/setSelUser", null);
    },
    onItemSaved(itemSaved) {
      const indexToUpdate = this.items.findIndex(item => item.id === itemSaved.id);
      // To keep everything up to date, update both the items array and the selected item.
      // Otherwise it might happen, that e.g. the comment is edited, then admin rights are changed, then the comment
      // flips back to the old comment before it was saved. The API is updated, but the UI then shows something else.
      // This might be confusing for users.
      this.items.splice(indexToUpdate, 1, Object.assign(this.items[indexToUpdate], itemSaved));
      this.$store.commit("user/setSelUser", Object.assign(this.selUser, itemSaved));
    },
    onItemSavedAdmin(itemSaved, grant) {
      const indexToUpdate = this.items.findIndex(item => item.id === itemSaved.id);
      const itemToUpdate = this.items[indexToUpdate];
      itemToUpdate.administrator = grant ? 1 : 0;
      // To keep everything up to date, update both the items array and the selected item.
      // Otherwise it might happen, that e.g. the comment is edited, then admin rights are changed, then the comment
      // flips back to the old comment before it was saved. The API is updated, but the UI then shows something else.
      // This might be confusing for users.
      this.items.splice(indexToUpdate, 1, Object.assign(this.items[indexToUpdate], itemToUpdate));
      this.$store.commit("user/setSelUser", Object.assign({}, itemToUpdate))
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (this.selUser && item.id === this.selUser.id) return 'selected';
    },
  },
};
</script>

<style scoped>
</style>
