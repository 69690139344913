<template>
    <div class="catalogue-detail">
        <!-- Catalogue Details -->
        <Collapsible id="toggle-catalogue-detail" :visible="true">
            <template slot="heading">{{$t('catalogue.detail.heading')}}</template>
            <template slot="content">
                <FormGroup :label="$t('catalogue.fields.id.label')">
                    <FormInput v-model="catalogueComputed.id"/>
                </FormGroup>
                <FormGroup :label="$t('catalogue.fields.name.label')">
                    <FormInput v-model="catalogueComputed.name" editable :errors="errors"
                               error-field-name="name"/>
                </FormGroup>
                <FormGroup :label="$t('catalogue.fields.description.label')">
                    <FormInput v-model="catalogueComputed.description" textarea editable
                               :errors="errors"
                               error-field-name="description"/>
                </FormGroup>
            </template>
        </Collapsible>
        <Collapsible class="mt-4" id="toggle-category-detail-product">
            <template slot="heading">{{$t('catalogue.detail.products.heading')}}</template>
            <template slot="content">
                <div v-if="draggableProductsFiltered.length > 0" class="wrapper">
                    <FormInput
                            v-model="filter"
                            :placeholder="$t('general.filter.default')"
                            type="search"
                            editable
                    />
                    <b-list-group>
                        <draggable v-model="draggableProducts">
                            <b-list-group-item
                                    v-for="p in draggableProductsFiltered"
                                    :key="p.order"
                                    :class="{'is-not-active': p.is_active === 0}"
                                    class="pointer-move product-list-item"
                            >
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <font-awesome-icon icon="grip-lines" fixed-width class="mr-3"/>
                                        <span class="smaller">
                                    <!--
                                    The products sent along with the catalogue have an object 'jobportal' which has
                                    a name property while the procuts sent along with the 'getProducts' request
                                    have a string 'jobportal' which represents the jobportals name.
                                    Therefore the check below is necessary.
                                    -->
                                    <b>{{p.jobportal.name || p.jobportal}}</b>
                                    <br>
                                    {{p.name}}
                                </span>
                                    </div>
                                    <div>
                                        <DeleteButtonCircle
                                                variant="danger"
                                                :icon-only="true"
                                                @click.native="onRemoveItem(p)"
                                        />
                                    </div>
                                </div>
                            </b-list-group-item>
                        </draggable>
                    </b-list-group>
                </div>
                <div v-else>
                    <font-awesome-icon icon="exclamation-circle" fixed-width class="mr-1"/>
                    <b class="smaller">{{$t('catalogue.detail.noProducts.heading')}}</b>
                </div>
            </template>
        </Collapsible>
        <!-- Add product -->
        <CatalogueAddProduct
                class="mt-4"
                @item:clicked="onItemClicked"
                :addedProducts="draggableProducts"
        />
        <Separator/>
        <!-- Footer -->
        <div class="d-flex align-items-center justify-content-between">
            <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
            <Button icon="save" variant="success" :loading="loading"
                    @click.native="onSave">
                {{$t('general.button.save')}}
            </Button>
        </div>
    </div>
</template>

<script>
  import FormGroup from '../../../components/base/input/FormGroup';
  import FormInput from '../../../components/base/input/FormInput';
  import Button from '../../../components/base/button/Button';
  import Separator from '../../../components/base/Separator';
  import NetworkCatalogue from '../../../helper/network/NetworkCatalogue';
  import draggable from 'vuedraggable';
  import DeleteButtonCircle from '../../../components/base/button/DeleteButtonCircle';
  import CatalogueAddProduct from './CatalogueAddProduct';
  import Collapsible from '../../../components/base/Collapsible';
  import {toaster} from '@/helper/mixins/toaster';
  import {errorHelper} from '@/helper/mixins/error-helper';

  export default {
    components: {
      Collapsible,
      CatalogueAddProduct,
      DeleteButtonCircle,
      Separator,
      Button,
      FormInput,
      FormGroup,
      draggable,
    },
    mixins: [toaster, errorHelper],
    props: {
      catalogue: {
        required: true,
        type: Object,
      }
    },
    data() {
      return {
        loading: false,
        filter: null,
      };
    },
    watch: {
      catalogue() {
        this.resetErrors();
      },
    },
    computed: {
      catalogueComputed() {
        const clone = Object.assign({}, this.catalogue);
        // Right after a new catalogue was created it is displayed in the details view.
        // But after the creation the API sends back the newly created catalogue but without a 'products' property.
        // When the product is saved in the details view, the API throws an error because the products array is missing.
        // The below code fixes this issue by adding an empty array as products in case the property is missing.
        if (!clone.hasOwnProperty('products'))
          clone.products = [];

        return clone;
      },
      draggableProducts: {
        get() {
          return this.catalogueComputed.products.map((p, index) => {
            // Add an order property to the products.
            p.order = index;
            return p;
          });
        },
        set(products) {
          this.$emit('productsChanged', products);
        },
      },
      /**
       * Apply a very basic filter, which checks whether the filter words match the product name and
       * the job portal name in any combination.
       * @return {*}
       */
      draggableProductsFiltered: {
        get() {
          if (!this.filter)
            return this.draggableProducts;

          return this.draggableProducts.filter(p => {
            const productName = p.name;
            const jobPortalName = p.jobportal || p.jobportal.name;
            let combined = productName + jobPortalName;
            combined = combined.toLowerCase().replace(/\s/g, '');

            const filterWords = this.filter.toLowerCase().split(' ');
            const numberOfFilterWords = filterWords.length;
            return filterWords.
                // Get an array of booleans which are true when the current filter word was matched.
                map(word => combined.indexOf(word) >= 0).
                // Get all the true booleans.
                filter(bool => bool === true).
                // Check if the number of matched words matches the number of filter words.
                // This means that each word of the filter was found in the combined string.
                length === numberOfFilterWords;
          });
        },
      },
    },
    methods: {
      onSave() {
        this.loading = true;
        NetworkCatalogue.updateCatalogue(this.catalogueComputed).
            then(res => {
              this.resetErrors();
              this.showSuccessMessageFromRes(res);
              this.$emit('item:saved', this.catalogueComputed);
            }).
            catch(err => this.setErrors(err)).
            then(() => this.loading = false);
      },
      onRemoveItem(item) {
        this.draggableProducts = this.draggableProducts.filter(p => p.id !== item.id);
      },
      onItemClicked(item) {
        const productIsAlreadyInCatalogue = this.draggableProducts.filter(p => p.id === item.id).length > 0;
        if (!productIsAlreadyInCatalogue) {
          this.draggableProducts = [...this.draggableProducts, item];
        } else {
          this.showMessage({
            message: 'Das Produkt ist bereits Teil des Katalogs.',
            title: 'Achtung',
            variant: 'warning',
          });
        }
      },
      onClose() {
        this.$emit('item:closed');
      },
    },
  };
</script>

<style scoped>
    .wrapper {
        overflow-y: scroll;
        max-height: 700px;
    }

    .pointer-move {
        cursor: move;
    }

    .product-list-item .delete-button-circle {
        transform: scale(0);
        transition: 0.2s;
    }

    .product-list-item:hover .delete-button-circle {
        transform: scale(1);
    }

    >>> .is-not-active {
        background-color: #efefef;
    }
</style>
