<template>
  <div>
    <div class="company-user">
      <Collapsible id="toggle-company-detail-extension">
        <template slot="heading">{{ $t('company_rule_extensions.rule_collapsible_heading') }}</template>
        <template slot="content">
          <b-table
              :items="extension_rules"
              :fields="ruleExtensionFields"
              :bordered="true"
              :responsive="true"
          >
            <!-- Default -->
            <template #cell(active)="row">
              <b-form-checkbox v-model="row.item.active" switch :value="1" :unchecked-value="0"
                               class="align-self-center"
                               @click.native.prevent="activateOrInactivateRule(row.item)">
                <b-spinner v-if="row.item.id === loadingRuleId" variant="primary" class="align-top"/>
                <span v-else>{{ row.item.active === 1 ? $t('general.active') : $t('general.inactive') }}</span>
              </b-form-checkbox>
            </template>
            <template #cell(deleteButton)="row">
              <Button
                  icon="minus"
                  variant="danger"
                  @click.native="openConfirmationDialog(row.item.id)"
                  :loading="deleteLoading === row.item.id"
                  icon-only
              />
            </template>
            <template #cell(testButton)="row">
              <Button
                  icon="table"
                  variant="info"
                  :loading="testLoading === row.item.id"
                  @click.native="onTestRuleClicked(row.item)"
                  icon-only
              />
            </template>
            <template #cell(editButton)="row">
              <Button
                  icon="edit"
                  variant="warning"
                  @click.native="onRowClicked(row.item)"
                  icon-only
              />
            </template>
            <template #cell(copyButton)="row">
              <Button
                  icon="copy"
                  @click.native="onCopyClicked(row.item)"
                  icon-only
              />
            </template>
          </b-table>
          <div class="d-flex align-items-center justify-content-center">
            <Button variant="success" icon="plus" @click="onRuleAdd" :loading="loading">
              {{ $t('general.button.add') }}
            </Button>
          </div>
        </template>
      </Collapsible>
    </div>

    <ConfirmationDialog
        v-model="confirmationDialog"
        :text="$tc('company_rule_extensions.modal_delete_description')"
        :title="$tc('company_rule_extensions.modal_delete_title')"
        @ok="onRuleDelete"/>
    <CompanyRuleExtensionModal v-model="openRuleDialog" id="company-rule-extension-modal" :company="company"/>
    <CompanyTestRuleExtensionModal v-if="selectedRuleId" v-model="openTestDialog" id="company-test-rule-extension-modal"
                                   :company="company"
                                   :rule-id="selectedRuleId"/>

  </div>
</template>

<script>
import Collapsible from '../../components/base/Collapsible';
import Button from '../../components/base/button/Button';
import NetworkCompany from '../../helper/network/NetworkCompany';
import {toaster} from '@/helper/mixins/toaster';
import CompanyRuleExtensionModal from "./CompanyRuleExtensionModal";
import ConfirmationDialog from "@/components/dialog/ConfirmationDialog";
import CompanyTestRuleExtensionModal from "@/views/company/CompanyTestRuleExtensionModal";

export default {
  components: {
    CompanyTestRuleExtensionModal,
    Collapsible,
    Button,
    CompanyRuleExtensionModal,
    ConfirmationDialog
  },
  mixins: [toaster],
  data() {
    return {
      confirmationDialog: false,
      openRuleDialog: false,
      openTestDialog: false,
      loading: false,
      deleteLoading: null,
      testLoading: null,
      deleteRuleId: null,
      selectedRow: {},
      loadingRuleId: null,
      selectedRuleId: null,
      ruleExtensionFields: [
        {key: 'active', label: ""},
        {key: 'id', label: this.$t('id')},
        {key: 'name', label: this.$t('company_rule_extensions.name')},
        {key: 'editButton', label: this.$t('company_rule_extensions.rule_edit')},
        {key: 'copyButton', label: this.$t('company_rule_extensions.rule_copy')},
        {key: 'testButton', label: this.$t('company_rule_extensions.rule_test')},
        {key: 'deleteButton', label: this.$t('delete')},
      ],
    };
  },
  computed: {
    company() {
      return this.$store.state.company.selectedItem;
    },
    extension_rules() {
      return this.$store.state.company.selectedItem.extension_rules;
    },
  },
  methods: {
    onRowClicked(item) {
      this.$store.commit('company/setSelectedRuleExtension', item);
      this.openRuleDialog = true;
    },
    async onCopyClicked(item) {
      try {
        this.loading = true;
        const res = await NetworkCompany.copyRules(this.company.id, item.id);
        const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
        this.$store.commit('company/setCompanyExtensionRules', resRules.data);
        this.showSuccessMessageFromRes(res);
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false;
      }
    },
    openConfirmationDialog(id) {
      this.confirmationDialog = true
      this.deleteRuleId = id;
    },
    async onTestRuleClicked(item) {
      try {
        this.testLoading = item.id
        this.selectedRuleId = item.id;
        const affectedJobpostings = await NetworkCompany.testRules(this.company.id, item.id, {params: {page: 1}})
        this.$store.commit('company/setAffectedJobpostings', affectedJobpostings.data);
        this.openTestDialog = true;
      } catch (e) {
        this.showErrorMessageFromErr(e)
        this.selectedRuleId = null
      } finally {
        this.testLoading = null
      }
    },

    async onRuleDelete() {
      try {
        this.deleteLoading = this.deleteRuleId;
        const res = await NetworkCompany.deleteRuleExtensions(this.company.id, this.deleteRuleId);
        const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
        this.$store.commit('company/setCompanyExtensionRules', resRules.data);
        this.showSuccessMessageFromRes(res);
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.deleteLoading = null;
        this.deleteRuleId = null;
      }
    },
    async onRuleAdd() {
      try {
        this.loading = true;
        const res = await NetworkCompany.setRuleExtension(this.company.id, {
          id: null,
          name: this.$t('company_rule_extensions.new_rule'),
          rule: [],
          location_extensions: [],
          title_extensions: []
        });
        const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
        this.$store.commit('company/setCompanyExtensionRules', resRules.data);
        this.showSuccessMessageFromRes(res);
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false;
      }
    },

    async activateOrInactivateRule(row) {
      this.loadingRuleId = row.id
      try {
        this.loading = true;
        const res = await NetworkCompany.activateOrInactivateRule(this.company.id, row.id, !row.active);
        const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
        this.$store.commit('company/setCompanyExtensionRules', resRules.data);
        this.showSuccessMessageFromRes(res);
      } catch (e) {
        console.error(e)
        this.showErrorMessageFromErr(e)
      } finally {
        this.loadingRuleId = null;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

.pointer {
  cursor: pointer;
}
</style>
