import axios, { AxiosRequestConfig } from 'axios'
//@ts-ignore
import NetworkHelper from './NetworkHelper'

export default class NetworkIndeedBudget extends NetworkHelper {
  // get snapshots summary/overview within timespan
  static getIndeedBudgetSnapshotsOverview(start: Date, end: Date, config?: AxiosRequestConfig) {
    return axios.get(`/api/statistic/budget/overview?start=${start.getTime()}&end=${end.getTime()}`, config)
  }

  // get detailed snapshots within timespan
  static getIndeedBudgetSnapshots(start: Date, end: Date, config?: AxiosRequestConfig) {
    return axios.get(`/api/statistic/budget?start=${start.getTime()}&end=${end.getTime()}`, config)
  }

  // get subaccounts
  static getIndeedBudgetSubaccounts(config?: AxiosRequestConfig) {
    return axios.get('/api/statistic/budget/subaccounts', config);
  }
}
