<template>
    <Collapsible id="company-catalogue">
        <template slot="heading">{{$t('company.catalogue.heading')}}</template>
        <template slot="content">
            <b-list-group v-if="company.catalogue" class="mb-3">
                <b-list-group-item>
                    <span class="font-weight-bolder mr-2">{{company.catalogue.name}}</span><br/>
                    <span>{{company.catalogue.description}}</span>
                </b-list-group-item>
            </b-list-group>
            <!-- Button -->
            <div class="d-flex justify-content-between">
                <div>
                    <span v-if="!company.catalogue">{{$t('company.catalogue.none')}}</span>
                </div>
                <Button v-if="!company.catalogue" icon="plus" variant="success" @click.native="addingCatalogueToCompany = true">
                    {{$t('general.button.add')}}
                </Button>
                <Button v-else icon="exchange-alt" variant="success" @click.native="addingCatalogueToCompany = true">
                    {{$t('general.button.change')}}
                </Button>
            </div>

            <!-- Modal to add catalogue to company -->
            <b-modal
                    v-model="addingCatalogueToCompany"
                    :title="$t('company.catalogue.add')"
                    hide-footer
                    centered
            >
                <FormInput
                        v-model="selectedCatalogue"
                        :options="catalogues"
                        :description="$t('company.catalogue.fields.catalogue.description')"
                        class="mb-3"
                        selectable
                />
                <div class="d-flex justify-content-end">
                    <Button
                            icon="save"
                            variant="success"
                            @click.native="onSave"
                            :disabled="!selectedCatalogue"
                            :loading="loading"
                    >
                        {{$t('general.button.save')}}
                    </Button>
                </div>
            </b-modal>
        </template>
    </Collapsible>
</template>

<script>
  import Collapsible from '../../components/base/Collapsible';
  import Button from '../../components/base/button/Button';
  import FormInput from '../../components/base/input/FormInput';
  import NetworkCatalogue from '../../helper/network/NetworkCatalogue';
  import {toaster} from '../../helper/mixins/toaster';
  import NetworkCompany from "../../helper/network/NetworkCompany";

  export default {
    components: {FormInput, Button, Collapsible},
    mixins: [toaster],
    data() {
      return {
        addingCatalogueToCompany: false,
        selectedCatalogue: null,
        catalogues: [],
        loading: false,
      }
    },
    computed: {
      company() {
        return this.$store.state.company.selectedItem
      },
    },
    created() {
      this.loading = true
      NetworkCatalogue.getCataloguesSimplified().
          then(res => this.catalogues = res.data.data.map(item => {return {value: item, text: item.name};})).
          catch(err => console.error(err));
      this.loading = false
    },
    watch: {
      company() {
        this.selectedCatalogue = null;
      },
    },
    methods: {
      async onSave() {
        this.loading = true;
          try {
            const res = await NetworkCompany.updateCatalogue(this.company, {catalogue_id: this.selectedCatalogue.id})
            this.showSuccessMessageFromRes(res)
            this.$store.commit('company/setCompanyCatalogue', this.selectedCatalogue)
            this.addingCatalogueToCompany = false;
          } catch(err) {
            this.showErrorMessageFromErr(err)
          } finally {
            this.loading = false
          }
      }
    }
  };
</script>

<style scoped>

</style>
