import router from '../router';

function initQuery(page = 1, perPage = 10, sortBy = null, sortDir = 'asc') {
  return {
    page,
    perPage,
    sortBy,
    sortDir,
    sortDesc: sortDir === 'desc', // used for bootstrap table sorting
  };
}

async function updateRouteWithoutDuplication(queryState) {
  for (let q in queryState) {
    const curQuery = router.history.current.query[q];
    if (curQuery === undefined) {
      await router.push({ query: queryState });
    } else if (queryState[q].toString() !== curQuery.toString()) {
      router.push({ query: queryState });
      }
  }
}

export { initQuery, updateRouteWithoutDuplication };
