<template>
  <b-modal v-model="modelHelper" :title="title" :ok-title="$t('yes')" :cancel-title="$t('cancel')" @ok="handleOk">
    <p>{{ text }}</p>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    title: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String,
    }
  },
  methods: {
    handleOk() {
      this.$emit("ok")
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}

</script>

<style scoped>

</style>
