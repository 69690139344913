export default class JWT {

    /**
     * returns the token with access_token and expires_at as date in milliseconds
     * @returns {accessToken, expires_at}
     */
    static getToken() {
        return {
            access_token: localStorage.getItem('token.access_token'),
            expires_at: localStorage.getItem('token.expires_at'),
        };
    }

    /**
     * Returns the jwt token as string to put in the request headers
     * @returns {string}
     */
    static getBearer() {
        return localStorage.getItem('token.access_token');
    }

    /**
     * Returns true, if localStorage contains an access token
     * @returns {boolean}
     */
    static isLoggedIn() {
        return !!this.getBearer();
    }

    /**
     * Saves the token to the local storage
     * @param response
     */
    static setToken(response) {
        // Time in milliseconds
        const now = new Date().getTime();
        localStorage.setItem('token.access_token', response.access_token);
        localStorage.setItem('token.expires_at', now + (response.expires_in * 1000));
    }

    /**
     * Clears the token entries from the local storage
     */
    static unsetToken() {
        localStorage.removeItem('token.access_token');
        localStorage.removeItem('token.expires_at');
    }

    /**
     * Returns the entry that is appended to every request
     * @returns {{Authorization: string}|{}}
     */
    static authHeaders() {
        const token = this.getBearer();
        if (token) {
            return { 'Authorization': 'Bearer ' + token };
        } else {
            return {};
        }
    }

}
