<template>
  <div>
    <!-- campaign status -->
    <Collapsible id="toggle-campaign-detail-status" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('campaign.campaignStatus.heading') }}</template>
      <template slot="content">
        <b-spinner class="d-block mx-auto my-4" variant="secondary" v-if="campaignStatuses.length === 0"/>
        <b-table-simple v-else borderless>
          <b-tr>
            <b-td class="align-middle">{{ $t('campaign.campaignStatus.fields.status.label') }}</b-td>
            <b-td class="align-middle">
             <b-form-select v-model="campaignSel.campaign_status.name" :options="campaignStatuses"/>
            </b-td>
          </b-tr>
        </b-table-simple>
        <!-- Controls -->
        <div class="d-flex align-items-right justify-content-between">
          <div class="ml-auto">
            <Button
                :loading="savingStatus"
                @click.native="onSave"
                icon="save"
                variant="success"
            >
              {{ $t('general.button.save') }}
            </Button>
          </div>
        </div>
      </template>
    </Collapsible>
    <!-- campaign status history -->
    <Collapsible id="campaign-detail-status-history" :visible="true">
      <template slot="heading">{{ $t('campaign.campaignStatusHistory.heading') }}</template>
      <template slot="content">
        <div class="d-lg-flex">
          <!-- Pagination -->
          <div class="mr-5">
            <b-pagination
                v-model="currentPage"
                :total-rows="campaignSel.campaign_status_history.length"
                :per-page="perPage"
            />
          </div>
          <!-- Per Page -->
          <div class="mb-2">
            <div class="d-flex align-items-center">
              <div class="text-nowrap mr-2">{{ $t('per_page') }}:</div>
              <div>
                <b-form-select v-model="perPage" :options="perPageOptions" />
              </div>
            </div>
          </div>
        </div>
        <b-table
            :items="campaignSel.campaign_status_history"
            :fields="fieldsCampaignStatusHistory"
            :bordered="true"
            :responsive="true"
            :hover="true"
            :sort-by="'created_at'"
            :sort-desc="true"
            :per-page="perPage"
            :current-page="currentPage"
        />

        <b-modal
            id="show-status-change-conflicts-modal"
            v-model="showConflictsModal"
            :title="$t('campaign.campaignStatus.conflictModalTitle')"
            :ok-title="$t('campaign.campaignStatus.conflictModalButtonOk')"
            :ok-only="true"
            @hide="closeConflictsModal"
        >
          <p>{{ $t('campaign.campaignStatus.conflictModalDescription') }}:</p>
          <ul>
            <li v-for="c in conflicts" :key="c.jobPostingId">
              <i>{{ c.jobPostingTitle }} ({{ c.jobPostingId }})</i><br>{{ $t('campaign.campaignStatus.conflictModalListConnector') }}:<br><i>{{ c.campaignName }} ({{ c.campaignId }})</i><br><br>&nbsp;
            </li>
          </ul>

        </b-modal>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import NetworkCampaign from '../../../helper/network/NetworkCampaign'
import Button from '../../../components/base/button/Button'
import { toaster } from '../../../helper/mixins/toaster'
import Separator from '../../../components/base/Separator'

export default {
  mixins: [toaster],
  components: { Separator, Button, Collapsible },
  data () {
    return {
      loadingStatuses: false,
      savingStatus: false,
      campaignStatuses: [],
      perPage: 5,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      conflicts: null,
      showConflictsModal: false,
      fieldsCampaignStatusHistory: [
        {
          key: 'name',
          label: this.$t('campaign.campaignStatusHistory.fields.name.label'),
        },
        {
          key: 'description',
          label: this.$t('campaign.campaignStatusHistory.fields.description.label'),
        },
        {
          key: 'created_at',
          label: this.$t('campaign.campaignStatusHistory.fields.created_at.label'),
        }
      ],
    }
  },
  computed: {
    campaignSel () {
      return this.$store.state.campaign.selectedItem;
    },
  },
  /**
   * Load available statuses on created.
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.loadingStatuses = true
      const res = await NetworkCampaign.getCampaignStatuses()
      this.campaignStatuses = res.data.data.map(status => {
        // 'value' and 'text' are set to status.name because an object is required
        // to disable certain statuses. Whether statuses are disabled or not depends
        // on the job posting. If the job posting is from an xml feed there are less statuses
        // available than there are when the job posting was added manually.
        return { value: status.name, text: status.name }
      })
    } catch (err) {
      this.showErrorMessageFromErr(err)
    } finally {
      this.loadingStatuses = false
    }
  },
  methods: {
    async onSave () {
      this.savingStatus = true
      let conflicts = await this.$store.dispatch('campaign/updateCampaignStatus', { vm: this })
      if (conflicts !== null) {
        this.conflicts = conflicts.data;
        this.showConflictsModal = true;
      } else {
        this.conflicts = null;
        this.showConflictsModal = false;
      }
      this.savingStatus = false
    },
    onClose () {
      this.$store.commit('campaign/setSelectedItem', null)
    },
    closeConflictsModal() {
      this.conflicts = null;
      this.showConflictsModal = false;
    },
  },
}
</script>

<style scoped></style>
