<template>

  <Collapsible id="order-product" visible>
    <template slot="heading">{{ $t('order.product.heading') }}</template>
    <template slot="content">
      <b-table
          :items="order.products"
          :fields="fields"
          :responsive="true"
          :bordered="true"
      >
        <template #cell(is_active)="data">
          <b-checkbox v-model="data.value" value="1" unchecked-value="0" disabled/>
        </template>
      </b-table>
    </template>
  </Collapsible>

</template>

<script>
import Collapsible from '@/components/base/Collapsible';

export default {
  components: {Collapsible},
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t('order.fields.products.name.label'),
        },
        {
          key: "description",
          label: this.$t('order.fields.products.description.label'),
        },
        {
          key: "is_active",
          label: this.$t('order.fields.products.is_active.label'),
        },
        {
          key: "term_in_days",
          label: this.$t('order.fields.products.term_in_days.label'),
        },
      ]
    }
  },
};
</script>

<style scoped>

</style>
