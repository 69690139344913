import NetworkJobPosting from '../../helper/network/NetworkJobPosting'
import { initQuery, updateRouteWithoutDuplication } from '@/helper/pagination'
import i18n from '../../i18n'

// TODO: refactor, there is toaster.js for toaster, maybe duplication is not the best solution here
const showSuccessMsg = (vm, msg) => vm.$bvToast.toast(msg, { title: i18n.t('general.toast.title.success'), variant: 'success' })
const showErrorMsg = (vm, msg) => vm.$bvToast.toast(msg, {
  title: i18n.t('general.toast.title.error'),
  variant: 'danger',
  autoHideDelay: 1000 * 60 * 60 * 24, // auto hide after one day ;)
})

export default {
  namespaced: true,
  state: () => ({
    // Data
    jobPostings: [],
    jobPostingsSel: [],
    jobPostingSel: null,
    // Pagination / Query
    query: initQuery(1, 10, 'created_at', 'desc'),
    meta: null,
    // Loading states
    isBusy: false,
    savingJobPosting: false,
  }),
  mutations: {
    setJobPostings: (state, jobPostings) => state.jobPostings = jobPostings,
    setJobPostingsSel: (state, jobPostings) => state.jobPostingsSel = jobPostings,
    addJobPostingsSel: (state, jobPostings) => {
      //Make the array of selected jobs unique
      state.jobPostingsSel = [... new Set(state.jobPostingsSel.concat(jobPostings))]
    },
    removeJobPostingsSel: (state, jobPostings) => {
      state.jobPostingsSel = state.jobPostingsSel.filter(jobPostingSel => {
        return !jobPostings.some(jp => jp.id === jobPostingSel.id)
      })
    },
    setJobPostingSel: (state, jobPosting) => state.jobPostingSel = jobPosting,
    setQuery: (state, query) => state.query = query,
    setMeta: (state, meta) => state.meta = meta,
    setBusy: (state, isBusy) => state.isBusy = isBusy,
  },
  actions: {
    /**
     * Fetch job postings paginated for display in table.
     * @param state
     * @param commit
     * @returns {Promise<AxiosResponse<any>|*>}
     */
    fetchJobPostings: async ({ state, commit }) => {
      try {
        commit('setBusy', true)
        const res = await NetworkJobPosting.getJobPostings({ params: state.query })
        commit('setJobPostings', res.data.data)
        commit('setMeta', res.data.meta)
        await updateRouteWithoutDuplication(state.query)
        return res;
      } catch (err) {
        console.error(err)
        return err
      } finally {
        commit('setBusy', false)
      }
    },
    /**
     * fetch all jobPostings and commit all selected Jobpostings new
     * @param state
     * @param commit
     * @param dispatch
     * @param vm
     * @returns {Promise<void>}
     */
    fetchSelJobPostings: async ({state, commit, dispatch}, { vm }) => {
      const selectedJobPostings = state.jobPostingsSel;
      await dispatch('fetchJobPostings')
      const newSelectedJobPostings = [];
      for (let jobPosting of state.jobPostings) {
        const found = selectedJobPostings.findIndex(job => job.id === jobPosting.id);
        if (found >= 0) {
          newSelectedJobPostings.push(jobPosting)
        }
      }
      if (selectedJobPostings.length !== newSelectedJobPostings.length) {
        showSuccessMsg(vm, i18n.t('general.selection_adjusted'))
      }
      commit('setJobPostingsSel', newSelectedJobPostings)
    },
    /**
     * Fetch a detailed job posting.
     * @param commit
     * @param jobPosting
     * @returns {Promise<void>}
     */
    fetchJobPosting: async ({ commit }, jobPosting) => {
      try {
        commit('setBusy', true)
        const { data } = await NetworkJobPosting.getJobPosting(jobPosting)
        commit('setJobPostingSel', data.data)
      } catch (err) {
        console.error(err)
      } finally {
        commit('setBusy', false)
      }
    },
    /**
     * Save the selected job posting and return the result.
     */
    saveJobPostingSel: async ({ state, dispatch }, { vm }) => {
      try {
        const res = await NetworkJobPosting.updateJobPosting(state.jobPostingSel)
        // Fetch the current job postings
        dispatch('fetchJobPostings')
        // Return the response
        showSuccessMsg(vm, res.data.message)
      } catch (err) {
        console.error(err)
        showErrorMsg(vm, err.response.data.message)
      }
    },
    /**
     * Update the status for the selected job posting.
     * @param state
     * @param dispatch
     * @param vm
     * @returns {Promise<AxiosResponse<any>|*>}
     */
    updateJobPostingStatus: async ({ state, dispatch }, { vm }) => {
      try {
        const res = await NetworkJobPosting.updateJobPostingStatus(state.jobPostingSel)
        // Update status history
        state.jobPostingSel.status_history = res.data.data.status_history
        // Fetch the current job postings
        dispatch('fetchJobPostings')
        // Show success message
        showSuccessMsg(vm, res.data.message)
      } catch (err) {
        showErrorMsg(vm, err.response.data.message)
      }
    },
    updateJobPostingUtmParameters: async ({ state }, { vm }) => {
      try {
        const res = await NetworkJobPosting.updateJobPostingUTMParameters(state.jobPostingSel)
        showSuccessMsg(vm, res.data.message)
      } catch (err) {
        showErrorMsg(vm, err.response.data.message)
      }
    },
    updateJobPostingCompany: async ({ state }, { vm, company }) => {
      try {
        const res = await NetworkJobPosting.changeAssociatedCompany(state.jobPostingSel, company)
        showSuccessMsg(vm, res.data.message)
      } catch (err) {
        showErrorMsg(vm, err.response.data.message)
      }
    },
    /**
     * Reset the query to default (reset filters).
     * @param commit
     */
    resetQuery: ({ commit }) => commit('setQuery', initQuery(1, 10, 'created_at', 'desc')),
    resetState: ({ commit }) => {
      commit('setJobPostingsSel', [])
      commit('setJobPostingSel', null)
    },
  },
}
