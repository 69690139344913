import NetworkJobPortal from '@/helper/network/NetworkJobPortal'

export default {
  namespaced: true,
  state: {
    jobPortals: [],
    jobPortalsLoading: false,
    selJobPortal: null,
    selJobPortalLoading: false,
    /**
     * An array holding all products with changes
     */
    productsDirty: [],
  },
  mutations: {
    setJobPortals: (state, jobPortals) => state.jobPortals = jobPortals,
    setJobPortalsLoading: (state, loading) => state.jobPortalsLoading = loading,
    setSelJobPortal: (state, jobPortal) => state.selJobPortal = jobPortal,
    setSelJobPortalLoading: (state, loading) => state.selJobPortalLoading = loading,
    /**
     * Replace the old job portal with the saved job portal.
     * @param state
     * @param jobPortal
     */
    updateSavedJobPortal: (state, jobPortal) => {
      const indexOfJobPortal = state.jobPortals.findIndex(jp => jp.id === jobPortal.id)
      state.jobPortals.splice(indexOfJobPortal, 1, jobPortal)
    },
    addProductDirty: (state, product) => {
      const prodAlreadyInArr = state.productsDirty.findIndex(p => p.id === product.id) >= 0
      if (!prodAlreadyInArr) {
        state.productsDirty.push(product)
      }
    },
    removeProductDirty: (state, product) => {
      const prodIndex = state.productsDirty.findIndex(p => p.id === product.id)
      state.productsDirty.splice(prodIndex, 1)
    },
    resetProductsDirty: (state) => state.productsDirty = [],
  },
  actions: {
    fetchJobPortals: async ({ commit }, config) => {
      try {
        commit('setJobPortalsLoading', true)
        const { data } = await NetworkJobPortal.getJobPortals(config)
        commit('setJobPortals', data.data)
        return data
      } catch (err) {
        console.error(err)
        return false
      } finally {
        commit('setJobPortalsLoading', false)
      }
    },
    fetchJobPortal: async ({ commit }, { jobPortal, config }) => {
      try {
        commit('setSelJobPortalLoading', true)
        const { data } = await NetworkJobPortal.getJobPortal(jobPortal, config)
        commit('setSelJobPortal', data.data)
      } catch (err) {
        console.error(err)
      } finally {
        commit('setSelJobPortalLoading', false)
      }
    },
  },
  getters: {
    getHasDirtyProduct: state => state.productsDirty.length > 0,
  },
}
