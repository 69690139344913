<template>

  <!-- Modal add product -->
  <b-modal id="job-posting-order-create" :title="$t('jobPosting.detail.jobPostingOrder.add.title')" centered
           hide-footer>
    <!-- Select job portal -->
    <b-form-group :label="$t('jobPosting.detail.jobPostingOrder.add.fields.jobPortal.label')"
                  :description="$t('jobPosting.detail.jobPostingOrder.add.fields.jobPortal.description')" class="mb-3">
      <b-form-select v-model="selectedJobPortal" :options="jobPortalOptions"/>
    </b-form-group>
    <!-- Select product -->
    <b-form-group :label="$t('jobPosting.detail.jobPostingOrder.add.fields.product.label')">
      <b-form-checkbox-group v-if="selectedJobPortal" v-model="selectedProducts"
                             :disabled="selectedJobPortal.products.length === 0">
        <b-form-checkbox v-for="(item, index) in selectedJobPortal.products" :key="index" :value="item.id"
                         class="d-block">
          <span>{{ item.name }}</span>
        </b-form-checkbox>
      </b-form-checkbox-group>
      <div v-else>
        <font-awesome-icon icon="exclamation-triangle"/>
        {{ $t('jobPosting.detail.jobPostingOrder.add.fields.product.noProducts.label') }}
      </div>
    </b-form-group>
    <!-- Budget -->
    <div class="mb-3">
      <label>{{ $t('jobPosting.detail.jobPostingOrder.add.fields.budget.label') }}</label>
      <div class="d-flex align-items-center">
        <b-input v-model="budget" :formatter="formatBudget" type="text" class="budget mr-1" placeholder="150"/>
        <span>€</span>
      </div>
    </div>
    <!-- Save button -->
    <div class="d-flex justify-content-end">
      <Button @click.native="onCreateProduct" :disabled="this.selectedProducts.length === 0" :loading="loading"
              icon="save" variant="success">
        {{ $t('general.button.create') }}
      </Button>
    </div>
  </b-modal>

</template>

<script>
import Button from '@/components/base/button/Button'
import NetworkJobPosting from '@/helper/network/NetworkJobPosting'
import NetworkJobPortal from '@/helper/network/NetworkJobPortal'
import {toaster} from '@/helper/mixins/toaster'

export default {
  components: {Button},
  mixins: [toaster],
  data() {
    return {
      /**
       * Array holding all job portals fetched from the API (simplified).
       */
      jobPortals: [],
      /**
       * The job portal selected which determines what products are displayed.
       */
      selectedJobPortal: null,
      /**
       * The products selected.
       */
      selectedProducts: [],
      /**
       * The budget the products should be ordered with.
       */
      budget: null,
      /**
       * Loading state of creating new order.
       */
      loading: false,
    }
  },
  computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
    jobPortalOptions() {
      let jobPortals = this.jobPortals.filter(jp => jp.products.length > 0).map(jp => ({value: jp, text: jp.name}))
      jobPortals = jobPortals.sort((a, b) => {
        const nameA = a.text.toUpperCase();
        const nameB = b.text.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      return jobPortals
    },
  },
  watch: {
    selectedJobPortal() {
      this.selectedProducts = []
    },
  },
  /**
   * Fetch job portals on created.
   * @returns {Promise<void>}
   */
  async created() {
    try {
      this.loading = true
      const {data} = await NetworkJobPortal.getJobPortalsSimplified()
      this.jobPortals = data.data
    } catch (err) {
      this.showErrorMessageFromErr(err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async onCreateProduct() {
      try {
        const order = {products: this.selectedProducts}
        if (this.budget) order.budget = this.budget
        const res = await NetworkJobPosting.createOrderForJobPosting(this.jobPostingSel, order)
        // Update jobPostingSel in store (triggered by watcher in JobPosting.vue)
        this.jobPostingSel.orders = res.data.data.orders
        // Show success toast
        this.showSuccessMessageFromRes(res)
        // Reset form
        this.selectedJobPortal = null
        this.selectedProducts = []
        this.budget = null
        // Emit 'created' event
        this.$emit('created', res.data.data.orders)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      }
    },
    formatBudget(value) {
      const isDigitsOnly = /^\d+$/.test(value)
      const isValidLength = value.length <= 5
      if (!isDigitsOnly) {
        return value.substr(0, value.length - 1)
      }
      if (!isValidLength) {
        return value.substr(0, value.length - 1)
      }
      return value
    },
  },
}
</script>

<style scoped>
.budget {
  max-width: 120px;
}
</style>
