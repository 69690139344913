<template>
  <b-row class="smaller">
    <b-col>
      <strong>{{ jobPosting.customer_refnum }}</strong>
    </b-col>
    <b-col>
      {{jobPosting.title}}
    </b-col>
    <b-col>
      {{jobPosting.city}}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    jobPosting: {
      required: true,
      type: Object,
    }
  },
};
</script>

<style scoped>

</style>
