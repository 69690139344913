<template>
  <div>
    <Collapsible id="toggle-job-posting-detail-utmParameter" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('utmParameter.heading') }}</template>
      <template slot="content">
        <UtmParameterPicker
            v-model="utmParameters"/>

        <div class="d-flex justify-content-end align-items-center">
          <Button
              variant="success"
              :loading="loading"
              icon="save"
              @click="onSave">
            {{ $t("general.button.save") }}
          </Button>
        </div>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import UtmParameterPicker from "../../../components/UtmParameterPicker";
import Button from "../../../components/base/button/Button";
import {toaster} from "../../../helper/mixins/toaster";
import Collapsible from "../../../components/base/Collapsible";
import {clone} from "../../../helper/utility";
import NetworkCampaign from "../../../helper/network/NetworkCampaign";

export default {
  components: {Collapsible, UtmParameterPicker, Button},
  mixins: [toaster],
  data() {
    return {
      utmParameters: [],
      loading: false
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler(val) {
        this.utmParameters = clone(val.utm_parameters)
      }
    }
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.selectedItem
    }
  },
  methods: {
    async onSave() {
      this.loading = true
      try {
        const res = await NetworkCampaign.updateCampaignUTMParameters(this.campaign.id, this.utmParameters)
        this.$store.commit('campaign/setUTMParameters', this.utmParameters)
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>