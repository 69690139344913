<template>
  <Collapsible id="create-company">
    <template slot="heading">{{ $t('company.create.heading') }}</template>
    <template slot="content">
      <b-card>
        <b-form @submit.prevent="onSubmit">
          <div class="row mb-3">
            <!-- Name -->
            <div class="col-md-6 col-lg-4 my-2">
              <FormInput
                  v-model="form.name"
                  :editable="true"
                  :label="$t(`company.fields.name.label`)"
                  :placeholder="$t(`company.fields.name.placeholder`)"
                  :errors="errors"
                  error-field-name="name"
              />
            </div>
          </div>
          <!-- Buttons -->
          <Button :loading="loading" icon="plus" type="submit" variant="success">
            {{ $t('general.button.create') }}
          </Button>
        </b-form>
      </b-card>
    </template>
  </Collapsible>
</template>

<script>
import FormInput from '../../components/base/input/FormInput';
import Button from '../../components/base/button/Button';
import {toaster} from '../../helper/mixins/toaster';
import {errorHelper} from '../../helper/mixins/error-helper';
import Collapsible from '../../components/base/Collapsible';
import NetworkCompany from "../../helper/network/NetworkCompany";

export default {
  components: {Collapsible, Button, FormInput},
  mixins: [toaster, errorHelper],
  data() {
    return {
      // Form
      form: {
        name: null,
      },
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true
      try {
        const res = await NetworkCompany.createCompany(this.form)
        this.showSuccessMessageFromRes(res)
        await this.$store.dispatch('company/fetchCompanies')
        this.$store.commit('company/setSelectedItem', res.data.data)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
        this.resetForm();
      }
    },
    resetForm() {
      this.form.name = null;
      this.form.street = null;
      this.form.postcode = null;
      this.form.city = null;
      this.form.country = null;
    },
    onFileChanged(event) {
      this.logo = event.target.files[0];
    },
  },
};
</script>

<style scoped>
</style>
