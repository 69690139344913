<template>
  <div>
    <Collapsible id="company-user-add">
      <template slot="heading">{{ $t('company.users.add.heading') }}</template>
      <template slot="content">
        <b-form>
          <!-- Input fields -->
          <div class="mb-3">
            <b-row class="mb-3">
              <b-col>
                <FormInput
                    v-model="form.email"
                    :label="$t('user.fields.email.label')"
                    :errors="errors"
                    error-field-name="email"
                    type="email"
                    editable
                />
              </b-col>
              <b-col>
                <FormInput
                    v-model="form.salutation"
                    :label="$t('user.fields.salutation.label')"
                    :options="salutationOptions"
                    :errors="errors"
                    error-field-name="salutation"
                    selectable
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <FormInput
                    v-model="form.firstname"
                    :label="$t('user.fields.firstname.label')"
                    :errors="errors"
                    error-field-name="firstname"
                    editable
                />
              </b-col>
              <b-col>
                <FormInput
                    v-model="form.lastname"
                    :label="$t('user.fields.lastname.label')"
                    :errors="errors"
                    error-field-name="lastname"
                    editable
                />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <FormInput
                    v-model="form.password"
                    :label="$t('user.fields.password.label')"
                    :errors="errors"
                    error-field-name="password"
                    type="password"
                    editable
                />
              </b-col>
              <b-col>
                <FormInput
                    v-model="form.password_confirmation"
                    :label="$t('user.fields.password_confirmation.label')"
                    :errors="errors"
                    error-field-name="password_confirmation"
                    type="password"
                    editable
                />
              </b-col>
            </b-row>
          </div>
          <Separator/>
          <!-- Footer -->
          <div class="d-flex justify-content-end">
            <Button
                type="submit"
                @click.prevent="onSave"
                :loading="loading"
                variant="success"
                icon="plus"
            >
              {{ $t('general.button.create') }}
            </Button>
          </div>
        </b-form>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import FormInput from '../../components/base/input/FormInput';
import Button from '../../components/base/button/Button';
import Separator from '../../components/base/Separator';
import Collapsible from '../../components/base/Collapsible';
import {toaster} from '../../helper/mixins/toaster';
import {errorHelper} from '../../helper/mixins/error-helper';
import NetworkCompany from "../../helper/network/NetworkCompany";

export default {
  mixins: [toaster, errorHelper],
  components: {Collapsible, Separator, Button, FormInput},
  data() {
    return {
      form: {
        email: null,
        salutation: null,
        firstname: null,
        lastname: null,
        password: null,
        password_confirmation: null,
      },
      salutationOptions: [
        {
          value: this.$t('user.fields.salutation.options.female'),
          text: this.$t('user.fields.salutation.options.female')
        },
        {value: this.$t('user.fields.salutation.options.male'), text: this.$t('user.fields.salutation.options.male')},
        {
          value: this.$t('user.fields.salutation.options.diverse'),
          text: this.$t('user.fields.salutation.options.diverse')
        },
      ],
      loading: false
    };
  },
  computed: {
    company() {
      return this.$store.state.company.selectedItem
    },
  },
  methods: {
    async onSave() {
      this.loading = true
      const user = Object.assign({}, this.form);
      try {
        const res = await NetworkCompany.addUser(this.company, user)
        this.showSuccessMessageFromRes(res)
        this.$store.commit('company/addUserToCompany', user)
        this.resetForm();
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$set(this.form, 'email', null);
      this.$set(this.form, 'salutation', null);
      this.$set(this.form, 'firstname', null);
      this.$set(this.form, 'lastname', null);
      this.$set(this.form, 'password', null);
      this.$set(this.form, 'password_confirmation', null);
    },
  },
};
</script>

<style scoped>

</style>
