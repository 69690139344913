<template>
  <ViewContainer id="campaign">
    <ViewToggle :show-detailed-view="selectedItem">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('campaign.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination v-model="query.page" :total-rows="totalPages" :per-page="query.perPage"/>
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2" cols="auto">
              <PerPagePicker v-model="query.perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton @click="$store.dispatch('campaign/resetQuery')"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy="isBusy"
              :sort-by="query.sortBy"
              :sort-desc="query.sortDesc"
              @sort-changed="sortingChanged"
              :no-local-sorting="true"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('campaign.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('campaign.loading') }}</strong>
              </div>
            </template>
            <!-- Cell 'created_at' -->
            <template #cell(created_at)="data">
              {{ getFormattedDate(data.value) }}
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.name"
                      :placeholder="$t('general.filter.name')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.jobposting_count"
                      :placeholder="$t('general.filter.jobposting_count')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.company"
                      :placeholder="$t('general.filter.company')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary"/>
              </b-tr>
            </template>
          </b-table>
        </ViewElement>
        <!-- Create campaign -->
        <ViewElement>
          <CampaignCreate
          />
        </ViewElement>
      </template>
      <template slot="detailed-view">
        <!-- Campaign detail -->
        <ViewElement class="mb-3">
          <CampaignDetail
          />
        </ViewElement>
        <!-- Campaign status -->
        <ViewElement class="mb-3">
          <CampaignStatus/>
        </ViewElement>
        <!-- Job postings -->
        <ViewElement class="mb-3">
          <CampaignJobposting
          />
        </ViewElement>
        <!-- Company -->
        <ViewElement class="mb-3">
          <CampaignCompany
          />
        </ViewElement>
        <!-- Orders -->
        <ViewElement class="mb-3">
          <CampaignOrder
          />
        </ViewElement>
        <!-- UTM-Parameter -->
        <ViewElement class="mb-3">
          <CampaignUTM
          />
        </ViewElement>
      </template>
    </ViewToggle>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../../components/base/ViewContainer';
import ViewToggle from '../../../components/base/ViewToggle';
import ViewElement from '../../../components/base/ViewElement';
import PageHeading from '../../../components/base/typography/PageHeading';
import CampaignDetail from './CampaignDetail';
import CampaignStatus from './CampaignStatus';
import CampaignCreate from './CampaignCreate';
import CampaignJobposting from './CampaignJobposting';
import CampaignCompany from '@/views/jobposting/campaign/CampaignCompany';
import {getFormattedDate} from "../../../helper/utility";
import CampaignOrder from '@/views/jobposting/campaign/CampaignOrder';
import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
import PerPagePicker from '@/components/PerPagePicker'
import CampaignUTM from "./CampaignUTM";
import NetworkCompany from "../../../helper/network/NetworkCompany";


export default {
  components: {
    CampaignUTM,
    PerPagePicker,
    RemoveFilterButton,
    CampaignOrder,
    CampaignCompany,
    CampaignJobposting,
    CampaignCreate,
    CampaignDetail,
    PageHeading,
    ViewElement,
    ViewToggle,
    ViewContainer,
    CampaignStatus
  },
  async created() {
    try {
      const res = await NetworkCompany.getCompaniesSimplified()
      let sortedCompanies = res.data.data
      sortedCompanies.sort((a, b) => {
        const aLower = a.name.toLowerCase();
        const bLower = b.name.toLowerCase();

        if (aLower === bLower) {
          return 0;
        } else {
          return aLower < bLower ? -1 : 1
        }
      });
      this.$store.commit('campaign/setCompaniesSimplified', sortedCompanies)
    } catch (err) {
      console.error(err)
    }
  },
  data() {
    return {
      // Table display options
      fields: [
        {
          key: 'name',
          label: this.$t('campaign.fields.name.label'),
          sortable: true,
        },
        {
          key: 'jobposting_count',
          label: this.$t('campaign.fields.jobposting_count.label'),
          sortable: false,
        },
        {
          key: 'company.name',
          label: this.$t('campaign.fields.company.name.label'),
          sortable: false,
        },
        {
          key: 'created_at',
          label: this.$t('campaign.fields.created_at.label'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    companies() {
      return this.$store.state.campaign.companiesSimplified
    },
    selectedItem: {
      get() {
        return this.$store.state.campaign.selectedItem
      },
      set(selItem) {
        this.$store.commit('campaign/setSelectedItem', selItem)
      }
    },
    items: {
      get() {
        return this.$store.state.campaign.items
      },
      set(items) {
        this.$store.commit('campaign/setItems', items)
      },
    },
    isBusy: {
      get() {
        return this.$store.state.campaign.isBusy
      },
      set(bool) {
        this.$store.commit('campaign/setIsBusy', bool)
      }
    },
    query: {
      get() {
        return this.$store.state.campaign.query
      },
      set(query) {
        this.$store.commit('campaign/setQuery', query)
      },
    },
    totalPages() {
      return this.$store.state.campaign.meta?.total ?? 999999
    },
  },
  methods: {
    sortingChanged(ctx) {
      // :sort-by.sync and :sort-desc.sync doesnt work, because it fires the watcher twice
      this.query.sortBy = ctx.sortBy
      this.query.sortDir = ctx.sortDesc ? 'desc' : 'asc'
    },
    onRowClicked(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        this.selectedItem = null;
      } else {
        this.$store.dispatch('campaign/fetchCampaign', item)
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      let cssClass = this.getClassByStatus(item, type)
      if (this.selectedItem && item.id === this.selectedItem.id) cssClass += ' selected'
      return cssClass.trim()
    },
    onToggleAll(checked) {
      for (const item of this.items) {
        this.$set(item, 'selected', checked);
      }
    },
    /**
     * Set the color for a given row depending on the status.
     * @param item
     */
    getClassByStatus(item) {
      let cssClass;
      // Switch status for job postings
      switch (item?.campaign_status) {
        case 'active':
          cssClass = 'table-success';
          break;
        case 'inactive':
          cssClass = 'table-secondary';
          break;
        case 'in progress':
          cssClass = 'table-primary';
          break;
        case 'error':
          cssClass = 'table-danger';
          break;
        default:
          cssClass = '';
      }

      return cssClass;
    },
    getFormattedDate,
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler() {
        this.$store.dispatch('campaign/fetchCampaigns')
      },
    },
  }
}
</script>

<style scoped>

</style>
