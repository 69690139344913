<template>
  <div class="job-posting-detail">
    <Collapsible id="toggle-job-posting-detail" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('job_posting_detail') }}</template>
      <template slot="content">
        <b-table-simple small borderless>
          <b-tr>
            <b-td class="align-middle">{{ $t('id') }}</b-td>
            <b-td>
              <b-form-input v-model="jobPostingSel.id" disabled/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('is_from_crawler') }}</b-td>
            <b-td>
              <b-form-checkbox v-model="jobPostingSel.is_from_crawler" :value="1" :unchecked-value="0" disabled/>
            </b-td>
          </b-tr>
          <b-tr v-if="system === 'automate'">
            <b-td class="align-middle">{{ $t('visible_for_customer') }}</b-td>
            <b-td>
              <b-form-checkbox id="visible-checkbox" v-model="jobPostingSel.visible_for_customer" :value="1"
                               :unchecked-value="0"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('title') }}</b-td>
            <b-td>
              <b-form-input v-model="jobPostingSel.title" disabled/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('comment') }}</b-td>
            <b-td>
              <b-form-textarea v-model="jobPostingSel.comment"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('refnum') }}</b-td>
            <b-td>
              <b-form-input v-model="jobPostingSel.refnum" disabled/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('customer_refnum') }}</b-td>
            <b-td>
              <b-form-input v-model="jobPostingSel.customer_refnum" disabled/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('preview') }}</b-td>
            <b-td><a :href="jobPostingSel.preview" target="_blank" class="break-all">{{ jobPostingSel.preview }}</a>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('jobposting_view') }}</b-td>
            <b-td><a :href="jobPostingSel.jobposting_view" target="_blank"
                     class="break-all">{{ jobPostingSel.jobposting_view }}</a>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('acc') }}</b-td>
            <b-td>
              <Button @click="openAcceptanceDialog = true" variant="primary" :disable="!jobPostingSel"
                      :tooltip-text="$t('jobPosting.fields.no_html.label')">{{ $t('send_acc_link') }}
              </Button>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">Lektorat</b-td>
            <b-td>
              <Button @click="openCopyTextDialog = true" variant="primary">
                Text für das Lektorat kopieren
              </Button>
            </b-td>
          </b-tr>
        </b-table-simple>
      </template>
    </Collapsible>
    <AcceptanceEmail
        v-model="openAcceptanceDialog"
        v-if="openAcceptanceDialog"
        :acceptance-link="jobPostingSel.acceptance"
        :preview-link="jobPostingSel.preview"
        :addresses="jobPostingSel.client_emails"
        :job-posting="jobPostingSel"/>
    <CopyTextForEditor v-model="openCopyTextDialog" v-if="openCopyTextDialog"/>
    <Collapsible id="toggle-job-posting-detail-xml" :visible="true">
      <template slot="heading">{{ $t('xml_fields') }}</template>
      <template slot="content">
        <JobPostingXMLFields/>
      </template>
    </Collapsible>
    <!-- Job posting origin -->
    <Collapsible
        v-if="jobPostingSel.jobposting_origin"
        :visible="false"
        id="job-posting-origin"
        class="mb-4"
    >
      <!-- Heading -->
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingOrigin.heading') }}</template>
      <!-- Content -->
      <template slot="content">
        <b-list-group>
          <b-list-group-item
              class="job-posting-origin"
          >
            <JobPostingRow :job-posting="jobPostingSel.jobposting_origin"/>
          </b-list-group-item>
        </b-list-group>
      </template>
    </Collapsible>
    <!-- Job posting references -->
    <Collapsible
        v-if="jobPostingSel.jobposting_references.length > 0"
        :visible="true"
        id="job-posting-references"
        class="mb-4"
    >
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingReferences.heading') }}</template>
      <template slot="content">
        <b-list-group>
          <b-list-group-item
              v-for="(jp, i) of jobPostingSel.jobposting_references"
              :key="i"
              class="job-posting-reference"
          >
            <JobPostingRow :job-posting="jp"/>
          </b-list-group-item>
        </b-list-group>
      </template>
    </Collapsible>
    <Separator/>
    <div class="d-flex justify-content-between align-items-center">
      <Button @click="onClose" icon="times">{{ $t('general.button.cancel') }}</Button>
      <Button @click="onSave" :loading="savingJobPosting" icon="save" variant="success">{{
          $t('general.button.save')
        }}
      </Button>
    </div>
  </div>
</template>


<script>
import Collapsible from '@/components/base/Collapsible'
import {clone} from '@/helper/utility'
import Button from '@/components/base/button/Button'
import Separator from '../../../components/base/Separator'
import {toaster} from '../../../helper/mixins/toaster'
import AcceptanceEmail from '../../../components/modular/AcceptanceEmail'
import CopyTextForEditor from '../../../components/modular/CopyTextForEditor'
import JobPostingRow from "./components/JobPostingRow";
import JobPostingXMLFields from "@/views/jobposting/jobposting/components/JobPostingXMLFields";

export default {
  components: {
    JobPostingXMLFields,
    JobPostingRow, Separator, Button, Collapsible, AcceptanceEmail, CopyTextForEditor},
  mixins: [toaster],
  data() {
    return {
      savingJobPosting: false,
      openAcceptanceDialog: false,
      openCopyTextDialog: false,
      system: process.env.VUE_APP_SYSTEM
    }
  },
  computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
  },
  methods: {
    clone,
    onClose() {
      this.$store.commit('jobPosting/setJobPostingSel', null)
    },

    async onSave() {
      this.savingJobPosting = true
      await this.$store.dispatch('jobPosting/saveJobPostingSel', {vm: this})
      this.savingJobPosting = false
    },
  },
}
</script>
<style scoped>

</style>
