<template>
  <div class="job-portal-detail">
    <!-- Job Portal Detail -->
    <Collapsible id="toggle-job-portal-detail" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('jobPortal.detail.heading') }}</template>
      <template slot="content">
        <FormGroup :label="$t('jobPortal.fields.logo_path.label')">
          <Photo
              @photo:removed="onLogoRemoved"
              @file:changed="onFileChanged"
              :src="selJobPortalEdit.logo_path"
              :errors="errors"
              error-field-name="logo"
          />
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.id.label')">
          <FormInput v-model="selJobPortalEdit.id"/>
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.name.label')">
          <FormInput
              v-model="selJobPortalEdit.name"
              :errors="errors"
              error-field-name="name"
              editable
          />
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.description.label')">
          <FormInput
              v-model="selJobPortalEdit.description"
              :errors="errors"
              error-field-name="description"
              editable textarea
          />
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.comment.label')">
          <FormInput
              v-model="selJobPortalEdit.comment"
              :errors="errors"
              error-field-name="comment"
              editable textarea
          />
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.is_active.label')">
          <FormInput
              v-model="selJobPortalEdit.is_active"
              :errors="errors"
              error-field-name="is_active"
              editable checkable
          />
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.created_at.label')">
          <FormInput v-model="selJobPortalEdit.created_at"/>
        </FormGroup>
        <FormGroup :label="$t('jobPortal.fields.updated_at.label')">
          <FormInput v-model="selJobPortalEdit.updated_at"/>
        </FormGroup>
      </template>
    </Collapsible>
    <!-- Categories -->
    <Collapsible id="collapse-job-portal-categories">
      <template slot="heading">{{ $t('jobPortal.detail.category.heading') }}</template>
      <template slot="content">
        <b-table
            :items="selJobPortalEdit.categories"
            :fields="fieldsCategories"
            :responsive="true"
            :hover="true"
            :bordered="true"
        >
          <template #cell(selected)="data">
            <FormInput v-model="data.item[data.field.key]" checkable/>
          </template>
        </b-table>
      </template>
    </Collapsible>
    <Separator/>
    <!-- Footer -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClosed">{{ $t('general.button.cancel') }}</Button>
      <Button
          icon="save"
          variant="success"
          :loading="loading"
          @click.native="onSave"
      >
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </div>
</template>

<script>
import FormGroup from '../../../components/base/input/FormGroup'
import FormInput from '../../../components/base/input/FormInput'
import Button from '../../../components/base/button/Button'
import NetworkJobPortal from '../../../helper/network/NetworkJobPortal'
import Photo from '../../../components/base/Photo'
import Collapsible from '../../../components/base/Collapsible'
import { toaster } from '@/helper/mixins/toaster'
import { errorHelper } from '@/helper/mixins/error-helper'
import { convertToFormData, clone } from '@/helper/utility'
import Separator from '../../../components/base/Separator'

export default {
  components: {
    Separator,
    Collapsible,
    Photo,
    Button,
    FormInput,
    FormGroup,
  },
  mixins: [toaster, errorHelper],
  data () {
    return {
      loading: false,
      logo: null,
      logoShouldBeDeleted: false,
      selJobPortalEdit: null,
      // Table data for categories
      fieldsCategories: [
        {
          key: 'id',
          label: this.$t('category.fields.id.label'),
        },
        {
          key: 'name',
          label: this.$t('category.fields.name.label'),
        },
        {
          key: 'selected',
          label: this.$t('category.fields.selected.label'),
        },
      ],
    }
  },
  created () {
    this.setEditableData()
  },
  computed: {
    selJobPortal () {
      return this.$store.state.jobPortal.selJobPortal
    },
  },
  watch: {
    selJobPortal () {
      this.resetErrors()
      this.setEditableData()
    },
  },
  methods: {
    convertToFormData,
    clone,
    setEditableData () {
      this.selJobPortalEdit = this.clone(this.$store.state.jobPortal.selJobPortal)
    },
    async onSave () {
      try {
        this.loading = true
        // Clone the current computed job portal in order to transform the categories array
        // into a numeric array for the API without changing the data visible for the user.
        const clone = this.clone(this.selJobPortalEdit)
        // 'parseInt' because the values 0 and 1 are sent as strings from the api
        clone.categories = clone.categories.filter(category => parseInt(category.selected)).map(category => category.id)
        const res = await NetworkJobPortal.updateJobPortal(clone)
        this.resetErrors()
        this.showSuccessMessageFromRes(res)
        await this.handleLogo()
        this.$store.commit('jobPortal/updateSavedJobPortal', this.clone(this.selJobPortalEdit))
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
      }
    },
    async handleLogo () {
      if (this.logo) {
        // Upload new or replace current logo
        const logoFormData = this.convertToFormData({ logo: this.logo })
        await NetworkJobPortal.updateLogo(this.selJobPortalEdit, logoFormData)
      } else if (this.logoShouldBeDeleted) {
        // Delete the current logo
        await NetworkJobPortal.deleteLogo(this.selJobPortalEdit)
      } else {
        // Do nothing
      }
    },
    onFileChanged (file) {
      this.logo = file
      this.logoShouldBeDeleted = false
      this.selJobPortalEdit.logo_path = URL.createObjectURL(file)
    },
    onLogoRemoved () {
      this.logo = null
      this.logoShouldBeDeleted = true
      this.selJobPortalEdit.logo_path = null
    },
    onClosed () {
      this.$store.commit('jobPortal/setSelJobPortal', null)
    },
  },
}
</script>

<style scoped>

</style>
