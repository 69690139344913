






































































































































import Vue from "vue";
import ViewContainer from '@/components/base/ViewContainer.vue';
import ViewElement from '@/components/base/ViewElement.vue';
import PageHeading from '../../../components/base/typography/PageHeading.vue';
import { IndeedSnapshot, IndeedSnapshotOverview, IndeedSubaccount } from "@/types/indeed-budget";
import { BvTableFieldArray } from 'bootstrap-vue';
import { clicksFormatter, costsFormatter, cpcFormatter, timestampFormatter } from "./helpers/formatters";
import LastSnapshotOverview from "./components/LastSnapshotOverview.vue";
import { getTimeframe } from "./helpers/date-helpers";
import Heading from "../../../components/base/typography/Heading.vue";

export default Vue.extend({
  components: { ViewElement, ViewContainer, PageHeading, LastSnapshotOverview, Heading },

  data() {
    const {start, end} = getTimeframe("month.this");

    return {
      timeframe: 'choose',
      subaccountSearch: '',
      selectedSubaccount: 'Overview',
      start: start,
      end: end
    }
  },

  created() {
    this.$store.dispatch("indeedBudget/fetchSubaccounts");
    this.fetchSnapshots();
  },

  computed: {
    snapshotsOverview(): IndeedSnapshotOverview[] {
      return this.$store.state.indeedBudget.snapshotsOverview;
    },

    snapshots(): IndeedSnapshot[] {
      return this
        .$store.state.indeedBudget.snapshots
        .filter((snapshot: IndeedSnapshot) => {
          return snapshot.subaccount.name === this.selectedSubaccount;
        });
    },

    latestSnapshotOverview(): IndeedSnapshotOverview {
      return this.$store.getters["indeedBudget/latestSnapshotOverview"];
    },

    subaccounts(): IndeedSubaccount[] {
      return this
        .$store.state.indeedBudget.subaccounts
        .filter((subaccount: IndeedSubaccount) => {
          return subaccount.name.toLowerCase().includes(this.subaccountSearch);
        });
    },

    snapshotsFields(): BvTableFieldArray {
      return [
        {
          key: "timestamp",
          label: "Datum und Uhrzeit",
          sortable: true,
          sortDirection: 'asc',
          formatter: timestampFormatter
        }, {
          key: "cost_cents",
          label: "Ausgaben" + (this.selectedSubaccount === 'Overview' ? " (gesamt)" : ""),
          formatter: costsFormatter
        },
        {
          key: "clicks",
          label: "Klicks" + (this.selectedSubaccount === 'Overview' ? " (gesamt)" : ""),
          formatter: clicksFormatter
        },
        {
          key: "cpc_cents",
          label: "CPC" + (this.selectedSubaccount === 'Overview' ? " (Durchschnitt)" : ""),
          formatter: cpcFormatter
        }
      ];
    },
  },

  watch: {
    start() {
      this.fetchSnapshots()
    },

    end() {
      this.fetchSnapshots()
    }
  },

  methods: {
    fetchSnapshots(): void {
      // The datepicker defaults to 00:00 of a day which excludes the snapshots of the end date
      this.end.setHours(23, 59, 59);
      this.$store.dispatch("indeedBudget/fetch", {start: this.start, end: this.end, overview: true});
      this.$store.dispatch("indeedBudget/fetch", {start: this.start, end: this.end, overview: false});
    },

    useTimeframe(timeframe: string) {
      this.timeframe = timeframe;
      const { start, end } = getTimeframe(timeframe);
      this.start = start;
      this.end = end;
    },

    pruneSubaccountName(subaccount: IndeedSubaccount): string {
      const prefixes = ["emplify für ", "Emplify für ", "emplify GmbH für "];

      for (const prefix of prefixes) {
        if (subaccount.name.startsWith(prefix)) {
          return subaccount.name.substr(prefix.length)
        }
      }

      return subaccount.name;
    },

    setSubaccount(subaccount: string): void {
      this.selectedSubaccount = subaccount;
    }
  },

  mounted() {
    const observer = new IntersectionObserver(function (entries) {
      if (entries[0].intersectionRatio === 0) {
        // @ts-ignore
        document.querySelector("#toolbar").classList.add("shadow");
      } else if (entries[0].intersectionRatio === 1) {
        // @ts-ignore
        document.querySelector("#toolbar").classList.remove("shadow");
      }
    }, {
      rootMargin: '-60px 0px 0px 0px',
      threshold: [0, 1]
    });
    // @ts-ignore
    observer.observe(document.querySelector("#above-toolbar"));
  },
});
