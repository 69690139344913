import NetworkCompany from '../../helper/network/NetworkCompany'
import {initQuery, updateRouteWithoutDuplication} from '@/helper/pagination';

export default {
    namespaced: true,
    state: () => ({
        items: [],
        selectedItem: null,
        selectedRuleExtension: {},
        affectedJobpostings: [],
        users: [],
        loading: false,
        // Pagination / Query
        query: initQuery(1, 10, 'created_at', 'desc'),
        queryTestRules: null,
        jass: [],
        meta: null,
    }),
    mutations: {
        setItems: (state, items) => state.items = items,
        setSelectedItem: (state, item) => state.selectedItem = item,
        setCompanyCatalogue: (state, catalogue) => state.selectedItem.catalogue = catalogue,
        setUsers: (state, users) => state.users = users,
        setJass: (state, jass) => state.jass = jass,
        removeJass: (state, jassItem) => {
            const index = state.jass.indexOf(jassItem);
            if (index > -1) {
                state.jass.splice(index, 1);
            }
        },
        addJass: (state, jassItem = null) => {
            if (jassItem !== null) {
                state.jass.push(jassItem);
                return;
            }
            state.jass.push({
                id: null,
                name: null,
                jass_customer_id: null
            });
        },
        setCompanyExtensionRules: (state, rules) => state.selectedItem.extension_rules = rules,
        setSelectedRuleExtension: (state, rule) => state.selectedRuleExtension = rule,
        setCompanyContactPerson: (state, user) => state.selectedItem.contact_person = user,
        addUserToCompany: (state, user) => state.selectedItem.users.push(user),
        setCompanyJassId: (state, id) => state.selectedItem.jass_customer_id = id,
        setAffectedJobpostings: (state, res) => {
            state.affectedJobpostings = res.data;
            state.queryTestRules = res;
        },
        // Pagination / Query
        setQuery: (state, query) => state.query = query,
        setMeta: (state, meta) => state.meta = meta,
        setLoading: (state, isLoading) => state.loading = isLoading
    },
    actions: {
        fetchCompanies: async ({state, commit}) => {
            commit('setLoading', true)
            try {
                const res = await NetworkCompany.getCompanies({params: state.query})
                commit('setItems', res.data.data)
                commit('setMeta', res.data.meta)
                await updateRouteWithoutDuplication(state.query);
                return res
            } catch (err) {
                console.error(err)
                return err
            } finally {
                commit('setLoading', false)
            }
        },
        resetQuery: ({commit}) => commit('setQuery', initQuery(1, 10, 'created_at', 'desc')),
    },
}
