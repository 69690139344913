<template>
  <div>
    <!-- Job posting status -->
    <Collapsible id="toggle-job-posting-detail-status" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingStatus.heading') }}</template>
      <template slot="content">
        <b-spinner class="d-block mx-auto my-4" variant="secondary" v-if="jobPostingStatusesFiltered.length === 0"/>
        <b-table-simple v-else borderless>
          <b-tr v-if="this.jobPostingActiveCampaigns">
            <b-td class="align-middle"></b-td>
            <b-td class="align-middle">
              <b-alert show variant="warning">{{ $t('jobPosting.detail.statusChangeDisabled') }}</b-alert>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="align-middle">{{ $t('jobPosting.fields.status.label') }}</b-td>
            <b-td class="align-middle">
              <b-form-select :disabled="this.jobPostingActiveCampaigns" v-model="jobPostingSel.status.name" :options="jobPostingStatusesFiltered"/>
            </b-td>
          </b-tr>
        </b-table-simple>
      </template>
    </Collapsible>
    <!-- Job posting status history -->
    <Collapsible id="job-posting-detail-status-history" :visible="true">
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingStatusHistory.heading') }}</template>
      <template slot="content">
        <b-table
            :items="jobPostingSel.status_history"
            :fields="fieldsJobPostingStatusHistory"
            :bordered="true"
            :responsive="true"
            :hover="true"
            :sort-by="'created_at'"
            :sort-desc="true"
        />
      </template>
    </Collapsible>
    <Separator/>
    <!-- Controls -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
      <Button
          :loading="savingStatus"
          @click.native="onSave"
          icon="save"
          variant="success"
          :disabled="this.jobPostingActiveCampaigns"
      >
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import NetworkJobPosting from '../../../helper/network/NetworkJobPosting'
import Button from '../../../components/base/button/Button'
import { toaster } from '../../../helper/mixins/toaster'
import Separator from '../../../components/base/Separator'

export default {
  mixins: [toaster],
  components: { Separator, Button, Collapsible },
  data () {
    return {
      loadingStatuses: false,
      savingStatus: false,
      jobPostingStatuses: [],
      fieldsJobPostingStatusHistory: [
        {
          key: 'name',
          label: this.$t('jobPosting.detail.jobPostingStatusHistory.fields.name.label'),
        },
        {
          key: 'description',
          label: this.$t('jobPosting.detail.jobPostingStatusHistory.fields.description.label'),
        },
        {
          key: 'created_at',
          label: this.$t('jobPosting.detail.jobPostingStatusHistory.fields.created_at.label'),
        }
      ],
    }
  },
  computed: {
    jobPostingSel () {
      return this.$store.state.jobPosting.jobPostingSel;
    },
    jobPostingActiveCampaigns() {
      // check if job posting is attached to at least one campaign with campaign_status == "active"
      return this.$store.state.jobPosting.jobPostingSel.campaigns.findIndex(campaign => {
        return campaign.campaign_status.name === "active"
      }) !== -1;
    },
    jobPostingStatusesFiltered () {
      if (!this.jobPostingSel.is_from_crawler) {
        return this.jobPostingStatuses
      } else {
        // Return job posting statuses with some options disabled
        return this.jobPostingStatuses.map(status => {
          const isStatusToDisable =
              status.text === 'rejected' ||
              status.text === 'approved' ||
              status.text === 'waiting for approval'

          // Disable status if the status is for job postings coming from xml feeds only.
          if (isStatusToDisable)
            status.disabled = true

          return status
        })
      }
    },
  },
  /**
   * Load available statuses on created.
   * @returns {Promise<void>}
   */
  async created () {
    try {
      this.loadingStatuses = true
      const res = await NetworkJobPosting.getJobPostingStatuses()
      this.jobPostingStatuses = res.data.data.map(status => {
        // 'value' and 'text' are set to status.name because an object is required
        // to disable certain statuses. Whether statuses are disabled or not depends
        // on the job posting. If the job posting is from an xml feed there are less statuses
        // available than there are when the job posting was added manually.
        return { value: status.name, text: status.name }
      })
    } catch (err) {
      this.showErrorMessageFromErr(err)
    } finally {
      this.loadingStatuses = false
    }
  },
  methods: {
    async onSave () {
      this.savingStatus = true
      await this.$store.dispatch('jobPosting/updateJobPostingStatus', { vm: this })
      this.savingStatus = false
    },
    onClose () {
      this.$store.commit('jobPosting/setJobPostingSel', null)
    },
  },
}
</script>

<style scoped>

</style>
