import Vue from 'vue';
import { Route } from 'vue-router';

export const routesHelper = {
  /**
   * Get the query parameters of the route and pass them as props.
   * Because the filter params differ from component to component it's useful
   * to pass them as an extra object. In this case 'filterParams'.
   * @param route
   * @return {{perPage: *, sortBy: *, page: (number|(function(*): string)|scopedSlots.page), sortDir: (string), filterParams: {}}}
   */
  getQueryProps: function(route: Route) {
    return {
      pagination: {
        page: Number(route.query.page),
        perPage: Number(route.query.perPage),
        sortDir: route.query.sortDir,
        sortBy: route.query.sortBy,
        // Filter params
        filterParams: getFilterParams(route),
      },
    };
  },
};

/**
 * Check if the current parameter is a default param like 'page' or 'sortBy'
 * or if it's a filter param like 'name', 'description', etc.
 * @param param
 * @return {boolean}
 */
function isDefaultParam(param: string) {
  return param === 'page' ||
      param === 'perPage' ||
      param === 'sortDir' ||
      param === 'sortBy';
}

/**
 * Get all filter query parameters of the route which are not default params.
 * @param route
 * @return {{}}
 */
function getFilterParams(route: Route) {
  const filterParams: any = {};
  for (const param in route.query) {
    if (!isDefaultParam(param)) {
      filterParams[param] = route.query[param];
    }
  }

  return filterParams;
}

export function setTitle() {
  Vue.nextTick(() => {
    // Set this as the default title.
    // This may be adapted to the current page in the future.
      if (process.env.VUE_APP_SYSTEM === 'reseller') {
        document.title = 'emplify reseller';
      } else {
        document.title = 'emplify automate backend';
      }
  });
}
