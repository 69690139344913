<template>
  <b-modal
      v-model="modelHelper"
      id="acceptanceEmail"
      :title="$t('acceptance.dialog.title')"
      size="lg"
      centered
      hide-footer>

    <!-- Recipients -->
    <h5>{{ $t('general.component.orderEmail.heading.email') }}</h5>
    <b-form-group
        :label="$t('general.component.orderEmail.fields.email_addresses.label')"
        class="mb-3">
      <b-form-input v-model="emailAddresses" list="input-list"></b-form-input>
      <b-form-datalist id="input-list" :options="addresses"></b-form-datalist>
    </b-form-group>
    <!-- CC -->
    <b-form-group
        :label="$t('general.component.orderEmail.fields.cc.label')"
        class="mb-3">
      <b-input
          v-model="ccAddresses"
          :placeholder="$t('general.component.orderEmail.fields.cc.placeholder')"/>
    </b-form-group>

    <!-- BCC -->
    <b-form-group
        :label="$t('general.component.orderEmail.fields.bcc.label')"
        class="mb-3">
      <b-input
          v-model="bccAddresses"
          :placeholder="$t('general.component.orderEmail.fields.bcc.placeholder')"/>
    </b-form-group>

    <!-- Subject -->
    <b-form-group
        :label="$t('general.component.orderEmail.fields.subject.label')"
        class="mb-3">
      <b-input
          v-model="mailSubject"/>
    </b-form-group>

    <!-- Email content -->
    <Trumbowyg v-model="mailContent" :config="trumbowygConfig" class="mb-3"/>

    <!-- Place email order button -->
    <div class="d-flex justify-content-end">
      <Button
          class="mt-2"
          @click="sendEmail()"
          :loading="loading"
          variant="success">
        {{ $t('acceptance.dialog.button') }}
      </Button>
    </div>
  </b-modal>
</template>

<script>
import NetworkReview from "../../helper/network/NetworkReview";
import Trumbowyg from "vue-trumbowyg";
import {toaster} from "../../helper/mixins/toaster";
import Button from "../base/button/Button";
import NetworkAuth from "../../helper/network/NetworkAuth";

export default {
  components: {Trumbowyg, Button},
  mixins: [toaster],
  props: {
    value: {
      required: true
    },
    previewLink: {
      required: true,
      type: String
    },
    acceptanceLink: {
      required: true,
      type: String,
    },
    jobPosting: {
      required: true,
      type: Object
    },
    addresses: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      emailAddresses: null,
      ccAddresses: null,
      bccAddresses: null,
      loading: false,
      mailSubject: null,
      mailContent: null,
      subject: "Freigabe: " + this.jobPosting.title + (this.jobPosting.customer_refnum !== null ? " - " + this.jobPosting.customer_refnum : ""),
      content: "Sehr geehrte Damen und Herren,<br>" +
          "<br>" +
          "Ihre Stellenanzeige ist nun zur Freigabe bereit.<br>" +
          `Bitte folgen Sie diesem Link: <a href='${this.acceptanceLink}'>${this.acceptanceLink}</a><br>` +
          "<br>" +
          `Falls Sie die Anzeige Ihren Kolleginnen und Kollegen zur Durchsicht zeigen möchten, finden Sie hier einen neutralen Vorschaulink: <a href='${this.previewLink}'>${this.previewLink}</a><br>` +
          "<br>" +
          "<br>" +
          "Mit freundlichen Grüßen,<br>emplify GmbH",
      trumbowygConfig: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['removeformat'],
        ],
      },
    }
  },
  created() {
    this.mailContent = this.content
    this.mailSubject = this.subject
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    async sendEmail() {
      this.loading = true;
      const book = {
        email_addresses: this.prepareEmailAddresses(this.emailAddresses),
        email_content: this.mailContent,
        company_id: this.jobPosting.client.id,
        subject: this.mailSubject
      }

      if (this.ccAddresses)
        book.email_cc = this.prepareEmailAddresses(this.ccAddresses);
      if (this.bccAddresses)
        book.email_bcc = this.prepareEmailAddresses(this.bccAddresses);

      try {
        const { data } = await NetworkAuth.getUserdata()
        book.user_sent = data.data.email
        const res = await NetworkReview.sendEmail(this.jobPosting.id, book)
        this.showSuccessMessageFromRes(res)
        this.setToDefault()
        await this.$store.dispatch('mail/fetchMails', this.jobPosting)
        this.$bvModal.hide("acceptanceEmail")
      } catch (e) {
        console.error(e)
        this.showErrorMessageFromErr(e)
      } finally {
        this.loading = false
      }
    },
    setToDefault() {
      this.emailAddresses = null
      this.ccAddresses = null
      this.bccAddresses = null
      this.loading = false
      this.mailcontent = this.content
      this.mailSubject = this.subject
    },
    /**
     * Take a string of email addresses and split them up into an array.
     * @param emailAddresses String of email addresses
     */
    prepareEmailAddresses(emailAddresses) {
      return emailAddresses === null ? [] : emailAddresses.replace(/\s/, '').split(';');
    },
  }

}
</script>

<style scoped>

</style>