import NetworkCampaign from '../../helper/network/NetworkCampaign'
import { initQuery, updateRouteWithoutDuplication } from '@/helper/pagination';
import i18n from '../../i18n'

// TODO: refactor, there is toaster.js for toaster, maybe duplication is not the best solution here
const showSuccessMsg = (vm, msg) => vm.$bvToast.toast(msg, { title: i18n.t('general.toast.title.success'), variant: 'success' })
const showErrorMsg = (vm, msg) => vm.$bvToast.toast(msg, {
  title: i18n.t('general.toast.title.error'),
  variant: 'danger',
  autoHideDelay: 1000 * 60 * 60 * 24, // auto hide after one day ;)
})


export default {
  namespaced: true,
  state: () => ({
    items: [],
    selectedItem: null,
    companiesSimplified: [],
    companyId: null,
    query: initQuery(1,10,'created_at', 'desc'),
    meta: null,
    // loading states
    isBusy: false,
  }),
  mutations: {
    setItems: (state, items) => state.items = items,
    setSelectedItem: (state, selectedItem) => state.selectedItem = selectedItem,
    setCompaniesSimplified: (state, companies) => state.companiesSimplified = companies,
    setCompanyId: (state, id) => state.companyId = id,
    setQuery: (state, query) => state.query = query,
    setUTMParameters: (state, utmparams) => state.selectedItem.utm_parameters = utmparams,
    setCampaignJobPostings: (state, jobPostings) => state.selectedItem.jobpostings = jobPostings,
    removeCampaignJobPosting: (state, index) => state.selectedItem.jobpostings.splice(index, 1),
    // remove all jobposting objects from the state where there id is in jobpostingIds
    removeCampaignJobPostings: (state, jobpostingIds) => {
      jobpostingIds.forEach((jobpostingId) => {
        let removeIndex = state.selectedItem.jobpostings.findIndex((o) => { return o.id === jobpostingId; })
        state.selectedItem.jobpostings.splice(removeIndex, 1);
      });
    },
    setCampaignOrders: (state, orders) => state.selectedItem.orders = orders,
    setMeta: (state, meta) => state.meta = meta,
    // loading setter
    setIsBusy: (state, bool) => state.isBusy = bool,
  },
  getters: {
    getCampaignJobPostings: state => state.selectedItem.jobpostings,
  },
  actions: {
    fetchCampaigns: async ({ state, commit }) => {
      try {
        commit('setIsBusy', true);
        const res = await NetworkCampaign.getCampaigns({
          params: state.query,
        });
        commit('setItems', res.data.data);
        commit('setMeta', res.data.meta);
        commit('setIsBusy', false);
        await updateRouteWithoutDuplication(state.query);
        return res
      }
      catch (err) {
        console.error(err)
        return err
      }
      finally {
        commit('setIsBusy', false)
      }
    },
    fetchCampaign: async ({ commit }, item) => {
      try {
        commit('setIsBusy', true)
        const { data } = await NetworkCampaign.getCampaign(item)
        commit('setSelectedItem', data.data)
      } catch (err) {
        console.error(err)
      } finally {
        commit('setIsBusy', false)
      }
    },
    deleteCampaignJobPosting: async ({state, commit, dispatch}, {jobPosting, vm}) => {
      try {
        commit('setIsBusy', true)
        const res = await NetworkCampaign.removeJobPostingsFromCampaign(state.selectedItem.id, [jobPosting.item.id])
        commit('removeCampaignJobPosting', jobPosting.index)
        showSuccessMsg(vm, res.data.message)
        dispatch('fetchCampaigns')
      } catch (err) {
        showErrorMsg(vm, err.response.data.message)
      } finally {
        commit('setIsBusy', false)
      }
    },
    /**
     * This Action receives an array of jobpostings ids from the CampaignJobposting component.
     * The corresponding jobpostings will be detached from the selected campaign.
     *
     * @param state
     * @param commit
     * @param dispatch
     * @param jobPostings array of jobposting ids
     * @param vm
     * @returns {Promise<void>}
     */
    deleteCampaignJobPostings: async ({state, commit, dispatch}, {jobPostings, vm}) => {
      try {
        commit('setIsBusy', true)
        const res = await NetworkCampaign.removeJobPostingsFromCampaign(state.selectedItem.id, jobPostings);
        commit('removeCampaignJobPostings', jobPostings)
        showSuccessMsg(vm, res.data.message)
        dispatch('fetchCampaigns')
      } catch (err) {
        showErrorMsg(vm, err.response.data.message)
      } finally {
        commit('setIsBusy', false)
      }
    },
    resetQuery: ({ commit }) => commit('setQuery', initQuery(1, 10, 'created_at', 'desc')),

    /**
     * Update the status for the selected campaign.
     * @param state
     * @param dispatch
     * @param vm
     * @returns {Promise<AxiosResponse<any>|*>}
     */
    updateCampaignStatus: async ({ state }, { vm }) => {
      try {
        const res = await NetworkCampaign.updateCampaignStatus(state.selectedItem);
        // Update status history
        state.selectedItem.campaign_status_history = res.data.data.campaign_status_history;
        // Show success message
        showSuccessMsg(vm, res.data.message);
        return null;
      } catch (err) {
        showErrorMsg(vm, err.response.data.message);
        if ("exception" in err.response.data && err.response.data.exception === "CampaignStatusChangeException") {
          return err.response.data;
        } else {
          return null;
        }
      }
    },
  }
}
