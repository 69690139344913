<template>

  <!-- Modal add order -->
  <b-modal
      id="campaign-order-add"
      :title="$t('campaign.order.add.title')"
      @hide="onHide"
      size="lg"
      centered
      hide-footer
  >
    <!-- Job portal -->
    <div class="mb-3">
      <b-form-group
          :label="$t('campaign.order.add.fields.jobPortal.label')"
          :description="$t('campaign.order.add.fields.jobPortal.description')"
      >
        <b-form-select
            v-model="jobPortal"
            :options="jobPortalOptions"
        />
      </b-form-group>
    </div>

    <!-- Available products -->
    <div class="mb-3">
      <div v-if="jobPortal">
        <label>{{ $t('campaign.order.add.fields.products.label') }}</label>
        <b-form-checkbox-group
            v-model="products"
            :options="productOptions"
            stacked
        />
      </div>
      <p v-else>
        <font-awesome-icon icon="exclamation-triangle"/>
        {{ $t('campaign.order.add.fields.products.message') }}
      </p>
    </div>

    <!-- Budget -->
    <b-form-group :label="$t('campaign.order.add.fields.budget.label')" class="mb-3">
      <div class="d-flex align-items-center">
        <b-form-input
            v-model="budget"
            :formatter="budgetFormatter"
            class="mr-2 budget"
        />
        <span>€</span>
      </div>
    </b-form-group>

    <!-- Save Button -->
    <div class="d-flex justify-content-end">
      <Button
          @click.native="createOrderForCampaign"
          :disabled="addButtonDisabled"
          :loading="loading"
          icon="plus"
          variant="success"
      >
        {{ $t('general.button.add') }}
      </Button>
    </div>
  </b-modal>

</template>

<script>
import {toaster} from '@/helper/mixins/toaster';
import Button from '@/components/base/button/Button';
import NetworkJobPortal from '@/helper/network/NetworkJobPortal';
import NetworkCampaign from "../../../helper/network/NetworkCampaign";

export default {
  components: {Button},
  mixins: [toaster],
  data() {
    return {
      budget: null,
      jobPortalOptions: [],
      jobPortal: null,
      products: [],
      loading: false,
    };
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.selectedItem
    },
    productOptions() {
      if (this.jobPortal) {
        // filter the products in the job portal
        // only keep campaign products
        return this.jobPortal.products.filter(p => {
          return p.is_campaign_product;
        }).map(p => {
          return {
            text: p.name + ' - t: ' + p.term_in_days + ' - min: ' + p.min_budget + ' €',
            value: p.id,
          };
        });
      } else {
        return [];
      }
    },
    addButtonDisabled() {
      return !(this.jobPortal && this.products.length > 0 && this.budget);
    },
  },
  watch: {
    jobPortal() {
      this.products = []; // reset selected products on job portal change
    },
  },
  created() {
    this.getJobPortalOptions();
  },
  methods: {
    getJobPortalOptions() {
      NetworkJobPortal.getJobPortalsSimplified().then(res => {
        // filter the fetched job portals
        // only keep job portals with at least one campaign product
        const options = res.data.data.filter(jp => {
          let t = jp.products.filter(p => {
            return p.is_campaign_product;
          });
          return t.length != 0;
        }).map(jp => ({text: jp.name, value: jp}));

        // Sort options by name/text alphabetically
        options.sort((a, b) => {
          const aToLower = a.text.toLowerCase();
          const bToLower = b.text.toLowerCase();
          if (aToLower === bToLower)
            return 0;
          else
            return aToLower < bToLower ? -1 : 1;
        });
        this.jobPortalOptions = options;
      }).catch(err => this.showErrorMessageFromErr(err));
    },
    async createOrderForCampaign() {
      try {
        const order = {
          budget: this.budget,
          products: this.products
        }
        const res = await NetworkCampaign.createOrderForCampaign(this.campaign.id, order)
        this.$store.commit('campaign/setCampaignOrders', res.data.data.orders)
        this.showSuccessMessageFromRes(res)
        this.$bvModal.hide('campaign-order-add');
      } catch (err) {
        this.showErrorMessageFromErr(err)
      }
    },
    onHide() {
      // Reset data
      this.jobPortal = null;
      this.products = [];
      this.budget = null;
    },
    budgetFormatter(val) {
      if (/^\d+$/.test(val))
        return val;
      else
        return val.substr(0, val.length - 1);
    },
  },
};
</script>

<style scoped>
.budget {
  max-width: 100px;
}
</style>
