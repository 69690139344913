<template>
  <!-- 'd-block' is necessary because 'invalid-feedback' is display none by default -->
  <div class="invalid-feedback d-block" v-if="errors">
    {{ errorMessage }}
  </div>
</template>

<script>
export default {
  props: ['errors', 'errorFieldName'], // errors object (see 'error-helper.js')
  computed: {
    hasError () {
      if (this.errors) {
        return this.errorFieldName in this.errors.allErrors
      } else {
        return false
      }
    },
    errorMessage () {
      if (!this.errors)
        return null

      if (!this.errors[this.errorFieldName])
        return null

      if (!this.errors[this.errorFieldName].length > 0)
        return null

      return this.errors[this.errorFieldName][0]
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.list-item-error {
  font-size: $small-font-size !important;
}
</style>
