<template>

  <Collapsible id="toggle-campaign-order" :visible="true">
    <template slot="heading">{{ $t('campaign.order.heading') }}</template>
    <template slot="content">

      <Orders
          :orders="campaign.orders"
          @order:save="onOrderSave"
          @order:delete="onOrderDelete"
      />

      <!-- Add new order -->
      <b-button-group class="mr-3">
        <Button v-b-modal.campaign-order-add icon="plus" variant="success" :disabled="campaign.orders.length != 0">
          {{ $t('general.button.new') }}
        </Button>
      </b-button-group>

      <!-- Activate order -->
      <b-button-group>
        <!-- Activate orders -->
        <Button
            @click.native="onOrdersActivate"
            :disabled="ordersSelected.length === 0 || isDirty || selectedOrdersAlreadyActivated"
            :loading="activatingOrder"
            variant="primary"
        >
          {{ $t('general.button.order.activate') }}
        </Button>
      </b-button-group>

      <!-- Add order to campaign modal -->
      <CampaignOrderAdd
      />
    </template>
  </Collapsible>

</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import CampaignOrderAdd from '@/views/jobposting/campaign/CampaignOrderAdd';
import Button from '@/components/base/button/Button';
import Orders from '@/components/modular/Orders';
import NetworkOrder from '@/helper/network/NetworkOrder';
import {toaster} from '@/helper/mixins/toaster';
import NetworkBooking from '@/helper/network/NetworkBooking';

export default {
  mixins: [toaster],
  components: {Orders, Button, CampaignOrderAdd, Collapsible},
  data() {
    return {
      activatingOrder: false
    }
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.selectedItem
    },
    isDirty() {
      return this.campaign.orders.some(order => order.isDirty === true);
    },
    ordersSelected() {
      return this.campaign.orders.filter(order => order.selected === true);
    },
    selectedOrdersAlreadyActivated() {
      let inactiveOrder = this.ordersSelected.find(o => {
        return o.activated_at == null;
      })
      return inactiveOrder === undefined;
    },
  },
  methods: {
    onOrderSave(order) {
      this.$set(order, 'loading', true);
      NetworkOrder.updateOrder(order).then(res => {
        this.$set(order, 'isDirty', false);
        this.showSuccessMessageFromRes(res);
      }).catch(err => this.showErrorMessageFromErr(err)).then(() => this.$set(order, 'loading', false));
    },
    onOrdersActivate() {
      this.activatingOrder = true;
      NetworkBooking.activateCampaignOrders(this.ordersSelected).then(res => {
        this.$store.dispatch('campaign/fetchCampaign', this.campaign)
        this.showSuccessMessageFromRes(res);
      }).catch(err => this.showErrorMessageFromErr(err)).then(() => this.activatingOrder = false);
    },
    async onOrderDelete(order) {
      this.$set(order, 'loading', true);
      try {
        const res = await NetworkOrder.deleteCampaignOrder([order.id])
        this.showSuccessMessageFromRes(res);
        this.campaign.orders = this.campaign.orders.filter(o => o.id !== order.id)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.$set(order, 'loading', false)
      }
    }
  }
};
</script>

<style scoped></style>
