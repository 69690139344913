<template>
  <div class="xml-feed-detail" v-if="xmlFeed.company != null">
    <Collapsible id="toggle-xml-feed-detail" :visible="true">
      <template slot="heading">{{ $t('xmlFeed.detail.heading') }}</template>
      <!-- Content -->
      <template slot="content">
        <!-- ID -->
        <FormGroup :label="$t('xmlFeed.fields.id.label')">
          <FormInput v-model="xmlFeed.id"/>
        </FormGroup>
        <!-- Name -->
        <FormGroup :label="$t('xmlFeed.fields.name.label')">
          <FormInput v-model="xmlFeed.name"/>
        </FormGroup>
        <!-- Publisher -->
        <FormGroup :label="$t('xmlFeed.fields.publisher.label')">
          <FormInput v-model="xmlFeed.publisher"/>
        </FormGroup>
        <!-- Publisher URL -->
        <FormGroup :label="$t('xmlFeed.fields.publisherurl.label')">
          <FormInput v-model="xmlFeed.publisherurl"/>
        </FormGroup>
        <!-- Feed Identifier -->
        <FormGroup :label="$t('xmlFeed.fields.feed_identifier.label')">
          <FormInput v-model="xmlFeed.feed_identifier"/>
        </FormGroup>
        <!-- Link -->
        <FormGroup :label="$t('xmlFeed.fields.link.label')">
          <FormInput v-model="xmlFeed.link"/>
        </FormGroup>
        <!-- Mode -->
        <FormGroup :label="$t('xmlFeed.fields.mode.label')">
          <FormInput v-model="xmlFeed.mode"/>
        </FormGroup>
        <!-- Created at -->
        <FormGroup :label="$t('xmlFeed.fields.created_at.label')">
          <FormInput v-model="xmlFeed.created_at"/>
        </FormGroup>
        <!-- Updated at -->
        <FormGroup :label="$t('xmlFeed.fields.updated_at.label')">
          <FormInput v-model="xmlFeed.updated_at"/>
        </FormGroup>
        <Separator/>
        <!-- Company Name -->
        <FormGroup :label="$t('xmlFeed.fields.company.name.label')">
          <FormInput v-model="xmlFeed.company.name"/>
        </FormGroup>
      </template>
    </Collapsible>

    <Separator/>

    <!-- Footer -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
    </div>
  </div>
</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import FormGroup from '@/components/base/input/FormGroup';
import FormInput from '@/components/base/input/FormInput';
import Separator from '@/components/base/Separator';
import Button from '@/components/base/button/Button';
export default {
  components: {Button, Separator, FormInput, FormGroup, Collapsible},
  props: {
    xmlFeed: {
      required: true,
      type: Object
    }
  },
  methods: {
    onClose() {
      this.$emit('item:closed');
    },
  }
};
</script>

<style scoped>

</style>
