<template>

  <Collapsible id="order-detail" visible>
    <template slot="heading">{{ $t('order.detail.heading') }}</template>
    <template slot="content">
      <FormGroup :label="$t('order.fields.company.label')">
        <FormInput v-model="order.company" disabled/>
      </FormGroup>
      <FormGroup :label="$t('order.fields.user_name.label')">
        <FormInput v-model="order.user_name" disabled/>
      </FormGroup>
      <FormGroup :label="$t('order.fields.user_email.label')">
        <FormInput v-model="order.user_email" disabled/>
      </FormGroup>
    </template>
  </Collapsible>

</template>

<script>
import FormGroup from '@/components/base/input/FormGroup';
import FormInput from '@/components/base/input/FormInput';
import Collapsible from '@/components/base/Collapsible';

export default {
  components: {Collapsible, FormInput, FormGroup},
  props: {
    order: {
      required: true,
      type: Object
    }
  },
};
</script>

<style scoped>

</style>
