<template>
  <!-- Modal place order -->
  <b-modal :title="$t('general.component.orderEmail.title')" :id="id" size="lg" centered hide-footer>
    <!-- Selected orders -->
    <h5>{{ $t('general.component.orderEmail.heading.orders') }}</h5>
    <b-list-group class="mb-4 max-height-list-group">
      <b-list-group-item v-for="order in ordersSel" :key="order.id">
        <div class="d-flex align-items-center justify-content-between">
          <div class="order-detail">
            <strong>{{ order.product.jobportal }}</strong><br>
            <span>{{ order.product.name }}</span>
          </div>
          <div>
            <b-badge v-if="order.budget" class="budget-list-item" variant="success">
              {{ order.budget ? order.budget + '€' : '-' }}
            </b-badge>
            <span v-else class="budget-list-item">-</span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>

    <!-- Recipients -->
    <h5>{{ $t('general.component.orderEmail.heading.email') }}</h5>
    <b-form-group :label="$t('general.component.orderEmail.fields.email_addresses.label')" class="mb-3">
      <b-input v-model="recipients"/>
    </b-form-group>
    <!-- CC -->
    <b-form-group :label="$t('general.component.orderEmail.fields.cc.label')" class="mb-3">
      <b-input v-model="cc" :placeholder="$t('general.component.orderEmail.fields.cc.placeholder')"/>
    </b-form-group>
    <!-- BCC -->
    <b-form-group :label="$t('general.component.orderEmail.fields.bcc.label')" class="mb-3">
      <b-input v-model="bcc" :placeholder="$t('general.component.orderEmail.fields.bcc.placeholder')"/>
    </b-form-group>

    <!-- Subject -->
    <b-form-group :label="$t('general.component.orderEmail.fields.subject.label')" class="mb-3">
      <b-input v-model="emailSubject"/>
    </b-form-group>
    <!-- Email content -->
    <trumbowyg v-model="emailContent" :config="trumbowygConfig"/>
    <!-- Place email order button -->
    <div class="d-flex justify-content-end mt-2">
      <Button :disabled="ordersSel.length === 0" :loading="loadingPlaceEmailOrder" @click.native="onPlaceEmailOrder" icon="shipping-fast" variant="success">
        {{ $t('general.button.order.email.place') }}
      </Button>
    </div>
  </b-modal>
</template>

<script>
import Button from '@/components/base/button/Button';
import NetworkBooking from '@/helper/network/NetworkBooking';
import {getSignificantValue} from '@/helper/utility';
import trumbowyg from "vue-trumbowyg";
import {toaster} from '@/helper/mixins/toaster';
import NetworkAuth from "../../helper/network/NetworkAuth";

export default {
  components: {Button, trumbowyg},
  mixins: [toaster],
  props: {
    id: {
      required: false,
      type: String,
      default: 'order-email',
    },
    jobPosting: {
      required: true,
      type: Object
    },
    ordersSel: {
      required: true,
      type: Array,
    },
    orderEmail: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      recipients: null,
      cc: null,
      bcc: null,
      loadingPlaceEmailOrder: false,
      emailContent: null,
      emailSubject: null,
      trumbowygConfig: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['removeformat'],
        ],
      },
    };
  },
  watch: {
    ordersSel: {
      immediate: true,
      handler () {
        this.recipients = this.orderEmail
        this.emailContent = this.composeMessage()
        this.emailSubject = this.composeSubject()
      },
    },
  },
  methods: {
    composeSubject () {
      const objectAtKey = this.jobPosting.jobposting_data.find(data => data.field === "company");
      return "Bestellung: " + (objectAtKey.value_xml !== null ? objectAtKey.value_xml + " - " : "") + this.jobPosting.title + (this.jobPosting.customer_refnum !== null ? " - " + this.jobPosting.customer_refnum : "")
    },
    composeMessage () {
      // Form in modal place order
      let message = 'Sehr geehrte Damen und Herren,<br><br>bitte veröffentlichen Sie die angehängten Stellenanzeigen zu den vereinbarten Konditionen:<br><br>';
      // Client
      const client = this.getSignificantValue(this.jobPosting, 'company');
      // Title
      const title = this.getSignificantValue(this.jobPosting, 'title');
      // Location
      let city = this.getSignificantValue(this.jobPosting, 'city');
      // Internal refnum
      let internalRefnum = this.getSignificantValue(this.jobPosting, 'referencenumber');
      // Go through products
      for (const order of this.ordersSel) {
        message += `PRODUKT: ${order.product.name}<br>` +
            `KUNDE: ${client}<br>` +
            `POSITION: ${title}<br>` +
            `STANDORT: ${city}<br>` +
            `EMPLIFY-REFERENZNUMMER: ${internalRefnum}<br><br>`;
      }
      // Add email ending
      message += 'Mit freundlichen Grüßen,<br>emplify GmbH';
      // Return composed message
      return message;
    },
    async onPlaceEmailOrder() {
      // Set loading state
      this.loadingPlaceEmailOrder = true;
      // Construct order object
      const order = {
        orders: this.ordersSel.map(order => order.id),
        email_addresses: this.prepareEmailAddresses(this.recipients),
        email_content: this.emailContent,
        company_id: this.jobPosting.client.id,
        jobposting_id: this.jobPosting.id,
        subject: this.emailSubject,
      };
      // Check if cc and bcc are set. If so, add them to the order object.
      if (this.cc)
        order.email_cc = this.prepareEmailAddresses(this.cc);
      if (this.bcc)
        order.email_bcc = this.prepareEmailAddresses(this.bcc);

      try {
        const { data } = await NetworkAuth.getUserdata()
        order.user_sent = data.data.email
        const res = await NetworkBooking.placeEmailOrderForJobPosting(order)
        this.showSuccessMessageFromRes(res)
        this.emailContent = this.content;
        this.emailSubject = this.subject;
        this.placingEmailOrder = false;
        await this.$store.dispatch('mail/fetchMails', this.jobPosting)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingPlaceEmailOrder = false
      }
    },
    /**
     * Take a string of email addresses and split them up into an array.
     * @param emailAddresses String of email addresses
     */
    prepareEmailAddresses(emailAddresses) {
      return emailAddresses.replace(/\s/, '').split(';');
    },
    getSignificantValue,
  },
};
</script>

<style scoped>
.budget-list-item {
  font-size: 1em;
  font-weight: 900;
}
</style>
