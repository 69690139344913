<template>
  <Collapsible id="company-xml-feed">
    <template slot="heading">{{ $t('company.xmlFeeds.heading') }}</template>
    <template slot="content">
      <b-table
          :items="company.xml_feeds"
          :fields="fields"
          :responsive="true"
          :bordered="true"
          :hover="true"
          :show-empty="true"
      >
        <template #cell(link)="data">
          <a :href="data.item.link" target="_blank">{{ data.value }}</a>
        </template>
        <template #cell(created_at)="data">
          <div>{{ getFormattedDate(data.item.created_at) }}</div>
        </template>
        <template #cell(updated_at)="data">
          <div>{{ getFormattedDate(data.item.updated_at) }}</div>
        </template>
        <template #cell(active)="data">
          <b-badge :variant="data.item.active === 1 ? 'success' : 'secondary'">
            {{ data.item.active === 1 ? $t('general.active') : $t('general.inactive') }}
          </b-badge>
        </template>
      </b-table>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '../../components/base/Collapsible';

export default {
  components: {Collapsible},
  data() {
    return {
      fields: [
        {
          key: 'active',
          label: ""
        },
        {
          key: 'name',
        },
        {
          key: 'publisher',
        },
        {
          key: 'link',
        },
        {
          key: 'mode',
          label: this.$t('xmlFeed.fields.mode.label'),
        },
        {
          key: 'created_at',
          label: this.$t('xmlFeed.fields.created_at.label'),
        },
        {
          key: 'updated_at',
          label: this.$t('xmlFeed.fields.updated_at.label'),
        }
      ]
    }
  },
  computed: {
    company() {
      return this.$store.state.company.selectedItem
    }
  },
  methods: {
    getFormattedDate(val) {
      return (new Date(val)).toLocaleString();
    }
  }
};
</script>

<style scoped>

</style>
