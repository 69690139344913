<template>
    <div class="create-jobPosting overflow-hidden">
        <Collapsible id="job-posting-create">
            <template slot="heading">{{$t('jobPosting.create')}}</template>
            <template slot="content">
                <b-card>
                    <b-form @submit.prevent="onSubmit">
                        <div class="row mb-3">
                            <!-- Name -->
                            <div class="col-md-6 col-lg-4 my-2">
                                <FormInput
                                        v-model="form.title"
                                        :editable="true"
                                        :label="$t(`jobPosting.fields.title.label`)"
                                        :errors="errors"
                                        error-field-name="title"
                                />
                            </div>
                            <div class="col-md-6 col-lg-4 my-2">
                                <FormInput
                                        v-model="form.company_id"
                                        :selectable="true"
                                        :options="this.companiesSorted"
                                        :label="$t(`jobPosting.fields.company.label`)"
                                        :errors="errors"
                                        error-field-name="name"
                                />
                            </div>
                        </div>
                        <!-- Buttons -->
                        <Button
                                :disabled="!allFieldsAreSet"
                                :loading="loading"
                                icon="plus"
                                type="submit"
                                variant="success"
                        >
                            {{$t('general.button.create')}}
                        </Button>
                    </b-form>
                </b-card>
            </template>
        </Collapsible>
    </div>
</template>

<script>
  import Button from '../../../components/base/button/Button';
  import FormInput from '../../../components/base/input/FormInput';
  import NetworkJobPosting from '../../../helper/network/NetworkJobPosting';
  import NetworkCompany from '../../../helper/network/NetworkCompany';
  import Collapsible from '../../../components/base/Collapsible';
  import {toaster} from '../../../helper/mixins/toaster';
  import {errorHelper} from '../../../helper/mixins/error-helper';

  export default {
    components: {Collapsible, FormInput, Button},
    mixins: [toaster, errorHelper],
    data() {
      return {
        form: {
          title: null,
          company_id: null,
        },
        companies: [],
        loading: false,
      };
    },
    computed: {
      companiesSorted() {
        return this.companies.
            map(company => ({value: company.id, text: company.name})).
            sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      },
      allFieldsAreSet() {
        return this.form.title !== null && this.form.company_id !== null;
      },
    },
    created() {
      NetworkCompany.getCompaniesSimplified().
          then(res => this.companies = res.data.data).
          catch(err => console.error(err));
    },
    methods: {
      async onSubmit() {
        this.loading = true;
        // Using company.text for the company title and company.value for the company id.
        // This is due to the select input options.
        try {
          const res = await NetworkJobPosting.createJobPosting(this.form)
          this.showSuccessMessageFromRes(res);
          this.resetForm();
          this.resetErrors();
          this.$emit('item:created', res.data.data); // emit to main view

          // Show jobposting in query, thereby the data will get refreshed
          const newQuery = {
            ...this.$store.state.jobPosting.query,
            page: 1
          };
          this.$store.commit('jobPosting/setQuery', newQuery);
        } catch (e) {
          this.setErrors(e)
        } finally {
          this.loading = false
        }
      },
      close() {
        this.$emit('closed');
      },
      resetForm() {
        this.form.title = null;
        this.form.company_id = null;
      },
    },
  };
</script>

<style scoped>

</style>
