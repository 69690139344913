<template>
    <router-link :to="to" class="smaller">
        <font-awesome-icon icon="external-link-alt" fixed-width/>
        <slot/>
    </router-link>
</template>

<script>
  export default {
    props: {
      to: {
        type: [Object, String],
        required: true
      },
    },
  };
</script>

<style scoped>

</style>
