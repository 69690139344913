<template>
  <ViewContainer id="xml-feed">
    <ViewToggle :show-detailed-view="selectedItem">
      <!-- Main View -->
      <template slot="main-view">
        <!-- Table -->
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('xmlFeed.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton v-model="filter"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :show-empty="true"
              :busy.sync="isBusy"
              :filter.sync="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :no-local-sorting="true"
              @row-clicked="onRowClicked"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('xmlFeed.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('xmlFeed.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary"/>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.name"
                      :placeholder="$t('general.filter.name')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.description"
                      :placeholder="$t('general.filter.description')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.link"
                      :placeholder="$t('general.filter.link')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="filter.mode"
                      :placeholder="$t('general.filter.mode')"
                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary"/>
              </b-tr>
            </template>
            <!-- Cell 'created_at' -->
            <template #cell(created_at)="data">
              {{ getFormattedDate(data.value) }}
            </template>
            <!-- Cell 'link' -->
            <template #cell(link)="data">
              <a :href="data.value" target="_blank">{{ data.value }}</a>
            </template>
            <template #cell(toggle)="data">
              <b-form-checkbox v-model="data.item.active" switch :value="1" :unchecked-value="0"
                               class="align-self-center"
                               @click.native.prevent="confirmationDialog(data.item)">
                <b-spinner v-if="data.item.id === loadingFeedId" variant="primary" class="align-top"/>
                <span v-else>{{ data.item.active === 1 ? $t('general.active') : $t('general.inactive') }}</span>
              </b-form-checkbox>
            </template>
          </b-table>
        </ViewElement>
      </template>
      <template slot="detailed-view">
        <ViewElement class="mb-3">
          <XmlFeedDetail
              @item:closed="onItemClosed"
              :xml-feed="selectedItem"
          />
        </ViewElement>
        <ViewElement class="mb-3" id="utmParameters">
          <UtmParameter
              :item="selectedItem"
              view="xmlfeed"
              @item:closed="onItemClosed"
              @item:saved="fetchData"
          />
        </ViewElement>
      </template>
    </ViewToggle>
    <ConfirmationDialogWithUserInput v-if="selectedXmlFeed" v-model="openConfirmationDialog"
                                     @ok="activateOrInactivateFeed"
                                     :text="$t('xmlFeed.deleteConfirmation', { company: selectedXmlFeed.name, active: getActivateText(false)})"
                                     :title="selectedXmlFeed.name + ' ' + getActivateText(false) + '?'"
                                     :ok-titel="getActivateText(true)"/>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../../components/base/ViewContainer';
import ViewToggle from '../../../components/base/ViewToggle';
import ViewElement from '../../../components/base/ViewElement';
import PageHeading from '../../../components/base/typography/PageHeading';
import NetworkXmlFeed from '../../../helper/network/NetworkXmlFeed';
import {paginate} from '@/helper/mixins/paginate';
import XmlFeedDetail from '@/views/jobposting/xmlfeed/XmlFeedDetail';
import {getFormattedDate} from '@/helper/utility';
import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
import UtmParameter from "../../../components/UtmParameter";
import PerPagePicker from '@/components/PerPagePicker'
import {toaster} from "../../../helper/mixins/toaster";
import ConfirmationDialogWithUserInput from "../../../components/dialog/ConfirmationDialogWithUserInput";

export default {
  components: {
    ConfirmationDialogWithUserInput,
    PerPagePicker,
    UtmParameter,
    RemoveFilterButton, XmlFeedDetail, PageHeading, ViewElement, ViewToggle, ViewContainer
  },
  mixins: [paginate, toaster],
  data() {
    return {
      // Table display options
      fields: [
        {
          key: 'toggle',
          sortable: false,
          label: "",
        },
        {
          key: 'name',
          sortable: true,
          label: this.$t('xmlFeed.fields.name.label'),
        },
        {
          key: 'description',
          sortable: true,
          label: this.$t('xmlFeed.fields.description.label'),
        },
        {
          key: 'link',
          sortable: true,
          label: this.$t('xmlFeed.fields.link.label'),
        },
        {
          key: 'mode',
          sortable: true,
          label: this.$t('xmlFeed.fields.mode.label'),
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$t('xmlFeed.fields.created_at.label'),
        },
      ],
      // Item handling
      items: [],
      selectedItem: null,
      loadingFeedId: null,
      openConfirmationDialog: false,
      // Busy state
      isBusy: false,
    };
  },
  computed: {
    showUTM() {
      return this.selectedItem && this.selectedItem.mode !== null;
    },
    selectedXmlFeed: {
      set(value) {
        this.$store.commit("xmlFeed/setSelectedXmlFeed", value);
      },
      get() {
        return this.$store.state.xmlFeed.selectedXmlFeed;
      }
    }
  },
  methods: {
    async fetchData() {
      this.isBusy = true;
      this.items = await NetworkXmlFeed.getXmlFeeds({params: this.getQuery()}).then(res => {
        this.totalItems = res.data.meta.total;
        this.isBusy = false;
        this.updateRoute();
        return res.data.data;
      });
    },
    confirmationDialog(item) {
      this.selectedXmlFeed = item;
      this.openConfirmationDialog = true;
    },
    // text for confirmation dialog
    getActivateText(firstLetterUp) {
      if (firstLetterUp) {
        return this.selectedXmlFeed.active === 1 ? this.$t('general.Deactivate') : this.$t('general.Activate')
      }
      return this.selectedXmlFeed.active === 1 ? this.$t('general.deactivate') : this.$t('general.activate')
    },
    /**
     * activate or inactivate feed
     * @param item
     * @returns {Promise<void>}
     */
    async activateOrInactivateFeed() {
      if (this.selectedXmlFeed === null) {
        return;
      }
      this.loadingFeedId = this.selectedXmlFeed.id
      try {
        const res = await NetworkXmlFeed.activateOrInactivateXmlFeed(this.selectedXmlFeed.id, !this.selectedXmlFeed.active)
        this.showSuccessMessageFromRes(res);
      } catch (e) {
        console.error(e)
        this.showErrorMessageFromErr(e)
      } finally {
        this.loadingFeedId = null;
        this.selectedXmlFeed = null;
        await this.fetchData()
      }
    },
    onItemClosed() {
      this.selectedItem = null;
    },
    onRowClicked(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row')
        return;
      if (this.selectedItem && item.id === this.selectedItem.id)
        return 'selected';
    },
    getFormattedDate
  },
};
</script>

<style scoped>
</style>
