<template>
  <div>
    <!-- Job posting jass-ids -->
    <Collapsible id="toggle-job-posting-detail-jass-ids" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('Jass.header') }}</template>
      <template slot="content">
        <b-card class="mb-1" v-if="jassCopy !== undefined && jassCopy.length > 0">
          <div v-for="(jassItem, index) in jassCopy" :key="`${jassItem.id}-${index}`">
            <b-row>
              <b-col sm="12" md="5" lg="5" xl="5" class="mb-1">
                <p class="font-weight-bold mb-1">{{ $t('Jass.company') }}</p>
              </b-col>
              <b-col sm="12" md="5" lg="5" xl="5" class="mb-1">
                <p class="font-weight-bold mb-1">{{ $t('Jass.id') }}</p>
              </b-col>
            </b-row>
            <b-row>

              <b-col sm="12" md="5" lg="5" xl="5" class="mb-3">
                <FormInput
                    v-model="jassItem.name"
                    :editable="true"
                    :errors="errors"
                    error-field-name="key"
                />
              </b-col>

              <b-col sm="12" md="6" lg="5" xl="5" class="mb-3">
                <FormInput
                    v-model="jassItem.jass_customer_id"
                    :editable="true"
                    :errors="errors"
                    error-field-name="value"
                />
              </b-col>
              <b-col sm="12" md="1" lg="1" xl="1" class="text-center">
                <Button icon="minus" variant="danger" @click.native="removeJassId(jassItem)" icon-only></Button>
              </b-col>

            </b-row>
          </div>
        </b-card>
        <b-card id="addJassId" class="align-items-center" v-if="showAddButton">
          <Button
              icon="plus"
              variant="success"
              @click.native="onAdd"
          >
            <b>{{ $t('Jass.add') }}</b>
          </Button>
        </b-card>
      </template>
    </Collapsible>
    <!-- Controls -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
      <Button @click="onSave" icon="save" variant="success" :loading="loading">{{ $t('general.button.save') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "./base/button/Button";
import FormInput from "./base/input/FormInput";
import Collapsible from "./base/Collapsible";
import {toaster} from "../helper/mixins/toaster";
import {errorHelper} from '../helper/mixins/error-helper';
import NetworkJobPosting from "../helper/network/NetworkJobPosting";

export default {
  mixins: [toaster, errorHelper],
  components: {Collapsible, FormInput, Button},
  props: {
    company: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      jassCopy: []
    }
  },
  computed: {
    showAddButton() {
      //return this.jassArray.length < 5;
      return true
    },
    jass: {
      get() {
        return this.$store.state.company.jass
      },
      set(value) {
        this.$store.commit("company/setJass", value)
      }
    }
  },
  async created() {
    await this.getJassIds()
  },
  watch: {
    async company() {
      await this.getJassIds()
    },
    jass() {
      this.jassCopy = JSON.parse(JSON.stringify(this.jass))
    }
  },
  methods: {
    async getJassIds() {
      try {
        this.loading = true;
        const res = await NetworkJobPosting.getJassCustomerIds(this.company.id)
        this.jass = res.data.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false;
      }
    },
    async onSave() {
      this.loading = true;
      try {
        let res = await NetworkJobPosting.updateJassCustomerIds(this.company.id, this.jassCopy)
        this.showSuccessMessageFromRes(res);
        await this.getJassIds()
      } catch (e) {
        this.showErrorMessageFromErr(e)
      } finally {
        this.loading = false
      }
    },
    onClose() {
      this.$emit('item:closed'); // emit to main view
    },
    onAdd() {
      this.jassCopy.push({
        id: null,
        name: null,
        jass_customer_id: null,
      });
    },
    removeJassId(jass) {
      const index = this.jassCopy.indexOf(jass);
      if (index > -1) {
        this.jassCopy.splice(index, 1);
      }
    }
  }
}

</script>


<style scoped>

</style>
