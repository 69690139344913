<template>
  <b-modal
      v-model="modelHelper"
      v-if="ruleExtension !== undefined && ruleExtension !== null"
      id="company-rule-extension-modal"
      centered
      hide-footer
      :title="$t('company_rule_extensions.modal_editor_title')"
      size="xl"
      no-close-on-backdrop
  >
    <b-form-group class="mt-3" :label="$tc('company_rule_extensions.name')">
      <b-form-input v-model="ruleExtension.name" editable/>
    </b-form-group>

    <Separator/>

    <b-form-group class="mt-3" :label="$tc('company_rule_extensions.add_category')">
      <b-form-input v-model="ruleExtension.category" editable/>
    </b-form-group>

    <Separator/>

    <div class="table-padding">{{ $t('company_rule_extensions.rule') }}</div>
    <b-table-simple borderless class="mb-0">
      <b-thead v-if="ruleExtension.rule !== undefined && ruleExtension.rule.length > 0">
        <b-tr>
          <b-th></b-th>
          <b-th>{{ $t('company_rule_extensions.operation') }}</b-th>
          <b-th>{{ $t('company_rule_extensions.field') }}</b-th>
          <b-th>{{ $t('company_rule_extensions.value') }}</b-th>
        </b-tr>
      </b-thead>
      <!-- Current params -->
      <draggable v-model="ruleExtension.rule" tag="b-tbody">

        <b-tr v-for="(param, index) in ruleExtension.rule" :key="param.id">
          <b-td>
            <BIconArrowsMove scale="1.5" shift-h="32" shift-v="-8"/>
          </b-td>
          <b-td>
            <b-form-select v-model="param.op" :options="opOptions"/>
          </b-td>
          <b-td>
            <b-form-select v-model="param.key" :options="keyOptions" :disabled="isLogicalOperator(param.op)"/>
          </b-td>
          <b-td>
            <b-form-input v-model="param.value" :disabled="isLogicalOperator(param.op)"/>
          </b-td>
          <b-td class="align-middle">
            <Button @click="deleteParameter(index)" icon="trash" icon-only variant="danger"/>
          </b-td>
        </b-tr>
      </draggable>
      <b-tr>
        <b-td class="align-middle">
          <Button @click="addParameter" icon="plus" icon-only variant="success"/>
        </b-td>
      </b-tr>
    </b-table-simple>


    <Separator/>
    <!-- Title Extensions -->

    <div class="table-padding">{{ $t('company_rule_extensions.title_extensions') }}</div>
    <b-table-simple borderless class="mb-0">
      <b-thead v-if="ruleExtension.title_extensions !== undefined && ruleExtension.title_extensions.length > 0">
        <b-tr>
          <b-th>{{ $t('company_rule_extensions.to_replace') }}</b-th>
          <b-th>
            {{ $t('company_rule_extensions.extension_title') }}
          </b-th>
          <b-th>{{ $t('company_rule_extensions.is_replacement') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Current params -->
        <b-tr v-for="(paramTitle, index) in ruleExtension.title_extensions" :key="index">
          <b-td>
            <b-form-input v-model="paramTitle.to_replace" :disabled="paramTitle.is_replacement === 0"/>
          </b-td>
          <b-td>
            <b-form-input v-model="paramTitle.extension_title"/>
          </b-td>

          <b-td>
            <b-form-select v-model="paramTitle.is_replacement" :options="dropDownOptions"
                           @change="updateParamTitle($event, index)"/>
          </b-td>
          <b-td class="align-middle">
            <Button @click="deleteTitleExtension(index, paramTitle.id)" icon="trash" icon-only variant="danger"/>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="align-middle">
            <Button @click="addTitleExtension" icon="plus" icon-only variant="success"/>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <Separator/>


    <!-- Location Extensions -->
    <div class="table-padding">{{ $t('company_rule_extensions.location_extensions') }}</div>

    <b-table-simple borderless class="mb-0">
      <b-thead v-if="ruleExtension.location_extensions !== undefined && ruleExtension.location_extensions.length > 0">
        <b-tr>
          <b-th>
            {{ $t('company_rule_extensions.city') }}
          </b-th>
          <b-th>
            {{ $t('company_rule_extensions.postalcode') }}
          </b-th>
          <b-th>
            {{ $t('company_rule_extensions.state') }}
          </b-th>
          <b-th>
            {{ $t('company_rule_extensions.country_code') }}
          </b-th>
          <b-th>
            {{ $t('company_rule_extensions.title_extension') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Current params -->

        <b-tr v-for="(param, index) in ruleExtension.location_extensions" :key="index">
          <b-td>
            <b-form-input v-model="param.city_extension"/>
          </b-td>
          <b-td>
            <b-form-input v-model="param.postcode_extension"/>
          </b-td>
          <b-td>
            <b-form-input v-model="param.state_extension"/>
          </b-td>
          <b-td>
            <b-form-input v-model="param.country_extension"/>
          </b-td>
          <b-td>
            <b-form-select v-model="param.title_extension" :options="dropDownOptions"/>
          </b-td>
          <b-td class="align-middle">
            <Button @click="deleteLocationExtension(index, param.id)" icon="trash" icon-only variant="danger"/>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="align-middle">
            <Button @click="addLocationExtension" icon="plus" icon-only variant="success"/>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>


    <Separator/>

    <div class="d-flex align-items-center justify-content-between">
      <Button
          icon="times"
          @click.native="onCloseExtensionModal"
      >{{ $t('general.button.cancel') }}
      </Button
      >
      <Button @click="onSaveClick"
              icon="save"
              variant="success"
              :loading="loading">
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </b-modal>
</template>
<script>

import NetworkCompany from "@/helper/network/NetworkCompany";
import Separator from "@/components/base/Separator";
import Button from '../../components/base/button/Button';
import {toaster} from "@/helper/mixins/toaster";
import draggable from 'vuedraggable';
import {BIconArrowsMove} from "bootstrap-vue";

export default {
  components: {
    Separator,
    Button,
    draggable,
    BIconArrowsMove,
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  mixins: [toaster],
  data() {
    return {
      loading: false,
      newParam: {op: null, key: null, value: null},
      newParamTitle: {extension_title: null, to_replace: null, is_replacement: 0},
      newParamLocation: {
        city_extension: null,
        postcode_extension: null,
        state_extension: null,
        country_extension: null,
        title_extension: 0,
      },
      keyOptions: [
        {value: 'title', text: this.$t('company_rule_extensions.title_key')},
        {value: 'city', text: this.$t('company_rule_extensions.city_key')},
        {value: 'referencenumber', text: this.$t('company_rule_extensions.referencenumber_key')},
        {value: 'category', text: this.$t('company_rule_extensions.category_key')},
        {value: 'url', text: this.$t('company_rule_extensions.url_key')},
        {value: 'company', text: this.$t('company_rule_extensions.company_key')},
        {value: 'subcompany', text: this.$t('company_rule_extensions.subcompany_key')},
        {value: 'country', text: this.$t('company_rule_extensions.country_key')},
        {value: 'description', text: this.$t('company_rule_extensions.description_key')},
        {value: 'state', text: this.$t('company_rule_extensions.state_key')},
        {value: 'postalcode', text: this.$t('company_rule_extensions.postalcode_key')},
        {value: 'email', text: this.$t('company_rule_extensions.email_key')},
      ],
      opOptions: [
        {value: 'contains', text: this.$t('company_rule_extensions.contains')},
        {value: 'equals', text: this.$t('company_rule_extensions.equals')},
        {value: 'containsNot', text: this.$t('company_rule_extensions.containsNot')},
        {value: 'equalsNot', text: this.$t('company_rule_extensions.equalsNot')},
        {value: 'TRUE', text: this.$t('company_rule_extensions.TRUE')},
        {value: 'AND', text: this.$t('company_rule_extensions.AND')},
        {value: 'OR', text: this.$t('company_rule_extensions.OR')},
        {value: 'NOT', text: this.$t('company_rule_extensions.NOT')},
        {value: 'L_BRACKET', text: '('},
        {value: 'R_BRACKET', text: ')'},

      ],
      dropDownOptions: [
        {value: 0, text: this.$t('company_rule_extensions.no')},
        {value: 1, text: this.$t('company_rule_extensions.yes')},
      ]
    };
  },
  computed: {
    ruleExtension: {
      get() {
        return this.$store.state.company.selectedRuleExtension
      },
    },
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    }
  },
  watch: {
    ruleExtension: {
      deep: true,
      handler(value) {
        this.$store.commit('company/setSelectedRuleExtension', value);
      }
    }
  },
  methods: {
    async onSaveClick() {
      try {
        const res = await NetworkCompany.setRuleExtension(this.company.id, {
          'id': this.ruleExtension.id,
          'name': this.ruleExtension.name,
          'rule': this.ruleExtension.rule,
          'category': this.ruleExtension.category,
          'title_extensions': this.ruleExtension.title_extensions,
          'location_extensions': this.ruleExtension.location_extensions,
        });

        this.showSuccessMessageFromRes(res);
        const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
        this.$store.commit('company/setCompanyExtensionRules', resRules.data);
        this.$store.commit('company/setSelectedRuleExtension', null);
        this.modelHelper = false;
      } catch (err) {
        this.showErrorMessageFromErr(err)
      }
    },
    updateParamTitle(event, index) {
      // set input field to null if user choose no replacement
      if (event === 0) {
        this.ruleExtension.title_extensions[index].to_replace = null;
      }
    },
    isLogicalOperator(op) {
      return op === 'AND' || op === 'OR' || op === 'TRUE' || op === 'L_BRACKET' || op === 'R_BRACKET' || op === 'NOT' || op === null;
    },
    addParameter() {
      this.ruleExtension.rule.push({
        'op': this.newParam.op,
        'key': this.newParam.key,
        'value': this.newParam.value
      })
      this.newParam.op = null
      this.newParam.key = null
      this.newParam.value = null
    },
    addTitleExtension() {
      this.ruleExtension.title_extensions.push({
        'extension_title': this.newParamTitle.extension_title,
        'to_replace': this.newParamTitle.to_replace,
        'is_replacement': this.newParamTitle.is_replacement,
      })
      this.newParamTitle.extension_title = null;
      this.newParamTitle.to_replace = null;
      this.newParamTitle.is_replacement = 0;
    },
    addLocationExtension() {
      this.ruleExtension.location_extensions.push({
        'city_extension': this.newParamLocation.city_extension,
        'postcode_extension': this.newParamLocation.postcode_extension,
        'state_extension': this.newParamLocation.state_extension,
        'country_extension': this.newParamLocation.country_extension,
        'title_extension': this.newParamLocation.title_extension,
      });

      this.newParamLocation.city_extension = null;
      this.newParamLocation.postcode_extension = null;
      this.newParamLocation.state_extension = null;
      this.newParamLocation.country_extension = null;
      this.newParamLocation.title_extension = 0;

    },
    deleteParameter(index) {
      this.ruleExtension.rule.splice(index, 1);
    },
    async deleteTitleExtension(index, id) {
      if (id) {
        try {
          const res = await NetworkCompany.deleteTitleExtension(this.company.id, id);
          this.showSuccessMessageFromRes(res);
          this.ruleExtension.title_extensions.splice(index, 1);
        } catch (err) {
          this.showErrorMessageFromErr(err);
        }
      } else {
        this.ruleExtension.title_extensions.splice(index, 1);
      }
    },
    async deleteLocationExtension(index, id) {
      if (id) {
        try {
          const res = await NetworkCompany.deleteLocationExtension(this.company.id, id);
          this.showSuccessMessageFromRes(res);
          this.ruleExtension.location_extensions.splice(index, 1);
        } catch (err) {
          this.showErrorMessageFromErr(err);
        }
      } else {
        this.ruleExtension.location_extensions.splice(index, 1);
      }
    },
    async onCloseExtensionModal() {
      const resRules = await NetworkCompany.getRuleExtensions(this.company.id);
      this.$store.commit('company/setCompanyExtensionRules', resRules.data);
      this.$store.commit('company/setSelectedRuleExtension', null);
      this.modelHelper = false
    }
  },
}
</script>

<style scoped>
.table-padding {
  padding-left: 0.75rem;
}

</style>
