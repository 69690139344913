<template>
    <div class="log-detail">
        <Collapsible id="toggle-log-detail" :visible="true">
            <template slot="heading">{{$t('log.detail.heading')}}</template>
            <template slot="content">
                <FormGroup :label="$t('log.fields.id.label')">
                    <FormInput v-model="log.id"/>
                </FormGroup>
                <FormGroup :label="$t('log.fields.user.label')">
                    <LinkDetail
                            :to="{name: 'user', query: {id: log.user}}"
                            class="py-1 px-2 smaller"
                            style="display: inline-block"
                    >
                        {{log.user}}
                    </LinkDetail>
                </FormGroup>
                <FormGroup :label="$t('log.fields.type.label')">
                    <FormInput v-model="log.type"/>
                </FormGroup>
                <FormGroup :label="$t('log.fields.keyword.label')">
                    <FormInput v-model="log.keyword"/>
                </FormGroup>
                <FormGroup :label="$t('log.fields.description.label')">
                    <FormInput v-model="log.description"/>
                </FormGroup>
                <FormGroup :label="$t('log.fields.changes.label')">
                    <FormInputDiv disabled>
                        <LogsFormatterChanges :json-string="log.changes"/>
                    </FormInputDiv>
                </FormGroup>
                <FormGroup :label="$t('log.fields.created_at.label')">
                    <FormInput v-model="log.created_at"/>
                </FormGroup>
                <FormGroup :label="$t('log.fields.updated_at.label')">
                    <FormInput v-model="log.updated_at"/>
                </FormGroup>
            </template>
        </Collapsible>
        <Separator/>
        <!-- Footer -->
        <div class="d-flex align-items-center justify-content-between">
            <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
        </div>
    </div>
</template>

<script>
  import Separator from '../../components/base/Separator';
  import FormGroup from '../../components/base/input/FormGroup';
  import FormInput from '../../components/base/input/FormInput';
  import Button from '../../components/base/button/Button';
  import LogsFormatterChanges from './LogFormatterChanges';
  import FormInputDiv from '../../components/base/input/FormInputDiv';
  import LinkDetail from '../../components/navigation/LinkDetail';
  import Collapsible from '../../components/base/Collapsible';

  export default {
    components: {
      Collapsible,
      LinkDetail, FormInputDiv, LogsFormatterChanges, Button, FormInput, FormGroup, Separator},
    props: {
      log: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        user: null
      }
    },
    methods: {
      onClose() {
        this.$emit('item:closed'); // emit to main view
      },
    },
  };
</script>

<style scoped>
</style>
