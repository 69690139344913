<template>
  <b-form-group class="mb-2" :label="label" label-size="md" label-align="right" :label-cols="3">
    <slot/>
  </b-form-group>
</template>

<script>
  export default {
    props: ['label'],
  };
</script>

<style scoped>

</style>
