import { render, staticRenderFns } from "./ViewElement.vue?vue&type=template&id=0a76467f&scoped=true&"
import script from "./ViewElement.vue?vue&type=script&lang=js&"
export * from "./ViewElement.vue?vue&type=script&lang=js&"
import style0 from "./ViewElement.vue?vue&type=style&index=0&id=0a76467f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a76467f",
  null
  
)

export default component.exports