<template>
  <ViewContainer id="company">
    <ViewToggle :show-detailed-view="selJobPortal">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('jobPortal.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton v-model="filter"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="jobPortals"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy.sync="isBusy"
              :filter.sync="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :no-local-sorting="true"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('jobPortal.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('jobPortal.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="1" variant="secondary">
                  <b-form-input
                      v-model="filter.name"
                      :placeholder="$t('general.filter.name')"

                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th colspan="1" variant="secondary">
                  <b-form-input
                      v-model="filter.description"
                      :placeholder="$t('general.filter.description')"

                      type="search"
                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-radio-group
                      v-model="filter.is_active"
                      :options="isActiveFilterOptions"

                      buttons
                  />
                </b-th>
              </b-tr>
            </template>
            <!-- Head 'Selected' -->
            <template #head(selected)>
              <b-form-checkbox class="mx-0 d-block" @change="onToggleAll"/>
            </template>
            <!-- Cell 'Selected' -->
            <template #cell(selected)="data">
              <b-form-checkbox v-model="data.item[data.field.key]" class="mx-0 d-block"/>
            </template>
            <!-- Cell 'is_active' -->
            <template #cell(is_active)="data">
              <FormInput v-model="data.value" checkable disabled/>
            </template>
          </b-table>
        </ViewElement>
        <!-- Create new company -->
        <ViewElement class="mb-3">
          <JobPortalCreate @item:created="onJobPortalCreated"/>
        </ViewElement>
      </template>
      <!-- Detailed View -->
      <template slot="detailed-view">
        <!-- Detail -->
        <ViewElement class="mb-3">
          <JobPortalDetail/>
        </ViewElement>
        <!-- Products -->
        <ViewElement class="mb-3">
          <JobPortalProduct @item:saved="onProductSaved" @item:changed="onProductChanged"/>
        </ViewElement>
      </template>
    </ViewToggle>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../../components/base/ViewContainer'
import ViewToggle from '../../../components/base/ViewToggle'
import ViewElement from '../../../components/base/ViewElement'
import { paginate } from '@/helper/mixins/paginate'
import FormInput from '../../../components/base/input/FormInput'
import JobPortalDetail from './JobPortalDetail'
import JobPortalProduct from './JobPortalProduct'
import PageHeading from '../../../components/base/typography/PageHeading'
import JobPortalCreate from './JobPortalCreate'
import { toaster } from '@/helper/mixins/toaster'
import RemoveFilterButton from '../../../components/base/button/RemoveFilterButton'
import PerPagePicker from '@/components/PerPagePicker'

export default {
  components: {
    PerPagePicker,
    RemoveFilterButton,
    JobPortalCreate,
    PageHeading,
    JobPortalProduct,
    JobPortalDetail,
    FormInput,
    ViewElement,
    ViewToggle,
    ViewContainer,
  },
  mixins: [paginate, toaster],
  computed: {
    isDirty () {
      return this.$store.getters['jobPortal/getHasDirtyProduct']
    },
    jobPortals () {
      return this.$store.state.jobPortal.jobPortals
    },
    selJobPortal: {
      get () {
        return this.$store.state.jobPortal.selJobPortal
      },
      set (jobPortal) {
        this.$store.commit('jobPortal/setSelJobPortal', jobPortal) // Can be job portal or null
      },
    },
  },
  data () {
    return {
      // Table data
      fields: [
        {
          key: 'name',
          label: this.$t('jobPortal.fields.name.label'),
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('jobPortal.fields.description.label'),
          sortable: true,
        },
        {
          key: 'is_active',
          label: this.$t('jobPortal.fields.is_active.label'),
          sortable: true,
        },
      ],
      isActiveFilterOptions: [
        { text: this.$t('general.filter.is_active.options.none'), value: null },
        { text: this.$t('general.filter.is_active.options.inactive'), value: 0 },
        { text: this.$t('general.filter.is_active.options.active'), value: 1 },
      ],
      // Item handling
      items: [],
      selectedItem: null,
      // Busy state
      isBusy: false,
      // Loading
      loadingDelete: false,
    }
  },
  methods: {
    async fetchData () {
      this.isBusy = true
      try {
        const { meta } = await this.$store.dispatch('jobPortal/fetchJobPortals', { params: this.getQuery() })
        this.totalItems = meta.total
        this.updateRoute()
      } catch (err) {
        console.error(err)
      } finally {
        this.isBusy = false
      }
    },
    async onRowClicked (jobPortal) {
      // The next function is passed as a callback to the bootstrap modal.
      // next is executed when not passed 'false' as a parameter (doNextStep).
      // Do next step
      const next = async doNextStep => {
        if (!doNextStep) {
          return
        }
        if (jobPortal.id === this.selJobPortal?.id) {
          this.selJobPortal = null
        } else {
          await this.$store.dispatch('jobPortal/fetchJobPortal', { jobPortal })
        }
      }

      if (this.isDirty) // Products were changed, therefore show the dialogue
        this.showDialogue(await next)
      else // Products were not changed, therefore continue the usual way
        await next(true)
    },
    onItemSaved (itemSaved) {
      const indexToUpdate = this.items.findIndex(item => item.id === itemSaved.id)
      this.items.splice(indexToUpdate, 1, Object.assign({}, itemSaved))
    },
    onJobPortalCreated (jobPortal) {
      this.fetchData()
      this.selectedItem = jobPortal
    },
    /**
     * Add the product id to the array of changed products.
     */
    onProductChanged (productId) {
      this.dirtyProductIds.push(productId)
      this.dirtyProductIds = [...new Set(this.dirtyProductIds)]
    },
    /**
     * Remove product id from array of changed products because the product was just saved.
     */
    onProductSaved (productId) {
      const index = this.dirtyProductIds.indexOf(productId)
      this.dirtyProductIds.splice(index, 1)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (this.selJobPortal?.id === item.id) return 'selected'
    },
    /**
     * Next a callback that determines what to do next. It can be the next function a vue-router
     * or a custom next function, e.g. continue selecting the item when a row was clicked.
     * If the prompt is confirmed, the callback will be called.
     * @param next callback.
     */
    async showDialogue (next) {
      try {
        const value = await this.$bvModal.msgBoxConfirm(this.$t('jobPortal.dialogue.areYouSure'), {
          title: this.$t('jobPortal.dialogue.title'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('general.options.yes'),
          cancelTitle: this.$t('general.options.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        if (value) {
          next(true)
          this.$store.commit('jobPortal/resetProductsDirty') // after navigation products are no longer dirty
        } else {
          next(false) // cancel navigation
        }
      } catch (err) {
        console.error(err)
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    // If no products were changed, just go to the next route.
    if (!this.isDirty) {
      next()
    } else {
      // If products where changed, show the dialogue and wait for the user input.
      this.showDialogue(next)
    }
  },
}
</script>

<style scoped>
</style>
