<template>
    <div>
        <div v-for="(value, key) in json" :key="key">
            <b>{{key}}</b>: {{value}}
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      jsonString: {
        required: true,
        type: String,
      },
    },
    computed: {
      json() {
        return JSON.parse(this.jsonString);
      },
    }
  };
</script>

<style scoped>
</style>
