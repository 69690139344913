<template>
    <ViewContainer id="log">
        <ViewToggle :show-detailed-view="selectedItem">
            <!-- Main View -->
            <template slot="main-view">
                <ViewElement class="mb-3">
                    <!-- Page Heading -->
                    <PageHeading>{{$t('log.pageHeading')}}</PageHeading>
                    <!-- Controls -->
                    <b-row>
                        <!-- Pagination -->
                        <b-col class="flex-grow-0">
                            <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalItems"
                                    :per-page="perPage"

                            />
                        </b-col>
                        <!-- Per page-->
                        <b-col class="flex-grow-0 mb-2">
                          <PerPagePicker v-model="perPage"/>
                        </b-col>
                      <b-col cols="auto">
                        <RemoveFilterButton v-model="filter"/>
                      </b-col>
                    </b-row>
                    <!-- Table -->
                    <b-table
                            :items="items"
                            :fields="fields"
                            :responsive="true"
                            :bordered="true"
                            :hover="true"
                            :show-empty="true"
                            :busy.sync="isBusy"
                            :filter.sync="filter"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :no-local-sorting="true"
                            :tbody-tr-class="rowClass"
                            @row-clicked="onRowClicked"
                            ref="dataTable"
                    >
                      <template #empty>
                        <span class="text-black-50">{{ $t('log.no_data') }}</span>
                      </template>
                      <!-- Loading -->
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>{{ $t('log.loading') }}</strong>
                        </div>
                      </template>
                        <!-- Filters -->
                        <template #thead-top="data">
                            <b-tr>
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.user"
                                            :placeholder="$t('general.filter.user')"
                                            debounce="700"
                                            type="search"

                                    />
                                </b-th>
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.type"
                                            :placeholder="$t('general.filter.type')"
                                            debounce="700"
                                            type="search"

                                    />
                                </b-th>
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.keyword"
                                            :placeholder="$t('general.filter.keyword')"
                                            debounce="700"
                                            type="search"

                                    />
                                </b-th>
                                <b-th variant="secondary">
                                    <b-input
                                            v-model="filter.description"
                                            :placeholder="$t('general.filter.description')"
                                            debounce="700"
                                            type="search"

                                    />
                                </b-th>
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.changes"
                                            :placeholder="$t('general.filter.changes')"
                                            debounce="700"
                                            type="search"

                                    />
                                </b-th>
                              <b-th variant="secondary"/>
                            </b-tr>
                        </template>
                        <!-- Cell Changes -->
                        <template #cell(changes)="data">
                            <div class="wrapper">
                                <LogFormatterChanges :json-string="data.value"/>
                            </div>
                        </template>
                      <template #cell(created_at)="data">
                        <div>{{ getFormattedDate(data.item.created_at) }}</div>
                      </template>
                    </b-table>
                </ViewElement>
            </template>
            <template slot="detailed-view">
                <ViewElement>
                    <LogDetail :log="selectedItem" @item:closed="onItemClosed"/>
                </ViewElement>
            </template>
        </ViewToggle>
    </ViewContainer>
</template>

<script>
import ViewContainer from '../../components/base/ViewContainer';
import ViewToggle from '../../components/base/ViewToggle';
import ViewElement from '../../components/base/ViewElement';
import PageHeading from '../../components/base/typography/PageHeading';
import {paginate} from '../../helper/mixins/paginate';
import NetworkLog from '../../helper/network/NetworkLog';
import LogFormatterChanges from './LogFormatterChanges';
import LogDetail from './LogDetail';
import RemoveFilterButton from "../../components/base/button/RemoveFilterButton";
import PerPagePicker from '@/components/PerPagePicker'

export default {
    components: {
      PerPagePicker,
      RemoveFilterButton, LogDetail, LogFormatterChanges, PageHeading, ViewElement, ViewToggle, ViewContainer},
    mixins: [paginate],
    data() {
      return {
        fields: [
          {
            key: 'user',
            label: this.$t('log.fields.user.label'),
            sortable: true,
          },
          {
            key: 'type',
            label: this.$t('log.fields.type.label'),
            sortable: true,
          },
          {
            key: 'keyword',
            label: this.$t('log.fields.keyword.label'),
            sortable: true,
          },
          {
            key: 'description',
            label: this.$t('log.fields.description.label'),
            sortable: true,
          },
          {
            key: 'changes',
            label: this.$t('log.fields.changes.label'),
            sortable: true,
          },
          {
            key: 'created_at',
            label: this.$t('general.date.label')
          }
        ],
        // Item handling
        items: [],
        selectedItem: null,
        // Busy state
        isBusy: false,
      }
    },
    methods: {
      async fetchData() {
        this.isBusy = true;
        this.items = await NetworkLog.getLogs({params: this.getQuery()}).
            then(res => {
              this.totalItems = res.data.meta.total;
              this.isBusy = false;
              this.updateRoute();
              return res.data.data;
            });
      },
      onRowClicked(item) {
        if (this.selectedItem && this.selectedItem.id === item.id)
          this.selectedItem = null;
        else
          this.selectedItem = item;
      },
      onItemClosed() {
        this.selectedItem = null;
      },
      rowClass(item, type) {
        if (!item || type !== 'row') return;
        if (this.selectedItem && item.id === this.selectedItem.id) return 'selected';
      },
      getFormattedDate(val) {
        return (new Date(val)).toLocaleString();
      }
    }
  };
</script>

<style scoped>
</style>
