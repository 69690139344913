import Login from '../views/auth/Login.vue';
import RequestPassword from '../views/auth/RequestPassword.vue';
import ResetPassword from '../views/auth/ResetPassword.vue';
import NotFound from '../views/NotFound.vue';
import user from '../views/user/User.vue';
import company from '../views/company/Company.vue';
import category from '../views/product/category/Category.vue';
import jobportal from '../views/product/jobportal/JobPortal.vue';
import catalogue from '../views/product/catalogue/Catalogue.vue';
import log from '../views/log/Log.vue';
import crawler from '../views/jobposting/crawler/Crawler.vue';
import jobPosting from '../views/jobposting/jobposting/JobPosting.vue';
import order from '../views/product/order/Order.vue';
import xmlFeed from '../views/jobposting/xmlfeed/XmlFeed.vue';
import campaign from '../views/jobposting/campaign/Campaign.vue';
import indeedBudget from "@/views/statistics/indeedBudget/IndeedBudget.vue";
import {storeUserOrBack} from './middleware/storeUserOrLogin';
import {routesHelper} from './helper/routes-helper';

const routes = [

  // ---------------------------------------------------------------------------------
  // AUTH
  // ---------------------------------------------------------------------------------

  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/request-password',
    name: 'request-password',
    component: RequestPassword,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/',
    redirect: '/jobposting',
  },

  // ---------------------------------------------------------------------------------
  // PROTECTED
  // ---------------------------------------------------------------------------------

  {
    path: '/user',
    name: 'user',
    component: user,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/company',
    name: 'company',
    component: company,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/category',
    name: 'category',
    component: category,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/jobportal',
    name: 'jobportal',
    component: jobportal,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/catalogue',
    name: 'catalogue',
    component: catalogue,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/crawler',
    name: 'crawler',
    component: crawler,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/xmlfeed',
    name: 'xmlfeed',
    component: xmlFeed,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/jobposting',
    name: 'jobposting',
    component: jobPosting,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/order',
    name: 'order',
    component: order,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: campaign,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/indeed-budget',
    name: 'indeedBudget',
    component: indeedBudget,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '/log',
    name: 'log',
    component: log,
    props: routesHelper.getQueryProps,
    beforeEnter: storeUserOrBack,
    meta: { hasSidebar: true }
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
];

export default routes;
