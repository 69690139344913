<template>

  <Collapsible id="order-job-posting" visible>
    <template slot="heading">{{ $t('order.jobPosting.heading') }}</template>
    <template slot="content">
      <b-table
          :items="order.jobpostings"
          :fields="fields"
          :responsive="true"
          :bordered="true"
      >
        <template #cell(status)="data">
          <StatusElement :status="data.value"/>
        </template>
      </b-table>
    </template>
  </Collapsible>

</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import StatusElement from '@/components/base/jobposting/StatusElement';

export default {
  components: {StatusElement, Collapsible},
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "customer_refnum",
          label: this.$t('order.fields.jobpostings.customer_refnum.label'),
        },
        {
          key: "title",
          label: this.$t('order.fields.jobpostings.title.label'),
        },
        {
          key: "city",
          label: this.$t('order.fields.jobpostings.city.label'),
        },
        {
          key: "postalcode",
          label: this.$t('order.fields.jobpostings.postalcode.label'),
        },
        {
          key: "status",
          label: this.$t('order.fields.jobpostings.status.label'),
        },
      ]
    }
  },
};
</script>

<style scoped>

</style>
