export default {
  methods: {
    getVariantForStatus(status) {
      // Switch status for job postings
      switch (status) {
        case 'created':
          return '';
        case 'active':
          return 'success';
        case 'inactive':
          return 'secondary';
        case 'reactivated':
          return 'warning';
        case 'waiting for approval':
          return 'info';
        case 'approved':
          return 'primary';
        case 'rejected':
          return 'danger';
        default:
          return '';
      }
    },
    getVariantForOrder(order) {
      const percentageForOrder = this.getPercentageForOrder(order);

      if (percentageForOrder <= 20)
        return 'danger';
      else if (percentageForOrder <= 50)
        return 'warning';
      else
        return 'success';
    },
    getPercentageForOrder(order) {
      const diffDays = this.getDiffInDaysForOrder(order);
      return 100 - ((diffDays / order.term_in_days) * 100).toFixed(2);
    },
    getDaysLeftForOrder(order) {
      return order.term_in_days - this.getDiffInDaysForOrder(order);
    },
    getDiffInDaysForOrder(order) {
      // Answer from stackoverflow: https://stackoverflow.com/a/3224854/5371227
      const date1 = new Date(order.activated_at);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  }
}
