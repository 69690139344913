<template>
  <div class="view-toggle">
    <!-- Main View -->
    <b-row>
      <b-col v-show="showMainView" :xl="showDetailedView || showBatchProcessingView ? 6 : 12">
        <slot name="main-view"/>
      </b-col>
      <!-- Detailed View -->
      <b-col v-if="showDetailedView" :xl="showMainView ? 6 : 12" class="detailed-view">
        <slot name="detailed-view"/>
      </b-col>
      <!-- Batch Processing View -->
      <b-col v-if="showBatchProcessingView" :xl="showMainView ? 6 : 12" class="detailed-view">
        <slot name="batch-processing-view"/>
      </b-col>
    </b-row>

    <!-- Toggle Button -->
    <transition name="slide-fade">
      <!-- Toggle button only displayed when detailed view is open -->
      <Button
          v-if="showToggleBtn && (showDetailedView || showBatchProcessingView)"
          @click.native="onMainViewToggle"
          :disabled="!showDetailedView && !showBatchProcessingView"
          :icon="showMainView ? 'eye-slash' : 'eye'"
          icon-only
          class="toggle-button"
          variant="primary"
      >
      </Button>
    </transition>

  </div>
</template>

<script>
import Button from './button/Button';

export default {
  components: {Button},
  props: {
    showDetailedView: {
      required: true,
      default: Boolean,
    },
    showBatchProcessingView: {
      required: false,
      default: false
    },
    showToggleBtn: {
      default: true,
    },
  },
  data() {
    return {
      showMainView: true
    };
  },
  // Fixes the problem that when you look at the job ads in the detail view,
  // by activating the "eye" button hide the table and then press cancel, you can not navigate further
  watch: {
    showDetailedView() {
      if (this.showDetailedView === null && !this.showBatchProcessingView) {
        this.showMainView = true;
      }
    },
    showBatchProcessingView() {
      if (!this.showBatchProcessingView && this.showDetailedView === null) {
        this.showMainView = true;
      }
    }
  },
  methods: {
    onMainViewToggle() {
      this.showMainView = !this.showMainView;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

.toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 15px;
  right: 25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 2rem;
  z-index: 9999;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all $transition-duration ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

</style>
