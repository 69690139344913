<template>
  <h1 class="m-0 mb-3 text-nowrap">
    <slot/>
  </h1>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
