<template>
    <Collapsible id="toggle-category-detail-job-portal" :visible="true">
        <template slot="heading">
            <Heading>{{$t('category.jobPortal.heading')}}</Heading>
        </template>
        <template slot="content">
            <b-table
                    :items="category.jobportals"
                    :responsive="true"
                    :bordered="true"
            />
        </template>
    </Collapsible>
</template>

<script>
  import Collapsible from '../../../components/base/Collapsible';
  import Heading from '../../../components/base/typography/Heading';

  export default {
    components: {Heading, Collapsible},
    props: {
      category: {
        required: true,
        type: Object,
      }
    }
  };
</script>

<style scoped>

</style>
