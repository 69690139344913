export default {
    namespaced: true,
    state: {
        selUser: null
    },
    mutations: {
        setSelUser: (state, user) => state.selUser = user,
    },
    actions: {},
    getters: {},
}
