<!-- This is the attached campaign job postings SCF -->
<template>
  <!-- Create a collapsible component -->
  <Collapsible id="campaign-detail-job-postings" :visible="true">
    <!-- The heading of the collapsible component -->
    <template slot="heading">{{ $t('campaign.jobPosting.heading') }}</template>
    <!-- The content of the collapsible component -->
    <template slot="content">
      <!-- Create the table to display all the campaign job postings -->
      <b-table
          :items="jobPostings"
          :fields="fields"
          :filter="filter"
          responsive
          bordered
          ref="campaignJobPostingsTable"
          selectable
          select-mode="multi"
          @row-selected="onRowSelected"
      >
        <!-- Create a filter component to filter the tables job postings -->
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="10" variant="secondary">
              <FormInput v-model="filter" :placeholder="$t('general.filter.default')" editable/>
            </b-th>
          </b-tr>
        </template>
        <!-- Display a roboter icon if the job posting was attached to the campaign by a crawler -->
        <!-- Display a keyboard icon if the job postings was attached to the campaign by a human -->
        <!-- This template belongs to the is_from_crawler column -->
        <template #cell(is_from_crawler)="data">
          <font-awesome-icon v-if="data.item.is_from_crawler" icon="robot" fixed-width/>
          <font-awesome-icon v-else icon="keyboard" fixed-width/>
        </template>
        <!-- This template belongs to the is_selected table header column -->
        <template #head(is_selected)="data">
          <Button @click="selectAll" icon="check" variant="default" icon-only />
        </template>
        <!-- This template belongs to the is_selected column -->
        <template #cell(is_selected)="{ rowSelected }">
          <!-- if the row is selected show the check icon -->
          <template v-if="rowSelected">
            <font-awesome-icon icon="check" fixed-width/>
          </template>
          <!-- if the row is not selected show nothing -->
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <!-- This template belonts to the created_at column -->
        <template #cell(created_at)="data">
          {{ getFormattedDate(data.value) }}
        </template>
      </b-table>
      <!-- This button is used to detach the selected job postings from the campaign -->
      <!-- This button is only enabled if at least one job posting is selected -->
      <Button icon="trash" variant="danger" :disabled="disableDeleteSelectedButton" v-b-modal.delete-jobpostings-modal>
        {{ $t('campaign.jobPosting.deleteSelectedJobPostingsButton') }}
      </Button>

      <!-- Create detach job postings confirmation modal -->
      <!-- Only if the user clicks on the ok button the job postings will be detached from the campaign -->
      <b-modal
          id="delete-jobpostings-modal"
          :title="$t('campaign.jobPosting.deletionModal.title')"
          :ok-title="$t('campaign.jobPosting.deletionModal.ok-title')"
          :cancel-title="$t('campaign.jobPosting.deletionModal.cancel-title')"
          @ok="removeJobPostingsFromCampaign"
          @cancel="resetSelection"
      >
        <ul>
          <li v-for="item in this.selected" :key="item.id">{{ item.title }}</li>
        </ul>
      </b-modal>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible';
import FormInput from '../../../components/base/input/FormInput';
import Button from '../../../components/base/button/Button';
import {toaster} from '@/helper/mixins/toaster';
import {getFormattedDate} from '@/helper/utility';

import { mapGetters } from "vuex"

export default {
  components: {Button, FormInput, Collapsible},
  mixins: [toaster],
  data() {
    return {
      // this array stores all the selected job posting rows
      selected: [],
      // this property is true if there are no selected job posting table rows
      disableDeleteSelectedButton: true,
      // filter
      filter: null,
      // define the job posting table columns
      fields: [
        { key: 'is_selected', label: '', tdClass: 'selectedColumn' },
        { key: 'is_from_crawler', label: '' },
        { key: 'company', label: this.$t('jobPosting.fields.company.label'), sortable: true },
        { key: 'title', label: this.$t('jobPosting.fields.title.label'), sortable: true },
        { key: 'customer_refnum', label: this.$t('jobPosting.fields.customer_refnum.label'), sortable: true },
        { key: 'refnum', label: this.$t('jobPosting.fields.refnum.label'), sortable: true },
        { key: 'status.name', label: this.$t('jobPosting.fields.status.label'), sortable: true },
        { key: 'city', label: this.$t('jobPosting.fields.city.label'), sortable: true },
        { key: 'category', label: this.$t('jobPosting.fields.category.label'), sortable: true },
        { key: 'created_at', label: this.$t('jobPosting.fields.created_at.label'), sortable: true }
      ],
    };
  },
  computed: {
    ...mapGetters('campaign', {getCampaignJobPostings: 'getCampaignJobPostings'}),
    jobPostings: {
      get() {
        return this.getCampaignJobPostings
      },
      set(jobs) {
        this.$store.commit('campaign/setCampaignJobPostings', jobs)
      }
    },
      loading: {
        get() {
          return this.$store.state.campaign.isBusy
        },
        set(bool) {
          this.$store.commit('campaign/setIsBusy', bool)
        }
      },
      campaign() {
          return this.$store.state.campaign.selectedItem
      },
  },
  watch: {
    campaign(val) {
      this.jobPostings = val.jobpostings;
    },
  },
  methods: {
    // update the selected job postings array
    // and check if the delete selected job postings button needs to be disabled
    onRowSelected(items) {
      this.selected = items
      this.disableDeleteSelectedButton = this.selected.length == 0 ? true : false;
    },
    // select all job postings table rows
    selectAll() {
      this.$refs.campaignJobPostingsTable.selectAllRows();
    },
    // de-select all job postings table rows
    resetSelection() {
      this.$refs.campaignJobPostingsTable.clearSelected();
    },
    // this function extracts the job posting id from a given job posting
    getJobPostingId(jobPosting) {
      return jobPosting.id;
    },
    // this method removes the job postings from the selected campaign
    removeJobPostingsFromCampaign() {
      let deleteJobPostingsIds = this.selected.map(this.getJobPostingId);
      this.$store.dispatch('campaign/deleteCampaignJobPostings', {jobPostings: deleteJobPostingsIds, vm: this});
    },
    // helper method
    getFormattedDate
  },
};
</script>

<style>
  .selectedColumn {
    width: 35px;
    text-align: center;
  }
</style>
