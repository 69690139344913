import NetworkHelper from './NetworkHelper';
import axios from 'axios';

export default class NetworkCompany extends NetworkHelper {
    static getCompanies(config) {
        return axios.get('/api/company', config);
    }

    static getCompaniesSimplified(config) {
        return axios.get('/api/company/simplified', config);
    }

    static getCompany(company, config) {
        return axios.get(`/api/company/${company.id}`, config);
    }

    static getJassId(company, config) {
        return axios.get(`/api/company/${company.id}/jassid`, config);
    }

    static getUsersForCompanyEmplify(config) {
        return axios.get(`/api/company/emplify`, config);
    }

    static createCompany(company, config) {
        return axios.post('/api/company', company, config);
    }

    static updateCompany(company, config) {
        return axios.put(`/api/company/${company.id}`, company, config);
    }

    static deleteMultipleCompanies(companies, config) {
        return axios.delete(`/api/company`, {
            data: {companies},
            ...config,
        });
    }

    static assignContactPerson(company, contact_person_id, config) {
        return axios.put(
            `/api/company/${company.id}/contact`,
            {contact_person_id},
            config,
        );
    }

    // ------------------------------------------------------------
    // LOGO
    // ------------------------------------------------------------

    static updateLogo(company, formData, config) {
        // makes the request method to be 'PUT'
        formData.append('_method', 'PUT');
        return axios.post(`/api/company/${company.id}/logo`, formData, config);
    }

    static deleteLogo(company, config) {
        return axios.delete(`/api/company/${company.id}/logo`, config);
    }

    static addUser(company, user, config) {
        return axios.post(`/api/company/${company.id}/user`, user, config);
    }

    // ------------------------------------------------------------
    // CONTINGENT
    // ------------------------------------------------------------

    /**
     * Update contingent if exists. Create new one if not.
     * @param company
     * @param productId
     * @param count
     * @param config
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updateContingent(company, productId, count, config) {
        return axios.post(
            `/api/company/${company.id}/contingent`,
            {product: productId, count: count},
            config,
        );
    }

    static deleteContingent(company, productId, config) {
        return axios.delete(
            `/api/company/${company.id}/contingent/${productId}`,
            config,
        );
    }

    // ------------------------------------------------------------
    // CATALOGUE
    // ------------------------------------------------------------

    static updateCatalogue(company, catalogue, config) {
        return axios.put(`/api/company/${company.id}/catalogue`, catalogue, config);
    }

    // ------------------------------------------------------------
    // CAMPAIGN
    // ------------------------------------------------------------

    static setCampaignsForCompany(campaignIds, companyId, config) {
        return axios.put(
            `/api/company/${companyId}/campaign`,
            {campaign_ids: campaignIds},
            config,
        );
    }

    // ------------------------------------------------------------
    // DASHBOARD
    // ------------------------------------------------------------

    static setDashboardTiles(dashboardTileIds, companyId, config) {
        return axios.put(
            `/api/company/${companyId}/dashboardtile`,
            {dashboard_tiles: dashboardTileIds},
            config,
        );
    }


    // ------------------------------------------------------------
    // RULE EXTENSIONS
    // ------------------------------------------------------------

    static getRuleExtensions(companyId) {
        return axios.get(`/api/company/${companyId}/ruleExtensions`)
    }

    static setRuleExtension(companyId, rule) {
        return axios.post(`/api/company/${companyId}/ruleExtensions`,
            rule
        )
    }

    static deleteRuleExtensions(companyId, id) {
        return axios.delete(`/api/company/${companyId}/ruleExtensions/${id}`)
    }


    static deleteTitleExtension(companyId, id) {
        return axios.delete(`/api/company/${companyId}/ruleExtensions/titleExtension/${id}`)
    }

    static deleteLocationExtension(companyId, id) {
        return axios.delete(`/api/company/${companyId}/ruleExtensions/locationExtension/${id}`)
    }

    static activateOrInactivateRule(companyId, id, activity) {
        return axios.put(`/api/company/${companyId}/ruleExtensions/${id}/active`, { active: activity})
    }

    static testRules(companyId, id, config) {
        return axios.get(`/api/company/${companyId}/ruleExtensions/${id}/test`, config)
    }

    static copyRules(companyId, id) {
        return axios.put(`/api/company/${companyId}/ruleExtensions/${id}/copy`)
    }

    // ------------------------------------------------------------
    // LOCATION EXTENSIONS
    // ------------------------------------------------------------

    static setLocationExtension(locationExtension, companyId) {
        return axios.put(`/api/company/${companyId}/locationExtension`, {
            locations: locationExtension,
        });
    }

    static getLocationExtension(companyId) {
        return axios.get(`/api/company/${companyId}/locationExtension`);
    }

    // ------------------------------------------------------------
    // LOCATION EXTENSIONS
    // ------------------------------------------------------------

    static setTitleExtension(titleExtension, companyId) {
        return axios.put(`/api/company/${companyId}/titleExtension`, {
            titles: titleExtension,
        });
    }

    static getTitleExtension(companyId) {
        return axios.get(`/api/company/${companyId}/titleExtension`);
    }


}
