<template>
  <ViewContainer id="company">
    <ViewToggle :show-detailed-view="selectedItem">
      <!-- Main View -->
      <template slot="main-view">
        <ViewElement class="mb-3">
          <!-- Page Heading -->
          <PageHeading>{{ $t('company.pageHeading') }}</PageHeading>
          <!-- Controls -->
          <b-row>
            <!-- Pagination -->
            <b-col class="flex-grow-0">
              <b-pagination
                  v-model="query.page"
                  :total-rows="totalPages"
                  :per-page="query.perPage"

              />
            </b-col>
            <!-- Per page-->
            <b-col class="flex-grow-0 mb-2">
              <PerPagePicker v-model="query.perPage"/>
            </b-col>
            <b-col cols="auto">
              <RemoveFilterButton @click="$store.dispatch('company/resetQuery')"/>
            </b-col>
          </b-row>
          <!-- Table -->
          <b-table
              :items="items"
              :fields="fields"
              :responsive="true"
              :bordered="true"
              :hover="true"
              :show-empty="true"
              :busy="isBusy"
              :sort-by="query.sortBy"
              :sort-desc="query.sortDesc"
              @sort-changed="sortingChanged"
              :no-local-sorting="true"
              :tbody-tr-class="rowClass"
              @row-clicked="onRowClicked"
              ref="dataTable"
          >
            <template #empty>
              <span class="text-black-50">{{ $t('company.no_data') }}</span>
            </template>
            <!-- Loading -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>{{ $t('company.loading') }}</strong>
              </div>
            </template>
            <!-- Filters -->
            <template #thead-top="data">
              <b-tr>
                <b-th variant="secondary" />
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.name"
                      :placeholder="$t('general.filter.name')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.street"
                      :placeholder="$t('general.filter.street')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.postcode"
                      :placeholder="$t('general.filter.postcode')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.city"
                      :placeholder="$t('general.filter.city')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
                <b-th variant="secondary">
                  <b-form-input
                      v-model="query.country"
                      :placeholder="$t('general.filter.country')"
                      type="search"
                      class="min-width-filter"

                      debounce="700"
                  />
                </b-th>
              </b-tr>
            </template>
            <!-- Head 'Selected' -->
            <template #head(selected)>
              <b-form-checkbox class="mx-0 d-block" @change="onToggleAll"/>
            </template>
            <!-- Cell default -->
            <template #cell()="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>
            <template #cell(extension_rules_enabled)="data">
              <font-awesome-icon v-if="data.value === 1" icon="check"/>
              <font-awesome-icon v-else-if="data.value === 0" icon="times"/>
              <font-awesome-icon v-else icon="question"/>
            </template>
          </b-table>
        </ViewElement>
        <!-- Create new company -->
        <ViewElement class="mb-3">
          <CompanyCreate/>
        </ViewElement>
      </template>
      <!-- Detailed View -->
      <template slot="detailed-view">
        <!-- Detail -->
        <ViewElement class="mb-3">
          <CompanyDetail/>
        </ViewElement>
        <!-- Contingents -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyContingent/>
        </ViewElement>
        <!-- Extensions -->
        <ViewElement class="mb-3">
          <CompanyExtension />
        </ViewElement>
        <!-- Users -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyUser/>
        </ViewElement>
        <!-- Add new User -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyUserAdd/>
        </ViewElement>
        <!-- Crawlers -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyCrawler/>
        </ViewElement>
        <!-- Jass ID input -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <JassIdInput :company="selectedItem" @item:closed="selectedItem = null"/>
        </ViewElement>
        <!-- Xml Feeds -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyXmlFeed/>
        </ViewElement>
        <!-- Catalogue -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyCatalogue/>
        </ViewElement>
        <!-- Contact person -->
        <ViewElement class="mb-3" v-if="system === 'automate'">
          <CompanyContactPerson/>
        </ViewElement>
        <!-- Dashboard tiles -->
        <ViewElement  v-if="system === 'automate'">
          <CompanyDashboardTile/>
        </ViewElement>
      </template>
    </ViewToggle>
  </ViewContainer>
</template>

<script>
import ViewContainer from '../../components/base/ViewContainer'
import ViewToggle from '../../components/base/ViewToggle'
import ViewElement from '../../components/base/ViewElement'
import CompanyDetail from './CompanyDetail'
import CompanyContingent from './CompanyContingent'
import CompanyCrawler from './CompanyCrawler'
import {toaster} from '@/helper/mixins/toaster'
import PageHeading from '../../components/base/typography/PageHeading'
import CompanyCreate from './CompanyCreate'
import CompanyXmlFeed from './CompanyXmlFeed'
import CompanyUser from './CompanyUser'
import CompanyUserAdd from './CompanyUserAdd'
import CompanyCatalogue from './CompanyCatalogue'
import CompanyContactPerson from '@/views/company/CompanyContactPerson'
import CompanyDashboardTile from '@/views/company/CompanyDashboardTile'
import CompanyExtension from '@/views/company/CompanyExtension'
import RemoveFilterButton from '../../components/base/button/RemoveFilterButton'
import PerPagePicker from '@/components/PerPagePicker'
import JassIdInput from '../../components/JassIdInput'
import NetworkCompany from "../../helper/network/NetworkCompany";

export default {
  components: {
    JassIdInput,
    PerPagePicker,
    RemoveFilterButton,
    CompanyDashboardTile,CompanyExtension,
    CompanyContactPerson,
    CompanyCatalogue,
    CompanyUserAdd,
    CompanyUser,
    CompanyXmlFeed,
    CompanyCreate,
    PageHeading,
    CompanyCrawler, CompanyContingent, CompanyDetail, ViewElement, ViewToggle, ViewContainer,
  },
  mixins: [toaster],
  data() {
    return {
      // Table display options
      fields: [
        {
          key: 'extension_rules_enabled',
          label: this.$t('company.fields.extension_flag'),
          sortable: false,
        },
        {
          key: 'name',
          label: this.$t('company.fields.name.label'),
          sortable: true,
        },
        {
          key: 'street',
          label: this.$t('company.fields.street.label'),
          sortable: true,
        },
        {
          key: 'postcode',
          label: this.$t('company.fields.postcode.label'),
          sortable: true,
        },
        {
          key: 'city',
          label: this.$t('company.fields.city.label'),
          sortable: true,
        },
        {
          key: 'country',
          label: this.$t('company.fields.country.label'),
        },
      ],
      system: process.env.VUE_APP_SYSTEM
    }
  },
  computed: {
    items() {
        return this.$store.state.company.items
    },
    isBusy: {
      get() {
        return this.$store.state.company.loading
      },
      set(val) {
        this.$store.commit('company/setLoading', val)
      }
    },
    selectedItem: {
      get() {
        return this.$store.state.company.selectedItem
      },
      set(selItem) {
        this.$store.commit('company/setSelectedItem', selItem)
      }
    },
    query: {
      get() {
        return this.$store.state.company.query
      },
      set(query) {
        this.$store.commit('company/setQuery', query)
      },
    },
    totalPages() {
      return this.$store.state.company.meta?.total ?? 999999
    },
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      handler() {
        this.$store.dispatch('company/fetchCompanies')
      },
    },
  },
  methods: {
    sortingChanged(ctx) {
      // :sort-by.sync and :sort-desc.sync doesnt work, because it fires the watcher twice
      this.query.sortBy = ctx.sortBy
      this.query.sortDir = ctx.sortDesc ? 'desc' : 'asc'
    },
    async onRowClicked(item) {
      if (this.selectedItem && this.selectedItem.id === item.id) {
        this.selectedItem = null
      } else {
        this.isBusy = true
        try {
          const res = await NetworkCompany.getCompany(item)
          this.selectedItem = res.data.data
        } catch (err) {
          console.error(err)
          return err
        } finally {
          this.isBusy = false
        }
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row')
        return
      if (this.selectedItem && item.id === this.selectedItem.id)
        return 'selected'
    },
  },
}
</script>

<style scoped>
</style>
