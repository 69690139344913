import { Route } from 'vue-router';
//@ts-ignore
import JWT from '../../helper/jwt';

export function protectRoutes(to: Route, from: Route, next: Function) {
    // redirect to login page if not logged in and trying to access a restricted page
    const privatePages = [
        '/users',
        '/company',
        '/category',
        '/jobportal',
        '/catalogue',
        '/jobposting',
        '/campaign',
        '/crawler',
        '/xmlfeed',
        '/log',
    ];
    const authRequired = privatePages.includes(to.path);
    const loggedIn = JWT.isLoggedIn();

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
}
