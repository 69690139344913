<template>
  <b-container class="p-4 scrollable w-100 light-bg" fluid>
    <slot></slot>
  </b-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

.light-bg {
  background-color: $light;
}
</style>
