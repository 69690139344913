import Vue from 'vue'
import Vuex from 'vuex'
//@ts-ignore
import company from '@/store/modules/company'
//@ts-ignore
import campaign from '@/store/modules/campaign'
//@ts-ignore
import jobPortal from '@/store/modules/jobPortal'
//@ts-ignore
import jobPosting from '@/store/modules/jobPosting'
//@ts-ignore
import xmlFeed from '@/store/modules/xmlFeed'
//@ts-ignore
import crawler from '@/store/modules/crawler'
//@ts-ignore
import mail from './modules/mail'
//@ts-ignore
import user from "./modules/user";
import indeedBudget from "@/store/modules/indeedBudget";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    company,
    campaign,
    jobPortal,
    jobPosting,
    user,
    mail,
    xmlFeed,
    crawler,
    indeedBudget
  }
})
