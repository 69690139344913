<template>
  <b-button @click="onClick" class="d-flex justify-content-center align-items-center delete-button-circle">
    <font-awesome-icon :icon="icon" fixed-width/>
  </b-button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'times',
    },
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.delete-button-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: none;
  &:hover {
    background-color: $danger !important;
  }
}
</style>
