<template>
  <div>
    <div>
      <div
          v-b-toggle="id"
          :class="{'active': isVisible}"
          class="d-flex align-items-center justify-content-between toggle-row p-2 rounded"
      >
        <Heading>
          <slot name="heading"/>
        </Heading>
        <div class="mr-2">
          <font-awesome-icon
              :class="{'point-to-bottom': isVisible}"
              fixed-width
              icon="chevron-left"
              class="chevron"
          />
        </div>
      </div>
    </div>
    <div>
      <b-collapse :id="id" v-model="isVisible">
        <div class="mt-3">
          <slot name="content"/>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import Heading from './typography/Heading'

export default {
  components: { Heading },
  props: {
    id: {
      required: true,
      type: String,
    },
    visible: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isVisible: this.visible,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.toggle-row {
  cursor: pointer;
  background-color: $light;
  @include transition;

  &:hover {
    background-color: $light-gray;
  }

  &.active {
    color: white;
    background-color: $dark;
  }

  .chevron {
    @include transition;

    &.point-to-bottom {
      transform: rotateZ(-90deg);
    }
  }
}
</style>
