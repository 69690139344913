<template>
    <ViewContainer>
        <ViewToggle :show-detailed-view="selectedItem">
            <!-- Main View -->
            <template slot="main-view">
                <ViewElement class="mb-3">
                    <!-- Table -->
                    <!-- Page Heading -->
                    <PageHeading>{{$t('category.pageHeading')}}</PageHeading>
                    <!-- Controls -->
                    <b-row>
                        <!-- Pagination -->
                        <b-col class="flex-grow-0">
                            <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalItems"
                                    :per-page="perPage"

                            />
                        </b-col>
                        <!-- Per page-->
                        <b-col class="flex-grow-0 mb-2">
                          <PerPagePicker v-model="perPage"/>
                        </b-col>
                      <b-col cols="auto">
                        <RemoveFilterButton v-model="filter"/>
                      </b-col>
                    </b-row>
                    <!-- Table -->
                    <b-table
                            :items="items"
                            :fields="fields"
                            :responsive="true"
                            :bordered="true"
                            :hover="true"
                            :show-empty="true"
                            :busy.sync="isBusy"
                            :filter.sync="filter"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :no-local-sorting="true"
                            :tbody-tr-class="rowClass"
                            @row-clicked="onRowClicked"
                            ref="dataTable"
                    >
                      <template #empty>
                        <span class="text-black-50">{{ $t('category.no_data') }}</span>
                      </template>
                      <!-- Loading -->
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>{{ $t('category.loading') }}</strong>
                        </div>
                      </template>
                        <!-- Filters -->
                        <template #thead-top="data">
                            <b-tr>
                                <b-th colspan="1" variant="secondary">
                                </b-th>
                                <!-- Loop through filters defined in data -->
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.name"
                                            :placeholder="$t('general.filter.name')"
                                            type="search"

                                            debounce="700"
                                    />
                                </b-th>
                            </b-tr>
                        </template>
                        <!-- Head 'Selected' -->
                        <template #head(selected)>
                            <b-form-checkbox class="mx-0 d-block" @change="onToggleAll"/>
                        </template>
                        <!-- Cell 'Selected' -->
                        <template #cell(selected)="data">
                            <b-form-checkbox v-model="data.item[data.field.key]" class="mx-0 d-block"/>
                        </template>
                        <!-- Cell default -->
                        <template #cell()="data">
                            <div class="text-nowrap">{{data.value}}</div>
                        </template>
                    </b-table>

                    <!-- Controls -->
                    <Button
                            :loading="loadingDelete"
                            :disabled="selectedItems.length === 0"
                            @click.native="onDelete"
                            variant="danger"
                            icon="trash"
                    >
                        {{$t('general.button.delete')}}
                    </Button>
                </ViewElement>
                <ViewElement class="mb-3">
                    <CategoryCreate @item:created="onItemCreated"/>
                </ViewElement>
            </template>
            <template slot="detailed-view">
                <ViewElement class="mb-3">
                    <CategoryDetail
                            :category="selectedItem"
                            @item:saved="onItemSaved"
                            @item:closed="onItemClosed"
                    />
                </ViewElement>

                <!-- Job portals -->
                <ViewElement class="mt-3">
                    <CategoryJobPortal :category="selectedItem"/>
                </ViewElement>
            </template>
        </ViewToggle>
    </ViewContainer>
</template>

<script>
  import Button from '../../../components/base/button/Button';
  import ViewToggle from '../../../components/base/ViewToggle';
  import ViewContainer from '../../../components/base/ViewContainer';
  import ViewElement from '../../../components/base/ViewElement';
  import {paginate} from '../../../helper/mixins/paginate';
  import NetworkCategory from '../../../helper/network/NetworkCategory';
  import CategoryDetail from './CategoryDetail';
  import CategoryJobPortal from './CategoryJobPortal';
  import PageHeading from '../../../components/base/typography/PageHeading';
  import CategoryCreate from './CategoryCreate';
  import {toaster} from '../../../helper/mixins/toaster';
  import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
  import PerPagePicker from '@/components/PerPagePicker'

  export default {
    components: {
      PerPagePicker,
      RemoveFilterButton,
      CategoryCreate,
      PageHeading,
      CategoryJobPortal,
      CategoryDetail,
      ViewElement,
      ViewContainer,
      ViewToggle,
      Button,
    },
    mixins: [paginate, toaster],
    data() {
      return {
        fields: [
          {
            key: 'selected',
            class: 'checkbox-col',
          },
          {
            key: 'name',
            label: this.$t('category.fields.name'),
            sortable: true,
          },
        ],
        // Item handling
        items: [],
        selectedItem: null,
        // Busy state
        isBusy: false,
        // Loading
        loadingDelete: false,
      };
    },
    computed: {
      selectedItems() {
        return this.items.filter(item => item.selected === true);
      },
    },
    methods: {
      async fetchData() {
        this.isBusy = true;
        this.items = await NetworkCategory.getCategories({params: this.getQuery()}).
            then(res => {
              this.totalItems = res.data.meta.total;
              this.isBusy = false;
              this.updateRoute();
              return res.data.data;
            });
      },
      onRowClicked(item) {
        if (this.selectedItem && this.selectedItem.id === item.id) {
          this.selectedItem = null;
        } else {
          NetworkCategory.getCategory(item).
              then(res => this.selectedItem = res.data.data).
              catch(err => console.error(err));
        }
      },
      onItemClosed() {
        this.selectedItem = null;
      },
      onItemSaved(itemSaved) {
        const indexToUpdate = this.items.findIndex(item => item.id === itemSaved.id);
        this.items.splice(indexToUpdate, 1, Object.assign({}, itemSaved));
      },
      onItemCreated(item) {
        this.fetchData();
        this.selectedItem = item;
      },
      onToggleAll(checked) {
        for (const item of this.items) {
          this.$set(item, 'selected', checked);
        }
      },
      onDelete() {
        this.loadingDelete = true;
        const categoryIds = this.selectedItems.map(item => item.id);
        NetworkCategory.deleteMultipleCategories(categoryIds).
            then(() => {
              this.showMessage({
                title: this.$t('general.toast.title.success'),
                message: this.$t('general.toast.delete.success'),
                variant: 'success',
              });
              this.fetchData();
            }).
            catch(err => this.showErrorMessageFromErr(err)).
            then(() => this.loadingDelete = false);
      },
      rowClass(item, type) {
        if (!item || type !== 'row') return;
        if (this.selectedItem && item.id === this.selectedItem.id) return 'selected';
      },
    },
  };
</script>

<style scoped>
</style>
