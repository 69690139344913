<template>
    <div class="category-detail">
        <!-- Category details -->
        <Collapsible id="toggle-category-detail" :visible="true">
            <template slot="heading">{{$t('category.detail.heading')}}</template>
            <template slot="content">
                <FormGroup :label="$t('category.fields.id.label')">
                    <FormInput v-model="categoryComputed.id"/>
                </FormGroup>
                <FormGroup :label="$t('category.fields.name.label')">
                    <FormInput v-model="categoryComputed.name" :errors="errors"
                               error-field-name="name" editable/>
                </FormGroup>
                <FormGroup :label="$t('category.fields.created_at.label')">
                    <FormInput v-model="categoryComputed.created_at"/>
                </FormGroup>
                <FormGroup :label="$t('category.fields.updated_at.label')">
                    <FormInput v-model="categoryComputed.updated_at"/>
                </FormGroup>
                <FormGroup :label="$t('category.fields.changed_by.label')">
                    <FormInput v-model="categoryComputed.changed_by"/>
                </FormGroup>
            </template>
        </Collapsible>
        <Separator/>
        <!-- Controls -->
        <div class="d-flex align-items-center justify-content-between">
            <Button icon="times" @click.native="onClose">{{$t('general.button.cancel')}}</Button>
            <Button
                    :loading="loading"
                    @click.native="onSave"
                    icon="save"
                    variant="success"
            >
                {{$t('general.button.save')}}
            </Button>
        </div>
    </div>
</template>

<script>
  import FormGroup from '../../../components/base/input/FormGroup';
  import FormInput from '../../../components/base/input/FormInput';
  import Button from '../../../components/base/button/Button';
  import NetworkCategory from '../../../helper/network/NetworkCategory';
  import Separator from '../../../components/base/Separator';
  import Collapsible from '../../../components/base/Collapsible';
  import {toaster} from '../../../helper/mixins/toaster';
  import {errorHelper} from '../../../helper/mixins/error-helper';

  export default {
    components: {Collapsible, Separator, Button, FormInput, FormGroup},
    mixins: [toaster, errorHelper],
    props: {
      category: {
        required: true,
        type: Object,
      }
    },
    data() {
      return {
        loading: false,
      };
    },
    watch: {
      category() {
        this.resetErrors();
      },
    },
    computed: {
      categoryComputed() {
        return Object.assign({}, this.category);
      },
    },
    methods: {
      onSave() {
        this.loading = true;
        NetworkCategory.updateCategory(this.categoryComputed).
            then(res => {
              this.resetErrors();
              this.showSuccessMessageFromRes(res);
              this.$emit('item:saved', this.categoryComputed);
              return res;
            }).
            catch(err => this.setErrors(err)).
            then(() => this.loading = false);
      },
      onClose() {
        this.$emit('item:closed'); // emit to main view
      },
    },
  };
</script>

<style scoped>

</style>
