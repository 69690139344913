<template>
  <Collapsible id="campaign-create">
    <template slot="heading">{{ $t('campaign.create.heading') }}</template>
    <template slot="content">
      <b-card>
        <b-row class="row mb-3">
          <!-- Name -->
          <b-col sm="12" md="6" class="my-2">
            <FormInput
                v-model="name"
                :editable="true"
                :label="$t(`campaign.create.fields.name.label`)"
                :placeholder="$t(`campaign.create.fields.name.placeholder`)"
                :errors="errors"
                error-field-name="name"
            />
          </b-col>
          <b-col sm="12" md="6" class="my-2">
            <FormInput
                v-model="companyId"
                :options="companiesOptions"
                :label="$t('campaign.create.fields.company.label')"
                :errors="errors"
                error-field-name="name"
                selectable
            />
          </b-col>
        </b-row>
        <!-- Buttons -->
        <Button
            @click.native="onSubmit"
            :loading="loading"
            :disabled="submitIsDisabled"
            icon="plus"
            type="submit"
            variant="success" class="mb-4"
        >
          {{ $t('general.button.create') }}
        </Button>
        <!-- Note -->
        <div>
          <span class="smaller">{{ $t('campaign.note') }}</span>
        </div>
      </b-card>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible';
import FormInput from '../../../components/base/input/FormInput';
import Button from '../../../components/base/button/Button';
import {toaster} from '@/helper/mixins/toaster';
import {errorHelper} from '@/helper/mixins/error-helper';
import NetworkCampaign from "../../../helper/network/NetworkCampaign";

export default {
  components: {Button, FormInput, Collapsible},
  mixins: [toaster, errorHelper],
  data() {
    return {
      loading: false,
      name: null,
    }
  },
  computed: {
    companies() {
        return this.$store.state.campaign.companiesSimplified
    },
    companyId: {
      get() {
        return this.$store.state.campaign.companyId
      },
      set(id) {
        this.$store.commit('campaign/setCompanyId', id)
      }
    },
    companiesOptions() {
      return this.companies.map(company => {
        return {
          text: company.name,
          value: company.id,
        }
      })
    },
    submitIsDisabled() {
      // TODO: After creating campaign buttons should be disabled but is enabled. This might be due to the loading functionality of the Button component but not sure.
      return !this.name || !this.companyId;
    },
  },
  methods: {
    onSubmit: async function() {
      this.loading = true
        try {
          const res = await NetworkCampaign.createCampaignEmpty(this.name, this.companyId)
          this.showSuccessMessageFromRes(res)
          await this.$store.dispatch('campaign/fetchCampaigns')
          this.$store.commit('campaign/setSelectedItem', res.data.data)
        } catch (err) {
          console.error(err)
          this.showErrorMessageFromErr(err)
        } finally {
          this.loading = false
          this.name = null
        }
    },
  },
};
</script>

<style scoped>

</style>
