<template>
  <div class="user-detail">
    <Collapsible id="toggle-user-detail" :visible="true">
      <template slot="heading">{{ $t('user.detail.heading') }}</template>
      <template slot="content">
        <!-- Content -->
        <FormGroup :label="$t('user.fields.photo.label')">
          <file-upload
              title="Profilbild auswählen oder per Drag & Drop in das Feld ziehen"
              v-model="refreshFiles"
              :show-thumbnails="true"
              :max-files="1"
              :existing-file="user.photo_path"
              :prevent-remove-event="preventRemoveImageEvent"
              @remove="onPhotoRemoved"
              @file="onFileChanged"/>
          <div class="photo-info">{{ $t('user.fields.photo.info') }}</div>
        </FormGroup>
        <FormGroup :label="$t('user.fields.id.label')">
          <FormInput v-model="user.id" :errors="errors"
                     error-field-name="id"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.email.label')">
          <FormInput v-model="user.email" editable :errors="errors"
                     error-field-name="email"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.salutation.label')">
          <FormInput v-model="user.salutation" editable :errors="errors"
                     error-field-name="salutation"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.firstname.label')">
          <FormInput v-model="user.firstname" editable :errors="errors"
                     error-field-name="firstname"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.lastname.label')">
          <FormInput v-model="user.lastname" editable :errors="errors"
                     error-field-name="lastname"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.position.label')">
          <FormInput v-model="user.position" editable :errors="errors"
                     error-field-name="position"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.phone.label')">
          <FormInput v-model="user.phone" editable :errors="errors"
                     error-field-name="phone"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.mobile.label')">
          <FormInput v-model="user.mobile" editable :errors="errors"
                     error-field-name="mobile"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.category.label')">
          <FormInput v-model="user.category" editable :errors="errors"
                     error-field-name="category"
                     v-b-tooltip.hover.left="$t('user.fields.category.tooltip')"
          />
        </FormGroup>
        <FormGroup :label="$t('user.fields.comment.label')">
          <FormInput v-model="user.comment" textarea editable :errors="errors"
                     error-field-name="comment"/>
        </FormGroup>
        <FormGroup :label="$t('user.fields.company.name.label')">
          <LinkDetail :to="{name: 'company', query: {id: user.company.id}}">
            {{ user.company.name }}
          </LinkDetail>
        </FormGroup>
      </template>
    </Collapsible>
    <Separator/>

    <!-- Footer -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
      <span id="disabled-wrapper" class="d-inline-block float-right" tabindex="0">
            <Button icon="save" variant="success" :loading="loading" :disable="fileToBig"
                    @click.native="onSave">
                {{ $t('general.button.save') }}
            </Button>
      </span>
      <b-tooltip v-if="fileToBig" target="disabled-wrapper">{{ $t('user.fields.photo.error') }}</b-tooltip>
    </div>
  </div>
</template>

<script>
import FormGroup from '../../components/base/input/FormGroup';
import FormInput from '../../components/base/input/FormInput';
import Button from '../../components/base/button/Button';
import Separator from '../../components/base/Separator';
import NetworkUser from '../../helper/network/NetworkUser';
import LinkDetail from '../../components/navigation/LinkDetail';
import Collapsible from '../../components/base/Collapsible';
import {errorHelper} from '../../helper/mixins/error-helper';
import {toaster} from '../../helper/mixins/toaster';
import FileUpload from "../../components/FileUpload";

export default {
  components: {FileUpload, Collapsible, LinkDetail, Separator, Button, FormInput, FormGroup},
  mixins: [toaster, errorHelper],
  data() {
    return {
      loading: false,
      photo: null,
      photoShouldBeDeleted: false,
      fileToBig: false,
      // required for FileUpload.vue
      preventRemoveImageEvent: false,
      refreshFiles: false,
    };
  },
  watch: {
    user() {
      this.resetErrors();
      this.loading = false
      this.photo = null
      this.photoShouldBeDeleted = false
      this.refreshFiles = true;
    },
  },
  computed: {
    user() {
      return this.$store.state.user.selUser;
    },
  },
  methods: {
    async onSave() {
      this.preventRemoveImageEvent = true;
      this.loading = true;
      try {
        let res = await NetworkUser.updateUser(this.user)
        res = await this.handlePhoto(res);
        this.showSuccessMessageFromRes(res);
        this.$emit('item:saved', this.user);
        this.resetErrors();
      } catch (err) {
        this.setErrors(err)
      } finally {
        this.loading = false
        this.preventRemoveImageEvent = false
      }
    },
    onClose() {
      this.$store.commit("user/setSelUser", null);
    },
    onFileChanged(file, fileToBig) {
      this.photo = file;
      this.fileToBig = fileToBig
      this.photoShouldBeDeleted = false;
    },
    onPhotoRemoved() {
      this.photo = null;
      this.user.photo_path = null;
      this.photoShouldBeDeleted = true;
      this.fileToBig = false
    },
    async handlePhoto(res) {
      if (this.photo) {
          // Upload new or replace current logo
          const photoFormData = this.convertToFormData({photo: this.photo});
          const res = await NetworkUser.updatePhoto(this.user, photoFormData);
          this.user.photo_path = res.data.path
          this.photo = res.data.path
          return res
      } else if (this.photoShouldBeDeleted) {
        // Delete the current logo
        return NetworkUser.deletePhoto(this.user);
      } else {
        // Don not change anything
        return res;
      }
    },
    /**
     * Convert a javascript object into form data.
     * @param obj
     * @return {null|FormData}
     */
    convertToFormData(obj) {
      const formData = new FormData();
      if (obj instanceof Object) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key))
            formData.append(key, obj[key]);
        }
        return formData;
      } else {
        console.error(
            'getFormData: Argument must be of type object, found ' +
            obj.constructor.name + 'instead.');

        return null;
      }
    },
  },
}

</script>

<style scoped>
.photo-info {
  font-size: 0.9rem;
  color: grey;
}

</style>
