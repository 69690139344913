<template>

  <Collapsible id="campaign-company">
    <template slot="heading">{{ $t('campaign.company.heading') }}</template>
    <template slot="content">
      <div class="smaller">
        <div v-if="!campaign.company" class="mb-2">
          {{ $t('campaign.company.none') }}
        </div>
        <div>
          <FormInput
              v-model="selectedCompanyId"
              :options="companiesOptions"
              :disabled="!companyCanBeChanged"
              selectable
          />
        </div>
        <Separator/>
        <div class="d-flex justify-content-end align-items-center">
          <Button
              @click.native="setCompanyForCampaign"
              :disabled="!companyCanBeChanged"
              :loading="loadingSetCompany"
              variant="success"
              icon="save"
          >
            {{ $t('general.button.save') }}
          </Button>
        </div>
      </div>
    </template>
  </Collapsible>

</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import FormInput from '@/components/base/input/FormInput';
import Separator from '@/components/base/Separator';
import Button from '@/components/base/button/Button';
import {toaster} from '@/helper/mixins/toaster';
import NetworkCompany from "../../../helper/network/NetworkCompany";

export default {
  components: {Button, Separator, FormInput, Collapsible},
  mixins: [toaster],
  data() {
    return {
      loadingSetCompany: false
    }
  },
  computed: {
    companies() {
        return this.$store.state.campaign.companiesSimplified
    },
    campaign() {
      return this.$store.state.campaign.selectedItem
    },
    companiesOptions() {
      return this.companies.map(company => {
        return {
          text: company.name,
          value: company.id,
        };
      });
    },
    companyCanBeChanged() {
      return this.campaign.jobpostings.length === 0;
    },
    selectedCompanyId: {
      get() {
        return this.$store.state.campaign.companyId
      },
      set(id) {
        this.$store.commit('campaign/setCompanyId', id)
      }
    }
  },
  watch: {
    campaign() {
      this.selectedCompanyId = this.getSelectedCompanyId();
    },
  },
  created() {
    this.selectedCompanyId = this.getSelectedCompanyId();
  },
  methods: {
    getSelectedCompanyId() {
      // If there is no company set for the campaign, there cannot be a selected company id.
      if (!this.campaign.company)
        return null;

      // Else check which company was selected
      const selectedCompany = this.companies.find(company => {
        return company.id === this.campaign.company.id;
      });
      return selectedCompany.id;
    },
    async setCompanyForCampaign() {
      this.loadingSetCompany = true;
        try {
          const res = await NetworkCompany.setCampaignsForCompany([this.campaign.id], this.selectedCompanyId)
          this.showSuccessMessageFromRes(res)
          await this.$store.dispatch('campaign/fetchCampaigns')
        } catch(err) {
          this.showErrorMessageFromErr(err)
        } finally {
          this.loadingSetCompany = false;
        }
    },
  },
};
</script>

<style scoped>

</style>
