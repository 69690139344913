<template>

  <Collapsible id="toggle-company-dashboard-tile" visible>
    <template slot="heading">{{ $t('company.dashboardTile.heading') }}</template>
    <template slot="content">
      <!-- Loading -->
      <div v-if="loadingDashboardTiles" class="d-flex justify-content-center align-items-center py-3">
        <b-spinner variant="secondary"/>
      </div>
      <!-- Done loading -->
      <div v-else>

        <!-- Ordering dashboard tiles -->
        <b-list-group class="mb-3">
          <draggable v-model="dbTsOrdered">
            <b-list-group-item
                v-for="(dbT, i) in dbTsOrdered"
                :key="i"
            >
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="grip-lines" class="mr-3" fixed-width/>
                <div>{{ dbT.name }}</div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-list-group>

        <!-- Select ids -->
        <b-form-checkbox-group
            v-model="dbTIdsSelected"
            :options="dbTsOptions"
            stacked
        />

        <!-- Save button -->
        <div class="d-flex justify-content-end">
          <Button
              :loading="settingDashboard"
              :disabled="dbTsAll.length === 0"
              @click.native="onDashboardTilesSaved"
              icon="save"
              variant="success"
          >
            {{ $t('general.button.save') }}
          </Button>
        </div>
      </div>
    </template>
  </Collapsible>

</template>

<script>
import Collapsible from '@/components/base/Collapsible';
import NetworkDashboard from '@/helper/network/NetworkDashboard';
import Button from '@/components/base/button/Button';
import draggable from 'vuedraggable';
import {toaster} from '@/helper/mixins/toaster';
import NetworkCompany from "../../helper/network/NetworkCompany";

export default {
  components: {draggable, Button, Collapsible},
  mixins: [toaster],
  data() {
    return {
      /**
       * Array holding all available dashboard tiles.
       */
      dbTsAll: [],
      /**
       * Array holding the dashboard tile ids that are selected
       */
      dbTIdsSelected: [],
      /**
       * Array holding the dashboard tiles in the same order has the dbTIdsSelected array.
       * Difference is these are not just the ids but the whole dashboard tiles.
       */
      dbTsOrdered: [],
      /**
       * Loading state when saving the changes.
       */
      settingDashboard: false,
      /**
       * Loading state when fetching dashboard tiles.
       */
      loadingDashboardTiles: false,
    };
  },
  computed: {
    company() {
      return this.$store.state.company.selectedItem
    },
    /**
     * Compute the dashboard tile options to make selecting and deselecting dashboard tiles possible.
     * @returns {{text: *, value: *}[]}
     */
    dbTsOptions() {
      return this.dbTsAll.map(dbT => ({text: dbT.name, value: dbT.id}));
    },
  },
  watch: {
    /**
     * Reset and initialize the new data when the company changes.
     */
    company() {
      this.initializeData();
    },
    /**
     * Whenever the selected dashboard tile ids change, this means the ordered dashboard tiles should be updated as well.
     * This happens when:
     * 1) The company changes
     * 2) A dashboard tile id was selected
     * 3) A dashboard tile id was deselected
     * @param selectedNew
     */
    dbTIdsSelected(selectedNew) {
      this.dbTsOrdered = selectedNew.map(id => this.dbTsAll.find(dbT => dbT.id === id));
    },
  },
  created() {
    this.loadingDashboardTiles = true;
    NetworkDashboard.getDashboardTiles().then(res => {
      this.dbTsAll = res.data.data;
      this.initializeData(); // Initialize data manually once after we fetched all the necessary data from the api
    }).catch(err => console.error(err)).then(() => this.loadingDashboardTiles = false);
  },
  methods: {
    /**
     * Initialize the data by setting the dbTIdsSelected to the
     * ordered dashboard tile ids already selected on the company.
     */
    initializeData() {
      /* [EAS-528] TODO: when creating a new company this error happens:
      "TypeError: Invalid attempt to spread non-iterable instance.
      In order to be iterable, non-array objects must have a [Symbol.iterator]() method." */
      const dbTsCompanyClone = [...this.company.dashboard_tiles];
      dbTsCompanyClone.sort((a, b) => a.order < b.order ? -1 : 1);
      this.dbTIdsSelected = dbTsCompanyClone.map(dbT => dbT.dashboard_tile_id);
    },
    /**
     * When saved, send the selected and ordered dashboard tile ids along with their order to the api.
     */
    async onDashboardTilesSaved() {
      // Set loading state
      this.settingDashboard = true;
      // Create data for request
      const dashboard_tiles = this.getFormattedDbTs();
      // Send request
      try {
        const res = await NetworkCompany.setDashboardTiles(dashboard_tiles, this.company.id)
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      }
      // Set loading state
      this.settingDashboard = false
    },
    /**
     * Since the dashboard tiles are already in the correct order (from left to right)
     * we can just go through the array, take the id and use the index as the order.
     * @returns {{id: *, order: number}[]}
     */
    getFormattedDbTs() {
      const dbTIdsOrdered = this.dbTsOrdered.map(dbT => dbT.id);
      const dbTIdsOrderedAndFiltered = dbTIdsOrdered.filter(dbTId => this.dbTIdsSelected.indexOf(dbTId) >= 0);
      return dbTIdsOrderedAndFiltered.map((dbTId, i) => ({id: dbTId, order: i}));
    },
  },
};
</script>

<style scoped>
</style>
