<template>
    <div class="company-contingents">
        <Collapsible id="toggle-company-contingents" :visible="true" class="mb-3">
            <template slot="heading">{{$t('company.contingents.heading')}}</template>
            <template slot="content">
                <!-- Table -->
                <b-table
                        :items="companyComputed.contingents"
                        :fields="fields"
                        :bordered="true"
                        :responsive="true"
                        :hover="true"
                >
                    <template #cell(count)="data">
                        <FormInput v-model="data.item.count" min-width="0" type="number" editable/>
                    </template>
                    <template #cell(save)="data">
                        <Button
                                @click.native="onSave(data.item)"
                                :loading="data.item.loadingSave"
                                variant="success"
                                icon="save"
                                icon-only
                        />
                    </template>
                    <template #cell(delete)="data">
                        <Button
                                @click.native="onDelete(data.item)"
                                :loading="data.item.loadingDelete"
                                variant="danger"
                                icon="trash"
                                icon-only
                        />
                    </template>
                </b-table>
            </template>
        </Collapsible>
        <Collapsible id="toggle-company-contingents-add-product">
            <template slot="heading">{{$t('company.addProduct.heading')}}</template>
            <template slot="content">
                <div class="wrapper">
                    <AddProductTable
                            @item:clicked="onItemClicked"
                            :added-products="addedProducts"
                    />
                </div>
            </template>
        </Collapsible>
    </div>
</template>

<script>
  import Collapsible from '../../components/base/Collapsible';
  import FormInput from '../../components/base/input/FormInput';
  import Button from '../../components/base/button/Button';
  import NetworkCompany from '../../helper/network/NetworkCompany';
  import AddProductTable from '../../components/modular/AddProductTable';
  import {toaster} from '../../helper/mixins/toaster';

  export default {
    components: {AddProductTable, Button, FormInput, Collapsible},
    mixins: [toaster],
    data() {
      return {
        fields: [
          {
            key: 'count',
            label: this.$t('company.contingents.fields.count.label'),
            tdClass: 'count',
          },
          {
            key: 'jobportal',
            label: this.$t('company.contingents.fields.jobPortal.label'),
          },
          {
            key: 'product_name',
            label: this.$t('company.contingents.fields.product_name.label'),
          },
          {
            key: 'save',
            label: this.$t('company.contingents.fields.save.label'),
          },
          {
            key: 'delete',
            label: this.$t('company.contingents.fields.delete.label'),
          },
        ],
      }
    },
    computed: {
      company() {
        return this.$store.state.company.selectedItem
      },
      companyComputed() {
        return Object.assign({}, this.company);
      },
      addedProducts() {
        if (this.companyComputed.contingents) {
          return this.companyComputed.contingents.
              map(contingent => {return {id: contingent.product_id};});
        } else {
          return [];
        }
      },
    },
    methods: {
      onSave(item) {
        this.updateOrCreateContingent(item);
      },
      onItemClicked(item) {
        const contingent = {
          product_id: item.id,
          product_name: item.name,
          jobportal: item.jobportal.name,
          count: 0,
        };
        // Only add the contingent if it does not already exist.
        if (!this.contingentAlreadyExists(contingent)) {
          this.updateOrCreateContingent(contingent).
              then(() => this.companyComputed.contingents.push(contingent));
        }
      },
      onDelete(item) {
        this.$set(item, 'loadingDelete', true);
        NetworkCompany.deleteContingent(this.companyComputed, item.product_id).
            then(res => {
              this.showSuccessMessageFromRes(res);
              // Remove element from company
              const contingents = this.companyComputed.contingents;
              const indexToRemove = contingents.map(contingent => contingent.product_id).indexOf(item.product_id);
              contingents.splice(indexToRemove, 1);
              // Note: Removing the object from the original data source prop ('company') is not necessary because when
              // the detailed company information is saved, contingents are ignored. Therefore all information in the
              // view should be up to date at any time.
            }).
            catch(err => this.showErrorMessageFromErr(err)).
            then(() => this.$set(item, 'loadingDelete', false));
      },
      updateOrCreateContingent(item) {
        this.$set(item, 'loadingSave', true);
        return NetworkCompany.updateContingent(this.companyComputed, item.product_id, item.count).
            // Note: Updating the object from the original data source prop ('company') is not necessary because when
            // the detailed company information is saved, contingents are ignored. Therefore all information in the
            // view should be up to date at any time.
            then(res => this.showSuccessMessageFromRes(res)).
            catch(err => this.showErrorMessageFromErr(err)).
            then(() => this.$set(item, 'loadingSave', false));
      },
      contingentAlreadyExists(contingent) {
        return this.companyComputed.contingents.filter(c => c.product_id === contingent.product_id).length > 0;
      },
    },
  };
</script>

<style>
    .count {
        max-width: 75px;
    }

    .wrapper {
        overflow-y: auto;
        max-height: 600px;
    }
</style>
