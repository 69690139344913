import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkJobPortal extends NetworkHelper {

  static getJobPortals(config) {
    return axios.get('/api/jobportal', config);
  }

  static getJobPortalsSimplified(config) {
    return axios.get('/api/jobportal/simplified', config);
  }

  static getJobPortal(jobPortal, config) {
    return axios.get(`/api/jobportal/${jobPortal.id}`, config);
  }

  static createJobPortal(jobPortal, config) {
    return axios.post('/api/jobportal', jobPortal, config);
  }

  static deleteJobPortal(jobPortal, config) {
    return axios.delete(`/api/jobportal/${jobPortal.id}`, config);
  }

  static deleteMultipleJobPortals(jobPortals, config) {
    return axios.delete(`/api/jobportal`, {data: {jobportals: jobPortals}, ...config});
  }

  static updateJobPortal(jobPortal, config) {
    return axios.put(`/api/jobportal/${jobPortal.id}`, jobPortal, config);
  }

  static updateLogo(jobPortal, formData, config) {
    // makes the request method to be 'PUT'
    formData.append('_method', 'PUT');
    return axios.post(`/api/jobportal/${jobPortal.id}/logo`, formData,
        config);
  }

  static deleteLogo(jobPortal, config) {
    return axios.delete(`/api/jobportal/${jobPortal.id}/logo`, config);
  }

}
