<template>
    <h5 class="my-0">
        <slot/>
    </h5>
</template>

<script>
  export default {
  };
</script>

<style scoped>

</style>
