<template>
  <!-- Job posting product -->
  <Collapsible id="toggle-job-posting-detail-product" :visible="true">
    <template slot="heading">{{ $t('jobPosting.detail.jobPostingOrder.heading') }}</template>
    <template slot="content">
      <!-- Orders -->
      <b-table @row-selected="onRowSelected" :items="jobPostingSel.orders" :fields="fields" select-mode="multi"
               selectable responsive bordered hover>
        <!-- Cell 'budget' -->
        <template #cell(budget)="data">
          <div class="d-flex">
            <b-form-input v-model="data.item.budget" :formatter="budgetFormatter" type="number" class="mr-1 budget"/>
            <div class="mt-1">€</div>
          </div>
        </template>
        <!-- Cell 'term_in_days' -->
        <template #cell(term_in_days)="data">
          <b-form-input v-model="data.item.term_in_days" type="number"/>
        </template>
        <template #cell(save)="data">
          <Button @click="onOrderSave(data.item)" :loading="orderIsSaving(data.item)" variant="success" icon="save"
                  icon-only/>
        </template>
        <template #cell(delete)="data">
          <Button @click="onOrderDelete(data.item)" :loading="orderIsDeleting(data.item)" variant="danger" icon="trash"
                  icon-only/>
        </template>
      </b-table>
      <!-- Controls -->
      <div class="d-flex">
        <!-- Add product -->
        <b-button-group class="mr-2">
          <Button v-b-modal.job-posting-order-create icon="plus" variant="success">
            {{ $t('general.button.new') }}
          </Button>
        </b-button-group>
        <!-- Order buttons -->
        <b-button-group class="mr-2">
          <!-- Place email order -->
          <Button v-b-modal.jobposting-order-email :disabled="!commonOrderEmail" variant="info">
            {{ $t('general.button.order.email.add') }}
          </Button>
          <Button v-b-modal.jobposting-order-api :disabled="ordersSel.length === 0" variant="info">
            {{ $t('general.button.order.api.add') }}
          </Button>
        </b-button-group>
        <!-- Order buttons -->
        <b-button-group>
          <!-- Activate orders -->
          <Button @click="onOrdersActivate" :disabled="ordersSel.length === 0" variant="primary">
            {{ $t('general.button.order.activate') }}
          </Button>
        </b-button-group>
      </div>

      <!-- Modal add product -->
      <JobPostingOrderCreate/>

      <!-- Modal email order -->
      <OrderEmail id="jobposting-order-email" :job-posting="jobPostingSel" :orders-sel="ordersSel"
                  :order-email="commonOrderEmail" @order:placed="orderEmailPlaced"/>

      <!-- Modal api order -->
      <OrderApi id="jobposting-order-api" :job-posting="jobPostingSel" :orders-sel="ordersSel"
                @order:placed="orderApiPlaced"/>

    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible'
import Button from '../../../components/base/button/Button'
import NetworkBooking from '../../../helper/network/NetworkBooking'
import JobPostingOrderCreate from '@/views/jobposting/jobposting/JobPostingOrderCreate'
import NetworkOrder from '@/helper/network/NetworkOrder'
import OrderEmail from '@/components/modular/OrderEmail'
import OrderApi from '@/components/modular/OrderApi'
import {toaster} from '../../../helper/mixins/toaster'

export default {
  components: {OrderEmail, OrderApi, JobPostingOrderCreate, Button, Collapsible},
  mixins: [toaster],
  data() {
    return {
      ordersSel: [],
      /**
       * An array holding all orders which are currently saving.
       */
      ordersSaving: [],
      /**
       * An array holding all orders which are currently deleting.
       */
      ordersDeleting: [],
      fields: [
        {
          key: 'product.jobportal',
          label: this.$t('general.component.orders.fields.product.jobportal.label'),
        },
        {
          key: 'product.name',
          label: this.$t('general.component.orders.fields.product.name.label'),
        },
        {
          key: 'budget',
          label: this.$t('general.component.orders.fields.budget.label'),
        },
        {
          key: 'term_in_days',
          label: this.$t('general.component.orders.fields.term_in_days.label'),
        },
        {
          key: 'activated_at',
          label: this.$t('general.component.orders.fields.activated_at.label'),
        },
        {
          key: 'ordered_at',
          label: this.$t('general.component.orders.fields.ordered_at.label'),
        },
        {
          key: 'save',
          label: this.$t('general.component.orders.fields.save.label'),
        },
        {
          key: 'delete',
          label: this.$t('general.component.orders.fields.delete.label'),
        },
      ],
    }
  },
  computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
    /**
     * If only products with the same order_email are selected, the email order is possible.
     */
    commonOrderEmail() {
      const orderEmails = this.ordersSel.map(order => order.product.order_email)
      // Answer from stackoverflow: https://stackoverflow.com/a/35568895/5371227
      const allOrderEmailsAreEqual = orderEmails.every((orderEmail, i, arr) => orderEmail === arr[0])
      return allOrderEmailsAreEqual ? orderEmails[0] : null
    },
    ordersToOrder() {
      return this.ordersSel.length > 0
    },
  },
  methods: {
    onRowSelected(ordersSel) {
      this.ordersSel = ordersSel
    },
    async onOrderSave(order) {
      this.ordersSaving.push(order)
      try {
        const res = await NetworkOrder.updateOrder(order)
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        // Remove all orders with the id of the current order from the array. This method also considers order which are added multiple times.
        this.ordersSaving = this.ordersSaving.filter(o => o.id !== order.id)
      }
    },
    orderIsSaving(order) {
      return this.ordersSaving.some(o => o.id === order.id)
    },
    async onOrderDelete(order) {
      // Show confirmation modal
      const userHasConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('general.confirmation.text'), {
        title: this.$t('general.confirmation.title'),
        okVariant: 'danger',
        okTitle: this.$t('delete'),
        cancelTitle: this.$t('cancel'),
        hideHeaderClose: true,
        centered: true,
      })
      if (!userHasConfirmed) return
      // Send delete request
      try {
        this.ordersDeleting.push(order)
        const res = await NetworkOrder.deleteOrder([order.id])
        this.showSuccessMessageFromRes(res)
        // Remove order from jobPostingSel
        const index = this.jobPostingSel.orders.findIndex(o => o.id === order.id)
        this.jobPostingSel.orders.splice(index, 1) // jobPostingSel gets updated via watcher in JobPosting.vue
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.ordersDeleting = this.ordersDeleting.filter(o => o.id !== order.id) // Stops loading animation
      }
    },
    orderIsDeleting(order) {
      return this.ordersDeleting.some(o => o.id === order.id)
    },
    async orderEmailPlaced(res) {
      this.$bvModal.hide('jobposting-order-email')
      await this.$store.dispatch('jobPosting/fetchJobPosting', this.jobPostingSel)
      // Show message. Do this here, otherwise as the modal closes the component is removed and the toast is no longer visible.
      this.showSuccessMessageFromRes(res)
    },
    async orderApiPlaced(res) {
      // Close modal
      this.placingApiOrder = false
      await this.$store.dispatch('jobPosting/fetchJobPosting', this.jobPostingSel)
      // Show message.
      // Do this here, otherwise as the modal closes the component is removed and the toast is no longer visible.
      this.showSuccessMessageFromRes(res)
      // Reset data
      this.jobPosting.orders = this.jobPosting.orders.map(item => {
        item.selected = false
        return item
      })
    },
    async onOrdersActivate() {
      this.activatingOrder = true
      try {
        const res = await NetworkBooking.activateOrders(this.ordersSel)
        await this.$store.dispatch('jobPosting/fetchJobPosting', this.jobPostingSel)
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.activatingOrder = false
      }
    },
    budgetFormatter(val) {
      if (val === '') return null

      if (!/^\d+$/.test(val))
        val = val.substr(val.length - 1)

      return parseInt(val)
    },
    /**
     * Runs when a new order is created.
     * @param orders All orders of the job posting,
     */
    onCreated(orders) {
      console.log(orders)
    },
  },
}
</script>

<style scoped>
</style>
