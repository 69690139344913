<template>
  <div>
    <Collapsible id="job-posting-detail-mails" :visible="true">
      <template slot="heading">{{ $t('jobPosting.detail.jobPostingMail.heading') }}
        <font-awesome-icon class="ml-2" icon="envelope"/>
      </template>
      <template slot="content">
        <b-table
            :fields="fields"
            :items="mails"
            :busy="isBusy"
            :empty-text="$t('jobPosting.detail.jobPostingMail.no_data')"
            :show-empty="empty"
            sort-desc
            sort-by="date"
            sticky-header
            hover
            @row-clicked="openMail">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Laden</strong>
            </div>
          </template>
        </b-table>
      </template>
    </Collapsible>
    <b-modal v-model="openModal" hide-footer hide-header centered size="xl">
      <Trumbowyg v-model="mailContent"
                 :config="{btns:  ['fullscreen'], disabled: true}"
                 :disabled="true"/>
      <!-- Place email order button -->
      <div class="d-flex justify-content-end">
        <Button
            class="mt-2"
            @click="openModal = false"
            variant="secondary">
          {{ $t('general.button.back') }}
        </Button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Collapsible from "../../../components/base/Collapsible";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import Button from "../../../components/base/button/Button";

export default {
  components: {Collapsible, Trumbowyg, Button},
  data() {
    return {
      fields: [
        {
          label: "Datum", key: "date", formatter: (value) => {
            return new Date(value).toLocaleString();
          }
        },
        {label: "Betreff", key: "subject"},
        {label: "Absender", key: "user_sent"}
      ],
      isBusy: false,
      mail: {
        to: null,
        from: null,
        cc: null,
        bcc: null,
        body: null,
        subject: null,
      },
      openModal: false,
    }
  },
  computed: {
    mailContent() {
      return "An: " + this.mail.to + "<br>Von: " + this.mail.from +
          "<br>Cc: " + (this.mail.cc !== null ? this.mail.cc : "--") + "<br>Bcc: " + (this.mail.bcc !== null ? this.mail.bcc : "--") +
          "<hr><br><br>" + this.mail.subject + "<br><br>" + this.mail.body
    },
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
    mails() {
      return this.$store.state.mail.mails
    },
    empty() {
      if (this.mails === null) {
        return true
      }
      return this.mails.length === 0;

    }

  },
  async created() {
    this.isBusy = true
    try {
      await this.$store.dispatch('mail/fetchMails', this.jobPostingSel)
    } catch (e) {
      console.error(e)
    } finally {
      this.isBusy = false;
    }
  },
  methods: {
    openMail(mail) {
      this.mail = mail
      this.openModal = true;
    }
  }
}
</script>

<style scoped>
.mail-table tr {
  cursor: pointer !important;
}

</style>