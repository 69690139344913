<template>
    <div>
        <Collapsible id="toggle-catalogue-detail-add-product">
            <template slot="heading">{{$t('catalogue.detail.addProducts.heading')}}</template>
            <template slot="content">
                <div class="wrapper">
                    <AddProductTable
                            @item:clicked="onItemClicked"
                            :added-products="addedProducts"
                    />
                </div>
            </template>
        </Collapsible>
    </div>
</template>

<script>
  import Collapsible from '../../../components/base/Collapsible';
  import AddProductTable from '../../../components/modular/AddProductTable';

  export default {
    components: {AddProductTable, Collapsible},
    props: {
      addedProducts: {
        type: Array,
        required: false,
      },
    },
    methods: {
      onItemClicked(item) {
        this.$emit('item:clicked', item);
      },
    },
  };
</script>

<style scoped>
    .wrapper {
        overflow-y: scroll;
        max-height: 600px;
    }
</style>
