<template>
  <div class="d-flex align-items-center">
    <b-form-group>
      <b-form-radio-group
          v-model="modelHelper"
          :options="options"
          class="min-width-radio-group text-nowrap"
          button-variant="outline-primary"
          buttons
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number],
    },
  },
  data () {
    return {
      options: [
        { text: this.$t('general.filter.is_from_crawler.options.both'), value: null },
        { text: this.$t('general.filter.is_from_crawler.options.from_xml'), value: 1 },
        { text: this.$t('general.filter.is_from_crawler.options.not_from_xml'), value: 0 },
      ],
    }
  },
  computed: {
    modelHelper: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    }
  }
}
</script>

<style scoped>

</style>
