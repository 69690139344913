<template>
  <div>
    <!-- Job posting UTM Parameters -->
    <Collapsible id="toggle-job-posting-detail-utmParameter" :visible="true" class="mb-4">
      <template slot="heading">{{ $t('utmParameter.heading') }}</template>
      <template slot="content">
        <b-card class="mb-1">
          <div v-for="utm in utmParametersComputed" :key="utm.id">
            <b-row>
              <b-col sm="12" md="5" lg="5" xl="5" class="mb-1">
                <p class="font-weight-bold mb-1">{{ $t('utmParameter.key') }}</p>
              </b-col>
              <b-col sm="12" md="5" lg="5" xl="5" class="mb-1">
                <p class="font-weight-bold mb-1">{{ $t('utmParameter.value') }}</p>
              </b-col>
            </b-row>
            <b-row>
              <!-- utm key -->
              <b-col sm="12" md="5" lg="5" xl="5" class="mb-3">
                <FormInput
                    v-model="utm.key"
                    :editable="true"
                    :errors="errors"
                    error-field-name="key"
                />
              </b-col>
              <!-- utm value -->
              <b-col sm="12" md="6" lg="5" xl="5" class="mb-3">
                <FormInput
                    v-model="utm.value"
                    :editable="true"
                    :errors="errors"
                    error-field-name="value"
                    v-b-tooltip.hover.bottom="$t('utmParameter.tooltip')"
                />
              </b-col>
              <b-col sm="12" md="1" lg="1" xl="1" class="text-center">
                <Button icon="minus" variant="danger" @click.native="removeUTM(utm)" icon-only></Button>
              </b-col>

            </b-row>
          </div>
        </b-card>
        <b-card id="addUTM" class="align-items-center" v-if="showAddButton">
          <Button
              icon="plus"
              variant="success"
              @click.native="onAddUTM"
          >
            <b>{{ $t('utmParameter.add') }}</b>
          </Button>
        </b-card>
      </template>
    </Collapsible>
    <!-- Controls -->
    <div class="d-flex align-items-center justify-content-between">
      <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
      <Button
          @click.native="onSave"
          icon="save"
          variant="success"
      >
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "./base/button/Button";
import FormInput from "./base/input/FormInput";
import Collapsible from "./base/Collapsible";
import {toaster} from "../helper/mixins/toaster";
import {errorHelper} from '../helper/mixins/error-helper';
import NetworkJobPosting from "../helper/network/NetworkJobPosting";
import NetworkXmlFeed from "../helper/network/NetworkXmlFeed";
import NetworkCampaign from "../helper/network/NetworkCampaign";

export default {
  mixins: [toaster, errorHelper],
  components: {Collapsible, FormInput, Button},
  props: {
    item: {
      required: true,
      type: Object,
    },
    /**
     * xmlfeed, jobposting or campaign
     * Needed to find the right API
     */
    view: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      jobPosting: "jobposting",
      xmlFeed: "xmlfeed",
      campaign: "campaign"
    }
  },
  computed: {
    showAddButton() {
      return this.utmParametersComputed.length < 5;
    },
    itemComputed() {
      if (this.view === this.xmlFeed) {
        return this.item
      }
      return Object.assign({}, this.item);
    },
    utmParametersComputed: {
      get() {
        return this.itemComputed.utm_parameters;
      },
      set(utmParameters) {
        this.$emit('utmParameters:changed', utmParameters);
      },
    },
  },
  methods: {
    async onSave() {
      this.loading = true;
      try {
        let res;
        switch (this.view) {
          case this.jobPosting: {
            res = await NetworkJobPosting.updateJobPostingUTMParameters(this.itemComputed.id, this.utmParametersComputed)
            break;
          }
          case this.xmlFeed: {
            res = await NetworkXmlFeed.updateXmlFeedTMParameters(this.itemComputed.id, this.utmParametersComputed)
            break;
          }
          case this.campaign: {
            res = NetworkCampaign.updateCampaignUTMParameters(this.campaignComputed.id, this.utmParametersComputed)
            break;
          }
        }
        this.showSuccessMessageFromRes(res);
        this.$emit('item:saved', this.itemComputed);
      } catch (e) {
        this.showErrorMessageFromErr(e)
      } finally {
        this.loading = false
      }
    },
    onClose() {
      this.$emit('item:closed'); // emit to main view
    },
    onAddUTM() {
      const param = {
        id: null,
        key: null,
        value: null
      };
      this.utmParametersComputed.push(param);
    },
    removeUTM(utm) {
      const index = this.utmParametersComputed.indexOf(utm);
      if (index > -1) {
        this.utmParametersComputed.splice(index, 1);
      }
    }
  }
}

</script>


<style scoped>

</style>