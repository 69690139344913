/**
 * This mixin handles the basic pagination for a component. It basically
 * provides the pagination data and filter data with according watchers and
 * function calls (e.g. mounted > fetchData).
 *
 * All the component using this mixin has to do is overriding the function
 * 'fetchData' and using the given pagination/filter data accordingly (e.g. in a
 * 'b-table' or a 'b-pagination' component). This probably is the most common
 * use case.
 *
 * @type {{data(): {totalItems: number, perPage, currentPage}, computed: {query(): {perPage: (*|number), page: (*|number)}}, watch: {filter: {handler(): void, deep: boolean}, perPage: {handler(): void}, currentPage: {handler(): void}}, methods: {fetchData(): Promise<void>, setFilterFromPagination(): void}, props: {pagination: {type: ObjectConstructor, required: boolean}}}}
 */
export const paginate = {
  /**
   * This property is passed by the vue router.
   */
  props: {
    pagination: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // Pagination
      currentPage: this.pagination.page || 1,
      perPage: this.pagination.perPage || 10,
      sortBy: this.pagination.sortBy || null,
      // sortDesc may be null. This results in the default of sortDir='desc' in
      // the 'getQuery' function.
      sortDesc: this.pagination.sortDir !== 'asc',
      totalItems: 999999,
      // Filter
      /**
       * To use a filter in a 'b-table' component, pass this data prop
       * to the 'b-table' component as a prop and set the according filters
       * in the '#thead-top' slot accordinglty.
       *
       * ----------------------------------------------------------------------
       * EXAMPLE:
       * ----------------------------------------------------------------------
       * To provide an email filter you would define an input in the thead-top
       * that uses 'filter.email' as the v-model. The filter value is the
       * retrieved via 'getQuery'.
       */
      filter: {},
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchData();
      },
    },
    perPage: {
      handler() {
        this.fetchData();
      },
    },
    sortBy: {
      handler() {
        this.fetchData();
      },
    },
    sortDesc: {
      handler() {
        this.fetchData();
      },
    },
    filter: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
  },
  /**
   * If overwritten in component, make sure to call those function in the
   * overriding 'mounted' function as well.
   */
  mounted() {
    this.setFilterFromPagination();
    this.fetchData();
  },
  methods: {
    /**
     * This function must be overwritten in the component using this mixin.
     * It is responsible for fetching the correct data from the api.
     * @returns {Promise<void>}
     */
    async fetchData() {
      console.warn(
          'Function \'fetchData\' not implemented.',
      );
    },
    /**
     * This function is used to set the filter by using the
     * query params passed along via vue router.
     * This enables sharing a link with pagination queries and filter queries.
     */
    setFilterFromPagination() {
      const params = this.pagination.filterParams;
      for (const key in params) {
        if (params.hasOwnProperty(key))
          this.$set(this.filter, key, params[key]);
      }
    },
    /**
     * Get the current data for pagination and filter.
     * This function is usually used in 'fetchData' to
     * provide the correct query for the request.
     * @returns {{perPage: (*|number), page: (*|number)}}
     */
    getQuery() {
      if (this.filter !== null) {
        // Return query object and spread filter
        return {
          page: this.currentPage,
          perPage: this.perPage,
          sortBy: this.sortBy || 'created_at',
          sortDir: this.sortDesc ? 'desc' : 'asc',
          ...this.filter,
        };
      } else {
        // Return query object only
        return {
          page: this.currentPage,
          perPage: this.perPage,
        };
      }
    },
    /**
     * This function is used to keep the url in sync with the pagination
     * and filter data. By doing so, sharing links with pagination data and
     * filter data is possible.
     */
    updateRoute() {
      this.$router.
          push({ query: this.getQuery() }).
          catch(() => false);
      // TODO:
      //  Handle 'NavigationDuplicated' error properly
      //  or try to prevent it in the first place.
    },
  },
};
