<template>
  <b-table-simple small borderless responsive>
    <b-tr>
      <b-th v-if="isBatchProcessingView" id="consider_checkbox" class="d-flex align-items-center">
        <FormInput v-model="selectAllConsiderCheckboxes" class="mr-1" :text="$t('batch_processing.consider')"
                   :indeterminate="!!selectAllConsiderCheckboxes && !allConsiderSelected" checkable/>
        <font-awesome-icon icon="info-circle" class="text-primary"/>
      </b-th>
      <b-tooltip target="consider_checkbox" triggers="hover">
        <p v-html="$t('batch_processing.consider_info')"/>
      </b-tooltip>
      <b-th>{{ $t('field') }}</b-th>
      <b-th v-if="!isBatchProcessingView">{{ $t('xml_value') }}</b-th>
      <b-th>{{ $t('user_value') }}</b-th>
      <b-th>
        <FormInput v-model="selectAllCheckboxes" :text="$t('overwrite_short')"
                   :indeterminate="!!selectAllCheckboxes && !allSelected" checkable/>
      </b-th>
    </b-tr>
    <b-tr v-for="(set, index) in (isBatchProcessingView ? modelHelper : jobPostingSel.jobposting_data)"
          :key="`${isBatchProcessingView ? index : jobPostingSel.id}-${set.field}`">
      <!-- Consider -->
      <b-td v-if="isBatchProcessingView" class="align-middle max-w-col">
        <b-form-checkbox :id="`consider-checkbox-${set.field}`" v-model="set.consider" :value="1"
                         :unchecked-value="0"/>
      </b-td>
      <!-- Label -->
      <b-td class="align-middle max-w-col">
        <span v-if="set.field === 'referencenumber'">reference&shy;number</span>
        <span v-else>
        {{ set.field }}<span v-if="set.is_mandatory">*</span>
          </span>
      </b-td>
      <!-- Value XML -->
      <b-td v-if="!isBatchProcessingView" class="break-all max-w-col">
        <Trumbowyg v-if="isDescription(set)" v-model="set.value_xml"
                   :config="{ ...trumbowygConfig, disabled: true }"/>
        <b-form-input v-else v-model="set.value_xml" disabled/>
      </b-td>
      <!-- Value -->
      <b-td class="break-all max-w-col">
        <Trumbowyg v-if="isDescription(set)" v-model="set.value" :config="trumbowygConfigOverride"/>
        <b-form-input v-else :disabled="isBatchProcessingView && !isConsider(set)" v-model="set.value"
                      :type="isDate(set) ? 'date' : null"
                      :class="{ 'border-success': !isBatchProcessingView && isOverride(set),
                      'border-warning': isBatchProcessingView && isOverride(set)}"
        />
      </b-td>
      <!-- Override -->
      <b-td class="align-middle max-w-col">
        <b-form-checkbox :id="`xml-checkbox-${set.field}`" :disabled="isBatchProcessingView && !set.consider"
                         v-model="set.xml_override"
                         :value="1"
                         :unchecked-value="0"/>
      </b-td>
    </b-tr>
  </b-table-simple>
</template>

<script>
import FormInput from "@/components/base/input/FormInput";
import 'trumbowyg/dist/ui/trumbowyg.css';
import "trumbowyg/dist/plugins/allowtagsfrompaste/trumbowyg.allowtagsfrompaste.min.js";
import Trumbowyg from "vue-trumbowyg";

export default {
  components: {FormInput, Trumbowyg},
  props: {
    value: {
      required: false,
    },
    isBatchProcessingView: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      trumbowygConfig: {btns: [['viewHTML'], ['fullscreen']]},
      trumbowygConfigOverride: {
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em'],
          ['link'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['fullscreen'],
        ],
        plugins: {
          allowTagsFromPaste: {
            allowedTags: ["a", "abbr", "acronym", "address", "applet", "area", "article", "aside", "audio", "b", "base", "basefont", "bdi", "bdo", "big", "blockquote", "body", "button", "canvas", "caption", "center", "cite", "code", "col", "colgroup", "data", "datalist", "dd", "del", "details", "dfn", "dialog", "dir", "div", "dl", "dt", "em", "embed", "fieldset", "figcaption", "figure", "font", "footer", "form", "frame", "frameset", "h1", "h2", "h3", "h4", "h5", "h6", "head", "header", "hr", "html", "i", "iframe", "img", "input", "ins", "kbd", "label", "legend", "li", "link", "main", "map", "mark", "meta", "meter", "nav", "noframes", "noscript", "object", "ol", "optgroup", "option", "output", "p", "param", "picture", "pre", "progress", "q", "rp", "rt", "ruby", "s", "samp", "script", "section", "select", "small", "source", "span", "strike", "strong", "style", "sub", "summary", "sup", "svg", "table", "tbody", "td", "template", "textarea", "tfoot",]
          }
        }
      },
      selectAllCheckboxes: false,
      selectAllConsiderCheckboxes: false
    }
  },
  watch: {
    /**
     * Select or deselect all Checkboxes
     */
    selectAllCheckboxes(newValue) {
      for (let jobPostingData of (this.isBatchProcessingView ? this.modelHelper : this.jobPostingSel.jobposting_data)) {
        if (this.isBatchProcessingView) {
          if (!jobPostingData.consider) {
            jobPostingData.xml_override = false
            continue;
          }
        }
        jobPostingData.xml_override = newValue;
      }
    },
    selectAllConsiderCheckboxes(newValue) {
      for (let jobPostingData of (this.isBatchProcessingView ? this.modelHelper : this.jobPostingSel.jobposting_data)) {
        jobPostingData.consider = newValue;
      }
    },
    value: {
      deep: true,
      // dismiss "überschreiben" checkbox if "consider" checkbox is unchecked
      handler() {
        if (!this.isBatchProcessingView) {
          return
        }
        this.modelHelper.forEach(entry => {
          if (!entry.consider) {
            entry.xml_override = false
          }
        })
      }
    }
  },
  computed: {
    jobPostingSel() {
      return this.$store.state.jobPosting.jobPostingSel
    },
    // needed if it is batch processing
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    /**
     * Check if one or more checkboxes are selected to show "select all" checkbox as indeterminate or not
     */
    allSelected() {
      for (let jobPostingData of (this.isBatchProcessingView ? this.modelHelper : this.jobPostingSel.jobposting_data)) {
        if (!jobPostingData.xml_override)
          return false
      }
      return true
    },
    allConsiderSelected() {
      for (let jobPostingData of (this.isBatchProcessingView ? this.modelHelper : this.jobPostingSel.jobposting_data)) {
        if (!jobPostingData.consider)
          return false
      }
      return true
    }
  },
  methods: {
    isDescription: set => set.field === 'description',
    isDate: set => set.field === 'date',
    isOverride: set => set.xml_override === 1,
    isConsider: set => set.consider === 1,
  }
}
</script>

<style scoped>
>>> .trumbowyg-editor {
  overflow-wrap: anywhere;
}

.max-w-col {
  max-width: 12vw;
}

</style>