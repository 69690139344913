<template>
    <b-container id="reset-password" class="mt-5">
        <PageHeading>{{$t('auth.resetPassword.pageHeading')}}</PageHeading>
        <div class="mt-3">
            <b-form @submit="onSubmit">
                <!-- Password -->
                <FormInput
                        v-model="form.password"
                        :label="$t('auth.resetPassword.fields.password.label')"
                        :placeholder="$t('auth.resetPassword.fields.password.placeholder')"
                        editable required type="password"
                />
                <!-- Password Confirmation -->
                <FormInput
                        v-model="form.password_confirmation"
                        :label="$t('auth.resetPassword.fields.password_confirmation.label')"
                        :placeholder="$t('auth.resetPassword.fields.password_confirmation.placeholder')"
                        class="mt-3"
                        editable required type="password"
                />
                <!-- Hidden email -->
                <FormInput
                        v-model="form.email"
                        :editable="false"
                        required type="email" hidden
                />
                <!-- Token -->
                <FormInput
                        v-model="form.token"
                        required type="text" hidden
                />
                <!-- Submit -->
                <Button
                        :loading="loading"
                        class="mt-3"
                        type="submit"
                        variant="primary"
                >
                    {{$t('auth.resetPassword.fields.submit.label')}}
                </Button>
            </b-form>
            <div class="mt-5">
                <router-link to="login">{{$t('auth.resetPassword.backToLogin')}}</router-link>
            </div>
            <div class="mt-3">
                <b-alert v-if="message" :show="true" variant="success"><span class="smaller">{{message}}</span></b-alert>
                <b-alert v-if="error" :show="true" variant="danger"><span class="smaller">{{error}}</span></b-alert>
            </div>
        </div>
    </b-container>
</template>

<script>
  import PageHeading from '../../components/base/typography/PageHeading';
  import FormInput from '../../components/base/input/FormInput';
  import Button from '../../components/base/button/Button';
  import NetworkAuth from '../../helper/network/NetworkAuth';

  export default {
    components: {Button, FormInput, PageHeading},
    data() {
      return {
        loading: false,
        form: {
          email: null,
          password: null,
          password_confirmation: null,
          token: null,
        },
        message: null,
        error: null,
      };
    },
    mounted() {
      // Parameters come from url in reset password email
      this.form.email = this.$route.query.email;
      this.form.token = this.$route.query.token;
    },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        this.loading = true;
        NetworkAuth.resetPassword(this.form).
            then(res => this.message = res.data.message).
            catch(err => this.error = err.response.data.message).
            then(() => this.loading = false);
      },
    },
  };
</script>

<style scoped>

</style>
