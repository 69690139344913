<template>

  <!-- Modal when creating a new campaign -->
  <b-modal id="campaign-create" :title="$t('jobPosting.campaign.create')" hide-footer centered>
    <FormInput
        v-model="campaignName"
        :placeholder="$t('campaign.create.fields.name.placeholder')"
        :description="$t('campaign.create.fields.name.description')"
        editable
    />

    <!-- Job portal -->
    <div class="mb-3">
      <b-form-group
          :label="$t('campaign.order.add.fields.jobPortal.label')"
          :description="$t('campaign.order.add.fields.jobPortal.description')"
      >
        <b-form-select
            v-model="jobPortal"
            :options="jobPortalOptions"
        />
      </b-form-group>
    </div>

    <!-- Available products -->
    <div class="mb-3">
      <div v-if="jobPortal">
        <label>{{ $t('campaign.order.add.fields.products.label') }}</label>
        <b-form-checkbox-group
            v-model="products"
            :options="productOptions"
            stacked
        />
      </div>
      <p v-else>
        <font-awesome-icon icon="exclamation-triangle"/>
        {{ $t('campaign.order.add.fields.products.message') }}
      </p>
    </div>

    <!-- Budget -->
    <b-form-group :label="$t('campaign.order.add.fields.budget.label')" class="mb-3">
      <div class="d-flex align-items-center">
        <b-form-input
            v-model="budget"
            :formatter="budgetFormatter"
            class="mr-2 budget"
        />
        <span>€</span>
      </div>
    </b-form-group>


    <!-- List -->
    <b-list-group class="mt-3 max-height-list-group">
      <b-list-group-item v-for="jp in jobPostingsSel" :key="jp.id">
        <div class="d-flex justify-content-between flex-grow-1">
          <div>
            <strong>{{ jp.title }}</strong><br>
            <span class="small">{{ $t('jobPosting.fields.customer_refnum.label') }}: {{ jp.customer_refnum }}</span>
            <span v-if="jp.refnum" class="small">| {{ $t('jobPosting.fields.refnum.label') }}: {{ jp.refnum }}</span>
          </div>
          <div class="d-flex align-items-center">
            <DeleteButtonCircle @click="onJobPostingRemove(jp)"/>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <!-- Save button -->
    <div class="d-flex justify-content-end mt-3">
      <Button
          :loading="loadingCreateCampaign"
          :disabled="jobPostingsSel.length === 0 || commonCompanyId === null"
          @click="onCreateNewCampaign"
          icon="save"
          variant="success"
      >
        {{ $t('general.button.save') }}
      </Button>
    </div>
  </b-modal>

</template>

<script>
import NetworkCampaign from '@/helper/network/NetworkCampaign'
import FormInput from '@/components/base/input/FormInput'
import DeleteButtonCircle from '@/components/base/button/DeleteButtonCircle'
import Button from '@/components/base/button/Button'
import { toaster } from '@/helper/mixins/toaster'
import NetworkJobPortal from "@/helper/network/NetworkJobPortal";

export default {
  components: { Button, DeleteButtonCircle, FormInput },
  mixins: [toaster],
  data () {
    return {
      campaignName: null,
      loadingCreateCampaign: false,
      jobPortalOptions: [],
      jobPortal: null,
      products: [],
      budget: null,
    }
  },
  computed: {
    jobPostingsSel () {
      return this.$store.state.jobPosting.jobPostingsSel
    },
    commonCompanyId () {
      if (this.jobPostingsSel.length === 0)
        return null

      // Check if all company ids are the same by putting all company ids in an array,
      // removing the duplicates and checking if there is only one element left in the array.
      const allCompanyIds = this.jobPostingsSel.map(jp => jp.company_id)
      const uniqueCompanyIds = [...new Set(allCompanyIds)]
      if (uniqueCompanyIds.length === 1)
        return uniqueCompanyIds[0]
      else
        return null
    },
    productOptions() {
      if (this.jobPortal) {
        // filter the products in the job portal
        // only keep campaign products
        return this.jobPortal.products.filter(p => {
          return p.is_campaign_product;
        }).map(p => {
          return {
            text: p.name + ' - t: ' + p.term_in_days + ' - min: ' + p.min_budget + ' €',
            value: p.id,
          };
        });
      } else {
        return [];
      }
    },
  },
  created() {
    this.getJobPortalOptions();
  },
  methods: {
    async onCreateNewCampaign () {
      try {
        this.loadingCreateCampaign = true;
        const order = {
          budget: this.budget,
          products: this.products
        }
        const res = await NetworkCampaign
            .createCampaign(
                this.campaignName,
                this.commonCompanyId,
                this.jobPostingsSel.map(jp => jp.id),
                order
            );
        this.campaignName = null;
        // Deselect all selected job postings
        this.$store.commit('jobPosting/setJobPostingsSel', [])
        this.$bvModal.hide('campaign-create')
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        this.showErrorMessageFromErr(err)
      } finally {
        this.loadingCreateCampaign = false;
      }
    },
    onJobPostingRemove (jobPosting) {
      this.$store.commit('jobPosting/removeJobPostingsSel', [jobPosting])
    },
    getJobPortalOptions() {
      NetworkJobPortal.getJobPortalsSimplified().then(res => {
        // filter the fetched job portals
        // only keep job portals with at least one campaign product
        const options = res.data.data.filter(jp => {
          let t = jp.products.filter(p => {
            return p.is_campaign_product;
          });
          return t.length != 0;
        }).map(jp => ({text: jp.name, value: jp}));

        // Sort options by name/text alphabetically
        options.sort((a, b) => {
          const aToLower = a.text.toLowerCase();
          const bToLower = b.text.toLowerCase();
          if (aToLower === bToLower)
            return 0;
          else
            return aToLower < bToLower ? -1 : 1;
        });
        this.jobPortalOptions = options;
      }).catch(err => this.showErrorMessageFromErr(err));
    },
    budgetFormatter(val) {
      if (/^\d+$/.test(val))
        return val;
      else
        return val.substr(0, val.length - 1);
    },
  },
}
</script>

<style scoped>

</style>
