<template>
  <div>
    <b-table
        :items="orders"
        :fields="fields"
        :bordered="true"
        :responsive="true"
        :hover="true"
        :tbody-tr-class="rowClass"
    >
      <!-- Cell 'Selected' -->
      <template #cell(selected)="data">
        <b-form-checkbox v-model="data.item.selected" class="mx-0 d-block"/>
      </template>
      <!-- Cell 'budget' -->
      <template #cell(budget)="data">
        <div class="d-flex">
          <b-form-input
              v-model="data.item.budget"
              :formatter="budgetFormatter"
              @input="setDirty(data.item)"
              type="number"
              class="mr-1 budget"
          />
          <div class="mt-1">€</div>
        </div>
      </template>
      <!-- Cell 'term_in_days' -->
      <template #cell(term_in_days)="data">
        <b-form-input
            v-model="data.item.term_in_days"
            @input="setDirty(data.item)"
            type="number"
        />
      </template>
      <template #cell(save)="data">
        <Button
            @click.native="onSaveOrder(data.item)"
            :loading="data.item.loading"
            variant="success"
            icon="save"
            icon-only
        />
      </template>
      <template #cell(delete)="data">
        <Button
            @click.native="confirmationDialog = true"
            :loading="data.item.loading"
            variant="danger"
            icon="trash"
            icon-only
        />
        <ConfirmationDialog
            v-model="confirmationDialog"
            :text="$t('general.confirmation.text')"
            :title="$t('general.confirmation.title')"
            @ok="onDeleteOrder(data.item)"/>
      </template>
    </b-table>
  </div>
</template>

<script>
import Button from '@/components/base/button/Button';
import ConfirmationDialog from "../dialog/ConfirmationDialog";

export default {
  components: {ConfirmationDialog, Button},
  props: {
    orders: {
      required: true,
      type: Array,
    },
    rowClass: {
      required: false,
      type: Function,
      default: function (item, type) {
        if (!item || type !== 'row') return;

        if (item.isDirty === true)
          return 'table-warning';

        if (!this.isDirty && this.commonOrderEmail) {
          if (this.commonOrderEmail === item.product.order_email)
            return 'table-success';
        }
      },
    }
  },
  data() {
    return {
      confirmationDialog: false,
      fields: [
        {
          key: 'selected',
          label: '',
        },
        {
          key: 'product.jobportal',
          label: this.$t('general.component.orders.fields.product.jobportal.label'),
        },
        {
          key: 'product.name',
          label: this.$t('general.component.orders.fields.product.name.label'),
        },
        {
          key: 'budget',
          label: this.$t('general.component.orders.fields.budget.label'),
        },
        {
          key: 'term_in_days',
          label: this.$t('general.component.orders.fields.term_in_days.label'),
        },
        {
          key: 'activated_at',
          label: this.$t('general.component.orders.fields.activated_at.label'),
        },
        {
          key: 'ordered_at',
          label: this.$t('general.component.orders.fields.ordered_at.label'),
        },
        {
          key: 'save',
          label: this.$t('general.component.orders.fields.save.label'),
        },
        {
          key: 'delete',
          label: this.$t('general.component.orders.fields.delete.label')
        }
      ],
    };
  },
  computed: {
    ordersSelected() {
      return this.orders.filter(order => order.selected === true);
    },
    /**
     * If only products with the same order_email are selected, the email order is possible.
     */
    commonOrderEmail() {
      const orderEmails = this.ordersSelected.map(item => item.product.order_email);
      // Answer from stackoverflow:
      // https://stackoverflow.com/a/35568895/5371227
      const allOrderEmailsAreEqual = orderEmails.every((orderEmail, i, arr) => orderEmail === arr[0]);
      if (allOrderEmailsAreEqual)
        return orderEmails[0];
      else
        return null;
    },
  },
  methods: {
    onSaveOrder(order) {
      this.$emit('order:save', order);
    },
    onDeleteOrder(order) {
      this.$emit('order:delete', order);
    },
    setDirty(order) {
      this.$set(order, 'isDirty', true);
    },
    budgetFormatter(val) {
      if (val === '') return null;

      if (!/^\d+$/.test(val))
        val = val.substr(val.length - 1);

      return parseInt(val);
    },
  },
};
</script>

<style scoped>
.budget {
  min-width: 100px;
}
</style>
