<template>
  <b-modal size="lg" v-model="modelHelper" :title="title" :ok-disabled="!confirm"
           :ok-title="okTitel !== null ? okTitel : $t('yes')" :cancel-title="$t('cancel')" @ok="handleOk">
    <p v-html="text"/>

    <b-form-checkbox v-model="confirm">
      {{ $t('yes') }}
    </b-form-checkbox>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    title: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String,
    },
    okTitel: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      confirm: false
    }
  },
  methods: {
    handleOk() {
      this.$emit("ok")
    }
  },
  computed: {
    modelHelper: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}

</script>

<style scoped>

</style>
