<template>
    <ViewContainer id="company">
        <ViewToggle :show-detailed-view="selectedItem">
            <!-- Main View -->
            <template slot="main-view">
                <ViewElement class="mb-3">
                    <!-- Page Heading -->
                    <PageHeading>{{$t('catalogue.pageHeading')}}</PageHeading>
                    <!-- Controls -->
                    <b-row>
                        <!-- Pagination -->
                        <b-col class="flex-grow-0">
                            <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalItems"
                                    :per-page="perPage"

                            />
                        </b-col>
                        <!-- Per page-->
                        <b-col class="flex-grow-0 mb-2">
                          <PerPagePicker v-model="perPage"/>
                        </b-col>
                      <b-col cols="auto">
                        <RemoveFilterButton v-model="filter"/>
                      </b-col>
                    </b-row>
                    <!-- Table -->
                    <b-table
                            :items="items"
                            :fields="fields"
                            :responsive="true"
                            :bordered="true"
                            :hover="true"
                            :show-empty="true"
                            :busy.sync="isBusy"
                            :filter.sync="filter"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :no-local-sorting="true"
                            :tbody-tr-class="rowClass"
                            @row-clicked="onRowClicked"
                            ref="dataTable"
                    >
                      <template #empty>
                        <span class="text-black-50">{{ $t('catalogue.no_data') }}</span>
                      </template>
                      <!-- Loading -->
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>{{ $t('catalogue.loading') }}</strong>
                        </div>
                      </template>
                        <!-- Filters -->
                        <template #thead-top="data">
                            <b-tr>
                                <b-th colspan="1" variant="secondary"/>
                                <!-- Loop through filters defined in data -->
                                <b-th variant="secondary">
                                    <b-form-input
                                            v-model="filter.name"
                                            :placeholder="$t('general.filter.name')"
                                            type="search"

                                            debounce="700"
                                    />
                                </b-th>
                            </b-tr>
                        </template>
                        <!-- Head 'Selected' -->
                        <template #head(selected)>
                            <b-form-checkbox class="mx-0 d-block" @change="onToggleAll"/>
                        </template>
                        <!-- Cell 'Selected' -->
                        <template #cell(selected)="data">
                            <b-form-checkbox v-model="data.item[data.field.key]" class="mx-0 d-block"/>
                        </template>
                    </b-table>
                    <!-- Controls -->
                    <Button
                            :loading="loadingDelete"
                            :disabled="selectedItems.length === 0"
                            @click.native="onDelete"
                            variant="danger"
                            icon="trash"
                    >
                        {{$t('general.button.delete')}}
                    </Button>
                </ViewElement>
                <!-- Create Catalogue -->
                <ViewElement class="mb-3">
                    <CatalogueCreate
                            @item:created="onItemCreated"
                    />
                </ViewElement>
            </template>
            <template slot="detailed-view">
                <!-- Detail -->
                <ViewElement>
                    <CatalogueDetail
                            :catalogue="selectedItem"
                            @item:closed="onItemClosed"
                            @item:saved="onItemSaved"
                            @productsChanged="onProductsChanged"
                    />
                </ViewElement>
            </template>
        </ViewToggle>
    </ViewContainer>
</template>

<script>
  import {paginate} from '../../../helper/mixins/paginate';
  import ViewContainer from '../../../components/base/ViewContainer';
  import ViewToggle from '../../../components/base/ViewToggle';
  import ViewElement from '../../../components/base/ViewElement';
  import PageHeading from '../../../components/base/typography/PageHeading';
  import NetworkCatalogue from '../../../helper/network/NetworkCatalogue';
  import CatalogueDetail from './CatalogueDetail';
  import CatalogueCreate from './CatalogueCreate';
  import Button from '../../../components/base/button/Button';
  import {toaster} from '../../../helper/mixins/toaster';
  import RemoveFilterButton from "../../../components/base/button/RemoveFilterButton";
  import PerPagePicker from '@/components/PerPagePicker'

  export default {
    components: {
      PerPagePicker,
      RemoveFilterButton,
      Button, CatalogueCreate, CatalogueDetail, PageHeading, ViewElement, ViewToggle, ViewContainer},
    mixins: [paginate, toaster],
    data() {
      return {
        // Table data
        fields: [
          {
            key: 'selected',
            class: 'checkbox-col',
          },
          {
            key: 'name',
            label: this.$t('catalogue.fields.name'),
            sortable: true,
          },
        ],
        // Item handling
        items: [],
        selectedItem: null,
        // Busy state
        isBusy: false,
        // Loading
        loadingDelete: false,
      };
    },
    computed: {
      selectedItems() {
        return this.items.filter(item => item.selected === true);
      },
    },
    methods: {
      async fetchData() {
        this.isBusy = true;
        this.items = await NetworkCatalogue.getCatalogues({params: this.getQuery()}).
            then(res => {
              this.totalItems = res.data.meta.total;
              this.isBusy = false;
              this.updateRoute();
              return res.data.data;
            });
      },
      onRowClicked(item) {
        if (this.selectedItem && this.selectedItem.id === item.id) {
          this.selectedItem = null;
        } else {
          this.isBusy = true;
          NetworkCatalogue.getCatalogue(item).
              then(res => this.selectedItem = res.data.data).
              catch(err => console.log(err)).
              then(() => this.isBusy = false);
        }
      },
      onItemClosed() {
        this.selectedItem = null;
      },
      onItemSaved(itemSaved) {
        const indexToUpdate = this.items.findIndex(item => item.id === itemSaved.id);
        this.items.splice(indexToUpdate, 1, Object.assign({}, itemSaved));
      },
      onItemCreated(item) {
        this.fetchData();
        this.selectedItem = item;
      },
      /**
       * This callback is triggered when the products are reordered or one product is removed in the child component.
       * The selectedItem is then updated, which triggers a rerender in the child component
       * since a property has changed.
       *
       * This is especially important for the products to be draggable.
       * Without this procedure the products will not be draggable.
       * @param products
       */
      onProductsChanged(products) {
        this.$set(this.selectedItem, 'products', products);
      },
      onDelete() {
        this.loadingDelete = true;
        const catalogueIds = this.selectedItems.map(item => item.id);
        NetworkCatalogue.deleteMultipleCatalogues(catalogueIds).
            then(() => {
              this.showMessage({
                title: this.$t('general.toast.title.success'),
                message: this.$t('general.toast.delete.success'),
                variant: 'success',
              });
              this.fetchData();
            }).
            catch(err => this.showErrorMessageFromErr(err)).
            then(() => this.loadingDelete = false);
      },
      onToggleAll(checked) {
        for (const item of this.items) {
          this.$set(item, 'selected', checked);
        }
      },
      rowClass(item, type) {
        if (!item || type !== 'row') return;
        if (this.selectedItem && item.id === this.selectedItem.id) return 'selected';
      },
    },
  };
</script>

<style scoped>
</style>
