<template>
  <div class="view-element bg-white p-4 shadow" :class="{'loading': loading}">
    <slot/>
  </div>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

.view-element {
  border-radius: 0.5rem;
  @include transition;
}

.view-element.loading {
  opacity: 0.5;
}
</style>
