<template>
  <div>
    <!-- Input field depending on given props -->
    <b-form-group :label="label" :description="description" :label-size="size" class="m-0">

      <!-- Date Field -->
      <b-form-datepicker
          v-if="date"
          :value="value"
          :style="{minWidth: (minWidth ? minWidth : 100) + 'px'}"
          :placeholder="placeholder"
          :size="size"
          :disabled="!editable"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :locale="locale"
          @input="updateValue"
      />

      <!-- Selectable -->
      <b-form-select
          v-else-if="selectable"
          :disabled="disabled"
          :style="{minWidth: (minWidth ? minWidth : 100) + 'px'}"
          :options="options"
          :state="state"
          :value="value"
          :required="required"
          :size="size"
          @input="updateValue"
      />

      <!-- Checkbox -->
      <b-form-checkbox
          v-else-if="checkable"
          v-model="checkboxValueComputed"
          :state="state"
          :indeterminate="indeterminate"
          :disabled="disabled"
          :unchecked-value="0"
          :value="1"
          :required="required"
          @input="updateValue">
        {{ text }}
      </b-form-checkbox>

      <!-- Textarea -->
      <b-textarea
          v-else-if="textarea"
          :value="value"
          :state="state"
          :disabled="disabled || !editable"
          :placeholder="placeholder"
          :size="size"
          :required="required"
          :rows="rows"
          @input="updateValue"
      />

      <!-- Editable / Clickable / Static -->
      <b-form-input
          v-else
          :disabled="disabled || !editable"
          :style="{minWidth: (minWidth ? minWidth : 100) + 'px'}"
          :class="{'clickable': clickable}"
          :state="state"
          :type="type"
          :value="value"
          :placeholder="placeholder"
          :required="required"
          :size="size"
          @input="updateValue"
      />

      <Error :errors="errors" :error-field-name="errorFieldName"/>
    </b-form-group>
  </div>
</template>

<script>
import Error from '../error/Error'

export default {
  components: { Error },
  props: {
    value: { default: null },
    errorFieldName: { default: null },
    disabled: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: 'de'
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
    },
    minWidth: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    // For textarea only
    rows: {
      type: [Number],
      default: 5,
    }
  },
  computed: {
    /**
     * Return the formatted date value.
     */
    formatted () {
      return new Date(this.value).toLocaleString().replace(/\//g, '.')
    },
    /**
     * This computed prop is necessary because the b-form-checkbox element uses the :value prop
     * to define which value a checked checkbox should have (e.g. false or 0), just like :unchecked-value
     * for unchecked checkboxes and therefore the v-model is handled differently.
     */
    checkboxValueComputed: {
      get () {
        return this.value
      },
      set () {
        this.updateValue(!this.value)
      },
    },
    /**
     * Colors the input field red if an error occurs.
     * @return {null|boolean}
     */
    state () {
      if (this.errors) {
        if (this.errors[this.errorFieldName]) {
          return false
        }
      }

      return null
    }
  },
  methods: {
    isDate (val) {
      return /\d{4}-\d{2}-\d{2}/gms.test(val)
    },
    updateValue: function (value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>
.clickable:not(:focus) {
  background-color: transparent;
  border: none;
}
</style>
