<template>
  <b-button
      :type="type"
      :variant="variant"
      :size="size"
      :disabled="disable"
      :class="{'not-clickable': loading}"
      @click="onClick"
      v-b-tooltip.hover :title="disable ? tooltipText : null"
  >
    <div class="position-relative">
      <div class="d-flex align-items-center" :class="{'transparent': loading}">
        <font-awesome-icon v-if="icon" :class="{'mr-2': !iconOnly}" :icon="icon"/>
        <span v-if="!iconOnly"><slot/></span>
      </div>
      <div v-if="loading" class="spinner-wrapper">
        <b-spinner small/>
      </div>
    </div>
  </b-button>
</template>

<script>
export default {
  props: {
    iconOnly: { type: Boolean, default: false },
    icon: { type: String },
    loading: { type: Boolean, default: false },
    type: { type: String },
    variant: { type: String },
    size: { type: String, default: 'md' },
    tooltipText: { type: String, required: false, default: null },
    disable: { type: Boolean, required: false, default: false },
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparent {
  opacity: 0;
}

.not-clickable {
  pointer-events: none;
}
</style>
