<template>
  <div id="app" class="d-flex flex-column vh-100 overflow-hidden">
    <!-- Top Nav -->
    <TopNavigation v-model="sidebarIsVisible" :show-toggle-btn="hasSidebar"/>
    <!-- Render main navigation when necessary -->
    <div class="h-100 position-relative">
      <router-view id="router-view" :class="{ 'active' : hasSidebar && sidebarIsVisible }"/>
    </div>
    <!-- Sidebar -->
    <MainNavigation v-if="hasSidebar" v-model="sidebarIsVisible" :administrator="administratorView()"/>
  </div>
</template>

<script>
import MainNavigation from './components/navigation/MainNavigation'
import TopNavigation from './components/navigation/TopNavigation'
import user from './helper/user'

export default {
  components: {
    MainNavigation, TopNavigation,
  },
  data () {
    return {
      /**
       * Determine whether sidebar is currently visible. Initial value depends on screen size (see mounted hook).
       */
      sidebarIsVisible: false,
    }
  },
  created() {
    window.addEventListener("resize", this.setSideBarVisibility);
  },
  mounted () {
    // Set initial value for sidebarIsVisible.
    this.setSideBarVisibility()
  },
  computed: {
    /**
     * Determines whether the current view has a sidebar or not.
     * @returns {boolean|*}
     */
    hasSidebar () {
      return this.$route.meta.hasSidebar
    },
  },
  methods: {
    administratorView () {
      return user.isAdministrator()
    },
    setSideBarVisibility() {
      this.sidebarIsVisible = window.innerWidth >= 991
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.setSideBarVisibility);
  },
}
</script>

<style>
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/main.scss";

#router-view {
  @include transition;
}

@include media-breakpoint-up(lg) {
  #router-view.active {
    padding-left: calc(320px + 2rem) !important;
  }
}

</style>
