import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
import {setTitle} from "./helper/routes-helper";
import {protectRoutes} from "./middleware/middlewares";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

/**
 * Protects the routes for logged in users
 */
router.beforeEach(protectRoutes);

/**
 * Change page title.
 */
router.afterEach(setTitle)

export default router;
