export const errorHelper = {
  data() {
    return {
      errors: null,
    }
  },
  methods: {
    setErrors(err) {
      this.errors = err.response.data.errors;
    },
    resetErrors() {
      this.errors = null;
    },
  }
}