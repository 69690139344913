<template>
  <div>
    <Collapsible id="collapse-job-portal-product" :visible="true" class="mb-3">
      <template slot="heading">{{ $t('jobPortal.detail.product.heading') }}</template>
      <template slot="content">
        <!-- Current products for job portal -->
        <b-list-group class="mb-3 smaller">
          <b-list-group-item
              v-for="(product, index) in productsEdit"
              :key="index"
              v-b-toggle="`product-${index}`"
              class="product"
          >
            <!-- First Row -->
            <h6 class="my-1">{{ product.name }}</h6>
            <!-- Collapse -->
            <b-collapse
                :id="`product-${index}`"
                accordion="product-accordion"
            >
              <!-- Second Row -->
              <b-row class="mt-4 mb-1">
                <!-- Is Active -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.is_active.label') }}</p>
                  <b-form-group @click.native="onItemChanged(product)" class="m-0">
                    <b-form-radio-group
                        v-model="product.is_active"
                        :options="toggleOptions"
                        button-variant="outline-primary"
                        name="radio-btn-outline"
                        buttons
                    />
                  </b-form-group>
                </b-col>
                <!-- Min. Budget -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.is_variable_term.label') }}</p>
                  <b-form-group class="m-0">
                    <b-form-radio-group
                        v-model="product.is_variable_term"
                        :options="toggleOptions"
                        @click.stop
                        button-variant="outline-primary"
                        name="radio-btn-outline"
                        buttons
                    />
                  </b-form-group>
                </b-col>
                <!-- Term in Days -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.term_in_days.label') }}</p>
                  <FormInput
                      v-model="product.term_in_days"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="term_in_days"
                      editable
                  />
                </b-col>
                <!-- Name -->
                <b-col sm="12" md="8" lg="12" xl="8" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.name.label') }}</p>
                  <FormInput
                      v-model="product.name"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="name"
                      editable
                  />
                </b-col>
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.min_budget.label') }} (€)</p>
                  <FormInput
                      v-model="product.min_budget"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="min_budget"
                      type="number"
                      editable
                  />

                </b-col>
                <!--                &lt;!&ndash; Description &ndash;&gt;-->
                <!--                <b-col sm="12" md="4" lg="12" xl="4" class="mb-1">-->
                <!--                  <p class="font-weight-bold mb-1">{{ $t('product.fields.description.label') }}</p>-->
                <!--                  <b-textarea-->
                <!--                      v-model="product.description"-->
                <!--                      @keydown="onItemChanged(product)"-->
                <!--                      @click.native.stop-->
                <!--                  />-->
                <!--                </b-col>-->
                <!-- Order Email -->
                <b-col sm="12" md="8" lg="8" xl="8" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.order_email.label') }}</p>
                  <FormInput
                      v-model="product.order_email"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="order_email"
                      editable
                  />
                </b-col>
              </b-row>
              <hr class="m-0">
              <!-- Campaign Product Row -->
              <b-row class="mt-3 mb-1">
                <!-- Is Campaign Product -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">
                    {{ $t('product.fields.is_campaign_product') }}</p>
                  <div @click="onItemChanged(product)">
                    <b-form-group class="m-0">
                      <b-form-radio-group
                          v-model="product.is_campaign_product"
                          :options="toggleOptions"
                          @click.stop
                          button-variant="outline-primary"
                          name="radio-btn-outline"
                          buttons
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <hr class="m-0">
              <!-- Third Row -->
              <b-row class="mt-3 mb-1">
                <!-- Is JOB AD PARTNER Product -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">
                    {{ $t('product.fields.is_jobadpartner_product.label') }}</p>
                  <div @click="onItemChanged(product)">
                    <b-form-group class="m-0">
                      <b-form-radio-group
                          v-model="product.is_jobadpartner_product"
                          :options="toggleOptions"
                          @click.stop
                          button-variant="outline-primary"
                          name="radio-btn-outline"
                          buttons
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <!-- JOB AD PARTNER ID -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.jobadpartner_id.label') }}</p>
                  <FormInput
                      v-model="product.jobadpartner_id"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="jobadpartner_id"
                      editable
                  />
                </b-col>
              </b-row>
              <hr class="m-0">
              <!-- Third Row -->
              <b-row class="mt-3 mb-1">
                <!-- Salesforce ID -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t("salesforce.name") }}</p>
                  <div>
                    <vue-bootstrap-typeahead
                        class="typeahead"
                        @click.native.stop
                        :data="salesforceIds"
                        :serializer="x => x.product_name"
                        @hit="product.salesforce_id = $event.product_id"
                        @input="resetInputs($event)"
                    />
                    <p class="font-weight-bold mb-1">{{ $t("salesforce.id") }}</p>
                    <div class="flex-column">
                      <FormInput
                        @click.native.stop
                        v-model="product.salesforce_id"
                        @keydown.native="onItemChanged(product)"
                        :errors="product.errors"
                        error-field-name="salesforce_id"
                      />
                      <Button @click="resetInputs(product)"
                      @click.native.stop>
                        Zurücksetzen
                      </Button>
                    </div>
                  </div>
                </b-col>
                <!-- list price -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t("salesforce.list_price") }}</p>
                  <FormInput
                      @click.native.stop
                      :value="product.salesforce !== null ? product.salesforce.list_price + ' €' : null"
                      :errors="product.errors"
                      error-field-name="list_id"
                  />
                </b-col>
                <!-- purchase cost -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t("salesforce.cost_price") }}</p>
                  <FormInput
                      @click.native.stop
                      :value="product.salesforce !== null ? product.salesforce.cost_price + ' €' : null"
                      :errors="product.errors"
                      error-field-name="cost_id"
                  />
                </b-col>
              </b-row>
              <hr class="m-0">
              <!-- Fourth Row -->
              <b-row class="mt-3 mb-1">
                <!-- Is Talentbait Product -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">
                    {{ $t('product.fields.is_talentbait_product.label') }}</p>
                  <div @click="onItemChanged(product)">
                    <b-form-group class="m-0">
                      <b-form-radio-group
                          v-model="product.is_talentbait_product"
                          :options="toggleOptions"
                          @click.stop
                          button-variant="outline-primary"
                          name="radio-btn-outline"
                          buttons
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <!-- Talentbait ID -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.talentbait_id.label') }}</p>
                  <FormInput
                      v-model="product.talentbait_id"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="talentbait_id"
                      editable
                  />
                </b-col>
                <!-- Talentbait Channels -->
                <b-col sm="12" md="4" lg="12" xl="4" class="mb-3">
                  <p class="font-weight-bold mb-1">{{ $t('product.fields.talentbait_channels.label') }}</p>
                  <FormInput
                      v-model="product.talentbait_channels"
                      @click.native.stop
                      @keydown.native="onItemChanged(product)"
                      :errors="product.errors"
                      error-field-name="talentbait_channels"
                      editable
                  />
                </b-col>
              </b-row>
              <hr class="m-0">
              <!-- Save Button Row -->
              <b-row class="mt-3">
                <b-col cols="12" class="mb-2">
                  <div class="d-flex justify-content-end align-items-center">
                    <Button
                        @click.native.stop="onItemSaved(product)"
                        :loading="product.loading"
                        variant="success"
                        icon="save"
                    >
                      {{ $t('general.button.save') }}
                    </Button>
                  </div>
                </b-col>
              </b-row>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </template>
    </Collapsible>
    <Collapsible id="collapse-job-portal-product-create">
      <template #heading>{{ $t('product.create') }}</template>
      <template slot="content">
        <b-card>
          <b-form @submit.prevent="onCreateProduct">
            <b-row>
              <!-- Name -->
              <b-col md="6" lg="4" class="mb-3">
                <FormInput
                    v-model="form.name"
                    :editable="true"
                    :label="$t(`jobPortal.fields.name.label`)"
                    :errors="errors"
                    error-field-name="name"
                />
              </b-col>
            </b-row>
            <!-- Buttons -->
            <Button icon="plus" type="submit" variant="success">
              {{ $t('general.button.create') }}
            </Button>
          </b-form>
        </b-card>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import Button from '../../../components/base/button/Button'
import NetworkProduct from '../../../helper/network/NetworkProduct'
import FormInput from '../../../components/base/input/FormInput'
import Collapsible from '../../../components/base/Collapsible'
import {toaster} from '@/helper/mixins/toaster'
import {errorHelper} from '@/helper/mixins/error-helper'
import {clone} from '@/helper/utility'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import axios from "axios";

export default {
  mixins: [toaster, errorHelper],
  components: {Collapsible, FormInput, Button, VueBootstrapTypeahead},
  data() {
    return {
      toggleOptions: [
        {text: this.$t('general.options.yes'), value: 1},
        {text: this.$t('general.options.no'), value: 0},
      ],
      filter: null,
      // Salesforce IDs for autocomplete
      salesforceIds: [],
      // Form for creating a new product
      form: {},
      productsEdit: [],
    }
  },
  created() {
    this.setEditableData()
    this.fetchSalesforceItems()
  },
  watch: {
    selJobPortal() {
      this.resetErrors()
      this.setEditableData()
    },
  },
  computed: {
    selJobPortal() {
      return this.$store.state.jobPortal.selJobPortal
    },
  },
  methods: {
    resetInputs(product) {
      product.salesforce_id = "";
    },
    async fetchSalesforceItems() {
      const products = await axios.get('/api/c/salesforce/products');
      this.salesforceIds = products.data;
    },
    setEditableData() {
      this.productsEdit = clone(this.selJobPortal.products)
    },
    async onItemSaved(item) {
      try {
        this.$set(item, 'loading', true)
        if (!item.min_budget) delete item.min_budget
        const res = await NetworkProduct.updateProduct(item)
        // Tell the store the product is no longer dirty because it was saved
        this.$store.commit('jobPortal/removeProductDirty', item)
        // Handle toast
        this.showSuccessMessageFromRes(res)
        // update UI
        let jobPortal = this.selJobPortal;
        jobPortal.products = this.productsEdit
        await this.$store.dispatch('jobPortal/fetchJobPortal', { jobPortal })
        // Handle error for item
        this.setErrorForSpecificItem(item, null)
      } catch (err) {
        this.showErrorMessageFromErr(err)
        this.setErrorForSpecificItem(item, err)
      } finally {
        this.$set(item, 'loading', false)
      }
    },
    onItemChanged(product) {
      this.$store.commit('jobPortal/addProductDirty', product)
    },
    async onCreateProduct() {
      try {
        const res = await NetworkProduct.createProduct(this.selJobPortal, this.form)
        this.showSuccessMessageFromRes(res)
        this.resetErrors()
        // Product
        const product = res.data.data
        this.productsEdit.push(product)
        // Reset form
        this.form.name = null
      } catch (err) {
        this.setErrors(err)
      }
    },
    formatBudget(value) {
      const isDigitsOnly = /^\d+$/.test(value)
      const isValidLength = value.length <= 5
      if (!isDigitsOnly) {
        return value.substr(0, value.length - 1)
      }
      if (!isValidLength) {
        return value.substr(0, value.length - 1)
      }
      return value
    },
    /**
     * Set error for specific item. Err can as well be null.
     * @param item
     * @param err
     */
    setErrorForSpecificItem(item, err) {
      const prod = this.productsEdit.find(p => p.id === item.id)
      prod.errors = err === null ? null : err.response.data.errors
    },
    setSalesForceId(x) {
      console.log(x);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/custom_variables.scss';

.typeahead {
  margin-bottom: 1rem;
}

.product {
  cursor: pointer;
  @include transition;

  &:hover {
    background-color: rgba(0, 0, 0, .025);
  }
}
</style>
