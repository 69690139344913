<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div>
        <h1>404 Error</h1>
        <p>Sorry, we couldn't find the page you were looking for :(</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped>

</style>
