import NetworkIndeedBudget from "@/helper/network/NetworkIndeedBudget";
import { IndeedBudgetState, IndeedSnapshot, IndeedSnapshotOverview, IndeedSubaccount } from "@/types/indeed-budget";

export default {
  namespaced: true,

  state: {
    snapshotsOverview: [],
    snapshots: [],
    subaccounts: []
  } as IndeedBudgetState,

  mutations: {
    setSnapshotsOverview: (state: IndeedBudgetState, snapshotsOverview: IndeedSnapshotOverview[]) => {
      state.snapshotsOverview = snapshotsOverview;
    },

    setSnapshots: (state: IndeedBudgetState, snapshots: IndeedSnapshot[]) => {
      state.snapshots = snapshots;
    },

    setSubaccounts: (state: IndeedBudgetState, subaccounts: IndeedSubaccount[]) => {
      state.subaccounts = subaccounts;
    }
  },

  actions: {
    async fetchSubaccounts({ commit }: { commit: any}) {
      let subaccounts: {data: {data: IndeedSubaccount[]}}

      try {
        subaccounts = await NetworkIndeedBudget.getIndeedBudgetSubaccounts();
      } catch (e) {
        console.error(e);
        return;
      }

      commit('setSubaccounts', subaccounts.data.data);
    },

    async fetch(
      { commit }: { commit: any },
      { start, end, overview }: { start: Date, end: Date, overview: boolean}
    ) {
      let response: {data: {data: IndeedSnapshot[]|IndeedSnapshotOverview[]}};

      try {
        if (overview) {
          response = await NetworkIndeedBudget.getIndeedBudgetSnapshotsOverview(start, end);
        } else {
          response = await NetworkIndeedBudget.getIndeedBudgetSnapshots(start, end);
        }
      } catch (e) {
        console.error(e);
        return;
      }

      commit(
        overview ? 'setSnapshotsOverview' : 'setSnapshots',
        response.data.data
      );
    },
  },

  getters: {
    latestSnapshotOverview: (state: IndeedBudgetState) => {
      if (state.snapshotsOverview.length > 0) {
        return state.snapshotsOverview[state.snapshotsOverview.length - 1];
      } else {
        return [];
      }
    }
  }
}
