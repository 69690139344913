<template>
  <b-sidebar
      v-model="modelHelper"
      :no-close-on-route-change="noCloseOnRouteChange"
      id="main-navigation"
      bg-variant="dark"
      text-variant="light"
      shadow="lg"
  >
    <div class="px-3 pb-2 pt-5">
      <NavLink to="user" icon="user" class="mb-2" @click="closeAll">{{ $t('general.navigation.user') }}</NavLink>
      <NavLink to="company" icon="building" class="mb-2" @click="closeAll">{{
          $t('general.navigation.company')
        }}
      </NavLink>
      <NavLinkToggle v-if="system === 'automate'" v-model="productsVisible" icon="shopping-bag" toggle>{{ $t('general.navigation.product') }}
        <template #dropdown>
          <div @click.prevent.stop>
            <NavLink to="category" icon="minus" class="mb-2" @click="closeOtherThan('products')">
              {{ $t('general.navigation.category') }}
            </NavLink>
            <NavLink to="jobportal" icon="minus" class="mb-2" @click="closeOtherThan('products')">
              {{ $t('general.navigation.jobPortal') }}
            </NavLink>
            <NavLink to="catalogue" icon="minus" class="mb-2" @click="closeOtherThan('products')">
              {{ $t('general.navigation.catalogue') }}
            </NavLink>
            <NavLink to="order" icon="minus" class="mb-2" @click="closeOtherThan('products')">{{
                $t('general.navigation.order')
              }}
            </NavLink>
          </div>
        </template>
      </NavLinkToggle>
      <NavLinkToggle v-model="jobPostingsVisible" icon="tag" toggle>{{ $t('general.navigation.jobPosting') }}
        <template #dropdown>
          <div @click.prevent.stop>
            <NavLink to="jobposting" icon="minus" class="mb-2" @click="closeOtherThan('jobPostings')">
              {{ $t('general.navigation.jobPosting') }}
            </NavLink>
            <NavLink v-if="system === 'automate'" to="campaign" icon="minus" class="mb-2" @click="closeOtherThan('jobPostings')">
              {{ $t('general.navigation.campaign') }}
            </NavLink>
            <NavLink to="crawler" icon="minus" class="mb-2" @click="closeOtherThan('jobPostings')">
              {{ $t('general.navigation.crawler') }}
            </NavLink>
            <NavLink v-if="system === 'automate'" to="xmlfeed" icon="minus" class="mb-2" @click="closeOtherThan('jobPostings')">
              {{ $t('general.navigation.xmlFeed') }}
            </NavLink>
          </div>
        </template>
      </NavLinkToggle>

      <NavLinkToggle
        v-if="system === 'automate'"
        v-model="statisticsVisible"
        icon="chart-bar"
        toggle
      >
        {{ $t('general.navigation.statistic') }}

        <template #dropdown>
          <div @click.prevent.stop>

            <NavLink
              to="indeed-budget"
              icon="minus"
              class="mb-2"
              @click="closeOtherThan('statistics')"
            >
              {{$t('general.navigation.indeedBudget')}}
            </NavLink>
          </div>
        </template>
      </NavLinkToggle>

      <NavLink
        :to="{name: 'log', query: {sortBy: 'created_at', sortDir: 'desc'}}"
        icon="clipboard"
        class="mb-2"
        v-if="system === 'automate'"
        @click="closeAll"
      >
        {{$t('general.navigation.log')}}
      </NavLink>

    </div>
    <div v-if="user">
      <hr class="hr">
      <div class="px-5 py-2 email">{{ user.email }}</div>
    </div>
  </b-sidebar>
</template>

<script>
import NetworkAuth from '@/helper/network/NetworkAuth'
import NavLink from '@/components/navigation/NavLink'
import NavLinkToggle from '@/components/navigation/NavLinkToggle'

export default {
  components: { NavLinkToggle, NavLink },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    administrator: {
      type: Boolean,
      default: false,
    },
    activePage: {
      type: Boolean,
      default: false,
    },
    sidebarIsVisible: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      productsVisible: false,
      jobPostingsVisible: false,
      statisticsVisible: false,
      user: null,
      system: process.env.VUE_APP_SYSTEM,
      noCloseOnRouteChange: false
    }
  },
  computed: {
    modelHelper: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  async created () {
    window.addEventListener("resize", this.setNoCloseOnRouteChange);
    this.setNoCloseOnRouteChange()
    try {
      const res = await NetworkAuth.getUserdata()
      this.user = res.data.data
    } catch (err) {
      console.error(err)
    }
  },
  methods: {
    closeOtherThan (navItem) {
      switch (navItem) {
        case 'products':
          this.jobPostingsVisible = false
          this.statisticsVisible = false
          break
        case 'jobPostings':
          this.productsVisible = false
          this.statisticsVisible = false
          break
        case 'statistics':
          this.jobPostingsVisible = false
          this.productsVisible = false
      }
    },
    setNoCloseOnRouteChange() {
      this.noCloseOnRouteChange = window.innerWidth >= 991
    },
    closeAll () {
      this.productsVisible = false
      this.jobPostingsVisible = false
      this.statisticsVisible = false
    },
    destroyed() {
      window.removeEventListener("resize", this.setNoCloseOnRouteChange);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

#main-navigation {
  background-color: $dark !important;

  .email {
    color: $light-gray;
  }

  hr {
    border: none;
    height: 1px;
    background-color: rgba(255, 255, 255, .5);
    margin-left: 5%;
    margin-right: 5%;
  }
}

</style>
