
















































import Vue from "vue";
import { IndeedSnapshotOverview } from "@/types/indeed-budget";
import { clicksFormatter, costsFormatter, cpcFormatter, timestampFormatter } from "./../helpers/formatters";
import InfoBox from "./InfoBox.vue";

export default Vue.extend({
  components: { InfoBox },

    computed: {
        latestSnapshotOverview(): IndeedSnapshotOverview {
            return this.$store.getters["indeedBudget/latestSnapshotOverview"];
        },
    },

    methods: {
        clicksFormatter: clicksFormatter,
        costsFormatter: costsFormatter,
        cpcFormatter: cpcFormatter,
        timestampFormatter: timestampFormatter
    },
});
