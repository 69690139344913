import axios from 'axios'
import NetworkHelper from './NetworkHelper'

export default class NetworkJobPosting extends NetworkHelper {

    static getMails(jobPosting, config) {
        return axios.get(`/api/jobposting/${jobPosting.id}/emails`, config)
    }

    // All job postings

    static getJobPostings(config) {
        return axios.get('/api/jobposting', config)
    }

    // Detailed job postings

    static getJobPosting(jobPosting, config) {
        return axios.get(`/api/jobposting/${jobPosting.id}`, config)
    }

    static createJobPosting(jobPosting, config) {
        return axios.post(`/api/company/${jobPosting.company_id}/jobposting`,
            jobPosting, config)
    }

    static copyJobPosting(jobPosting, copyWithProducts, config) {
        return axios.post(`/api/jobposting/${jobPosting.id}/copy`,
            {with_products: copyWithProducts},
            config)
    }

    static updateJobPosting(jobPosting, config) {
        return axios.put(`/api/jobposting/${jobPosting.id}`, jobPosting, config)
    }

    // Batch Processing

    static updateJobPostings(jobPostingIds, jobPostingData, config) {
        return axios.put(`/api/jobposting/batch`, {
            jobposting_data: jobPostingData,
            jobposting_ids: jobPostingIds
        }, config)
    }

    static addCategories(jobPostingIds, categoriesToAdd, config) {
        return axios.put("/api/jobposting/categories", {
            categories_to_add: categoriesToAdd,
            jobposting_ids: jobPostingIds
        }, config)
    }

    static deleteCategories(jobPostingIds, categoriesToDelete, config) {
        return axios.delete("/api/jobposting/categories",
            {data: {categories_to_delete: categoriesToDelete, jobposting_ids: jobPostingIds}, ...config})
    }

    static deleteMultipleJobPostings(jobPostings, config) {
        return axios.delete(`/api/jobposting`,
            {data: {jobpostings: jobPostings}, ...config})
    }

    // Job posting status

    static getJobPostingStatuses(config) {
        return axios.get('/api/jobpostingstatus', config)
    }

    static updateJobPostingStatus(jobPosting, config) {
        return axios.put(`/api/jobposting/${jobPosting.id}/status`,
            {status: jobPosting.status.name}, config)
    }

    static updateJobPostingsStatuses(jobPostings, status, config) {
        return axios.put(`/api/jobposting/statuses`, {
            jobpostings: jobPostings.map(jp => jp.id),
            status: status,
        }, config)
    }

    static updateJobPostingUTMParameters(jobPosting, config) {
        return axios.post(`/api/utmparameter`, {
            jobposting_id: jobPosting.id,
            utm_parameters: jobPosting.utm_parameters
        }, config)
    }

    // Job posting customer visibility

    static updateCustomerVisibility(jobPostings, visibility, config) {
        return axios.put(
            `/api/jobposting/visibilities`,
            {jobpostings: jobPostings, visibility: visibility},
            config
        );
    }


    // Job posting files

    static getJobPostingFiles(jobPosting, config) {
        return axios.get(`/api/jobposting/${jobPosting.id}/file`, config)
    }

    static getJobPostingFile(jobPosting, file, config) {
        return axios.get(`/api/jobposting/${jobPosting.id}/file/${file.filename}`,
            config)
    }

    static uploadFile(jobPosting, formData, userEmail, config) {
        formData.append('user_uploaded', userEmail)
        formData.append('_method', 'PUT')
        return axios.post(`/api/jobposting/${jobPosting.id}/file`, formData,
            config)
    }

    static updateHTMLFile(jobPosting, file, html, userEmail, config) {
        return axios.post(`/api/jobposting/${jobPosting.id}/file`, {
            filename: file.filename,
            data: html,
            user_uploaded: userEmail,
        }, config)
    }

    static deleteFile(jobPosting, file, config) {
        return axios.delete(
            `/api/jobposting/${jobPosting.id}/file/${file.filename}`, config)
    }

    static downloadFilesZipped(jobPosting, config) {
        // Code from here:
        // https://jetrockets.pro/blog/l8dadq8oac-how-to-download-files-with-axios
        axios.request({
            url: `/api/jobposting/${jobPosting.id}/file/zip`,
            method: 'GET',
            responseType: 'blob', //important
            ...config,
        }).then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', 'jobposting.zip') //any other extension
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
    }

    // Orders

    static createOrderForJobPosting(jobPosting, order, config) {
        return axios.post(`/api/jobposting/${jobPosting.id}/order`, order, config)
    }

    // Company

    static changeAssociatedCompany(jobPosting, company, config) {
        return axios.put(
            `/api/jobposting/${jobPosting.id}/company`,
            {company_id: company.id},
            config,
        )
    }

    // Jass

    static updateJassCustomerIds(companyId, jassIds, config) {
        return axios.post(`/api/jasscustomerid`,
            {company_id: companyId, jass_customer_ids: jassIds}, config);
    }

    static getJassCustomerIds(companyId, config) {
        return axios.get(`/api/jasscustomerid?company=${companyId}`, config);
    }
}
