<template>
  <div class="status">
    <b-badge pill :variant="getVariantForStatus(status)">{{ status }}</b-badge>
  </div>
</template>

<script>
import variant from '@/helper/mixins/variant';

export default {
  props: {
    status: {
      required: true,
      type: String,
    },
  },
  mixins: [variant],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.status {
  font-size: $text-bigger;
}
</style>
