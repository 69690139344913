












import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    }
  }
});
