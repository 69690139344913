<template>
  <div id="campaign-detail">
    <Collapsible id="toggle-company-detail" :visible="true">
      <template slot="heading">{{ $t('campaign.detail.heading') }}</template>
      <template slot="content">
        <div class="mb-4">
          <FormGroup :label="$t('campaign.fields.id.label')">
            <FormInput v-model="campaignComputed.id"/>
          </FormGroup>
          <FormGroup :label="$t('campaign.fields.name.label')">
            <FormInput
                v-model="campaignComputed.name"
                :errors="errors"
                error-field-name="name"
                editable
            />
          </FormGroup>
          <FormGroup :label="$t('campaign_categories')">
            <FormInput
                v-model="campaignComputed.campaign_categories"
                :errors="errors"
                error-field-name="campaign_categories"
                editable
            />
          </FormGroup>
          <FormGroup :label="$t('campaign.fields.comment.label')">
            <FormInput
                v-model="campaignComputed.comment"
                :errors="errors"
                error-field-name="comment"
                textarea editable
            />
          </FormGroup>
          <FormGroup :label="$t('campaign.fields.visible_for_customer.label')">
            <FormInput
                v-model="campaignComputed.visible_for_customer"
                :errors="errors"
                error-field-name="visible_for_customer"
                checkable editable
            />
          </FormGroup>

          <FormGroup :label="$t('manageable_by_customer')">
            <FormInput
                v-model="campaignComputed.manageable_by_customer"
                :errors="errors"
                error-field-name="manageable_by_customer"
                checkable editable
            />
          </FormGroup>
          <FormGroup :label="$t('campaign.fields.created_at.label')">
            <FormInput v-model="campaignComputed.created_at"/>
          </FormGroup>
          <FormGroup :label="$t('campaign.fields.updated_at.label')">
            <FormInput v-model="campaignComputed.updated_at"/>
          </FormGroup>
        </div>
        <!-- Footer -->
        <div class="d-flex align-items-center justify-content-between">
          <Button icon="times" @click.native="onClose">{{ $t('general.button.cancel') }}</Button>
          <Button
              @click.native="onSave"
              :loading="loading"
              icon="save"
              variant="success">
            {{ $t('general.button.save') }}
          </Button>
        </div>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import Collapsible from '../../../components/base/Collapsible';
import FormGroup from '../../../components/base/input/FormGroup';
import FormInput from '../../../components/base/input/FormInput';
import {toaster} from '@/helper/mixins/toaster';
import {errorHelper} from '@/helper/mixins/error-helper';
import Button from '../../../components/base/button/Button';
import NetworkCampaign from "../../../helper/network/NetworkCampaign";

export default {
  components: {Button, FormInput, FormGroup, Collapsible},
  mixins: [toaster, errorHelper],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.selectedItem
    },
    campaignComputed() {
      return Object.assign({}, this.campaign);
    },
  },
  watch: {
    campaign() {
      this.resetErrors();
    },
  },
  methods: {
    async onSave() {
      this.loading = true
      try {
        const res = await NetworkCampaign.updateCampaign(this.campaignComputed)
        this.$store.commit('campaign/setSelectedItem', res.data.data)
        this.showSuccessMessageFromRes(res)
      } catch (err) {
        console.error(err)
        this.showErrorMessageFromErr(err)
      } finally {
        this.loading = false
      }
    },
    onClose() {
      this.loading = true
      this.$store.commit('campaign/setSelectedItem', null)
      this.loading = false
    },
  }
};
</script>

<style scoped>

</style>
