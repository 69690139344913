import NetworkHelper from "./NetworkHelper";
import axios from "axios";

export default class NetworkCountry extends NetworkHelper {

    static getCountries(headers, query) {
        return axios.get('/api/country',
            {headers: this.buildHeaders(headers), params: query});
    }

}
