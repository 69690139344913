import axios from 'axios';
import NetworkHelper from './NetworkHelper';

export default class NetworkBooking extends NetworkHelper {

  static placeEmailOrderForJobPosting(order, config) {
    return axios.post(`/api/booking/email`, order, config);
  }

  static placeApiOrderForJobPosting(orderIds, jassId, comment, config) {
    return axios.post(`/api/booking/jass`, { orders: orderIds, jass_id: jassId, comment:  comment}, config);
  }

  static activateOrders(orders, config) {
    return axios.post(`/api/booking/activate`, {
      orders: orders.map(order => order.id)
    }, config);
  }

  static activateCampaignOrders(orders, config) {
    return axios.post(`/api/booking/activateCampaign`, {
      orders: orders.map(order => order.id)
    }, config);
  }

}

