<template>
  <div>
    <b-table-simple borderless class="mb-0">
      <b-thead>
        <b-tr>
          <b-th>{{ $t('utmParameter.key') }}</b-th>
          <b-th>{{ $t('utmParameter.value') }}</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Current params -->
        <b-tr v-for="(param, index) in modelHelper" :key="param.id">
          <b-td>
            <b-form-input v-model="param.key"/>
          </b-td>
          <b-td>
            <b-form-input v-model="param.value"/>
          </b-td>
          <b-td class="align-middle">
            <Button @click="deleteParameter(index)" icon="trash" icon-only variant="danger"/>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <b-form-input v-model="newParam.key" @keydown.enter="addParameter" ref="inputKey"/>
          </b-td>
          <b-td>
            <b-form-input v-model="newParam.value" @keydown.enter="addParameter"/>
          </b-td>
          <b-td class="align-middle">
            <Button @click="addParameter" icon="plus" icon-only variant="success"/>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- Current query parameters -->
    <div v-if="paramsPreview" class="p-3 text-secondary">
      <p class="font-weight-bold mb-1">{{ $t('preview')}}</p>
      <span>{{ paramsPreview }}</span>
    </div>
  </div>
</template>

<script>
import Button from './base/button/Button'

export default {
  components: { Button },
  props: {
    value: {
      required: false,
      type: Array,
    },
  },
  data () {
    return {
      newParam: { key: null, value: null },
    }
  },
  computed: {
    modelHelper: {
      get () {
        return this.value
      },
      set (params) {
        this.$emit('input', params)
      },
    },
    paramsPreview () {
      const paramsStr = this.modelHelper.map(param => `${param.key}=${param.value}`).join('&')
      return encodeURI(paramsStr)
    },
  },
  methods: {
    addParameter () {
      this.$emit('input', [...this.modelHelper, { id: null, ...this.newParam }]) // TODO: Make id not required when saving utm params in api
      this.newParam.key = null
      this.newParam.value = null
      this.$refs.inputKey.$el.focus()
    },
    deleteParameter (index) {
      this.modelHelper.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
