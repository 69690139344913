export default {
    namespaced: true,
    state: {
        selectedCrawler: null
    },
    mutations: {
        setSelectedCrawler: (state, crawler) => state.selectedCrawler = crawler,
    },
    actions: {},
    getters: {},
}
